import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { CalendarMonth, Assignment, Search } from '@mui/icons-material';
import TaskChat from './TaskChat';
import InterviewChat from './InterviewChat';

interface CommandBarProps {
  matterId: string;
}

const CommandBar = ({ matterId }: CommandBarProps) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [chatType, setChatType] = useState<'task' | 'interview' | ''>('');

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Ctrl+K or Cmd+K
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        event.preventDefault();
        setOpen(true);
      } else if (event.key === 'Escape' && open) {
        setOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [inputValue]);

  const handleClose = () => {
    setOpen(false);
    setInputValue('');
    setSelectedIndex(0);
  };

  const handleCloseChat = () => {
    setShowChat(false);
    setChatType('');
  };

  const commandOptions = [
    {
      icon: <CalendarMonth fontSize="small" />,
      text: 'Schedule an interview',
      action: () => {
        handleClose();
        setChatType('interview');
        setShowChat(true);
      },
    },
    {
      icon: <Assignment fontSize="small" />,
      text: 'Create a task',
      action: () => {
        handleClose();
        setChatType('task');
        setShowChat(true);
      },
    },
  ];

  const filteredOptions =
    inputValue.length > 0
      ? commandOptions.filter((option) =>
          option.text.toLowerCase().includes(inputValue.toLowerCase())
        )
      : commandOptions;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && filteredOptions.length > 0) {
      filteredOptions[selectedIndex].action();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  return (
    <>
      <Dialog
        open={open && !showChat}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: '8px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            maxHeight: '60vh',
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ p: 2, pb: inputValue.length > 0 ? 1 : 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Search sx={{ color: 'text.secondary', mr: 1, opacity: 0.7 }} />
              <TextField
                autoFocus
                fullWidth
                placeholder="What would you like to do?"
                variant="standard"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: 'off',
                  sx: {
                    fontSize: '1.25rem',
                    '::placeholder': {
                      color: 'text.primary',
                      opacity: 0.8,
                    },
                  },
                }}
              />
            </Box>
            {inputValue.length === 0 && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ ml: 4, mb: 1, opacity: 0.7 }}
              >
                I can help you schedule an interview or create a task.
              </Typography>
            )}
          </Box>

          {filteredOptions.length > 0 && (
            <List sx={{ pt: 0 }}>
              {filteredOptions.map((option, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={option.action}
                  selected={index === selectedIndex}
                  sx={{
                    py: 1,
                    px: 3,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.07)',
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '36px',
                      color: 'text.secondary',
                      opacity: 0.6,
                    }}
                  >
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={option.text}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: '0.95rem',
                        fontWeight: 400,
                        color: 'text.secondary',
                      },
                    }}
                  />
                  {index === 0 && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.disabled',
                        fontSize: '0.75rem',
                      }}
                    >
                      Enter
                    </Typography>
                  )}
                </ListItem>
              ))}
            </List>
          )}

          {inputValue.length > 0 && filteredOptions.length === 0 && (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography color="text.secondary">
                No matching commands found
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Render the appropriate chat component based on chatType */}
      {showChat && chatType === 'task' && (
        <TaskChat
          open={showChat}
          onClose={handleCloseChat}
          matterId={matterId}
        />
      )}

      {showChat && chatType === 'interview' && (
        <InterviewChat
          open={showChat}
          onClose={handleCloseChat}
          matterId={matterId}
        />
      )}
    </>
  );
};

export default CommandBar;
