/* eslint-disable */
import { createOpenAI } from '@ai-sdk/openai';
import {
  streamText,
  tool as createTool,
  type CoreMessage,
  type TextStreamPart,
} from 'ai';
import { z } from 'zod';

// ================== Types ==================

/**
 * Message in the chat
 */
export interface ChatMessage {
  id: string;
  role: 'user' | 'assistant' | 'system';
  content: string;
  timestamp: number;
  isStreaming?: boolean;
}

/**
 * Tool execution options
 */
export interface ToolExecutionOptions {
  dryRun?: boolean;
}

/**
 * Tool execution result
 */
export interface ToolExecutionResult {
  success: boolean;
  toolName: string;
  result?: any;
  error?: Error;
  dryRun: boolean;
  params: any;
}

/**
 * LLMChat configuration options
 */
export interface LLMChatOptions {
  /** Unique identifier for this chat */
  id?: string;
  /** OpenAI API key */
  apiKey?: string;
  /** Model to use (default: gpt-4o) */
  modelName?: string;
  /** System prompt */
  systemPrompt?: string;
  /** Temperature (0-1) */
  temperature?: number;
  /** Tool calling strategy */
  toolCallStrategy?: 'parallel' | 're-ask';
}

/**
 * Tool definitions using Zod schemas
 */
export interface ZodToolDefinition<T extends z.ZodType> {
  name: string;
  description: string;
  schema: T;
  handler: (params: z.infer<T>, options?: ToolExecutionOptions) => Promise<any>;
}

/**
 * Event types the LLMChat can emit
 */
export type LLMChatEvent =
  | { type: 'message:added'; message: ChatMessage }
  | { type: 'message:updated'; message: ChatMessage }
  | { type: 'tool:registered'; toolName: string; description: string }
  | { type: 'tool:unregistered'; toolName: string }
  | {
      type: 'tool:beforeExecute';
      toolName: string;
      params: any;
      dryRun: boolean;
    }
  | { type: 'tool:executed'; result: ToolExecutionResult }
  | { type: 'stream:start'; messageId: string }
  | { type: 'stream:chunk'; messageId: string; chunk: TextStreamPart<{}> }
  | { type: 'stream:end'; messageId: string; message: ChatMessage }
  | { type: 'error'; error: Error; context?: string };

/**
 * Event listener function
 */
export type EventListener = (event: LLMChatEvent) => void;

// ================== LLMChat Class ==================

/**
 * LLMChat - Core chat API for interacting with LLM models using Vercel AI SDK
 */
export class LLMChat {
  private id: string;

  private apiKey: string;

  private modelName: string;

  private systemPrompt: string;

  private temperature: number;

  private toolCallStrategy: 'parallel' | 're-ask';

  private messages: ChatMessage[] = [];

  private toolDefinitions: Map<string, ZodToolDefinition<any>> = new Map();

  private eventListeners: EventListener[] = [];

  private contextFiles: { content: string; name: string }[] = [];

  /**
   * Create a new LLMChat instance
   */
  constructor(options: LLMChatOptions = {}) {
    this.id = options.id || `chat_${Date.now()}`;
    this.apiKey = options.apiKey || this.getApiKeyFromEnv();
    this.modelName = options.modelName || 'gpt-4o';
    this.systemPrompt = options.systemPrompt || '';
    this.temperature =
      options.temperature !== undefined ? options.temperature : 0.7;
    this.toolCallStrategy = options.toolCallStrategy || 're-ask';
  }

  /**
   * Get API key from environment
   */
  private getApiKeyFromEnv(): string {
    // Try to get from process.env (Node.js)
    if (
      typeof process !== 'undefined' &&
      process.env?.REACT_APP_OPENAI_API_KEY
    ) {
      return process.env.REACT_APP_OPENAI_API_KEY;
    }

    return '';
  }

  /**
   * Get the chat ID
   */
  getId(): string {
    return this.id;
  }

  /**
   * Register a tool with the chat using Zod schema
   */
  registerTool<T extends z.ZodType>(toolDef: ZodToolDefinition<T>): void {
    this.toolDefinitions.set(toolDef.name, toolDef);
    this.emitEvent({
      type: 'tool:registered',
      toolName: toolDef.name,
      description: toolDef.description,
    });
  }

  /**
   * Unregister a tool from the chat
   */
  unregisterTool(toolName: string): void {
    if (this.toolDefinitions.has(toolName)) {
      this.toolDefinitions.delete(toolName);
      this.emitEvent({ type: 'tool:unregistered', toolName });
    }
  }

  /**
   * Get all registered tool names
   */
  getToolNames(): string[] {
    return Array.from(this.toolDefinitions.keys());
  }

  /**
   * Execute a specific tool
   */
  async executeTool(
    toolName: string,
    params: any,
    options: ToolExecutionOptions = {}
  ): Promise<ToolExecutionResult> {
    const toolDef = this.toolDefinitions.get(toolName);

    if (!toolDef) {
      const error = new Error(`Tool "${toolName}" not found`);
      this.emitEvent({ type: 'error', error, context: 'executeTool' });
      return {
        success: false,
        toolName,
        error,
        dryRun: !!options.dryRun,
        params,
      };
    }

    // Emit before execute event
    this.emitEvent({
      type: 'tool:beforeExecute',
      toolName,
      params,
      dryRun: !!options.dryRun,
    });

    // Validate parameters using Zod schema
    try {
      // Parse and validate using the Zod schema
      toolDef.schema.parse(params);
    } catch (error) {
      const zodError = error as z.ZodError;
      const formattedError = new Error(
        `Invalid parameters for tool "${toolName}": ${zodError.errors
          .map((e) => `${e.path.join('.')}: ${e.message}`)
          .join(', ')}`
      );

      this.emitEvent({
        type: 'error',
        error: formattedError,
        context: 'executeTool:validation',
      });

      return {
        success: false,
        toolName,
        error: formattedError,
        dryRun: !!options.dryRun,
        params,
      };
    }

    // If this is a dry run, don't actually execute
    if (options.dryRun) {
      const result: ToolExecutionResult = {
        success: true,
        toolName,
        result: {
          message: 'Dry run - tool would be executed with these parameters',
        },
        dryRun: true,
        params,
      };
      this.emitEvent({ type: 'tool:executed', result });
      return result;
    }

    // Execute the tool
    try {
      const result = await toolDef.handler(params, options);
      const executionResult: ToolExecutionResult = {
        success: true,
        toolName,
        result,
        dryRun: false,
        params,
      };
      this.emitEvent({ type: 'tool:executed', result: executionResult });
      return executionResult;
    } catch (error) {
      const err = error instanceof Error ? error : new Error(String(error));
      const executionResult: ToolExecutionResult = {
        success: false,
        toolName,
        error: err,
        dryRun: false,
        params,
      };
      this.emitEvent({
        type: 'error',
        error: err,
        context: `Executing tool: ${toolName}`,
      });
      this.emitEvent({ type: 'tool:executed', result: executionResult });
      return executionResult;
    }
  }

  /**
   * Add a file to the chat context
   */
  addContextFile(content: string, name = 'file.txt'): void {
    this.contextFiles.push({ content, name });
  }

  /**
   * Clear all context files
   */
  clearContextFiles(): void {
    this.contextFiles = [];
  }

  /**
   * Get all messages in the chat
   */
  getMessages(): ChatMessage[] {
    return [...this.messages];
  }

  /**
   * Add a user message and optionally generate a response
   */
  async sendMessage(content: string, generateResponse = true): Promise<string> {
    // Create a new user message
    const userMessage: ChatMessage = {
      id: `msg_${Date.now()}_${Math.random().toString(36).substring(2, 11)}`,
      role: 'user',
      content,
      timestamp: Date.now(),
    };

    // Add the message
    this.messages.push(userMessage);
    this.emitEvent({ type: 'message:added', message: userMessage });

    // Generate response if requested
    if (generateResponse) {
      return this.generateResponse();
    }

    return userMessage.id;
  }

  /**
   * Generate a response from the AI based on the current conversation
   */
  async generateResponse(): Promise<string> {
    try {
      // Create a placeholder for the assistant's response
      const assistantMessageId = `msg_${Date.now()}_${Math.random()
        .toString(36)
        .substring(2, 11)}`;
      const assistantMessage: ChatMessage = {
        id: assistantMessageId,
        role: 'assistant',
        content: '',
        timestamp: Date.now(),
        isStreaming: true,
      };

      // Add the message
      this.messages.push(assistantMessage);
      this.emitEvent({ type: 'message:added', message: assistantMessage });

      // Prepare the client
      const openai = this.createClient();

      // Build the system prompt including any context files
      let fullSystemPrompt = this.systemPrompt;

      if (this.contextFiles.length > 0) {
        const fileContexts = this.contextFiles
          .map((file) => `File: ${file.name}\nContent:\n${file.content}`)
          .join('\n\n');

        fullSystemPrompt += `\n\nThe following files are available for reference:\n\n${fileContexts}`;
      }

      // Convert tools for the AI SDK
      const aiTools: any = {};

      // Only add tools if we have some defined
      if (this.toolDefinitions.size > 0) {
        for (const [name, toolDef] of this.toolDefinitions.entries()) {
          aiTools[name] = this.createAiSdkTool(toolDef);
        }
      }

      // Prepare the messages for the API
      const messagesForApi: CoreMessage[] = this.messages
        .filter((m) => !m.isStreaming && m.id !== assistantMessageId)
        .map((m) => ({
          role: m.role,
          content: m.content,
        }));

      // Start streaming
      this.emitEvent({ type: 'stream:start', messageId: assistantMessageId });

      // Build stream options
      const streamOptions = {
        model: openai,
        messages: messagesForApi,
        temperature: this.temperature,
        // Only add these if we have values/tools
        ...(fullSystemPrompt && { system: fullSystemPrompt }),
        ...(Object.keys(aiTools).length > 0 && { tools: aiTools }),
        ...(Object.keys(aiTools).length > 0 && {
          toolCallStrategy: this.toolCallStrategy,
        }),
      };

      // Stream the response
      const result = streamText(streamOptions);

      let fullResponse = '';

      // Process the streaming response
      for await (const chunk of result.fullStream) {
        // Emit the chunk event
        this.emitEvent({
          type: 'stream:chunk',
          messageId: assistantMessageId,
          chunk: chunk as any,
        });

        if (chunk.type === 'text-delta') {
          fullResponse += chunk.textDelta;

          // Update message
          this.updateAssistantMessage(assistantMessageId, fullResponse);
        }
        // Tool call handling is managed by the AI SDK with the re-ask strategy
      }

      // Mark as no longer streaming
      this.updateAssistantMessage(assistantMessageId, fullResponse, false);

      assistantMessage.content = fullResponse;

      // Stream is complete
      this.emitEvent({
        type: 'stream:end',
        messageId: assistantMessageId,
        message: assistantMessage,
      });

      return assistantMessageId;
    } catch (error) {
      const err = error instanceof Error ? error : new Error(String(error));
      this.emitEvent({
        type: 'error',
        error: err,
        context: 'generateResponse',
      });
      throw err;
    }
  }

  /**
   * Execute a tool and integrate the result with the conversation
   */
  async executeToolAndAddToConversation(
    toolName: string,
    params: any,
    options?: ToolExecutionOptions
  ): Promise<ToolExecutionResult> {
    // Don't emit events for dry runs
    const isDryRun = options?.dryRun === true;

    // First, execute the tool
    const result = await this.executeTool(toolName, params, options);

    // Skip rest of processing for dry runs
    if (isDryRun) {
      return result;
    }

    // If successful and not a dry run, add a system message with the result
    if (result.success) {
      // Format the result as a message
      const formattedResult =
        typeof result.result === 'object'
          ? JSON.stringify(result.result, null, 2)
          : String(result.result);

      // Add a system message with the tool result
      const systemMessage: ChatMessage = {
        id: `tool_${Date.now()}_${Math.random().toString(36).substring(2, 11)}`,
        role: 'system',
        content: `Tool ${toolName} executed with result:\n${formattedResult}`,
        timestamp: Date.now(),
      };

      // Add message to the conversation
      this.messages.push(systemMessage);
      this.emitEvent({ type: 'message:added', message: systemMessage });

      // Generate a response to the tool result if appropriate
      // This helps the AI acknowledge and respond to the tool execution
      const messages = this.getMessages();
      if (
        messages.length > 0 &&
        messages[messages.length - 1].role !== 'user'
      ) {
        // Create a user message prompting the AI to respond to the tool execution
        const promptMessage: ChatMessage = {
          id: `prompt_${Date.now()}_${Math.random()
            .toString(36)
            .substring(2, 11)}`,
          role: 'user',
          content: `The tool ${toolName} has been executed successfully. Please acknowledge and continue the conversation.`,
          timestamp: Date.now(),
        };

        // Add the prompt message but don't emit an event for it (it's internal)
        this.messages.push(promptMessage);

        // Generate a response to this internal prompt
        await this.generateResponse();
      }
    }

    return result;
  }

  /**
   * Update an assistant message
   */
  private updateAssistantMessage(
    messageId: string,
    content: string,
    isStreaming = true
  ): void {
    // Find and update the message
    const messageIndex = this.messages.findIndex((m) => m.id === messageId);
    if (messageIndex >= 0) {
      const updatedMessage = {
        ...this.messages[messageIndex],
        content,
        isStreaming,
      };

      this.messages[messageIndex] = updatedMessage;
      this.emitEvent({ type: 'message:updated', message: updatedMessage });
    }
  }

  /**
   * Clear all messages in the chat
   */
  clearMessages(): void {
    this.messages = [];
  }

  /**
   * Sets all the messages in the chat
   */
  setMessages(messages: ChatMessage[]): void {
    this.messages = messages;
  }

  /**
   * Appends messages to the chat
   */
  appendMessages(messages: ChatMessage[]): void {
    this.messages = [...this.messages, ...messages];
  }

  /**
   * Create an OpenAI client
   */
  private createClient() {
    if (!this.apiKey) {
      throw new Error('OpenAI API key is not set');
    }

    const openai = createOpenAI({ apiKey: this.apiKey });
    return openai(this.modelName);
  }

  /**
   * Create an AI SDK tool from our definition
   */
  private createAiSdkTool<T extends z.ZodType>(def: ZodToolDefinition<T>) {
    return {
      // warning
      type: 'function' as const,
      function: {
        name: def.name,
        description: def.description,
        parameters: def.schema,
      },
      execute: async (params: z.infer<T>) => {
        try {
          // Emit the event for tool execution
          this.emitEvent({
            type: 'tool:beforeExecute',
            toolName: def.name,
            params,
            dryRun: false,
          });

          // Call the handler
          const result = await def.handler(params);

          // Emit the success event
          this.emitEvent({
            type: 'tool:executed',
            result: {
              success: true,
              toolName: def.name,
              result,
              dryRun: false,
              params,
            },
          });

          return result;
        } catch (error) {
          // Handle errors
          const err = error instanceof Error ? error : new Error(String(error));

          // Emit the error events
          this.emitEvent({
            type: 'error',
            error: err,
            context: `Executing tool: ${def.name}`,
          });

          this.emitEvent({
            type: 'tool:executed',
            result: {
              success: false,
              toolName: def.name,
              error: err,
              dryRun: false,
              params,
            },
          });

          throw err;
        }
      },
    };
  }

  /**
   * Subscribe to events
   * @returns A function to unsubscribe
   */
  subscribe(listener: EventListener): () => void {
    this.eventListeners.push(listener);
    return () => {
      this.eventListeners = this.eventListeners.filter((l) => l !== listener);
    };
  }

  /**
   * Emit an event to all listeners
   */
  private emitEvent(event: LLMChatEvent): void {
    for (const listener of this.eventListeners) {
      try {
        listener(event);
      } catch (error) {
        console.error('Error in event listener:', error);
      }
    }
  }
}
