import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import ListButtons from '../../shared/list-buttons/ListButtons';
import '../../../pages/styles/userlist.css';
import { usersRequest } from '../../../api/users';
import { User } from '../../../types/auth';
import UsersListTable from '../../shared/users-list-table/UsersListTable';
import { useUsersPermissions } from '../../../hooks/usePermissions';
import LEIAAContext from '../../../context/context';
import { matterDetailsRequest } from '../../../api/matters';

interface MatterDetailUsersProps {
  matterId: any;
  matterDetails: any;
}

const MatterDetailUsers = ({
  matterId,
  matterDetails,
}: MatterDetailUsersProps) => {
  const userPerms = useUsersPermissions();
  const [loading, setLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const { user } = useContext(LEIAAContext);
  const navigate = useNavigate();
  const [usersListData, setUsersListData] = useState<User[] | null>(null);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleFetchUsersList = async (searchText = '') => {
    setLoading(true); // Start loading

    try {
      const response = await usersRequest(
        'GET',
        undefined,
        searchText,
        matterId
      );
      setUsersListData(response as User[]);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchUsersList(searchText);
  };

  const handleEditUser = async () => {
    navigate(`/user-details/${selectedUser?.id}/edit`, {
      state: { user: selectedUser },
    });
  };

  const handleEditUserClick = () => {
    matterDetailsRequest(matterId).then((response) => {
      navigate(`/matters/edit/${matterId}`, {
        state: {
          currentStep: 1,
          matterDetails: response,
          isEdit: true,
          showHeader: false,
        },
      });
    });
  };

  const handleDetailUser = (_user: any) => {
    navigate(`/user-details/${_user?.id}`, {
      state: { user: _user, matterId },
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '40px',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}>
            {userPerms.add && (
              <Button
                variant="contained"
                onClick={handleEditUserClick}
                sx={{ ...primaryButtonStyles(), height: '40px' }}
              >
                <span className="material-icons-round">manage_accounts</span>
                <Typography>Manage users</Typography>
              </Button>
            )}
          </Box>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search by user..."
            sx={{
              ...homePortalSearchInputStyles,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& > span': { color: '#69707B' } }}
                >
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              onChange: handleListSearchbarInputChange,
              inputRef,
            }}
            variant="standard"
          />
        </Box>
        {checkedUsers.length > 0 &&
          userPerms.change &&
          ['Admin', 'Superuser', 'Leader'].includes(user?.role || '') && (
            <ListButtons
              editHandler={handleEditUser}
              checkedResources={checkedUsers}
              resourceName="user"
            />
          )}
      </Box>
      <Box sx={{ width: '100%' }}>
        <UsersListTable
          usersListData={usersListData}
          loading={loading}
          setCheckedUsers={setCheckedUsers}
          setSelectedUser={setSelectedUser}
          handleDetailUser={handleDetailUser}
        />
      </Box>
    </>
  );
};

export default MatterDetailUsers;
