import { Box, Typography, List, ListItem, Button } from '@mui/material';
import { karlaProBoldFontStyles } from '../../../styles/textStyles';
import { tertiaryButtonStyles } from '../../styles';

interface FolderFileActionsProps {
  item: any;
  folderPermissionsHandler?: any;
  folderUploadLinkHandler?: any;
  handleDownloadFile?: any;
  addToFraudDetectionHandler?: any;
  renameHandler?: any;
  fileDownloadLinkHandler?: any;
}

const FolderFileActions = ({
  item,
  folderPermissionsHandler,
  folderUploadLinkHandler,
  handleDownloadFile,
  addToFraudDetectionHandler,
  renameHandler,
  fileDownloadLinkHandler,
}: FolderFileActionsProps) => {
  return (
    <Box>
      {/* TODO: files that are imported should have access to actions */}
      {((!item.folder && item.source === 'Leiaa') || item.folder) && (
        <List sx={{ marginBottom: '8px' }}>
          {renameHandler && (
            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Button
                  sx={{
                    ...tertiaryButtonStyles(),
                    height: '34px',
                  }}
                  variant="text"
                  onClick={() => renameHandler()}
                >
                  <span
                    style={{ fontSize: '24px' }}
                    className="material-icons-round"
                  >
                    border_color
                  </span>
                  <Typography>Rename</Typography>
                </Button>
              </Box>
            </ListItem>
          )}
          {item.folder ? (
            <>
              {folderPermissionsHandler && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => folderPermissionsHandler()}
                    >
                      <span
                        style={{ fontSize: '24px' }}
                        className="material-icons-round"
                      >
                        manage_accounts
                      </span>
                      <Typography>Folder Permissions</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
              {folderUploadLinkHandler && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => folderUploadLinkHandler()}
                    >
                      <span
                        style={{ fontSize: '24px' }}
                        className="material-icons-round"
                      >
                        drive_folder_upload
                      </span>
                      <Typography>Folder Upload Link</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
            </>
          ) : (
            <>
              {handleDownloadFile && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => handleDownloadFile()}
                    >
                      <span
                        style={{ fontSize: '24px' }}
                        className="material-icons-round"
                      >
                        cloud_download
                      </span>
                      <Typography>Download</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
              {addToFraudDetectionHandler &&
                process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' && (
                  <ListItem>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Button
                        sx={{
                          ...tertiaryButtonStyles(),
                          height: '34px',
                        }}
                        variant="text"
                        onClick={() => addToFraudDetectionHandler()}
                      >
                        <span
                          style={{
                            fontSize: '24px',
                          }}
                          className="material-icons-round"
                        >
                          add
                        </span>
                        <Typography>Add to Fraud Detection Database</Typography>
                      </Button>
                    </Box>
                  </ListItem>
                )}
              {fileDownloadLinkHandler && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => fileDownloadLinkHandler()}
                    >
                      <span
                        style={{
                          fontSize: '24px',
                        }}
                        className="material-icons-round"
                      >
                        file_present
                      </span>
                      <Typography>Create Download Link</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
            </>
          )}
        </List>
      )}
    </Box>
  );
};

export default FolderFileActions;
