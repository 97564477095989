/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Box, Breadcrumbs, Link, Typography, Stack } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import '../../../pages/styles/matterlist.css';
import '../../styles/datagridStyles.css';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';
import NotificationToast from '../../shared/toast/NotificationToast';
import FraudDocumentPreview from '../../shared/misc-components/FraudDocumentPreview';
import ImageDisplayFraud from '../../shared/fraud-misc/ImageDisplayFraud';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const MatterDetailFraudDetail = () => {
  const { matterId, resourceId } = useParams();
  const location = useLocation();
  const { response, matterDetails } = location.state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [analysisData, setAnalysisData] = useState<any>(
    location.state.response.data
  );
  let backgroundScoreColor = '#e0e0e0';
  let fontScoreColor = '#FFFFFF';
  const [highlightsList, setHighlightsList] = useState(null);

  switch (analysisData.score) {
    case 'TRUSTED':
      backgroundScoreColor = '#38B763';
      break;
    case 'NORMAL':
      backgroundScoreColor = '#3375FF';
      break;
    case 'WARNING':
      backgroundScoreColor = '#EDB900';
      fontScoreColor = '#202020';
      break;
    case 'HIGH_RISK':
      backgroundScoreColor = '#E22727';
      break;
    default:
      backgroundScoreColor = '#e0e0e0';
      break;
  }

  const formatDate = (dateString: any) => {
    const dateObject = new Date(dateString);

    return `${dateObject.toISOString().split('T')[0]}, ${
      dateObject.toTimeString().split(' ')[0]
    } ${dateObject.toTimeString().split(' ')[1]}`;
  };

  const handleSetHighlightsList = (indicator: any) => {
    setHighlightsList(indicator);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          },
        }}
      >
        <Box>
          <Breadcrumbs
            separator={
              <span className="material-icons-round">chevron_right</span>
            }
            aria-label="breadcrumb"
            sx={{
              '& > ol': {
                gap: '6px',
                '& > .MuiBreadcrumbs-li > div': {
                  '& > button': {
                    ...sofiaProRegularFontStyles,
                    color: '#69707B',
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    padding: '10px 0px',
                  },
                  '& > p': {
                    color: '#949CA9',
                    ...karlaProRegularFontStyles,
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.2px',
                  },
                },
                '& > .MuiBreadcrumbs-separator': {
                  margin: '0px',
                  padding: '10px 0',
                  alignSelf: 'baseline',
                },
              },
            }}
          >
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => navigate(-1)}
              >
                {matterDetails.name}
              </Link>
              <Typography>Matter</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() =>
                  navigate(`/matters/detail/${matterId}`, {
                    state: { currentStep: 2 },
                  })
                }
              >
                Documents
              </Link>
              <Typography>Folder</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() =>
                  navigate(`/matters/detail/${matterId}`, {
                    state: { currentStep: 2 },
                  })
                }
              >
                Fraud Detection
              </Link>
              <Typography>Action</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                sx={{
                  ...sofiaProBoldFontStyles,
                  color: '#202020 !important',
                  fontSize: '18px !important',
                  fontWeight: '700 !important',
                  lineHeight: 'normal !important',
                  letterSpacing: '2px !important',
                  textTransform: 'uppercase !important',
                  padding: '10px 0 !important',
                  cursor: 'default',
                  pointerEvents: 'none',
                }}
              >
                {analysisData.filename}
              </Link>
              <Typography>File</Typography>
            </Box>
          </Breadcrumbs>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '40px',
              alignSelf: 'stretch',
              '& > div:nth-of-type(1)': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flex: '1 0 0',
              },
              // width: '50%',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Box sx={{ padding: '0px 16px' }}>
                <Typography
                  variant="body2"
                  sx={{
                    ...sofiaProBoldFontStyles,
                    color: '#202020 !important',
                    fontSize: '18px !important',
                    fontWeight: '700 !important',
                    lineHeight: 'normal !important',
                    letterSpacing: '2px !important',
                    textTransform: 'uppercase !important',
                    padding: '10px 0 !important',
                  }}
                >
                  Fraud Analysis
                </Typography>
              </Box>
              <Box sx={{ padding: '0px 16px' }}>
                <Typography
                  sx={{
                    backgroundColor: backgroundScoreColor,
                    padding: '8px 16px',
                    color: fontScoreColor,
                    borderRadius: '5px',
                    marginBottom: '10px',
                  }}
                >
                  {analysisData.score === 'HIGH_RISK'
                    ? 'HIGH RISK'
                    : analysisData.score}
                </Typography>
              </Box>
              <Box sx={{ padding: '0px 16px' }}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={12}
                  sx={{
                    '& > div > p': {
                      fontSize: '16px',
                      ...karlaProRegularFontStyles,
                      lineHeight: '22px',
                      fontWeight: '500',
                    },
                    '& > div > p > span': {
                      color: '#464B53',
                      fontSize: '16px',
                      ...karlaProRegularFontStyles,
                      lineHeight: '22px',
                    },
                    marginBottom: '10px',
                  }}
                >
                  <Box>
                    <Typography>
                      <span>Author</span>{' '}
                      {analysisData.data.sample_metadata.author || '-'}
                    </Typography>
                    <Typography>
                      <span>Document type</span>{' '}
                      {analysisData.data.file_type || '-'}
                    </Typography>
                    <Typography>
                      <span>Uploaded by</span> {analysisData.uploaded || '-'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <span>Analysis triggered by</span>{' '}
                      {analysisData.triggered || '-'}
                    </Typography>
                    <Typography>
                      <span>Creation date</span>{' '}
                      {analysisData.data.sample_metadata.creation_date
                        ? formatDate(
                            analysisData.data.sample_metadata.creation_date
                          )
                        : '-'}
                    </Typography>
                    <Typography>
                      <span>Modified date</span>{' '}
                      {analysisData.data.sample_metadata.mod_date
                        ? formatDate(analysisData.data.sample_metadata.mod_date)
                        : '-'}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              {analysisData.data.indicators.filter(
                (indicator: any) => indicator.type === 'RISK'
              ).length !== 0 && (
                <Box
                  sx={{
                    padding: '16px',
                    backgroundColor: '#F5FAFD',
                    borderRadius: '14px',
                    width: '100%',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      marginBottom: '15px',
                      '& > div > p': {
                        fontSize: '16px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                      },
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{ color: '#E22727', marginRight: '8px' }}
                        className="material-icons-round"
                      >
                        cancel
                      </span>
                      <Typography>Risk indicators</Typography>
                    </Box>

                    <Box>
                      <Typography sx={{ color: '#8e8e8e' }}>
                        {
                          analysisData.data.indicators.filter(
                            (indicator: any) => indicator.type === 'RISK'
                          ).length
                        }{' '}
                        Risk indicators
                      </Typography>
                    </Box>
                  </Stack>
                  {analysisData.data.indicators
                    .filter((indicator: any) => indicator.type === 'RISK')
                    .map((indicator: any, index: any) => (
                      <Accordion
                        sx={{
                          background: '#fff8f8',
                          width: '100%',
                          '& > div:nth-child(2) ': {
                            background: 'white !important',
                          },
                        }}
                        key={`RISK - ${index}`}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography
                            sx={{
                              ...karlaProRegularFontStyles,
                              fontSize: '16px',
                              lineHeight: '16px',
                              letterSpacing: '0.2px',
                            }}
                          >
                            {indicator.title}
                          </Typography>
                          {indicator.metadata &&
                            indicator.metadata.type ===
                              'ElementsCollection' && (
                              <Typography
                                sx={{
                                  ...karlaProRegularFontStyles,
                                  fontWeight: '400',
                                  fontSize: '15px',
                                  lineHeight: '16px',
                                  letterSpacing: '0.2px',
                                  marginLeft: '15px',
                                  color: '#4981f7',
                                  '&:hover': {
                                    color: '#0647d2',
                                  },
                                }}
                                onClick={() =>
                                  handleSetHighlightsList(indicator)
                                }
                              >
                                show
                              </Typography>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            sx={{
                              ...karlaProRegularFontStyles,
                              fontSize: '14px',
                              lineHeight: '16px',
                              letterSpacing: '0.2px',
                            }}
                          >
                            {indicator.description}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </Box>
              )}
              {analysisData.data.indicators.filter(
                (indicator: any) => indicator.type === 'TRUST'
              ).length !== 0 && (
                <Box
                  sx={{
                    padding: '16px',
                    backgroundColor: '#F5FAFD',
                    borderRadius: '14px',
                    marginBottom: '5px !important',
                    width: '100%',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      marginBottom: '15px',
                      '& > div > p': {
                        fontSize: '16px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                      },
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{ color: '#38B763', marginRight: '8px' }}
                        className="material-icons-round"
                      >
                        check_circle
                      </span>
                      <Typography>Trust indicators</Typography>
                    </Box>

                    <Box>
                      <Typography sx={{ color: '#8e8e8e' }}>
                        {
                          analysisData.data.indicators.filter(
                            (indicator: any) => indicator.type === 'TRUST'
                          ).length
                        }{' '}
                        trust indicators
                      </Typography>
                    </Box>
                  </Stack>
                  {analysisData.data.indicators
                    .filter((indicator: any) => indicator.type === 'TRUST')
                    .map((indicator: any, index: any) => (
                      <Accordion
                        key={`TRUST - ${index}`}
                        sx={{
                          background: '#f8fff8',
                          '& > div:nth-child(2) ': {
                            background: 'white !important',
                          },
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography
                            sx={{
                              ...karlaProRegularFontStyles,
                              fontSize: '16px',
                              lineHeight: '16px',
                              letterSpacing: '0.2px',
                            }}
                          >
                            {indicator.title}
                          </Typography>
                          {indicator.metadata &&
                            indicator.metadata.type ===
                              'ElementsCollection' && (
                              <Typography
                                sx={{
                                  ...karlaProRegularFontStyles,
                                  fontWeight: '400',
                                  fontSize: '15px',
                                  lineHeight: '16px',
                                  letterSpacing: '0.2px',
                                  marginLeft: '15px',
                                  color: '#4981f7',
                                  '&:hover': {
                                    color: '#0647d2',
                                  },
                                }}
                                onClick={() =>
                                  handleSetHighlightsList(indicator)
                                }
                              >
                                show
                              </Typography>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            sx={{
                              ...karlaProRegularFontStyles,
                              fontSize: '14px',
                              lineHeight: '16px',
                              letterSpacing: '0.2px',
                            }}
                          >
                            {indicator.description}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </Box>
              )}
            </Stack>
          </Box>
          {analysisData.data.file_type &&
          (analysisData.data.file_type.toLowerCase() === 'pdf' ||
            analysisData.data.file_type.toLowerCase() === 'jpeg' ||
            analysisData.data.file_type.toLowerCase() === 'png' ||
            analysisData.data.file_type.toLowerCase() === 'gif' ||
            analysisData.data.file_type.toLowerCase() === 'svg' ||
            analysisData.data.file_type.toLowerCase() === 'webp' ||
            analysisData.data.file_type.toLowerCase() === 'image') ? (
            <Box>
              {analysisData.data.file_type.toLowerCase() === 'pdf' ? (
                <FraudDocumentPreview
                  indicator={highlightsList}
                  analysisData={analysisData}
                />
              ) : (
                <ImageDisplayFraud
                  indicator={highlightsList}
                  analysisData={analysisData}
                />
              )}
            </Box>
          ) : null}
        </Stack>
      </Box>
    </Box>
  );
};

export default MatterDetailFraudDetail;
