import { Box, Popover, Stack, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { LEIAADatePicker } from '../../../inputs';
import { formatDateToReadable } from '../../../../utils/utils';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';

interface MatterDetailTimelineJumpToProps {
  currentDate: any;
  events: any;
  handleDateClick: any;
}

const MatterDetailTimelineJumpTo = ({
  currentDate,
  events,
  handleDateClick,
}: MatterDetailTimelineJumpToProps) => {
  const { control, setValue, watch } = useForm({
    defaultValues: {
      currentDate: formatDateToReadable(currentDate),
    } as any,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const selectedCurrentDate = watch('currentDate');

  const clearCurrentDate = () => {
    setValue('currentDate', null);
    if (events && events.length > 0) {
      const firstEvent = events.reduce((earliest: any, currentEvent: any) => {
        const resetDate = new Date(currentEvent.date);
        return resetDate < earliest ? resetDate : earliest;
      }, new Date());
      handleDateClick(firstEvent);
    }
    handleClose();
  };

  useEffect(() => {
    setValue('currentDate', formatDateToReadable(currentDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <Box>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '16px',
          ...karlaProRegularFontStyles,
          fontWeight: '500',
          letterSpacing: '0.2px',
          cursor: 'pointer',
          color: '#0053FF',
          '&:hover': {
            color: '#0B25B0',
          },
        }}
        onClick={handleClick}
      >
        Jump To
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          boxShadow: 'none !important',
          border: '1px solid #D3ECF7',
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none !important',
          },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '5px 10px',
            border: '1px solid #D3ECF7',
            flexDirection: 'row !important',
            borderRadius: '5px',
          }}
        >
          <Box position="relative" display="inline-flex" alignItems="center">
            <Controller
              name="currentDate"
              control={control}
              render={({ field }) => (
                <LEIAADatePicker
                  labelText=""
                  inputPlaceholder="Target Date"
                  controllerName="currentDate"
                  control={control}
                  {...field}
                  handleChange={(date: any) => {
                    field.onChange(date);
                    setValue('currentDate', date);
                    const [day, month, year] = date.split('/');
                    handleDateClick(new Date(`${year}-${month}-${day}`));
                    handleClose();
                  }}
                  required={false}
                  disableHelper
                  useBlueStyles
                />
              )}
            />
            {selectedCurrentDate && (
              <IconButton
                onClick={clearCurrentDate}
                size="small"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '25%',
                  transform: 'translate(50%, -50%)',
                  backgroundColor: 'white',
                  zIndex: 1,
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Stack>
      </Popover>
    </Box>
  );
};

export default MatterDetailTimelineJumpTo;
