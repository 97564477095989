/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
import { useState, useRef, useEffect } from 'react';
import {
  Box,
  Tooltip,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ChatInterface, { Message } from './ChatInterface';
import { LLMChat, LLMChatEvent } from './api';

interface FixedContextualHelpWrapperProps {
  children?: React.ReactNode;
  systemPrompt: string;
  title?: string;
  tooltipText?: string;
  chatWidth?: string;
  chatHeight?: string;
  iconColor?: string;
  iconSize?: string;
  iconPosition?: {
    right?: string;
    offset?: string;
  };
  welcomeMessageContent?: string;
  indicatorText?: string;
}

const FixedContextualHelpWrapper = ({
  children,
  systemPrompt,
  title = 'Help Assistant',
  tooltipText = 'Get help with this feature',
  chatWidth = '400px',
  chatHeight = '500px',
  iconColor = '#0053ff',
  iconSize = '24px',
  iconPosition = { right: '20px', offset: '0px' },
  welcomeMessageContent = 'How can I help you? Feel free to ask any questions.',
  indicatorText = 'Assistant',
}: FixedContextualHelpWrapperProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const llmChatRef = useRef<LLMChat | null>(null);
  const initialized = useRef(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      initializeChat();
    }

    return () => {
      if (llmChatRef.current) {
        llmChatRef.current = null;
        initialized.current = false;
      }
    };
  }, []);

  const handleChatEvent = (event: LLMChatEvent) => {
    try {
      switch (event.type) {
        case 'message:added':
          // eslint-disable-next-line no-case-declarations
          const newMessage: Message = {
            id: event.message.id,
            role: event.message.role,
            content: event.message.content,
            createdAt: new Date(event.message.timestamp),
          };

          if (newMessage.role === 'user' && !newMessage.content.trim()) {
            break;
          }

          setMessages((prevMessages) => {
            const exists = prevMessages.some((msg) => msg.id === newMessage.id);
            if (exists) return prevMessages;
            return [...prevMessages, newMessage];
          });
          break;

        case 'message:updated':
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === event.message.id
                ? {
                    ...msg,
                    content: event.message.content,
                  }
                : msg
            )
          );
          break;

        case 'stream:start':
          setIsStreaming(true);
          break;

        case 'stream:end':
          setIsStreaming(false);
          break;

        case 'error':
          console.error('LLMChat error:', event.error);
          setError(`Error: ${event.error.message}`);
          setIsStreaming(false);
          break;

        default:
          break;
      }
    } catch (err) {
      console.error('Error handling chat event:', err);
      setError(
        `Error handling chat event: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  const initializeChat = () => {
    try {
      if (!process.env.REACT_APP_OPENAI_API_KEY) {
        console.error('No OpenAI API key provided');
        setError('Missing API key. Please provide an OpenAI API key.');
        return;
      }

      const chat = new LLMChat({
        id: `contextual_help_${uuidv4()}`,
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        systemPrompt,
        temperature: 0.7,
      });

      const systemMessage: Message = {
        id: uuidv4(),
        role: 'system',
        content: systemPrompt,
        createdAt: new Date(),
      };

      const welcomeMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content: welcomeMessageContent,
        createdAt: new Date(),
        isWelcomeMessage: true,
      };

      setMessages([systemMessage, welcomeMessage]);
      setError(null);

      const unsubscribe = chat.subscribe(handleChatEvent);

      llmChatRef.current = chat;
      initialized.current = true;

      // eslint-disable-next-line consistent-return
      return () => {
        unsubscribe();
        llmChatRef.current = null;
        initialized.current = false;
      };
    } catch (err) {
      console.error('Error initializing LLMChat:', err);
      setError(
        `Failed to initialize chat: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubmitMessage = async (input: string) => {
    if (isStreaming) {
      setIsStreaming(false);
      return;
    }

    if (!input.trim()) {
      return;
    }

    try {
      setError(null);
      if (llmChatRef.current) {
        setIsStreaming(true);

        try {
          await llmChatRef.current.sendMessage(input);
        } catch (sendError) {
          console.error('Error sending message:', sendError);
          setIsStreaming(false);
          setError(
            `Failed to send message: ${
              sendError instanceof Error ? sendError.message : String(sendError)
            }`
          );
        }
      } else {
        console.error('LLMChat instance not available');
        setError('Chat system not initialized. Please try again.');
        if (!initialized.current) {
          initializeChat();
        }
      }
    } catch (err) {
      console.error('Error in submit handler:', err);
      setError(
        `Error sending message: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
      setIsStreaming(false);
    }
  };

  const renderIndicator = () => {
    return (
      <Tooltip title={tooltipText} arrow placement="left">
        <Box
          onClick={handleToggleExpand}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            cursor: 'pointer',
          }}
        >
          <Typography
            sx={{
              transform: 'rotate(-90deg)',
              transformOrigin: 'center',
              color: isExpanded ? 'transparent' : iconColor,
              fontSize: '13px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              letterSpacing: '1.5px',
              textTransform: 'uppercase',
              width: '30px',
              marginRight: '-5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '150px',
              position: 'relative',
              zIndex: 1,
              visibility: 'visible',
            }}
          >
            {indicatorText}
          </Typography>

          <Box
            sx={{
              position: 'relative',
              backgroundColor: iconColor,
              height: '200px',
              width: '4px',
              borderRadius: '2px',
              transition: 'box-shadow 0.2s ease',
              '&:hover': {
                boxShadow: '0 0 8px rgba(0, 83, 255, 0.5)',
              },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `2px solid ${iconColor}`,
                zIndex: 2,
                boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
              }}
            >
              <span
                className="material-icons-round"
                style={{
                  fontSize: '18px',
                  color: iconColor,
                  transition: 'transform 0.3s ease',
                  marginRight: '2px',
                }}
              >
                {isExpanded ? 'chevron_right' : 'chevron_left'}
              </span>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      {children}

      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          transform: 'translateY(-50%)',
          right: iconPosition.right,
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            transition:
              'transform 0.3s ease, width 0.3s ease, opacity 0.3s ease',
            transform: isExpanded ? 'translateX(0)' : 'translateX(100%)',
            opacity: isExpanded ? 1 : 0,
            width: isExpanded ? (isMobile ? '90vw' : chatWidth) : '0px',
            overflow: 'hidden',
            maxHeight: '80vh',
            borderRadius: '15px',
          }}
        >
          <ChatInterface
            title={title}
            messages={messages.filter((msg) => msg.role !== 'system')}
            sessions={[]}
            onSubmit={handleSubmitMessage}
            onLoadSession={() => {}}
            onCreateNewSession={() => {}}
            isStreaming={isStreaming}
            chatWidth={isMobile ? '90vw' : chatWidth}
            chatHeight={chatHeight}
            cardStyles={{
              border: '1px solid #e4e4e4',
              borderRadius: '16px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
            }}
            enableChatHistory={false}
            allowMinimize={false}
          />
        </Box>

        {renderIndicator()}
      </Box>
    </>
  );
};

export default FixedContextualHelpWrapper;
