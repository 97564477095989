import { GridPagination, useGridApiContext } from '@mui/x-data-grid';
import { TablePaginationProps } from '@mui/material/TablePagination';
import MuiPagination from '@mui/material/Pagination';

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();

  const pageCount = Math.ceil(rowCount / 5);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      showFirstButton
      showLastButton
      shape="rounded"
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
      sx={{ padding: '0', margin: '0 !important' }}
    />
  );
}

const LEIAAPagination = (props: any) => {
  return (
    <GridPagination
      sx={{
        marginRight: 'auto',
        padding: '0',
        '& > div > div ': { display: 'none' },
        '& > div > p ': { display: 'none' },
        '& > div  ': { padding: '0' },
      }}
      ActionsComponent={Pagination}
      {...props}
    />
  );
};

export default LEIAAPagination;
