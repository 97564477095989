import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
  matterDocumentsInputWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationWrapperStyles,
  matterQuestionnaireUploadAreaWrapperStyles,
} from '../matter-information-step/styles';
import LEIAAUploadFileArea from '../../../../inputs/LEIAAUploadFileArea';
import LEIAADialog from '../../../../inputs/LEIAADialog';
import {
  MatterAddedDocumentsInformation,
  MatterAddedGeneralInformation,
  SidePanelInformation,
} from '../../sidepanel-information';
import {
  matterUsersAccordionDetailsStyles,
  matterUsersAccordionWrapperStyles,
} from '../matter-users-step/styles';
import { LEIAASelect } from '../../../../inputs';
import { matterAvailableUsersRequest } from '../../../../../api/matters';
import { MatterUser } from '../../../../../types/matters';
import { karlaProRegularFontStyles } from '../../../../../styles/textStyles';
import NotificationToast from '../../../../shared/toast/NotificationToast';

interface MatterDocumentsProps {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  currentStep: number;
  previousStep: number | null;
  control: Control<FieldValues>;
}

interface Permission {
  delete: boolean;
  change: boolean;
  view: boolean;
}

interface FileWithId extends File {
  id: string;
  uploaded: boolean;
  permissions: Permission[];
}

const permissionsCombinations = {
  'true,false,false': 'R (Read)',
  'false,true,false': 'U (Update)',
  'false,false,true': 'D (Delete)',
  'true,false,true': 'RD (Read, Delete)',
  'true,true,false': 'RU (Read, Update)',
  'false,true,true': 'UD (Update, Delete)',
  'true,true,true': 'RUD (Read, Update, Delete)',
  'false,false,false': 'No permission',
};

const MatterDocuments = ({
  watch,
  setValue,
  currentStep,
  previousStep,
  control,
}: MatterDocumentsProps) => {
  const matterDocuments = watch('files');
  const matterUsers = watch('users');
  const [users, setUsers] = useState<MatterUser[]>();
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [repeatedFiles, setRepeatedFiles] = useState<FileWithId[]>([]);
  const [expandedAccordionDocId, setExpandedAccordionDocId] = useState<
    number | null
  >(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);

  const matterInfoFields = [
    {
      label: process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
        ? 'Organisation Name'
        : 'Client Name',
      value: watch('client'),
    },
    { label: 'Matter Name', value: watch('name') },
    { label: 'Matter Description', value: watch('description') },
    { label: 'Jurisdiction', value: watch('jurisdictionName') },
    { label: 'Template', value: watch('template') },
  ];

  const updateDocsUploadedStatus = (files: FileWithId[]) => {
    return files.map((file: FileWithId) => ({
      id: file.id,
      name: file.name,
      size: file.size,
      type: file.type,
      permissions: file.permissions,
      uploaded: true,
    }));
  };

  const handleDocUpload = () => {
    if ((currentStep && previousStep === null) || previousStep === 4) {
      if (matterDocuments && matterDocuments.length > 0) {
        const updatedFiles = updateDocsUploadedStatus(matterDocuments);
        setValue('files', updatedFiles);
      }
    }
  };

  const handleFetchUsers = async () => {
    try {
      const response = await matterAvailableUsersRequest();
      setUsers(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
      console.error(error);
    }
  };

  const permissionsObjectToString = (permissions: any) => {
    const order = ['view', 'change', 'delete'];
    return order.map((key) => (permissions[key] ? 'true' : 'false')).join(',');
  };
  useEffect(() => {
    handleDocUpload();
    handleFetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFiles = (fileList: FileList) => {
    let existingFiles: FileWithId[] = [];

    if (fileList.length === 0) return;

    // Check if any of the selected files already exist in matterDocuments
    if (matterDocuments && matterDocuments.length > 0) {
      existingFiles = matterDocuments.filter((md: FileWithId) =>
        Array.from(fileList).some(
          (selectedFile) => md.name === selectedFile.name
        )
      );
      setRepeatedFiles(existingFiles);
    }

    if (existingFiles.length > 0) {
      setOpenAlertOpenDialog(true);
    } else {
      // Convert FileList to an array and update the uploadedFiles state
      const filesArray: FileWithId[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        // Generate a unique id for each file
        const fileId = uuidv4();
        // Add the id property to the file object
        const fileWithId = Object.assign(file, {
          id: fileId,
          uploaded: false,
          permissions:
            matterUsers && matterUsers.length > 0
              ? matterUsers.map((user: any) => ({
                  user_id: user.user_id,
                  delete: false,
                  change: false,
                  view: true,
                  file_id: fileId,
                }))
              : [],
        });
        filesArray.push(fileWithId);
      }

      // Update the 'files' field in the form payload
      setValue('files', [...(matterDocuments || []), ...filesArray]);
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.target.files as FileList;
    handleUploadFiles(fileList);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const fileList = e.dataTransfer.files;
      handleUploadFiles(fileList);
    }
  };

  const handleRemoveFile = (id: string | number) => {
    // Update the 'files' field in the form payload
    setValue(
      'files',
      (matterDocuments || []).filter((file: FileWithId) => file.id !== id)
    );
    if (typeof id === 'number') {
      const filesIdsToDelete = watch('files_to_delete') || [];
      setValue('files_to_delete', [...filesIdsToDelete, id]);
    }
  };

  const userIdToName = (id: any) => {
    const user = users?.filter((u: any) => u.id === id)[0];
    if (user) {
      return user.name;
    }
    return '';
  };

  return (
    <Box sx={matterInformationWrapperStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <Box sx={matterInformationFormWrapperStyles}>
        <Typography>Add documents to the matter.</Typography>
        <Box sx={matterDocumentsInputWrapperStyles}>
          <LEIAAUploadFileArea
            title="Upload documents, emails, images, videos, etc."
            handleDrag={handleDrag}
            handleAddFile={handleAddFile}
            uploadAreaStyles={{
              ...matterQuestionnaireUploadAreaWrapperStyles,
              paddingLeft: '0px',
            }}
          />
          {matterDocuments && matterDocuments.length > 0 && (
            <Typography
              sx={{
                color: '#202020',
                fontSize: '14px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
              }}
            >
              Change the respective document permissions for each user.
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              alignSelf: 'stretch',
            }}
          >
            {matterDocuments &&
              matterDocuments.length > 0 &&
              matterDocuments.map((doc: any, documentIndex: number) => {
                return (
                  <Accordion
                    key={doc.id}
                    sx={matterUsersAccordionWrapperStyles(
                      doc.id === expandedAccordionDocId
                    )}
                    expanded={doc.id === expandedAccordionDocId}
                    onChange={() => {
                      if (doc.id === expandedAccordionDocId) {
                        setExpandedAccordionDocId(null);
                      } else {
                        setExpandedAccordionDocId(doc.id);
                      }
                    }}
                  >
                    <Box>
                      <AccordionSummary
                        expandIcon={
                          <span className="material-icons-round">
                            arrow_drop_down
                          </span>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography>{doc.name}</Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                    </Box>
                    <AccordionDetails sx={matterUsersAccordionDetailsStyles}>
                      <Box>
                        <Box>
                          <Typography>Document Permissions</Typography>
                        </Box>
                        {doc.permissions &&
                          Object.entries(doc.permissions).map(
                            ([userPermission]: any, index: any) => {
                              const docUserId = doc.permissions[index].user_id;
                              const docId = doc.permissions[index].file_id;
                              const filesControllerName = `files[${documentIndex}].permissions[${index}]`;

                              const handleChangeFilePermissions = (
                                event: any
                              ) => {
                                const selectedValue = event.target.value;
                                const [view, change, del] =
                                  selectedValue.split(',');

                                const groupPermissions = {
                                  user_id: docUserId,
                                  view: view === 'true',
                                  change: change === 'true',
                                  delete: del === 'true',
                                  file_id: docId,
                                };

                                const updatedPermissions = {
                                  ...doc.permissions,
                                  [userPermission]: groupPermissions,
                                };

                                setValue(
                                  filesControllerName,
                                  updatedPermissions[index]
                                );
                              };
                              return (
                                <Box key={`${doc.id}-${index}}`}>
                                  <Box>
                                    <Typography>
                                      {userIdToName(
                                        doc.permissions[index].user_id
                                      )}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <LEIAASelect
                                      controllerName={filesControllerName}
                                      control={control}
                                      required={false}
                                      inputHeight="40px !important"
                                      inputWidth="100%"
                                      paperCustomStylings={{
                                        height: '300px',
                                      }}
                                      handleChange={handleChangeFilePermissions}
                                      mapFunction={permissionsObjectToString}
                                    >
                                      <MenuItem disabled value="">
                                        Select a permission
                                      </MenuItem>
                                      {Object.entries(
                                        permissionsCombinations
                                      ).map(([value, label]) => {
                                        return (
                                          <MenuItem key={value} value={value}>
                                            {label}
                                          </MenuItem>
                                        );
                                      })}
                                    </LEIAASelect>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Box>
        </Box>
      </Box>

      <SidePanelInformation>
        {matterDocuments && matterDocuments.length > 0 && (
          <MatterAddedDocumentsInformation
            matterDocuments={matterDocuments}
            handleRemoveFile={handleRemoveFile}
          />
        )}

        {matterInfoFields &&
          matterInfoFields.filter(
            (mi) => mi.value !== '' && mi.value !== undefined
          ).length > 0 && (
            <MatterAddedGeneralInformation
              matterInfoFields={matterInfoFields}
            />
          )}
      </SidePanelInformation>

      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description="We have detected similar existing files. Please change the final name if you want to add this file."
        simpleButtonText="Ok"
        onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
        isTextAlign
      />
    </Box>
  );
};

export default MatterDocuments;
