import { GridPagination, useGridApiContext } from '@mui/x-data-grid';
import { TablePaginationProps } from '@mui/material/TablePagination';
import MuiPagination from '@mui/material/Pagination';

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();
  const { pageSize } = apiRef.current.state.pagination.paginationModel;

  const pageCount = Math.ceil(rowCount / pageSize);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      showFirstButton
      showLastButton
      shape="rounded"
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
      sx={{ padding: '0', margin: '0 !important' }}
    />
  );
}

const LEIAAPagination = (props: any) => {
  return (
    <GridPagination
      sx={{
        marginRight: 'auto',
        padding: '0',
        '& > div > div.MuiTablePagination-spacer': {
          display: 'none',
        },
        '& > div > div.MuiInputBase-root ': {
          border: '1px solid #f5fafd',
          color: 'rgb(110 110 110) !important',
          fontSize: '0.875rem',
          borderRadius: '4px',
          padding: '1px 0px',
          pointerEvents: 'none', // TODO: remove this after fixing the page size picker
        },
        '& > div > div.MuiInputBase-root > svg ': {
          color: '#949ca9 !important',
          display: 'none', // TODO: remove this after fixing the page size picker
        },
        '& > div > p ': { display: 'none' },
        '& > div  ': { padding: '0' },
      }}
      ActionsComponent={Pagination}
      {...props}
    />
  );
};

export default LEIAAPagination;
