import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface FileIconCellProps {
  file: any;
}

const FileIconCell = ({ file }: FileIconCellProps) => {
  let icon;

  if (file.folder) {
    icon = 'folder';
  } else {
    switch (file.file_extension?.toLowerCase()) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        icon = 'image';
        break;
      case 'txt':
        icon = 'text_fields';
        break;
      case 'pdf':
        icon = 'picture_as_pdf';
        break;
      case 'email':
        icon = 'mail';
        break;
      case 'docx':
        icon = 'article';
        break;
      case '':
        icon = '';
        break;
      default:
        icon = 'insert_drive_file';
        break;
    }
  }

  return (
    <Box>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#69707B',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          lineHeight: '22px',
          '& > span': {
            fontSize: '20px',
            marginRight: '5px',
          },
        }}
      >
        <span className="material-icons-round">{icon}</span>
        {file.name}
      </Typography>
    </Box>
  );
};

export default FileIconCell;
