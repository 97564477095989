import { karlaProRegularFontStyles } from '../../styles/textStyles';
import {
  getTaskStatusBackgroundColor,
  getSeverityBackgroundColor,
  getReportStatusBackgroundColor,
  getReportWhistleblowerStatusBackgroundColor,
  getStageStatusBackgroundColor,
} from '../../styles/sharedStyles';

export const datePickerStyles = (useBlueStyles = false) => {
  const blueColor = '#d3ecf7';
  const calendarIconColor = '#69707b';

  return {
    margin: '0px',
    borderWidth: '1px',
    width: '100%',
    '&:focus-within': {
      borderColor: useBlueStyles ? blueColor : '#32353C',
    },
    '&:focus-within > div > fieldset': {
      borderColor: useBlueStyles ? `${blueColor} !important` : '#32353C',
      borderWidth: '1px !important',
    },
    '&:focus-within > div.Mui-error > fieldset': {
      borderColor: '#E22727 !important',
      borderWidth: '1px !important',
    },

    '& input': {
      caretColor: useBlueStyles ? blueColor : '#32353C',
    },
    '& > div': {
      width: '100%',
      height: '40px',
      padding: '0px !important',
      display: 'flex',
      justifyContent: 'space-between',

      '& > input': {
        padding: '0px 12px !important',
        borderRadius: '14px !important',
        width: 'auto',
        height: '40px',
        ...karlaProRegularFontStyles,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#202020',
        '&:hover': {
          cursor: 'default',
        },
        '::placeholder': {
          ...karlaProRegularFontStyles,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
          display: 'flex',
          alignItems: 'center',
          color: '#69707B',
        },
      },
      '& > div.MuiInputAdornment-root': {
        margin: '0px 12px 0px 0px',
        '& > button > div > span': {
          color: useBlueStyles ? calendarIconColor : '#32353C',
        },
      },

      '& > fieldset': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        borderWidth: '1px',
        border: useBlueStyles ? `1px solid ${blueColor}` : '1px solid #32353C',
        borderRadius: '14px',
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0,
      },
      '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
        borderColor: useBlueStyles ? `${blueColor} !important` : '#32353C',
      },
    },
    '& > div.Mui-error': {
      '& > input, & > textarea': {
        color: '#202020',
      },
      '& > fieldset': {
        backgroundColor: 'rgba(226, 39, 39, 0.04)',
        border: '1px solid #E22727',
      },
    },
    '& > p.MuiFormHelperText-root.Mui-error': {
      ...karlaProRegularFontStyles,
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.2px',
      color: '#E22727',
    },
    '& > p.MuiFormHelperText-root': {
      ...karlaProRegularFontStyles,
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.2px',
      color: '#454545',
      margin: '8px 0px 0px 0px',
      padding: '0px 8px',
    },
  };
};

export const dateTextLikePickerStyles = (colorOverride = false) => {
  return {
    margin: '0px',
    border: '0 !important',
    '&:focus-within': {
      border: '0 !important',
    },
    '&:focus-within > div > fieldset': {
      border: '0 !important',
    },
    '&:focus-within > div.Mui-error > fieldset': {
      border: '0 !important',
    },

    '& input': {
      caretColor: '#32353C',
      border: '0 !important',
    },
    '& > div': {
      width: '100%',
      height: '40px',
      padding: '0px !important',
      display: 'flex',
      justifyContent: 'space-between',
      border: '0 !important',
      '& > input': {
        width: '100px',
        border: '0 !important',
        height: '40px',
        ...karlaProRegularFontStyles,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0.2px',
        color: '#0053FF',
        '&:hover': {
          cursor: 'default',
        },
        '::placeholder': {
          ...karlaProRegularFontStyles,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
          display: 'flex',
          alignItems: 'center',
          color: '#69707B',
        },
      },
      '& > div.MuiInputAdornment-root': {
        margin: '0',
        '& > button > div > span': {
          color: '#32353C',
        },
      },

      '& > fieldset': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        border: '0 !important',
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0,
      },
      '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
        border: '0 !important',
      },
    },
    '& > div.Mui-error': {
      '& > input, & > textarea': {
        color: '#202020',
      },
      '& > fieldset': {
        backgroundColor: 'rgba(226, 39, 39, 0.04)',
        border: '0 !important',
      },
    },
    '& > p.MuiFormHelperText-root.Mui-error': {
      ...karlaProRegularFontStyles,
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.2px',
      color: '#E22727',
    },
    '& > p.MuiFormHelperText-root': {
      ...karlaProRegularFontStyles,
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.2px',
      color: '#454545',
      margin: '8px 0px 0px 0px',
      padding: '0px 8px',
    },
  };
};

export const statusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#FFF',
    backgroundColor: getTaskStatusBackgroundColor(inputValue),
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: getTaskStatusBackgroundColor(inputValue),
    },
  };
};

// refactor down
export const severitySelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',
    backgroundColor: getSeverityBackgroundColor(inputValue),
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: getSeverityBackgroundColor(inputValue),
    },
  };
};

// refactor down
export const reportStatusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',
    backgroundColor: getReportStatusBackgroundColor(inputValue),
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: getReportStatusBackgroundColor(inputValue),
    },
  };
};

// refactor down
export const reportWhistleblowerStatusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',
    backgroundColor: getReportWhistleblowerStatusBackgroundColor(inputValue),
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: () => {
        switch (inputValue) {
          case 'Received':
            return '#A962D5';
          case 'In Progress':
            return '#3375FF';
          case 'Further Information Required':
            return '#EDB900';
          case 'Completed':
            return '#38B763';
          default:
            return '#CCCCCC';
        }
      },
    },
  };
};

// refactor down
export const stageStatusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',

    backgroundColor: getStageStatusBackgroundColor(inputValue),
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: () => {
        switch (inputValue) {
          case 'New':
            return '#38B763';
          case 'Open':
            return '#3375FF';
          case 'Delayed':
            return '#EDB900';
          case 'Blocked':
            return '#E22727';
          case 'Closed':
            return '#A962D5';
          default:
            return '#CCCCCC';
        }
      },
    },
  };
};
