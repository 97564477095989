import { useMemo } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { primaryButtonStyles } from '../styles';

interface LoginButtonProps {
  authSuccessCallback: (token: string) => void;
}

interface MicrosoftLoginProps {
  authSuccessCallback: (token: string) => void;
  microsoftData: {
    values: {
      client_id: string;
    };
  };
}

const LoginButton = ({ authSuccessCallback }: LoginButtonProps) => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ['Files.Read', 'Files.Read.All', 'Sites.Read.All', 'user.read'],
      });

      const tokenResponse = await instance.acquireTokenSilent({
        scopes: ['Files.Read', 'Files.Read.All', 'Sites.Read.All', 'user.read'],
        account: loginResponse.account,
      });

      authSuccessCallback(tokenResponse.accessToken);
    } catch (e) {
      console.error('Error during login or token acquisition:', e);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{ width: '100%', marginTop: '30px' }}
    >
      <Button
        variant="contained"
        sx={{ ...primaryButtonStyles(), width: '100%' }}
        onClick={handleLogin}
      >
        <Typography>Authenticate</Typography>
        <span className="material-icons-round">settings_remote</span>
      </Button>
    </Stack>
  );
};

const MicrosoftLogin = ({
  microsoftData,
  authSuccessCallback,
}: MicrosoftLoginProps) => {
  const isAuthenticated = useIsAuthenticated();
  const msalConfig = useMemo(
    () => ({
      auth: {
        clientId: microsoftData.values.client_id,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin,
      },
    }),
    [microsoftData.values.client_id]
  );

  const msalInstance = useMemo(
    () => new PublicClientApplication(msalConfig),
    [msalConfig]
  );

  return (
    <MsalProvider instance={msalInstance}>
      <Box sx={{ width: '100%' }}>
        {!isAuthenticated && (
          <LoginButton authSuccessCallback={authSuccessCallback} />
        )}
      </Box>
    </MsalProvider>
  );
};

export default MicrosoftLogin;
