import { Box, Grid } from '@mui/material';
import StatusUpdates from '../../../components/status-updates/StatusUpdates';
import ScheduledResources from './ScheduledResources';

const MyCalendar = () => {
  return (
    <Grid container spacing={2} sx={{ height: '100%' }}>
      <Grid item xs={8} md={8}>
        <ScheduledResources page="my-dashboard" />
      </Grid>
      <Grid item xs={4} md={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            padding: '0px',
            gap: '32px',
            width: '100%',
          }}
        >
          <StatusUpdates dashboard />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MyCalendar;
