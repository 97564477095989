import { Box, Stack, Typography } from '@mui/material';
import MicrosoftSmall from '../../../../assets/integrations/MicrosoftSmall.png';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';
import MatterDetailMicrosoft from '../MatterDetailMicrosoft';

interface MatterMicrosoftSettingsProps {
  matterId: any;
  matterData: any;
}

const MatterMicrosoftSettings = ({
  matterId,
  matterData,
}: MatterMicrosoftSettingsProps) => {
  const integration = {
    name: 'Microsoft',
    description:
      'Connect seamlessly with Microsoft to access, manage, and collaborate on files from OneDrive and SharePoint.',
    image: MicrosoftSmall,
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '10px',
        border: '1px solid #F5FAFD',
        backgroundColor: 'white',
        borderRadius: '15px',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid #F3F3F3',
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            src={integration.image}
            style={{
              width: '100px',
              height: '100px',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            alt={integration.name}
          />
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginLeft: '5px',
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#69707B',
                  ...karlaProRegularFontStyles,
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '22px',
                }}
              >
                {integration.name}
              </Typography>
            </Stack>

            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#69707B',
                ...karlaProRegularFontStyles,
                fontSize: '14px',
                lineHeight: '22px',
              }}
            >
              {integration.description}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <MatterDetailMicrosoft matterData={matterData} matterId={matterId} />
      </Box>
    </Stack>
  );
};

export default MatterMicrosoftSettings;
