import {
  Box,
  Button,
  InputLabel,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { matterDetailIntegrationConfigRequest } from '../../../api/matters';
import { LEIAAInput } from '../../inputs';
import RelativityConnectionTest from '../../relativity/RelativityConnectionTest';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../matter-creation/matter-creation-steps/matter-information-step/styles';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px',
  },
}));

interface MatterDetailRelativityProps {
  matterData: any;
  matterId: any;
}

const MatterDetailRelativity = ({
  matterData,
  matterId,
}: MatterDetailRelativityProps) => {
  const [showUpdateNotification, setShowUpdateNotification] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmitRelativityConfig: SubmitHandler<FieldValues> = async (
    data: any
  ) => {
    try {
      const integrationData = {
        ...data,
        integration: 'relativity',
      };
      if (matterId) {
        await matterDetailIntegrationConfigRequest(matterId, integrationData);
      }
      setShowUpdateNotification(true);
      matterData.instance = data.instance;
      matterData.project = data.project;
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValue('instance', matterData.instance);
    setValue('project', matterData.project);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTestConnection = async () => {
    await handleSubmit(onSubmitRelativityConfig)();
    setShowUpdateNotification(false);
    setOpen(true);
  };

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmitRelativityConfig)}
    >
      {showUpdateNotification && (
        <CreationNotification
          title="E-Discovery settings updated!"
          subTitle=""
          showNotification={showUpdateNotification}
          handleCloseNotification={() => setShowUpdateNotification(false)}
        />
      )}
      <RelativityConnectionTest
        matterId={matterId}
        open={open}
        setOpen={setOpen}
      />
      <Box sx={matterInformationWrapperStyles}>
        <Box
          sx={{
            ...matterInformationFormWrapperStyles,
            width: '100%',
            alignItems: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...matterInformationInputWrapperStyles,
              gap: '8px !important',
            }}
          >
            <Box>
              <LEIAAInput
                labelText="Relativity instance url"
                controllerName="instance"
                control={control}
                inputPlaceholder="Enter instance url"
                inputWidth="100%"
                inputHeight="40px"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row !important',
                gap: '0px !important',
              }}
            >
              <InputLabel
                sx={{
                  display: 'flex',
                  padding: '0px 12px',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  color: '#464B53',
                  fontSize: '14px',
                  ...karlaProRegularFontStyles,
                  lineHeight: '25px',
                }}
                htmlFor="project"
              >
                <span>*</span>Relativity project ID
              </InputLabel>
              <CustomTooltip
                title="The project id should be a property of the matter inside your Relativity instance, if it is not shown by default in the matters list either ask an admin to add the Project ID field into the fields list or do this yourself by editing the fields list inside your Relativity instance settings"
                placement="right"
              >
                <span
                  style={{ alignSelf: 'center', color: 'grey' }}
                  className="material-icons-round"
                >
                  help_outline
                </span>
              </CustomTooltip>
            </Box>
            <LEIAAInput
              controllerName="project"
              control={control}
              inputPlaceholder="Enter project id"
              inputWidth="100%"
              inputHeight="40px"
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <Button
              variant="contained"
              sx={secondaryButtonStyles()}
              onClick={handleTestConnection}
              disabled={!(watch('instance') && watch('project'))}
            >
              <Typography>Test Connection</Typography>
              <span className="material-icons-round">settings_remote</span>
            </Button>
            <Button
              variant="contained"
              sx={primaryButtonStyles()}
              type="submit"
            >
              <Typography>Save</Typography>
              <span className="material-icons-round">save</span>
            </Button>
          </Stack>
        </Box>
      </Box>
    </form>
  );
};

export default MatterDetailRelativity;
