import { Box, Modal } from '@mui/material';
import { useState } from 'react';
import ImanageAuth from '../../../imanage/ImanageAuth';
import MicrosoftAuth from '../../../microsoft/MicrosoftAuth';
import RelativityAuth from '../../../relativity/RelativityAuth';

interface IntegrationsAuthModalProps {
  matterId: string;
  integrationResponse: any;
  openAuthModal: boolean;
  targetIntegration: null | string;
  setOpenAuthModal: any;
  authSuccessHandler: any;
}

const IntegrationsAuthModal = ({
  matterId,
  integrationResponse,
  openAuthModal,
  targetIntegration,
  setOpenAuthModal,
  authSuccessHandler,
}: IntegrationsAuthModalProps) => {
  const [imanageUserData, setImanageUserData] = useState({
    user: '',
    password: '',
    connected: false,
    token: '',
    customer: '',
  });

  const [relativityUserData, setRelativityUserData] = useState({
    user: '',
    password: '',
    connected: false,
  });

  const closeWithSuccess = (integration: string) => {
    setOpenAuthModal(false);
    authSuccessHandler(integration);
  };

  return (
    <>
      {openAuthModal && targetIntegration && (
        <Modal
          open={openAuthModal}
          onClose={() => setOpenAuthModal(false)}
          aria-labelledby="integration-modal"
          aria-describedby="integration-auth-modal"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'auto',
              padding: '0',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
            }}
          >
            {targetIntegration === 'iManage' && (
              <ImanageAuth
                matterId={matterId}
                imanageUserData={imanageUserData}
                setImanageUserData={setImanageUserData}
                integrationAuthPopup
                closeIntegrationAuthPopup={closeWithSuccess}
              />
            )}
            {targetIntegration === 'Relativity' && (
              <RelativityAuth
                matterId={matterId}
                relativityUserData={relativityUserData}
                setRelativityUserData={setRelativityUserData}
                integrationAuthPopup
                closeIntegrationAuthPopup={closeWithSuccess}
              />
            )}
            {targetIntegration === 'Microsoft' && (
              <MicrosoftAuth
                matterId={matterId}
                integrationAuthPopup
                closeIntegrationAuthPopup={closeWithSuccess}
                microsoftData={integrationResponse.microsoft}
              />
            )}
          </Box>
        </Modal>
      )}
      <Box />
    </>
  );
};

export default IntegrationsAuthModal;
