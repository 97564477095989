import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  matterCreationFooterWrapperStyles,
  matterCreationHeaderWrapperStyles,
} from '../../components/matters/matter-creation/styles';
import {
  primaryButtonStyles,
  simpleGoBackButtonStyles,
  tertiaryButtonStyles,
} from '../../components/styles';
import MatterTasks from '../../components/matters/matter-creation/matter-creation-steps/matter-tasks-step/MatterTasks';
import { MatterTasksPayload } from '../../types/matters';
import { validateStepPayload } from '../../utils/utils';
import LoaderSpinner from '../../components/shared/misc-components/LoaderSpinner';
import NotificationToast from '../../components/shared/toast/NotificationToast';
import { createMatterStepRequest } from '../../api/matters';

const AddTask = () => {
  const { matterId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const matterDetails: any = location.state?.matterDetails;
  matterDetails.tasks = [
    {
      id: uuidv4(),
      name: '',
      description: '',
      status: 'To Do',
      deadline: undefined,
      assigned_to: undefined,
    },
  ];
  const [showNotification, setShowNotification] = useState(false);
  const [loading, Setloading] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    unregister,
    formState: { touchedFields },
  } = useForm({
    defaultValues: matterDetails || {},
  });

  const handleGoBack = () => {
    navigate(`/matters/detail/${matterId}`, {
      state: { currentStep: 3 },
    });
  };

  // Step 3: Matter Tasks
  const validateMatterTasks = (payload: any): MatterTasksPayload => {
    const allowedFields: (keyof MatterTasksPayload)[] = [
      'tasks',
      'tasks_to_delete',
    ];

    return validateStepPayload<MatterTasksPayload>(
      payload,
      allowedFields
    ) as MatterTasksPayload;
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    if (matterId) {
      const sanitizedData = data;

      const cleanedData = validateMatterTasks(sanitizedData);

      try {
        Setloading(true);
        await createMatterStepRequest(matterId, 3, cleanedData);
        Setloading(false);
        handleGoBack();
        setRequestError(null);
        setShowNotification(false);
      } catch (error: any) {
        Setloading(false);
        setRequestError(error.response.data.message);
        setShowNotification(true);
      }
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        position: 'relative',

        '& > form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '0px 32px',
          '&  > div:nth-of-type(1)': {
            padding: '15px 0px',
          },
          '&  > div:nth-of-type(2)': {
            paddingBottom: '20px',
          },
          '& > div:last-of-type': {
            marginTop: 'auto',
          },

          width: '100%',
        },
      }}
      id="creation-box"
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {loading && (
        <LoaderSpinner loading={loading} onClose={() => Setloading(false)} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={matterCreationHeaderWrapperStyles}>
          <Box>
            <Box>
              <Typography>Add Task</Typography>
            </Box>
            <Button
              sx={simpleGoBackButtonStyles}
              variant="text"
              onClick={handleGoBack}
            >
              <span className="material-symbols-outlined">arrow_back</span>
              <Typography variant="caption" component="p">
                Back
              </Typography>
            </Button>
          </Box>
        </Box>

        <MatterTasks
          control={control}
          watch={watch}
          setValue={setValue}
          currentStep={2}
          previousStep={2}
          touchedFields={touchedFields}
        />
        <Box sx={matterCreationFooterWrapperStyles}>
          <Box>
            <Button
              sx={tertiaryButtonStyles()}
              variant="contained"
              onClick={handleGoBack}
            >
              <Typography>Cancel</Typography>
            </Button>

            <Button
              variant="contained"
              sx={primaryButtonStyles()}
              type="submit"
            >
              <Typography>Save</Typography>
              <span className="material-icons-round">save</span>
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddTask;
