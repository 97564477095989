import { motion } from 'motion/react';
import { Box, Stack, Typography, Skeleton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
  sofiaProRegularFontStyles,
} from '../../../../styles/textStyles';
import { TimelineEvent } from '../types';
import MatterDetailTimelineEditEvent from './MatterDetailTimelineEditEvent';

interface MatterDetailTimelineListProps {
  currentDate: Date;
  formatEntryDate: (date: Date) => { dayOfWeek: string; fullDate: string };
  events: TimelineEvent[];
  loading: boolean;
  matterId: any;
  matterDetails: any;
  refreshTimeline: any;
  setShowArchiveNotification: any;
  setShowEditNotification: any;
}

const MatterDetailTimelineList = ({
  currentDate,
  formatEntryDate,
  events,
  loading,
  matterId,
  matterDetails,
  refreshTimeline,
  setShowArchiveNotification,
  setShowEditNotification,
}: MatterDetailTimelineListProps) => {
  const groupedEvents: Record<string, TimelineEvent[]> = {};
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const currentDateRef = useRef<HTMLDivElement | null>(null);
  const lastEventRef = useRef<HTMLDivElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [clickedEvent, setClickedEvent] = useState<TimelineEvent | null>(null);

  events.forEach((event) => {
    const eventDate = event.date;
    if (!groupedEvents[eventDate]) {
      groupedEvents[eventDate] = [];
    }
    groupedEvents[eventDate].push(event);

    Object.keys(groupedEvents).forEach((date) => {
      groupedEvents[date].sort((a, b) => {
        const isATimeInvalid = a.time === '-';
        const isBTimeInvalid = b.time === '-';

        if (isATimeInvalid && isBTimeInvalid) return 0;

        if (isATimeInvalid) return -1;
        if (isBTimeInvalid) return 1;

        return (
          new Date(`1970-01-01T${a.time}`).getTime() -
          new Date(`1970-01-01T${b.time}`).getTime()
        );
      });
    });
  });

  const uniqueDates = Object.keys(groupedEvents).sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  const eventClickHandler = (event: TimelineEvent) => {
    setClickedEvent(event);
    setOpenModal(true);
  };

  useEffect(() => {
    if (currentDateRef.current && timelineRef.current) {
      currentDateRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentDate]);

  useEffect(() => {
    if (currentDateRef.current && timelineRef.current) {
      setTimeout(() => {
        currentDateRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 0);
    }
  }, [currentDate]);

  return (
    <>
      <MatterDetailTimelineEditEvent
        matterId={matterId}
        matterDetails={matterDetails}
        refreshTimeline={refreshTimeline}
        event={clickedEvent}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setShowArchiveNotification={setShowArchiveNotification}
        setShowEditNotification={setShowEditNotification}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {!loading && (
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="scrollable-content"
            style={{
              overflowY: 'auto',
              maxHeight: '80vh',
              marginTop: '16px',
              maxWidth: '70%',
              width: '100%',
            }}
            ref={timelineRef}
          >
            {uniqueDates.length === 0 ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                style={{
                  textAlign: 'center',
                  color: '#69707B',
                  marginTop: '100px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    ...karlaProRegularFontStyles,
                  }}
                >
                  No Events
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    marginTop: '8px',
                    ...karlaProRegularItalicFontStyles,
                  }}
                >
                  Click <strong>+ Add Event</strong> to log key details and
                  build the matter timeline.
                </Typography>
              </motion.div>
            ) : (
              uniqueDates.map((dateString, index) => {
                const date = new Date(dateString);
                const formattedDate = formatEntryDate(date);
                let daysPassed = null;

                if (index > 0) {
                  const previousDate = new Date(uniqueDates[index - 1]);
                  const differenceInTime =
                    date.getTime() - previousDate.getTime();
                  daysPassed = Math.ceil(differenceInTime / (1000 * 3600 * 24));
                }

                const currentYear = date.getFullYear();
                const previousYear =
                  index > 0
                    ? new Date(uniqueDates[index - 1]).getFullYear()
                    : null;

                return (
                  <Box key={dateString} sx={{ position: 'relative', mt: 4 }}>
                    {previousYear !== currentYear && (
                      <Box
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '24px',
                            color: '#464B53',
                            ...sofiaProRegularFontStyles,
                          }}
                        >
                          {currentYear}
                        </Typography>
                      </Box>
                    )}

                    {/* Date Square */}
                    <motion.div
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.3 }}
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      {daysPassed && daysPassed > 1 && (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#69707B',
                            textAlign: 'center',
                            ...karlaProRegularItalicFontStyles,
                            margin: '10px',
                          }}
                        >
                          {daysPassed} days after
                        </Typography>
                      )}
                      <Box
                        sx={{
                          borderRadius: '5px',
                          padding: '16px',
                          textAlign: 'center',
                          backgroundColor: '#F5FAFD',
                          border:
                            date.toDateString() === currentDate.toDateString()
                              ? '2px solid #EFEFEF'
                              : '2px solid #F5FAFD',
                        }}
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Typography
                            sx={{
                              ...karlaProRegularFontStyles,
                              fontWeight: 300,
                              fontSize: '12px',
                              lineHeight: '12px',
                              letterSpacing: '0.2px',
                              textTransform: 'uppercase',
                              color: '#69707B',
                            }}
                          >
                            {formattedDate.dayOfWeek}
                          </Typography>
                          <Typography
                            sx={{
                              ...sofiaProRegularFontStyles,
                              fontWeight: 400,
                              fontSize: '18px',
                              lineHeight: '21px',
                              letterSpacing: '2px',
                              textTransform: 'uppercase',
                              color: '#69707B',
                            }}
                          >
                            {formattedDate.fullDate}
                          </Typography>
                        </Stack>
                      </Box>
                    </motion.div>

                    <Box
                      sx={{
                        position: 'relative',
                        left: '50%',
                        top: 0,
                        height: '15px',
                        width: '2px',
                        backgroundColor: '#EFEFEF',
                        zIndex: 1,
                        transform: 'translateX(-50%)',
                      }}
                    />

                    {/* Date Events */}
                    <Box sx={{ position: 'relative' }}>
                      {/* Vert Event Connector */}
                      <Box
                        sx={{
                          position: 'absolute',
                          left: '50%',
                          top: 0,
                          height: `calc(100%)`,
                          width: '2px',
                          backgroundColor: '#EFEFEF',
                          zIndex: 1,
                          transform: 'translateX(-50%)',
                        }}
                      />

                      {groupedEvents[dateString].map((event, eventIndex) => {
                        const isLastEvent =
                          eventIndex === groupedEvents[dateString].length - 1;

                        return (
                          <motion.div
                            key={eventIndex}
                            ref={isLastEvent ? lastEventRef : null}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                              duration: 0.4,
                              delay: eventIndex * 0.1,
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingTop: '10px',
                            }}
                          >
                            {/* Left: Storypoints */}
                            {event.type === 'Storypoint' ? (
                              <Box
                                sx={{
                                  textAlign: 'right',
                                  flex: 1,
                                  cursor: 'pointer',
                                }}
                                onClick={() => eventClickHandler(event)}
                              >
                                <Typography
                                  sx={{
                                    marginRight: '27px',
                                    color: '#464B53',
                                    fontSize: '12px',
                                    ...karlaProRegularFontStyles,
                                    lineHeight: '18px',
                                  }}
                                >
                                  {event.time}
                                </Typography>
                                <Stack
                                  direction="row"
                                  spacing={0}
                                  sx={{
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      p: 2,
                                      background: 'white',
                                      border: '1px solid #EFEFEF',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: '#464B53',
                                        fontSize: '14px',
                                        ...karlaProRegularFontStyles,
                                        lineHeight: '22px',
                                      }}
                                    >
                                      {event.title}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '20px',
                                      background: '#EFEFEF',
                                      height: '2px',
                                    }}
                                  />
                                </Stack>
                              </Box>
                            ) : (
                              <Box sx={{ flex: 1 }} />
                            )}

                            {/* Right: Activity, Evidence, and Event */}
                            {event.type !== 'Storypoint' ? (
                              <Box
                                sx={{
                                  textAlign: 'left',
                                  flex: 1,
                                  cursor: 'pointer',
                                }}
                                onClick={() => eventClickHandler(event)}
                              >
                                <Typography
                                  sx={{
                                    marginLeft: '27px',
                                    color: '#464B53',
                                    fontSize: '12px',
                                    ...karlaProRegularFontStyles,
                                    lineHeight: '18px',
                                  }}
                                >
                                  {event.time}
                                </Typography>
                                <Stack
                                  direction="row"
                                  spacing={0}
                                  sx={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '20px',
                                      background: '#EFEFEF',
                                      height: '2px',
                                    }}
                                  />
                                  <Box
                                    sx={
                                      event.type === 'Internal Event'
                                        ? {
                                            padding: '5px 16px',
                                            background: '#3375FF',
                                            border: '1px solid #3375FF',
                                            borderRadius: '10px',
                                            height: 'auto',
                                          }
                                        : {
                                            p: 2,
                                            background: '#F5FAFD',
                                            border: '1px solid #F5FAFD',
                                            borderRadius: '10px',
                                          }
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        color:
                                          event.type === 'Internal Event'
                                            ? 'white'
                                            : '#464B53',
                                        fontSize: '14px',
                                        ...karlaProRegularFontStyles,
                                        lineHeight: '22px',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {event.title}
                                      {event.type === 'Note' && (
                                        <span
                                          className="material-icons-outlined"
                                          style={{
                                            marginLeft: '8px',
                                          }}
                                        >
                                          sticky_note_2
                                        </span>
                                      )}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                            ) : (
                              <Box sx={{ flex: 1 }} />
                            )}
                          </motion.div>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })
            )}
          </motion.div>
        )}
      </Box>
    </>
  );
};

export default MatterDetailTimelineList;
