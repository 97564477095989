import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Menu,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import TasksListTable from '../../shared/tasks-list-table/TasksListTable';
import {
  batchDeleteTasksRequest,
  matterDetailTasksRequest,
  matterDetailsRequest,
} from '../../../api/matters';
import { primaryButtonStyles } from '../../styles';
import ListButtons from '../../shared/list-buttons/ListButtons';
import { useTaskPermissions } from '../../../hooks/usePermissions';
import NotificationToast from '../../shared/toast/NotificationToast';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import { getTaskBoardRequest } from '../../../api/tasks';
import { Column } from '../../../pages/my-dashboard/tasks/taskstyles';
import TaskBoard from '../../../pages/my-dashboard/tasks/TaskBoard';
import { sortButtonsPaperWrapperStyles } from '../../user-profile/styles';
import MatterNotesListModal from '../../matter-notes/MatterNotesListModal';

interface MatterDetailTasksProps {
  matterId: any;
  matterDetails: any;
  setMatterDetails: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ display: value !== index ? 'none' : 'block' }}
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const MatterDetailTasks = ({
  matterId,
  matterDetails,
  setMatterDetails,
}: MatterDetailTasksProps) => {
  const taskPermissions = useTaskPermissions();
  const [loading, setLoading] = useState(true);
  const [matterDetailTasks, setMatterDetailsTasks] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [checkedTasks, setCheckedTasks] = useState([]);
  const [, setSelectedTask] = useState<any>(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [columns, setColumns] = useState<Column | null>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const openSortMenu = Boolean(sortAnchorEl);
  const [selectedSortTasks, setSelectedSortTasks] = useState('');
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);

  const handleFetchColumnData = async (sort: any = false) => {
    setLoading(true); // Start loading

    try {
      const response = await getTaskBoardRequest(matterId, sort);

      setColumns(response);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setShowErrorNotification(true);
        setRequestError(error.response.data.message);
      } else {
        setShowErrorNotification(true);
        setRequestError('Task management error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGetDashboardDetailsTasks = async (searchText = '') => {
    setLoading(true); // Start loading
    try {
      if (matterId) {
        const response = await matterDetailTasksRequest(matterId, searchText);

        setMatterDetailsTasks({ task_list: response.tasks });
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDashboardDetailsTasks();
    handleFetchColumnData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleGetDashboardDetailsTasks(searchText);
  };

  const handleTaskAdd = () => {
    navigate(`/matter/addTask/${matterId}`, {
      state: { currentStep: 2, matterDetails },
    });
  };

  const handleEditTask = async () => {
    navigate(`/matters/edit/${matterId}`, {
      state: {
        currentStep: 2,
        matterDetails,
        isEdit: true,
        showSteps: false,
        hideFooterNav: true,
      },
    });
  };

  const handleUpdateMatterDetails = async () => {
    try {
      if (matterId) {
        const response = await matterDetailsRequest(matterId);
        setMatterDetails(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleBatchDeleteTasks = async () => {
    try {
      await batchDeleteTasksRequest(checkedTasks);
      await handleGetDashboardDetailsTasks();
      await handleUpdateMatterDetails();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCheckedTasks([]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setTabValue(newValue);
    if (newValue === 0) {
      handleGetDashboardDetailsTasks();
    } else {
      handleFetchColumnData();
    }
  };

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortTasksChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const { value } = event.currentTarget.childNodes[0] as HTMLInputElement;
    if (value === selectedSortTasks) {
      setSelectedSortTasks('');
      handleFetchColumnData();
    } else if (value) {
      setSelectedSortTasks(value);
      handleFetchColumnData(value);
    }
  };

  const addNoteHandler = () => {
    setOpenNoteModal(true);
  };

  return (
    <>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Task archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {openNoteModal && (
        <MatterNotesListModal
          matterId={matterId}
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          resourceName="InvestigationTask"
          resourceId={checkedTasks[0]}
        />
      )}
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            borderBottom: '1px solid #949CA9',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& > div': {
                '& > div': {
                  '& > button': {
                    display: 'flex',
                    width: '224px',
                    padding: '8px 24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '24px 24px 0px 0px',
                    '&.Mui-selected': {
                      backgroundColor: '#F5FAFD',
                      color: '#0053FF',
                    },

                    color: '#69707B',
                    textTransform: 'none',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                '& > span.MuiTabs-indicator': {
                  backgroundColor: '#0053FF',
                },
              },
            }}
          >
            <Tab label="List View" {...a11yProps(0)} />
            <Tab label="Board View" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '40px',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}>
            {taskPermissions.add && (
              <Button
                variant="contained"
                onClick={handleTaskAdd}
                sx={{ ...primaryButtonStyles(), height: '40px' }}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add task</Typography>
              </Button>
            )}
            {tabValue === 1 && (
              <>
                <Button
                  id="sort-button"
                  aria-controls={openSortMenu ? 'sort-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSortMenu ? 'true' : undefined}
                  onClick={handleSortClick}
                  endIcon={
                    openSortMenu ? (
                      <span className="material-icons-round">
                        arrow_drop_up
                      </span>
                    ) : (
                      <span className="material-icons-round">
                        arrow_drop_down
                      </span>
                    )
                  }
                  sx={{
                    boxShadow: openSortMenu
                      ? '0px 0px 10px 0px rgba(21, 10, 84, 0.30)'
                      : 'none',
                    '& > p': {
                      width: '56px',
                      ...karlaProRegularFontStyles,
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: openSortMenu ? 700 : 500,
                      letterSpacing: '0.2px',
                    },
                    display: 'flex',
                    height: '40px',
                    padding: '8px 8px 8px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '16px',
                    border: '1px solid #0053FF',
                    background: '#FFF',
                    color: '#0053FF',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#EBF8FE',
                      color: '#0B25B0',
                      border: '1px solid #0B25B0',
                    },
                    '&:focus': {
                      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
                    },
                    '&:active': {
                      backgroundColor: '#EBF8FE',
                      borderColor: '#140070',
                      color: '#140070',
                    },
                  }}
                >
                  <Typography variant="caption" component="p">
                    Sort By
                  </Typography>
                </Button>
                <Menu
                  id="sort-menu"
                  anchorEl={sortAnchorEl}
                  open={openSortMenu}
                  onClose={handleSortClose}
                  MenuListProps={{
                    'aria-labelledby': 'sort-button',
                  }}
                  sx={sortButtonsPaperWrapperStyles}
                >
                  <RadioGroup value={selectedSortTasks}>
                    <FormControlLabel
                      value="due_date"
                      control={<Radio onClick={handleSortTasksChange} />}
                      label="Due Date: newest to oldest"
                    />
                    <FormControlLabel
                      value="title"
                      control={<Radio onClick={handleSortTasksChange} />}
                      label="Task Name: Alphabetically"
                    />
                  </RadioGroup>
                </Menu>
              </>
            )}
          </Box>
          {tabValue === 0 && (
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by task..."
              sx={{
                ...homePortalSearchInputStyles,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
          )}
        </Box>
        {checkedTasks.length > 0 &&
          (taskPermissions.change || taskPermissions.delete) &&
          tabValue === 0 && (
            <ListButtons
              editHandler={taskPermissions.change && handleEditTask}
              checkedResources={checkedTasks}
              resourceName="task"
              deleteHandler={taskPermissions.delete && handleBatchDeleteTasks}
              addNoteHandler={addNoteHandler}
            />
          )}
      </Box>
      <Box sx={{ width: '100%', marginTop: '-40px' }}>
        <CustomTabPanel value={tabValue} index={0}>
          {matterDetailTasks && !loading && (
            <TasksListTable
              tasksListData={matterDetailTasks}
              listClass="task-list"
              loading={loading}
              setCheckedTasks={setCheckedTasks}
              setSelectedTask={setSelectedTask}
              handleDetailTask={(task: any) => {
                navigate(`/matters/detail/${matterId}/tasks/${task.id}`, {
                  state: { currentStep: 4, matterDetails },
                });
              }}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          {columns && !loading && (
            <Box sx={{ marginTop: '40px' }}>
              <TaskBoard
                columns={columns}
                setShowErrorNotification={setShowErrorNotification}
                setRequestError={setRequestError}
                setColumns={setColumns}
                matterDetail
              />
            </Box>
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default MatterDetailTasks;
