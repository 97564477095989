/* eslint-disable no-useless-escape */
import { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ChatInterface, { Message } from './ChatInterface';
import { LLMChat, LLMChatEvent } from './api';
import { processInterviewRequest } from '../../api/interviews';

interface InterviewData {
  category: 'Scoping' | 'Witness';
  type: 'In-Person' | 'Automated';
  firstName: string;
  lastName: string;
  jobTitle: string;
  organisation: string;
  subdivision: string;
  employeeId: string;
  email: string;
  interviewDate: string;
  interviewStartTime: string;
  interviewEndTime: string;
  // timezone: string;
  interviewers: string[];
  questions: string[];
  purpose: string;
  instructions: string;
}

interface InterviewChatProps {
  open: boolean;
  onClose: () => void;
  matterId: string;
}

const InterviewChat = ({ open, onClose, matterId }: InterviewChatProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [interviewData, setInterviewData] = useState<InterviewData | null>(
    null
  );
  const [confirmationMessageId, setConfirmationMessageId] = useState<
    string | null
  >(null);

  const llmChatRef = useRef<LLMChat | null>(null);
  const initialized = useRef(false);

  useEffect(() => {
    if (messages.length === 1 && messages[0].role === 'system') {
      const welcomeMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content:
          "I'll help you schedule an interview. First, let's determine what type of interview this will be. Is this a 'Scoping' or 'Witness' interview?",
        createdAt: new Date(),
        isWelcomeMessage: true,
      };
      setMessages([...messages, welcomeMessage]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const extractInterviewDataFromMessage = (message: string): InterviewData => {
    const data: InterviewData = {
      category: 'Scoping',
      type: 'In-Person',
      firstName: '',
      lastName: '',
      jobTitle: '',
      organisation: '',
      subdivision: '',
      employeeId: '',
      email: '',
      interviewDate: '',
      interviewStartTime: '',
      interviewEndTime: '',
      // timezone: '',
      interviewers: [],
      questions: [],
      purpose: '',
      instructions: '',
    };

    const messageLines = message.split('\n');

    // eslint-disable-next-line no-restricted-syntax
    for (const line of messageLines) {
      const lowerLine = line.toLowerCase().trim();

      // Interview category detection
      if (
        lowerLine.startsWith('interview category:') ||
        lowerLine.startsWith('category:')
      ) {
        const categoryValue = line
          .substring(line.indexOf(':') + 1)
          .trim()
          .toLowerCase();
        if (categoryValue.includes('scoping')) {
          data.category = 'Scoping';
        } else if (categoryValue.includes('witness')) {
          data.category = 'Witness';
        }
      }

      // Interview type detection
      else if (
        lowerLine.startsWith('interview type:') ||
        lowerLine.startsWith('type:')
      ) {
        const typeValue = line
          .substring(line.indexOf(':') + 1)
          .trim()
          .toLowerCase();
        if (
          typeValue.includes('in-person') ||
          typeValue.includes('in person')
        ) {
          data.type = 'In-Person';
        } else if (typeValue.includes('automated')) {
          data.type = 'Automated';
        }
      }

      // Interviewee information
      else if (lowerLine.startsWith('first name:')) {
        data.firstName = line.substring(line.indexOf(':') + 1).trim();
      } else if (lowerLine.startsWith('last name:')) {
        data.lastName = line.substring(line.indexOf(':') + 1).trim();
      } else if (lowerLine.startsWith('job title:')) {
        data.jobTitle = line.substring(line.indexOf(':') + 1).trim();
      } else if (
        lowerLine.startsWith('organisation:') ||
        lowerLine.startsWith('organization:')
      ) {
        data.organisation = line.substring(line.indexOf(':') + 1).trim();
      } else if (
        lowerLine.startsWith('sub-division:') ||
        lowerLine.startsWith('subdivision:') ||
        lowerLine.startsWith('subsidiary:') ||
        lowerLine.startsWith('business unit:')
      ) {
        data.subdivision = line.substring(line.indexOf(':') + 1).trim();
      } else if (lowerLine.startsWith('employee id:')) {
        data.employeeId = line.substring(line.indexOf(':') + 1).trim();
      } else if (
        lowerLine.startsWith('email:') ||
        lowerLine.startsWith('email address:')
      ) {
        data.email = line.substring(line.indexOf(':') + 1).trim();
      }

      // Interview scheduling
      else if (
        lowerLine.startsWith('interview date:') ||
        lowerLine.startsWith('date:')
      ) {
        data.interviewDate = line.substring(line.indexOf(':') + 1).trim();
      } else if (lowerLine.startsWith('start time:')) {
        data.interviewStartTime = line.substring(line.indexOf(':') + 1).trim();
      } else if (lowerLine.startsWith('end time:')) {
        data.interviewEndTime = line.substring(line.indexOf(':') + 1).trim();
      } else if (lowerLine.startsWith('time:')) {
        const timeString = line.substring(line.indexOf(':') + 1).trim();
        if (timeString.includes('-')) {
          const [startTime, endTime] = timeString
            .split('-')
            .map((t) => t.trim());
          data.interviewStartTime = startTime;
          data.interviewEndTime = endTime;
        }
      }
      // else if (lowerLine.startsWith('timezone:')) {
      //   data.timezone = line.substring(line.indexOf(':') + 1).trim();
      // }

      // Interviewers
      else if (lowerLine.startsWith('interviewers:')) {
        const interviewersString = line.substring(line.indexOf(':') + 1).trim();
        data.interviewers = interviewersString.split(',').map((i) => i.trim());
      }

      // Questions
      else if (lowerLine.startsWith('questions:')) {
        const questionsStartIndex = messageLines.indexOf(line);
        let currentIndex = questionsStartIndex + 1;

        while (
          currentIndex < messageLines.length &&
          !messageLines[currentIndex].toLowerCase().startsWith('purpose:') &&
          !messageLines[currentIndex].toLowerCase().startsWith('instructions:')
        ) {
          const questionLine = messageLines[currentIndex].trim();

          if (
            questionLine &&
            !questionLine.toLowerCase().startsWith('questions:')
          ) {
            // Clean up numbering if present
            let cleanQuestion = questionLine;
            if (/^\d+[\.\)]\s/.test(questionLine)) {
              cleanQuestion = questionLine.replace(/^\d+[\.\)]\s/, '');
            }

            if (cleanQuestion) {
              data.questions.push(cleanQuestion);
            }
          }

          currentIndex += 1;
        }
      }

      // Purpose and instructions
      else if (
        lowerLine.startsWith('purpose:') ||
        lowerLine.startsWith('what this interview is for:')
      ) {
        data.purpose = line.substring(line.indexOf(':') + 1).trim();
      } else if (
        lowerLine.startsWith('instructions:') ||
        lowerLine.startsWith('specific instructions:')
      ) {
        data.instructions = line.substring(line.indexOf(':') + 1).trim();
      }
    }

    return data;
  };

  const addConfirmationMessage = (
    data: InterviewData,
    originalMessageId: string
  ) => {
    console.log('data', data);
    setConfirmationMessageId(originalMessageId);

    const confirmationMessage: Message = {
      id: uuidv4(),
      role: 'assistant',
      content: 'Please confirm interview creation',
      createdAt: new Date(),
      isConfirmation: true,
      confirmationData: data,
    };

    setMessages((prevMessages) => [...prevMessages, confirmationMessage]);
  };

  const handleChatEvent = (event: LLMChatEvent) => {
    try {
      switch (event.type) {
        case 'message:added':
          // eslint-disable-next-line no-case-declarations
          const newMessage: Message = {
            id: event.message.id,
            role: event.message.role,
            content: event.message.content,
            createdAt: new Date(event.message.timestamp),
          };

          if (newMessage.role === 'user' && !newMessage.content.trim()) {
            break;
          }

          setMessages((prevMessages) => {
            const exists = prevMessages.some((msg) => msg.id === newMessage.id);
            if (exists) return prevMessages;
            return [...prevMessages, newMessage];
          });
          break;

        case 'message:updated':
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === event.message.id
                ? {
                    ...msg,
                    content: event.message.content,
                  }
                : msg
            )
          );
          break;

        case 'stream:start':
          setIsStreaming(true);
          break;

        case 'stream:end':
          setIsStreaming(false);

          // Check if the last message indicates we should show a confirmation
          // eslint-disable-next-line no-case-declarations
          const lastMessage = event.message;
          if (
            lastMessage &&
            lastMessage.role === 'assistant' &&
            lastMessage.content
              .toLowerCase()
              .includes('would you like to proceed')
          ) {
            const extractedData = extractInterviewDataFromMessage(
              lastMessage.content
            );

            if (extractedData) {
              setInterviewData(extractedData);
              addConfirmationMessage(extractedData, lastMessage.id);

              setMessages((prevMessages) =>
                prevMessages.filter((msg) => msg.id !== lastMessage.id)
              );
            }
          }
          break;

        case 'error':
          console.error('LLMChat error:', event.error);
          setError(`Error: ${event.error.message}`);
          setIsStreaming(false);
          break;
        default:
          break;
      }
    } catch (err) {
      console.error('Error handling chat event:', err);
      setError(
        `Error handling chat event: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  const initializeChat = () => {
    try {
      if (!process.env.REACT_APP_OPENAI_API_KEY) {
        console.error('No OpenAI API key provided');
        setError('Missing API key. Please provide an OpenAI API key.');
        return;
      }

      const INTERVIEW_SYSTEM_PROMPT = `You are an interview scheduling assistant. Help the user create an interview by collecting the following required details (marked with *) and optional details:

Step 1: Choose interview category and type
*Interview Category: Scoping or Witness
*Interview Type: In-Person or Automated

Step 2: Add individual to be interviewed
*First Name
*Last Name
Job Title
*Organisation
Sub-division/Subsidiary/Business Unit
Employee ID
*Email Address

Step 3: Schedule the interview
*Interview Date (in DD/MM/YYYY format)
*Interview Time (Start and End time in HH:MM format, ensure end time is after start time)

Step 4: Add users to conduct the interview
Interviewers (comma-separated list)

Step 5: Prepare the interview outline
Questions (list of questions for the interview)

Step 6: Additional information (optional)
Purpose (What this interview is for)
Instructions (Specific instructions for the interviewer)

Once you have collected all required information and any optional information the user provides, ask the user if they want to proceed by saying:

"I have all the information needed to schedule this interview. Here is what I have gathered:

Interview Category: [Collected Category]
Interview Type: [Collected Type]
First Name: [Collected First Name]
Last Name: [Collected Last Name]
Job Title: [Collected Job Title]
Organisation: [Collected Organisation]
Subdivision: [Collected Subdivision]
Employee ID: [Collected Employee ID]
Email Address: [Collected Email]
Interview Date: [Collected Date in DD/MM/YYYY format]
Time: [Start Time in HH:MM format] - [End Time in HH:MM format]
Interviewers: [List of Interviewers]
Questions: [List of Questions]
Purpose: [Collected Purpose]
Instructions: [Collected Instructions]

Would you like to proceed?"
`;

      const chat = new LLMChat({
        id: `interview_chat_${uuidv4()}`,
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        systemPrompt: INTERVIEW_SYSTEM_PROMPT,
        temperature: 0,
      });

      const systemMessage: Message = {
        id: uuidv4(),
        role: 'system',
        content: INTERVIEW_SYSTEM_PROMPT,
        createdAt: new Date(),
      };

      setMessages([systemMessage]);
      setConfirmationMessageId(null);

      const unsubscribe = chat.subscribe(handleChatEvent);

      llmChatRef.current = chat;
      initialized.current = true;
      setInterviewData(null);

      // eslint-disable-next-line consistent-return
      return () => {
        unsubscribe();
        llmChatRef.current = null;
        initialized.current = false;
      };
    } catch (err) {
      console.error('Error initializing LLMChat:', err);
      setError(
        `Failed to initialize chat: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  const onSubmit = async (input: string) => {
    if (isStreaming) {
      setIsStreaming(false);
      return;
    }

    if (!input.trim()) {
      return;
    }

    try {
      setError(null);
      if (llmChatRef.current) {
        setIsStreaming(true);

        try {
          await llmChatRef.current.sendMessage(input);
        } catch (sendError) {
          console.error('Error sending message:', sendError);
          setIsStreaming(false);
          setError(
            `Failed to send message: ${
              sendError instanceof Error ? sendError.message : String(sendError)
            }`
          );
        }
      } else {
        console.error('LLMChat instance not available');
        setError('Chat system not initialized. Please reload the page.');
      }
    } catch (err) {
      console.error('Error in submit handler:', err);
      setError(
        `Error sending message: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
      setIsStreaming(false);
    }
  };

  const handleApproveInterview = async (data: any) => {
    console.log('gathered data:', data);

    try {
      await processInterviewRequest(matterId, data);

      const confirmationMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content: `Interview with ${data.firstName} ${data.lastName} has been scheduled successfully!`,
        createdAt: new Date(),
      };

      setMessages((prev) => [...prev, confirmationMessage]);
      setError(null);
    } catch (e: any) {
      console.error('Error processing interview data:', e);

      let errorMessage = 'An unexpected error occurred';

      if (e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      } else if (e.message) {
        errorMessage = e.message;
      }

      const combinedMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content: `Oops! It seems there was an issue with scheduling this interview: ${errorMessage}. Would you like to try adjusting any details?`,
        createdAt: new Date(),
      };

      setMessages((prev) => [...prev, combinedMessage]);
    }
  };

  const handleRejectInterview = () => {
    const rejectionMessage: Message = {
      id: uuidv4(),
      role: 'assistant',
      content:
        "Interview scheduling cancelled. Is there anything you'd like to change about the interview details?",
      createdAt: new Date(),
    };

    setMessages((prev) => [...prev, rejectionMessage]);
  };

  const filteredMessages = messages.filter(
    (msg) => msg.role === 'system' || msg.id !== confirmationMessageId
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (open && !initialized.current) {
      const cleanup = initializeChat();
      return cleanup;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        {error && (
          <Box
            sx={{
              color: 'red',
              margin: '10px 0',
              padding: '10px',
              background: '#ffeeee',
              borderRadius: '4px',
            }}
          >
            {error}
          </Box>
        )}

        <ChatInterface
          title="Schedule an Interview"
          messages={filteredMessages.filter((msg) => msg.role !== 'system')}
          sessions={[]}
          onSubmit={onSubmit}
          onLoadSession={() => {}}
          onCreateNewSession={() => {}}
          isStreaming={isStreaming}
          chatWidth="100%"
          chatHeight="60vh"
          allowMinimize={false}
          cardStyles={{
            border: '1px solid rgb(242 242 242)',
            borderRadius: '5px',
            boxShadow: 'unset',
          }}
          enableChatHistory={false}
          onConfirmAction={handleApproveInterview}
          onRejectAction={handleRejectInterview}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InterviewChat;
