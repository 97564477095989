import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { User } from '../types/auth';
import ActivityHistoryList from '../components/user-profile/ActivityHistoryList';
import {
  userDetailsSingularFieldWrapperStyles,
  userDetailsMainContentStyles,
  userDetailsFieldsWrapperStyles,
  userDetailsPageWrapperStyles,
  userDetailsHeaderWrapperStyles,
  userDetailsHeaderInfoWrapperStyles,
} from './styles/userDetails';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  simpleGoBackButtonStyles,
} from '../components/styles';
import { userDeleteRequest } from '../api/users';
import { userDetailsRequest, userMeDetailsRequest } from '../api/auth';
import NotificationToast from '../components/shared/toast/NotificationToast';
import CreationNotification from '../components/shared/creation-notification/CreationNotification';
import LEIAAContext from '../context/context';

const inputFieldsLabels = [
  {
    label: 'First Name',
    fieldName: 'first_name',
    icon: <span className="material-icons-round filled">assignment_ind</span>,
  },
  {
    label: 'Last Name',
    fieldName: 'last_name',
    icon: <span className="material-icons-round filled">assignment_ind</span>,
  },
  {
    label: 'Organisation',
    fieldName: 'organisation',
    icon: <span className="material-icons-round">corporate_fare</span>,
  },
  {
    label: 'Role',
    fieldName: 'role',
    icon: <span className="material-icons-round">person</span>,
  },
  {
    label: 'Email Address',
    fieldName: 'email',
    icon: <span className="material-icons-round">mail</span>,
  },
  {
    label: 'Phone Number',
    fieldName: 'phone_number',
    icon: <span className="material-icons-round">call</span>,
  },
];

const UserDetails = () => {
  const { user } = useContext(LEIAAContext);
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState<User>();
  const [meUser, setMeUser] = useState<User>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const location = useLocation();
  const matterId: any = location.state?.matterId;

  useEffect(() => {
    setIsLoading(true);
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setIsLoading(false);
    }
  }, [currentUser]);

  const handleGoToEditPage = () => {
    if (!userId) {
      navigate('/user-details/edit');
    } else {
      navigate(`/user-details/${userId}/edit`, {
        state: { user: currentUser },
      });
    }
  };

  const getMeDetails = async () => {
    try {
      const meDetails = await userMeDetailsRequest('GET');
      setMeUser(meDetails.user);
      return meDetails;
    } catch (error: any) {
      console.error(error);
    }
    return null;
  };

  const getUserDetails = async () => {
    let userDetails;
    try {
      if (userId) {
        userDetails = await userDetailsRequest('GET', undefined, userId);
      } else {
        userDetails = await userMeDetailsRequest('GET');
      }
      setCurrentUser(userDetails?.user);
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
    getMeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleGoBack = () => {
    if (matterId) {
      navigate(`/matters/detail/${matterId}`, {
        state: { currentStep: 0 },
      });
    } else {
      navigate(-1);
    }
  };

  const handleArchiveUser = async (userIdentifier: string) => {
    try {
      await userDeleteRequest(userIdentifier);
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  return (
    <Box sx={userDetailsPageWrapperStyles}>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="User archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            '& > span': {
              color: '#0053FF',
            },
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* User Details Header */}
          <Box sx={userDetailsHeaderWrapperStyles}>
            <Box>
              <Box sx={userDetailsHeaderInfoWrapperStyles}>
                <Box>
                  <Typography variant="h4">{currentUser?.username}</Typography>
                </Box>
                <Box>
                  <Typography variant="caption" component="span">
                    User Profile
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Button
              sx={simpleGoBackButtonStyles}
              variant="text"
              onClick={handleGoBack}
            >
              <span className="material-symbols-outlined">arrow_back</span>
              <Typography variant="caption" component="p">
                Back
              </Typography>
            </Button>
          </Box>

          {/* Edit - Archive buttons */}
          <Box sx={{ display: 'flex', width: '100%', gap: '24px' }}>
            {(!userId ||
              ['Admin', 'Superuser', 'Leader'].includes(user?.role || '')) && (
              <Button
                sx={primaryButtonStyles()}
                variant="contained"
                onClick={handleGoToEditPage}
                role="button"
              >
                <span className="material-icons-round">edit</span>
                <Typography variant="caption" component="p">
                  Edit
                </Typography>
              </Button>
            )}
            {userId &&
              meUser?.username !== currentUser?.username &&
              ['Admin', 'Superuser', 'Leader'].includes(user?.role || '') && (
                <Button
                  sx={secondaryButtonStyles()}
                  variant="contained"
                  role="button"
                  onClick={() => {
                    if (currentUser?.id) handleArchiveUser(currentUser?.id);
                  }}
                >
                  <span className="material-icons-round">archive</span>
                  <Typography variant="caption" component="p">
                    Archive
                  </Typography>
                </Button>
              )}
          </Box>

          <Box className="scrollable-content" sx={userDetailsMainContentStyles}>
            {/* Fields and Buttons  */}
            <Box sx={userDetailsFieldsWrapperStyles}>
              <Box>
                {/* Field  */}
                {inputFieldsLabels.map((field) => {
                  const userFieldValue =
                    currentUser &&
                    (currentUser[field.fieldName as keyof User] as string);
                  return (
                    <Box
                      key={field.label}
                      sx={userDetailsSingularFieldWrapperStyles}
                    >
                      <Box>
                        <Typography variant="caption" component="p">
                          {field.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Box>
                          {field.icon}
                          <Typography variant="caption" component="p">
                            {userFieldValue}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <ActivityHistoryList
              title="Matter History"
              typeOfData="MATTERS"
              currentUser={userId ? currentUser : null}
            />
            <ActivityHistoryList
              title="Task History"
              typeOfData="TASKS"
              currentUser={userId ? currentUser : null}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserDetails;
