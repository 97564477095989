import { Box, Button, Typography } from '@mui/material';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  tertiaryButtonStyles,
} from '../../styles';
import { matterCreationFooterWrapperStyles } from './styles';

interface MatterCreationFooterProps {
  handleCancel: () => void;
  handlePrevious: () => void;
  isLastStep: boolean;
  activeStep: number;
  isEdit?: boolean;
  hideNavigationButtons?: boolean;
}

const MatterCreationFooter = ({
  handleCancel,
  handlePrevious,
  isLastStep,
  activeStep,
  isEdit = false,
  hideNavigationButtons = false,
}: MatterCreationFooterProps) => {
  const nextButtonContent =
    isLastStep || isEdit ? (
      <>
        <Typography>Finish</Typography>
        <span className="material-icons-round">save</span>
      </>
    ) : (
      <>
        <Typography>Next</Typography>
        <span className="material-icons-round">arrow_forward</span>
      </>
    );

  return (
    <Box sx={matterCreationFooterWrapperStyles}>
      <Box>
        <Button
          sx={tertiaryButtonStyles()}
          variant="contained"
          onClick={handleCancel}
        >
          <Typography>Cancel</Typography>
        </Button>

        {activeStep !== 0 && !hideNavigationButtons ? (
          <Button
            sx={secondaryButtonStyles()}
            variant="contained"
            onClick={handlePrevious}
          >
            <span className="material-icons-round">arrow_back</span>
            <Typography>Previous</Typography>
          </Button>
        ) : null}

        {activeStep === 4 && (
          <Button
            variant="contained"
            sx={tertiaryButtonStyles()}
            type="submit"
            name="addAnotherBtn"
          >
            <Typography>Save and add another</Typography>
            <span style={{ color: '#0053FF' }} className="material-icons-round">
              add_task
            </span>
          </Button>
        )}

        <Button
          variant="contained"
          sx={{ ...secondaryButtonStyles(), display: 'none' }}
        >
          <span className="material-icons-round">draft</span>
          <Typography>Save as draft</Typography>
        </Button>

        <Button
          variant="contained"
          sx={primaryButtonStyles()}
          type="submit"
          name="finishButton"
        >
          {nextButtonContent}
        </Button>
        {!isLastStep && isEdit && (
          <Button
            variant="contained"
            sx={primaryButtonStyles()}
            type="submit"
            name="saveAndCloseButton"
          >
            <Typography>Save and Close</Typography>
            <span className="material-icons-round">save</span>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MatterCreationFooter;
