import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { primaryButtonStyles, secondaryButtonStyles } from '../../../styles';
import NotificationToast from '../../../shared/toast/NotificationToast';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../../styles/textStyles';
import { LEIAADatePicker, LEIAAInput, LEIAATimeInput } from '../../../inputs';
import { matterInformationInputWrapperStyles } from '../../matter-creation/matter-creation-steps/matter-information-step/styles';
import MatterNote from '../../../matter-notes/MatterNotes';
import { createMatterTimelineEvent } from '../../../../api/matters';

interface MatterDetailTimelineAddEventProps {
  matterId: any;
  matterDetails: any;
  refreshTimeline: any;
  setShowEditNotification: any;
}

const eventTypes = [
  { icon: 'history', label: 'Storypoint', iconType: 'material-icons-round' },
  { icon: 'task_alt', label: 'Activity', iconType: 'material-icons-round' },
  { icon: 'sticky_note_2', label: 'Note', iconType: 'material-icons-outlined' },
];

const MatterDetailTimelineAddEvent = ({
  matterId,
  matterDetails,
  refreshTimeline,
  setShowEditNotification,
}: MatterDetailTimelineAddEventProps) => {
  const { handleSubmit, control, reset } = useForm();
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [eventType, setEventType] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);

  const resetForm = (fullReset = true) => {
    setShowErrorNotification(false);
    setRequestError(null);
    setEventType(null);
    if (fullReset) reset();
  };

  const onClose = () => {
    setOpenModal(false);
    setTimeout(() => {
      resetForm();
    }, 150);
  };

  const onSubmitEvent: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      if (!data.date || !data.time) {
        setRequestError('Please set a date and time of occurrence');
        setShowErrorNotification(true);
      } else {
        await createMatterTimelineEvent(
          matterId,
          data.date,
          data.time,
          eventType,
          data.description,
          data.name
        );
        refreshTimeline();
        onClose();
        setShowEditNotification(true);
      }
    } catch (error: any) {
      setRequestError(error as string);
      setShowErrorNotification(true);
    }
  };

  const typeSelector = (type: string) => {
    if (type === 'note') {
      setOpenNoteModal(true);
      onClose();
    } else {
      setEventType(type);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpenModal(true)}
        sx={{ ...primaryButtonStyles(), height: '40px' }}
      >
        <span className="material-icons-round">add</span>
        <Typography>Add Event</Typography>
      </Button>
      {showErrorNotification && (
        <Box
          sx={{
            position: 'fixed',
            top: '48px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '99999 !important',
            width: '100%',
          }}
        >
          <NotificationToast
            showNotification={showErrorNotification}
            requestError={requestError}
            handleCloseNotification={() => setShowErrorNotification(false)}
          />
        </Box>
      )}
      {openNoteModal && (
        <MatterNote
          matterId={matterId}
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          tableRefresh={() => {}}
          note={undefined}
          resourceName="Investigation"
          tagResourceId={matterId}
          backdrop
        />
      )}
      <Dialog
        onClose={() => onClose()}
        open={openModal}
        sx={{
          '& > div:nth-of-type(3)': {
            background: 'rgba(211, 236, 246, 0.70)',
            backdropFilter: 'blur(2px)',
            '& > div.MuiPaper-root': {
              padding: '10px',
              borderRadius: '24px',
              border: '1px solid #D3ECF6',
              background: '#FFF',
              boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
            },
          },
        }}
      >
        {eventType && eventType !== 'note' ? (
          <form
            style={{ width: '100%' }}
            onSubmit={handleSubmit(onSubmitEvent)}
          >
            <Box
              sx={{
                width: '550px !important',
                padding: '15px',
              }}
            >
              <IconButton
                onClick={() => onClose()}
                sx={{ marginLeft: '90%', '& > span': { color: '#464B53' } }}
              >
                <span className="material-icons-round">close</span>
              </IconButton>
              <Box>
                <Typography
                  sx={{
                    color: '#202020',
                    ...sofiaProBoldFontStyles,
                    fontSize: '18px',
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    marginBottom: '20px',
                    textAlign: 'center',
                  }}
                >
                  {eventType}
                </Typography>
                <Stack
                  direction="column"
                  spacing={4}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      ...matterInformationInputWrapperStyles,
                      gap: '0 !important',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <LEIAAInput
                        labelText="Name"
                        controllerName="name"
                        control={control}
                        inputPlaceholder="Brief description of the event"
                        inputWidth="100%"
                        inputHeight="40px"
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <LEIAADatePicker
                        labelText="Date of occurrence"
                        inputPlaceholder="Date of Occurrence"
                        controllerName="date"
                        control={control}
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <LEIAATimeInput
                        labelText="Hour of Occurrence"
                        controllerName="time"
                        control={control}
                        inputPlaceholder="Hour of Occurrence"
                        inputWidth="100%"
                        inputHeight="40px"
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <LEIAAInput
                        labelText="Description"
                        controllerName="description"
                        control={control}
                        inputPlaceholder="Provide a detailed description of the event"
                        inputWidth="100%"
                        inputHeight="130px"
                        multiline
                        customClassName="scrollable-content"
                        required={false}
                      />
                    </Box>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        ...secondaryButtonStyles(),
                        width: '100%',
                      }}
                      onClick={() => resetForm(false)}
                    >
                      <Typography>Change Type</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        ...primaryButtonStyles(),
                        width: '100%',
                      }}
                      type="submit"
                    >
                      <Typography>Create</Typography>
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </form>
        ) : (
          <Box>
            <Stack
              direction="row"
              spacing={0}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                border: '4px solid #F5FAFD',
                borderRadius: '15px',
              }}
            >
              {eventTypes.map((item: any, index: number) => (
                <Stack
                  key={index}
                  direction="column"
                  spacing={0}
                  onClick={() => typeSelector(item.label.toLowerCase())}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    width: '180px',
                    minHeight: '130px',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    cursor: 'pointer',
                    '&:not(:last-child)': {
                      boxShadow: '4px 0 0 0 #F5FAFD',
                    },
                    '& > span': {
                      color: '#464B53',
                      fontSize: '30px',
                    },
                    '& > p': {
                      textAlign: 'center',
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '25px',
                      color: '#464B53',
                      textTransform: 'uppercase',
                    },
                    '&:hover': {
                      backgroundColor: '#F0F4F8',
                      transform: 'scale(1.05)',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <span className={item.iconType}>{item.icon}</span>
                  <Typography>{item.label}</Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default MatterDetailTimelineAddEvent;
