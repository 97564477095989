import { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ChatInterface, { Message } from './ChatInterface';
import { LLMChat, LLMChatEvent } from './api';
import { processTasksRequest } from '../../api/tasks';
import { MatterTask } from '../../types/matters';

interface TaskData {
  name: string;
  description: string;
  status: 'To Do' | 'In Progress' | 'Blocked' | 'Done' | 'In Review';
  deadline: string;
  assignee: string;
}

interface TaskChatProps {
  open: boolean;
  onClose: () => void;
  matterId: string;
}

const TaskChat = ({ open, onClose, matterId }: TaskChatProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [taskData, setTaskData] = useState<TaskData | null>(null);
  const [confirmationMessageId, setConfirmationMessageId] = useState<
    string | null
  >(null);

  const llmChatRef = useRef<LLMChat | null>(null);
  const initialized = useRef(false);

  useEffect(() => {
    if (messages.length === 1 && messages[0].role === 'system') {
      const welcomeMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content:
          "I'll help you create a new task. What would you like to name this task?",
        createdAt: new Date(),
        isWelcomeMessage: true,
      };
      setMessages([...messages, welcomeMessage]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const extractTaskDataFromMessage = (message: string): TaskData => {
    const data: TaskData = {
      name: '',
      description: '',
      status: 'To Do',
      deadline: '',
      assignee: '',
    };

    const messageLines = message.split('\n');

    // eslint-disable-next-line no-restricted-syntax
    for (const line of messageLines) {
      const lowerLine = line.toLowerCase().trim();

      // Task name detection
      if (
        lowerLine.startsWith('name:') ||
        lowerLine.startsWith('task name:') ||
        lowerLine.startsWith('task:')
      ) {
        data.name = line.substring(line.indexOf(':') + 1).trim();
      }

      // Description detection
      else if (
        lowerLine.startsWith('description:') ||
        lowerLine.startsWith('desc:') ||
        lowerLine.startsWith('task description:')
      ) {
        data.description = line.substring(line.indexOf(':') + 1).trim();
      }

      // Status detection
      else if (lowerLine.startsWith('status:')) {
        const statusValue = line.substring(line.indexOf(':') + 1).trim();
        const lowerStatus = statusValue.toLowerCase();

        if (lowerStatus.includes('to do') || lowerStatus.includes('todo')) {
          data.status = 'To Do';
        } else if (lowerStatus.includes('in progress')) {
          data.status = 'In Progress';
        } else if (lowerStatus.includes('blocked')) {
          data.status = 'Blocked';
        } else if (lowerStatus.includes('done')) {
          data.status = 'Done';
        } else if (lowerStatus.includes('review')) {
          data.status = 'In Review';
        } else if (
          ['To Do', 'In Progress', 'Blocked', 'Done', 'In Review'].includes(
            statusValue
          )
        ) {
          data.status = statusValue as TaskData['status'];
        }
      }

      // Deadline detection
      else if (
        lowerLine.startsWith('deadline:') ||
        lowerLine.startsWith('due date:') ||
        lowerLine.startsWith('due:')
      ) {
        data.deadline = line.substring(line.indexOf(':') + 1).trim();
      }

      // Assignee detection
      else if (
        lowerLine.startsWith('assignee:') ||
        lowerLine.startsWith('assign:') ||
        lowerLine.startsWith('assigned to:')
      ) {
        data.assignee = line.substring(line.indexOf(':') + 1).trim();
      }
    }

    return data;
  };

  const addConfirmationMessage = (
    data: TaskData,
    originalMessageId: string
  ) => {
    setConfirmationMessageId(originalMessageId);

    const confirmationMessage: Message = {
      id: uuidv4(),
      role: 'assistant',
      content: 'Please confirm task creation',
      createdAt: new Date(),
      isConfirmation: true,
      confirmationData: data,
    };

    setMessages((prevMessages) => [...prevMessages, confirmationMessage]);
  };

  const handleChatEvent = (event: LLMChatEvent) => {
    try {
      switch (event.type) {
        case 'message:added':
          // eslint-disable-next-line no-case-declarations
          const newMessage: Message = {
            id: event.message.id,
            role: event.message.role,
            content: event.message.content,
            createdAt: new Date(event.message.timestamp),
          };

          if (newMessage.role === 'user' && !newMessage.content.trim()) {
            break;
          }

          setMessages((prevMessages) => {
            const exists = prevMessages.some((msg) => msg.id === newMessage.id);
            if (exists) return prevMessages;
            return [...prevMessages, newMessage];
          });
          break;

        case 'message:updated':
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === event.message.id
                ? {
                    ...msg,
                    content: event.message.content,
                  }
                : msg
            )
          );
          break;

        case 'stream:start':
          setIsStreaming(true);
          break;

        case 'stream:end':
          setIsStreaming(false);

          // Check if the last message indicates we should show a confirmation
          // eslint-disable-next-line no-case-declarations
          const lastMessage = event.message;
          if (
            lastMessage &&
            lastMessage.role === 'assistant' &&
            lastMessage.content
              .toLowerCase()
              .includes('would you like to proceed')
          ) {
            const extractedData = extractTaskDataFromMessage(
              lastMessage.content
            );

            if (extractedData) {
              setTaskData(extractedData);
              addConfirmationMessage(extractedData, lastMessage.id);

              setMessages((prevMessages) =>
                prevMessages.filter((msg) => msg.id !== lastMessage.id)
              );
            }
          }
          break;

        case 'error':
          console.error('LLMChat error:', event.error);
          setError(`Error: ${event.error.message}`);
          setIsStreaming(false);
          break;
        default:
          break;
      }
    } catch (err) {
      console.error('Error handling chat event:', err);
      setError(
        `Error handling chat event: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  const initializeChat = () => {
    try {
      if (!process.env.REACT_APP_OPENAI_API_KEY) {
        console.error('No OpenAI API key provided');
        setError('Missing API key. Please provide an OpenAI API key.');
        return;
      }

      const TASK_SYSTEM_PROMPT = `You are a task creation assistant. Help the user create a task by collecting the following details:

Task Name: What is the name of the task?
Task Description: Provide a detailed description of the task.
Task Status: Choose from To Do, In Progress, Blocked, Done, In Review.
Deadline: Specify the due date for the task.
Assignee: Search for and select a user to allocate the task to.

Once you have collected all of these details, ask the user if they want to proceed by only saying:

"I have all the information needed to create this task. Here is what I have gathered:

Task Name: [Collected Task Name]
Task Description: [Collected Task Description]
Task Status: [Collected Task Status]
Deadline: [Collected Deadline in DD/MM/YYYY format]
Assignee: [Collected Assignee]

Would you like to proceed?"
`;

      const chat = new LLMChat({
        id: `task_chat_${uuidv4()}`,
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        systemPrompt: TASK_SYSTEM_PROMPT,
        temperature: 0,
      });

      const systemMessage: Message = {
        id: uuidv4(),
        role: 'system',
        content: TASK_SYSTEM_PROMPT,
        createdAt: new Date(),
      };

      setMessages([systemMessage]);
      setConfirmationMessageId(null);

      const unsubscribe = chat.subscribe(handleChatEvent);

      llmChatRef.current = chat;
      initialized.current = true;
      setTaskData(null);

      // eslint-disable-next-line consistent-return
      return () => {
        unsubscribe();
        llmChatRef.current = null;
        initialized.current = false;
      };
    } catch (err) {
      console.error('Error initializing LLMChat:', err);
      setError(
        `Failed to initialize chat: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  const onSubmit = async (input: string) => {
    if (isStreaming) {
      setIsStreaming(false);
      return;
    }

    if (!input.trim()) {
      return;
    }

    try {
      setError(null);
      if (llmChatRef.current) {
        setIsStreaming(true);

        try {
          await llmChatRef.current.sendMessage(input);
        } catch (sendError) {
          console.error('Error sending message:', sendError);
          setIsStreaming(false);
          setError(
            `Failed to send message: ${
              sendError instanceof Error ? sendError.message : String(sendError)
            }`
          );
        }
      } else {
        console.error('LLMChat instance not available');
        setError('Chat system not initialized. Please reload the page.');
      }
    } catch (err) {
      console.error('Error in submit handler:', err);
      setError(
        `Error sending message: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
      setIsStreaming(false);
    }
  };

  const handleApproveTask = async (data: any) => {
    const createTaskData: MatterTask = {
      id: uuidv4(),
      name: data.name,
      description: data.description,
      status: data.status,
      assigned_to: data.assignee,
      deadline: data.deadline,
    };

    try {
      await processTasksRequest(matterId, [createTaskData], [], []);

      const confirmationMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content: `Task "${data.name}" has been created successfully!`,
        createdAt: new Date(),
      };

      setMessages((prev) => [...prev, confirmationMessage]);
      setError(null);
    } catch (e: any) {
      console.error('Error processing task data:', e);

      let errorMessage = 'An unexpected error occurred';

      if (e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      } else if (e.message) {
        errorMessage = e.message;
      }

      const combinedMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content: `Oops! It seems there was an issue with creating your task: ${errorMessage}. Would you like to try adjusting any details?`,
        createdAt: new Date(),
      };

      setMessages((prev) => [...prev, combinedMessage]);
    }
  };

  const handleRejectTask = () => {
    const rejectionMessage: Message = {
      id: uuidv4(),
      role: 'assistant',
      content:
        "Task creation cancelled. Is there anything you'd like to change about the task details?",
      createdAt: new Date(),
    };

    setMessages((prev) => [...prev, rejectionMessage]);
  };

  const filteredMessages = messages.filter(
    (msg) => msg.role === 'system' || msg.id !== confirmationMessageId
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (open && !initialized.current) {
      const cleanup = initializeChat();
      return cleanup;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        {error && (
          <Box
            sx={{
              color: 'red',
              margin: '10px 0',
              padding: '10px',
              background: '#ffeeee',
              borderRadius: '4px',
            }}
          >
            {error}
          </Box>
        )}

        <ChatInterface
          title="Create a Task"
          messages={filteredMessages.filter((msg) => msg.role !== 'system')}
          sessions={[]}
          onSubmit={onSubmit}
          onLoadSession={() => {}}
          onCreateNewSession={() => {}}
          isStreaming={isStreaming}
          chatWidth="100%"
          chatHeight="60vh"
          allowMinimize={false}
          cardStyles={{
            border: '1px solid rgb(242 242 242)',
            borderRadius: '5px',
            boxShadow: 'unset',
          }}
          enableChatHistory={false}
          onConfirmAction={handleApproveTask}
          onRejectAction={handleRejectTask}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TaskChat;
