import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface FileStatusProps {
  file: any;
}

const FileStatus = ({ file }: FileStatusProps) => {
  const status = file.status || 'no data';

  let statusColors = {
    color: '#CCCCCC',
    borderColor: '#CCCCCC',
    icon: 'help_outline',
  };

  switch (status) {
    case 'Stashed':
      statusColors = {
        color: '#4CAF50',
        borderColor: '#4CAF50',
        icon: 'check_circle',
      };
      break;
    case 'Stashing...':
      statusColors = {
        color: '#2196F3',
        borderColor: '#2196F3',
        icon: 'sync',
      };
      break;
    case 'Stashed (Stale)':
      statusColors = {
        color: '#FFC107',
        borderColor: '#FFC107',
        icon: 'warning',
      };
      break;
    case 'Error':
      statusColors = {
        color: '#F44336', // Red (Error)
        borderColor: '#F44336',
        icon: 'error',
      };
      break;
    case 'Unstashed':
      statusColors = {
        color: '#607D8B',
        borderColor: '#607D8B',
        icon: 'cloud_off',
      };
      break;
    default:
      statusColors = {
        color: '#9E9E9E',
        borderColor: '#9E9E9E',
        icon: 'help_outline',
      };
  }

  return (
    <Box>
      {status !== 'no data' && (
        <Typography
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            padding: {
              xs: '2px 8px',
              sm: '4px 12px',
            },
            borderRadius: '16px',
            backgroundColor: 'white',
            border: `1px solid ${statusColors.borderColor}`,
            color: statusColors.color,
            ...karlaProRegularFontStyles,
            fontSize: {
              xs: '12px',
              sm: '14px',
            },
            lineHeight: {
              xs: '18px',
              sm: '22px',
            },
            gap: {
              xs: '4px',
              sm: '6px',
            },
            whiteSpace: 'nowrap',
          }}
        >
          <span
            className="material-icons-round"
            style={{
              fontSize: '16px',
            }}
          >
            {statusColors.icon}
          </span>
          {status}
        </Typography>
      )}
    </Box>
  );
};

export default FileStatus;
