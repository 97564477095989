/* eslint-disable react/prop-types */

import { Box, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  GridPaginationModel,
  GridRowHeightParams,
  useGridApiRef,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import LEIAATruePagination from '../lists/LEIAATruePagination';
import SortableHeader from '../matters-list-table/SortableHeader';
import FileActionsMenu from './FileActionsMenu';
import FileStatus from './FileStatus';
import FileIconCell from './FileIconCell';
import ListSkeletonLoader from '../lists/ListSkeletonLoader';
import PredictedRelevance from './PredictedRelevance';

interface DocumentsListTableProps {
  documentsListData: {
    documents: any[];
    page: number;
    total_pages: number;
    total_items: number;
    has_next: boolean;
    has_previous: boolean;
  } | null;
  loading: boolean;
  listClass: string;
  matterId?: string | number;
  dashboard?: boolean;
  setCheckedDocs?: (ids: any[]) => void;
  setSelectedDocs?: (doc: any) => void;
  handleDetailDoc?: (doc: any) => void;
  informationSideBarHandler?: () => void;
  onPageSizeChange?: (pageSize: number) => void;
  pageSize: number;
  onPageChange?: (page: number) => void;
  // action handlers
  addNoteHandler?: () => void;
  handleDownloadFile?: () => void;
  renameHandler?: () => void;
  fileDownloadLinkHandler?: () => void;
  deleteHandler?: () => void;
  handleImanageDocumentsOperations?: (
    action: string,
    all_documents: boolean,
    targetResource: any | boolean,
    docs_to_upload?: any[],
    folder?: string
  ) => void;
  handleMicrosoftDocumentsOperations?: (
    action: string,
    targetResource: any
  ) => void;
  handleEdiscoveryDocumentsOperations?: (
    action: string,
    targetResource: any
  ) => void;
  setShowPermissionsUpdatedNotification?: (
    showPermissionsUpdatedNotification: boolean
  ) => void;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No documents.
    </Typography>
  </GridOverlay>
);

const DocumentsListTable = ({
  documentsListData,
  loading,
  dashboard = false,
  listClass,
  matterId,
  setCheckedDocs,
  setSelectedDocs,
  handleDetailDoc,
  informationSideBarHandler,
  onPageChange,
  pageSize,
  onPageSizeChange,
  addNoteHandler,
  handleDownloadFile,
  renameHandler,
  fileDownloadLinkHandler,
  deleteHandler,
  handleImanageDocumentsOperations,
  handleMicrosoftDocumentsOperations,
  handleEdiscoveryDocumentsOperations,
  setShowPermissionsUpdatedNotification,
}: DocumentsListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize,
    page: 0,
  });

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && documentsListData?.documents !== undefined) {
      addScrollListener();
    }

    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, documentsListData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      minWidth: 250,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        return <FileIconCell file={params.row} />;
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'source_id',
      headerName: 'Source ID',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'updated',
      headerName: 'Last Modified',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        const jsDate = new Date(cellValue);
        const day = jsDate.getDate().toString().padStart(2, '0');
        const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
        const year = jsDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        return (
          <Box>
            <p className="MuiDataGrid-cellContent">{formattedDate}</p>
          </Box>
        );
      },
    },
    {
      field: 'relevance',
      headerName: 'Predicted Relevance',
      minWidth: 180,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        return <PredictedRelevance file={params.row} />;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 180,
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        return <FileStatus file={params.row} />;
      },
    },
    {
      field: ' ',
      headerName: ' ',
      width: 60,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <Box
            className="MuiDataGrid-columnHeaderTitle"
            sx={{
              color: '#69707b',
              fontWeight: '400',
            }}
          >
            {params.colDef.headerName}
          </Box>
        );
      },
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            onClick={(e) => {
              e.stopPropagation(); // This prevents the click from bubbling up to the row
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FileActionsMenu
              matterId={matterId}
              file={params.row}
              addNoteHandler={addNoteHandler}
              informationSideBarHandler={informationSideBarHandler}
              handleDownloadFile={handleDownloadFile}
              renameHandler={renameHandler}
              fileDownloadLinkHandler={fileDownloadLinkHandler}
              deleteHandler={deleteHandler}
              handleImanageDocumentsOperations={
                handleImanageDocumentsOperations
              }
              handleMicrosoftDocumentsOperations={
                handleMicrosoftDocumentsOperations
              }
              handleEdiscoveryDocumentsOperations={
                handleEdiscoveryDocumentsOperations
              }
              setShowPermissionsUpdatedNotification={
                setShowPermissionsUpdatedNotification
              }
            />
          </Box>
        );
      },
    },
  ];

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);

    // if (onPageSizeChange) {
    //   onPageSizeChange(newPaginationModel.pageSize);
    // }

    if (onPageChange) {
      onPageChange(newPaginationModel.page + 1);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
        marginTop: '32px',
        height: 'auto !important',
        flexGrow: 1,
        width: '100%',
        overflowX: 'auto',
      }}
      className="scrollable-content"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '0px',
          width: '100%',
          height: '100%',
          position: 'relative',
          minWidth: '800px',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            width: '84px',
            height: '524px',
            right: '-29px',
            top: '0px',
            flex: 'none',
            order: 2,
            flexGrow: 0,
            zIndex: 2,
            display: showShadow ? 'block' : 'none',
            '& > div': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              padding: '0px',
              position: 'absolute',
              width: '32px',
              height: '524px',
              right: '0px',
              '& > div': {
                width: '32px',
                height: '524px',
                background: '#FFFFFF',
                boxShadow: '5px 0px 20px rgba(21, 10, 84, 0.1)',
                flex: 'none',
                order: 0,
                alignSelf: 'stretch',
                flexGrow: 0,
              },
            },
          }}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            autoHeight
            getRowHeight={(props: GridRowHeightParams) => {
              const rowHeight = 76;

              const height = props.model?.services
                ? props.model.services.length * rowHeight + rowHeight
                : rowHeight;

              return height;
            }}
            ref={containerRef}
            rows={documentsListData?.documents || []}
            columns={columns}
            apiRef={apiRef}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            paginationMode="server"
            rowCount={documentsListData?.total_items || 0}
            checkboxSelection={!dashboard}
            onRowSelectionModelChange={(ids) => {
              if (setCheckedDocs) {
                setCheckedDocs(ids);
              }
              if (ids.length !== 0 && setSelectedDocs) {
                setSelectedDocs(
                  documentsListData?.documents.find(
                    (row: any) => row.id === ids[0]
                  )
                );
              }
            }}
            onRowClick={(params) => {
              if (handleDetailDoc) handleDetailDoc(params.row);
              if (!params.row.folder) {
                if (setSelectedDocs) {
                  setSelectedDocs(
                    documentsListData?.documents.find(
                      (row: any) => row.id === params.id
                    )
                  );
                }
                if (informationSideBarHandler) {
                  informationSideBarHandler();
                }
              }
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAATruePagination,
            }}
            hideFooter={dashboard}
            sx={{
              border: 'unset',
              width: '100%',
              '& > div': {
                '& > div.MuiDataGrid-columnHeaders': {
                  border: 'unset',
                  '& > div.MuiDataGrid-columnHeadersInner': {
                    '& > div > div:nth-of-type(1)': {
                      maxWidth: 'unset !important',
                      width: 'unset !important',
                    },
                    '& > div > div': {
                      padding: '0px !important',
                      height: '52px !important',
                      '&:focus-within': {
                        outline: 'unset',
                      },
                      '& > div > div.MuiDataGrid-columnHeaderTitleContainer': {
                        padding: '8px 16px',
                      },
                    },
                  },
                },
                '& > div.MuiDataGrid-virtualScroller': {
                  margin: '8px 0px',
                  '& > div > div': {
                    gap: '8px',
                  },
                  '& > div > div > div': {
                    minHeight: 'unset !important',
                    maxHeight: 'unset !important',
                    borderWidth: '1px',
                    borderRadius: '24px',
                    '& > div.MuiDataGrid-cell': {
                      padding: '16px',
                      border: 'none',
                      height: '68px !important',
                      maxHeight: '68px !important',
                      minHeight: '68px !important',
                      '&:focus-within': {
                        outline: 'unset',
                      },
                    },
                  },
                  '& > div > div > div.Mui-even': {
                    backgroundColor: '#F5FAFD',
                  },
                  '& > div > div > div.Mui-odd': {
                    backgroundColor: '#FFFFFF',
                  },
                },
              },
            }}
          />
        ) : (
          <ListSkeletonLoader pageSize={pageSize} />
        )}
      </Box>
    </Box>
  );
};

export default DocumentsListTable;
