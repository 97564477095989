import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { matterDetailIntegrationConfigRequest } from '../../../api/matters';
import { LEIAAInput } from '../../inputs';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../matter-creation/matter-creation-steps/matter-information-step/styles';

interface MatterDetailMicrosoftProps {
  matterData: any;
  matterId: any;
}

const MatterDetailMicrosoft = ({
  matterData,
  matterId,
}: MatterDetailMicrosoftProps) => {
  const [showUpdateNotification, setShowUpdateNotification] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmitMicrosoftServerConfig: SubmitHandler<FieldValues> = async (
    data: any
  ) => {
    try {
      const integrationData = {
        ...data,
        integration: 'microsoft',
      };
      if (matterId) {
        await matterDetailIntegrationConfigRequest(matterId, integrationData);
      }
      matterData.client_id = data.client_id;
      setShowUpdateNotification(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValue('client_id', matterData.client_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmitMicrosoftServerConfig)}
    >
      {showUpdateNotification && (
        <CreationNotification
          title="Microsoft settings updated!"
          subTitle=""
          showNotification={showUpdateNotification}
          handleCloseNotification={() => setShowUpdateNotification(false)}
        />
      )}

      <Box
        sx={{
          ...matterInformationFormWrapperStyles,
          width: '100%',
          alignItems: 'flex-end',
        }}
      >
        <Box sx={matterInformationInputWrapperStyles}>
          <Box>
            <LEIAAInput
              labelText="Client ID"
              controllerName="client_id"
              control={control}
              inputPlaceholder="Ex. ae47c96b-be50-3a42-97a9-c3449babc90s"
              inputWidth="100%"
              inputHeight="40px"
            />
          </Box>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          <Button
            variant="contained"
            sx={{ ...secondaryButtonStyles(), display: 'none' }} // TODO: remove this after upgrade
            disabled={!watch('client_id')}
          >
            <Typography>Test Connection</Typography>
            <span className="material-icons-round">settings_remote</span>
          </Button>
          <Button variant="contained" sx={primaryButtonStyles()} type="submit">
            <Typography>Save</Typography>
            <span className="material-icons-round">save</span>
          </Button>
        </Stack>
      </Box>
    </form>
  );
};

export default MatterDetailMicrosoft;
