import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { useState } from 'react';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import { menuIconStyle, menuItemStyle, menuItemTextStyle } from './styles';

interface FolderActionsMenuProps {
  folder: any;
  renameHandler?: (folder: any) => void;
  informationSideBarHandler?: (folder: any) => void;
}

const FolderActionsMenu = ({
  folder,
  renameHandler,
  informationSideBarHandler,
}: FolderActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#69707B',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          lineHeight: '22px',
          marginLeft: '10px',
          '& > span': {
            fontSize: '16px',
          },
          '&:hover': {
            '& > *': {
              color: '#0B25B0',
            },
          },
        }}
        onClick={handleClick}
      >
        <span className="material-icons-round">more_vert</span>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 'auto',
            borderRadius: '5px',
            boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
          },
        }}
      >
        <MenuList>
          {/* Integration Folder Actions */}
          {/* Folder Actions */}
          <MenuItem
            onClick={() => renameHandler && renameHandler(folder)}
            sx={menuItemStyle}
          >
            <ListItemIcon sx={menuIconStyle}>
              <span className="material-icons-round">border_color</span>
            </ListItemIcon>
            <ListItemText sx={menuItemTextStyle}>Rename</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (informationSideBarHandler) {
                informationSideBarHandler(folder);
              }
              handleClose();
            }}
            sx={menuItemStyle}
          >
            <ListItemIcon sx={menuIconStyle}>
              <span className="material-icons-round">info</span>
            </ListItemIcon>
            <ListItemText sx={menuItemTextStyle}>Information</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default FolderActionsMenu;
