import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  matterCreationFooterWrapperStyles,
  matterCreationHeaderWrapperStyles,
} from '../../components/matters/matter-creation/styles';
import {
  primaryButtonStyles,
  simpleGoBackButtonStyles,
  tertiaryButtonStyles,
} from '../../components/styles';
import { MatterInterviewsPayload } from '../../types/matters';
import { validateStepPayload } from '../../utils/utils';
import LoaderSpinner from '../../components/shared/misc-components/LoaderSpinner';
import NotificationToast from '../../components/shared/toast/NotificationToast';
import { createMatterStepRequest } from '../../api/matters';
import MatterInterviews from '../../components/matters/matter-creation/matter-creation-steps/matter-interviews-step/MatterInterviews';
import { useInterviewManagement } from '../../hooks';

const AddInterview = () => {
  const { matterId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentStep: number = location.state?.currentStep;
  const matterDetails: any = location.state?.matterDetails;
  const baseTimeObj = {
    value: 'Etc/GMT',
    label: '(GMT+0:00) UTC',
    offset: 0,
    abbrev: 'GMT',
    altName: 'British Standard Time',
  };
  matterDetails.interviews = [
    {
      id: uuidv4(),
      name: '',
      interview_type: '',
      interview_category: '',
      description: '',
      instructions: '',
      questionnaire: [],
      interviewee_id: uuidv4(),
      whistleblower: false,
      transcription_enabled: true,
      interviewee_first_name: '',
      interviewee_last_name: '',
      interviewee_email: '',
      interviewee_organisation: '',
      interviewee_subsidiary: '',
      interviewee_job_title: '',
      interviewee_employee_id: '',
      timezone: `${baseTimeObj.abbrev}, ${baseTimeObj.label}; ${baseTimeObj.value}`,
    },
  ];
  const [showNotification, setShowNotification] = useState(false);
  const [loading, Setloading] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    unregister,
    formState: { touchedFields },
  } = useForm({
    defaultValues: matterDetails || {},
  });

  const handleGoBack = () => {
    navigate(`/matters/detail/${matterId}`, {
      state: { currentStep: 4 },
    });
  };

  // Step 5: Matter Interviews
  const validateMatterInterviews = (payload: any): MatterInterviewsPayload => {
    const allowedFields: (keyof MatterInterviewsPayload)[] = [
      'interviews',
      'interviews_to_delete',
    ];

    return validateStepPayload<MatterInterviewsPayload>(
      payload,
      allowedFields
    ) as MatterInterviewsPayload;
  };

  const { handleAddMatterInterview } = useInterviewManagement(
    watch,
    setValue,
    setSelectedIndex
  );

  const onSubmit: SubmitHandler<any> = async (data: any, e: any) => {
    if (matterId) {
      const sanitizedData = data;

      const cleanedData = validateMatterInterviews(sanitizedData);

      try {
        Setloading(true);
        const response = await createMatterStepRequest(
          matterId,
          5,
          cleanedData
        );
        if (e.nativeEvent.submitter.name === 'addAnotherBtn') {
          if (response) {
            response.forEach(
              (successItem: {
                id: any;
                submission_id: any;
                interviewee_id: any;
              }) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { id, submission_id, interviewee_id } = successItem;
                const matterInterviews = watch('interviews');
                const matchingInterview = matterInterviews.find(
                  (interview: { id: any }) => interview.id === submission_id
                );

                if (matchingInterview) {
                  matchingInterview.id = id;
                  matchingInterview.interviewee_id = interviewee_id;
                }
              }
            );
          }
          handleAddMatterInterview(
            watch(`interviews[${selectedIndex}].interview_category`)
          );
        }

        Setloading(false);
        if (e.nativeEvent.submitter.name === 'finishButton') {
          handleGoBack();
        }
        setRequestError(null);
        setShowNotification(false);
      } catch (error: any) {
        Setloading(false);
        setRequestError(error.response.data.message);
        setShowNotification(true);
      }
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        position: 'relative',

        '& > form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '0px 32px',
          '&  > div:nth-of-type(1)': {
            padding: '15px 0px',
          },
          '&  > div:nth-of-type(2)': {
            paddingBottom: '20px',
          },
          '& > div:last-of-type': {
            marginTop: 'auto',
          },

          width: '100%',
        },
      }}
      id="creation-box"
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {loading && (
        <LoaderSpinner loading={loading} onClose={() => Setloading(false)} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={matterCreationHeaderWrapperStyles}>
          <Box>
            <Box>
              <Typography>Add Interview</Typography>
            </Box>
            <Button
              sx={simpleGoBackButtonStyles}
              variant="text"
              onClick={handleGoBack}
            >
              <span className="material-symbols-outlined">arrow_back</span>
              <Typography variant="caption" component="p">
                Back
              </Typography>
            </Button>
          </Box>
        </Box>

        <MatterInterviews
          control={control}
          watch={watch}
          setValue={setValue}
          unregister={unregister}
          handleAddMatterInterview={handleAddMatterInterview}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <Box sx={matterCreationFooterWrapperStyles}>
          <Box>
            <Button
              sx={tertiaryButtonStyles()}
              variant="contained"
              onClick={handleGoBack}
            >
              <Typography>Cancel</Typography>
            </Button>

            <Button
              variant="contained"
              sx={tertiaryButtonStyles()}
              type="submit"
              name="addAnotherBtn"
            >
              <Typography>Save and add another</Typography>
              <span
                style={{ color: '#0053FF' }}
                className="material-icons-round"
              >
                add_task
              </span>
            </Button>

            <Button
              variant="contained"
              sx={primaryButtonStyles()}
              type="submit"
              name="finishButton"
            >
              <Typography>Finish</Typography>
              <span className="material-icons-round">save</span>
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddInterview;
