/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */

// TODO: This is a temporary component to display the knowledge graph.
// It should be replaced with a more permanent component that is more
// user-friendly and easier to maintain.

import { useEffect, useRef, useState, useCallback } from 'react';
import * as d3 from 'd3';
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  TextField,
  Popover,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  Drawer,
  Fade,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HeatMapIcon from '@mui/icons-material/Whatshot';
import CodeIcon from '@mui/icons-material/Code';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { karlaProRegularFontStyles } from '../../styles/textStyles';

interface KnowledgeGraphProps {
  content: any;
}

interface Node {
  id: string;
  label: string;
  group: number;
  description?: string;
  properties?: Record<string, any>;
  connectionCount?: number;
}

interface Link {
  source: string | Node;
  target: string | Node;
  value: number;
  label?: string;
}

interface GraphData {
  nodes: Node[];
  links: Link[];
}

const KnowledgeGraph = ({ content = false }: KnowledgeGraphProps) => {
  const svgRef = useRef(null);
  const [graphData, setGraphData] = useState<GraphData | null>(
    content ? JSON.parse(content) : null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(600);
  const [jsonInput, setJsonInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [heatMapMode, setHeatMapMode] = useState(false);
  const [showJsonDrawer, setShowJsonDrawer] = useState(false);

  const linkRef = useRef<any>(null);
  const linkLabelsRef = useRef<any>(null);
  const nodeRef = useRef<any>(null);

  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [anchorPosition, setAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleJsonInput = (e: any) => {
    setJsonInput(e.target.value);
  };

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
    if (e.target.value === '') {
      resetHighlights();
    } else {
      performSearch(e.target.value);
    }
  };

  const toggleHeatMapMode = () => {
    setHeatMapMode(!heatMapMode);
  };

  const toggleJsonDrawer = () => {
    setShowJsonDrawer(!showJsonDrawer);
  };

  const performSearch = useCallback(
    (query: string) => {
      if (
        !graphData ||
        !nodeRef.current ||
        !linkRef.current ||
        !linkLabelsRef.current ||
        !query
      ) {
        return;
      }

      const lowercaseQuery = query.toLowerCase();

      const matchingNodeIds = new Set<string>();
      const connectedNodeIds = new Set<string>();

      graphData.nodes.forEach((node) => {
        if (
          node.label.toLowerCase().includes(lowercaseQuery) ||
          node.id.toLowerCase().includes(lowercaseQuery) ||
          node.description?.toLowerCase().includes(lowercaseQuery)
        ) {
          matchingNodeIds.add(node.id);
        }
      });

      graphData.links.forEach((link) => {
        const sourceId =
          typeof link.source === 'string' ? link.source : link.source.id;
        const targetId =
          typeof link.target === 'string' ? link.target : link.target.id;

        // If either source or target is a matching node, highlight both
        if (matchingNodeIds.has(sourceId)) {
          connectedNodeIds.add(targetId);
        }
        if (matchingNodeIds.has(targetId)) {
          connectedNodeIds.add(sourceId);
        }
      });

      // Now highlight matched and connected nodes
      nodeRef.current.transition().duration(200).style('opacity', 0.2);

      // Highlight directly matching nodes with a stronger highlight
      nodeRef.current
        .filter((n: any) => matchingNodeIds.has(n.id))
        .transition()
        .duration(200)
        .style('opacity', 1)
        .select('circle')
        .attr('stroke', '#ff0000')
        .attr('stroke-width', 2)
        .attr('r', 10);

      // Highlight connected nodes with a lesser highlight
      nodeRef.current
        .filter(
          (n: any) => !matchingNodeIds.has(n.id) && connectedNodeIds.has(n.id)
        )
        .transition()
        .duration(200)
        .style('opacity', 0.8)
        .select('circle')
        .attr('stroke', '#ff7700')
        .attr('stroke-width', 1.5)
        .attr('r', 9);

      // Find and highlight relevant links
      const relevantLinkIndices = new Set<number>();

      graphData.links.forEach((link, index) => {
        const sourceId =
          typeof link.source === 'string' ? link.source : link.source.id;
        const targetId =
          typeof link.target === 'string' ? link.target : link.target.id;

        if (matchingNodeIds.has(sourceId) || matchingNodeIds.has(targetId)) {
          relevantLinkIndices.add(index);
        }
      });

      // Dim all links
      linkRef.current
        .transition()
        .duration(200)
        .attr('stroke-opacity', 0.1)
        .attr('stroke', '#ddd');

      // Highlight relevant links
      linkRef.current
        .filter((l: any, i: number) => relevantLinkIndices.has(i))
        .transition()
        .duration(200)
        .attr('stroke-opacity', 0.8)
        .attr('stroke', '#ff7700')
        .attr('stroke-width', (d: any) => Math.sqrt(d.value) * 1.5);

      // Show link labels for relevant links
      linkLabelsRef.current.attr('opacity', 0);
      linkLabelsRef.current
        .filter((l: any, i: number) => relevantLinkIndices.has(i))
        .attr('opacity', 1)
        .attr('font-weight', 'bold')
        .attr('fill', '#ff7700');
    },
    [graphData]
  );

  const parseJsonInput = () => {
    setLoading(true);
    setError(null);

    try {
      const json = JSON.parse(jsonInput);

      // Calculate connection counts for heat map
      const nodeCounts = new Map();

      json.links.forEach((link: Link) => {
        const sourceId =
          typeof link.source === 'string' ? link.source : link.source.id;
        const targetId =
          typeof link.target === 'string' ? link.target : link.target.id;

        nodeCounts.set(sourceId, (nodeCounts.get(sourceId) || 0) + 1);
        nodeCounts.set(targetId, (nodeCounts.get(targetId) || 0) + 1);
      });

      json.nodes.forEach((node: Node) => {
        node.connectionCount = nodeCounts.get(node.id) || 0;
      });

      setGraphData(json as GraphData);
      setLoading(false);

      setShowJsonDrawer(false);
    } catch (err) {
      setError(`Failed to parse JSON: ${err}`);
      setLoading(false);
    }
  };

  const highlightConnections = useCallback(
    (d: Node) => {
      if (
        !graphData ||
        !linkRef.current ||
        !linkLabelsRef.current ||
        !nodeRef.current
      )
        return;

      // Find connected links
      const connectedLinkIndices = new Set();
      const connectedNodeIds = new Set([d.id]);

      graphData.links.forEach((l: any, i: number) => {
        const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
        const targetId = typeof l.target === 'string' ? l.target : l.target.id;

        if (sourceId === d.id || targetId === d.id) {
          connectedLinkIndices.add(i);
          if (sourceId === d.id) connectedNodeIds.add(targetId);
          if (targetId === d.id) connectedNodeIds.add(sourceId);
        }
      });

      // Dim all links
      linkRef.current
        .transition()
        .duration(200)
        .attr('stroke-opacity', 0.2)
        .attr('stroke', '#ddd');

      // Highlight connected links
      linkRef.current
        .filter((l: any, i: number) => connectedLinkIndices.has(i))
        .transition()
        .duration(200)
        .attr('stroke-opacity', 1)
        .attr('stroke', '#ff7700')
        .attr('stroke-width', (de: any) => Math.sqrt(de.value) * 2);

      // Show link labels for connected links
      linkLabelsRef.current.attr('opacity', 0);
      linkLabelsRef.current
        .filter((l: any, i: number) => connectedLinkIndices.has(i))
        .attr('opacity', 1)
        .attr('font-weight', 'bold')
        .attr('fill', '#ff7700');

      // Dim all nodes
      nodeRef.current.transition().duration(200).style('opacity', 0.4);

      // Highlight connected nodes
      nodeRef.current
        .filter((n: any) => connectedNodeIds.has(n.id))
        .transition()
        .duration(200)
        .style('opacity', 1);
    },
    [graphData]
  );

  const resetHighlights = useCallback(() => {
    if (
      !linkRef.current ||
      !linkLabelsRef.current ||
      !nodeRef.current ||
      !graphData
    )
      return;

    // Reset links
    linkRef.current
      .transition()
      .duration(200)
      .attr('stroke-opacity', 0.6)
      .attr('stroke', '#999')
      .attr('stroke-width', (d: any) => Math.sqrt(d.value));

    // Reset link labels
    linkLabelsRef.current
      .transition()
      .duration(200)
      .attr('opacity', 1)
      .attr('font-weight', 'normal')
      .attr('fill', '#666');

    // Reset nodes - but respect heat map mode setting
    if (heatMapMode) {
      applyHeatMapStyling();
    } else {
      nodeRef.current
        .transition()
        .duration(200)
        .style('opacity', 1)
        .select('circle')
        .attr('r', 8)
        .attr('stroke', null)
        .attr('stroke-width', 0);
    }
  }, [heatMapMode, graphData]);

  const applyHeatMapStyling = useCallback(() => {
    if (!graphData || !nodeRef.current) return;

    // Find max connection count for scaling
    const maxConnections = Math.max(
      ...graphData.nodes.map((n) => n.connectionCount || 0)
    );

    // Create scales for radius and color intensity
    const radiusScale = d3
      .scaleLinear()
      .domain([0, maxConnections])
      .range([6, 20]); // Min to max radius

    const colorScale = d3
      .scaleLinear<string>()
      .domain([0, maxConnections / 2, maxConnections])
      .range(['#74b9ff', '#ff7675', '#d63031']) // Cool to hot color gradient
      .interpolate(d3.interpolateRgb.gamma(2.2));

    // Apply to all nodes
    nodeRef.current
      .transition()
      .duration(500)
      .style('opacity', 1)
      .select('circle')
      .attr('r', (d: Node) => radiusScale(d.connectionCount || 0))
      .attr('fill', (d: Node) => colorScale(d.connectionCount || 0))
      .attr('stroke', (d: Node) =>
        d3.rgb(colorScale(d.connectionCount || 0)).darker(0.5)
      )
      .attr('stroke-width', 1);
  }, [graphData]);

  const handleNodeClick = useCallback(
    (node: Node, event: MouseEvent) => {
      event.stopPropagation(); // Prevent event bubbling

      if (selectedNode && selectedNode.id === node.id) {
        setSelectedNode(null);
        setAnchorPosition(null);
        resetHighlights();
        return;
      }

      setSelectedNode(node);
      setAnchorPosition({
        top: event.clientY,
        left: event.clientX,
      });

      highlightConnections(node);
    },
    [selectedNode, highlightConnections, resetHighlights]
  );

  const handleClosePopover = useCallback(() => {
    setSelectedNode(null);
    setAnchorPosition(null);
    resetHighlights();
  }, [resetHighlights]);

  const handleBackgroundClick = useCallback(() => {
    handleClosePopover();
  }, [handleClosePopover]);

  useEffect(() => {
    const handleResize = () => {
      const container = (svgRef.current as any)?.parentElement;
      if (container) {
        setWidth(container.clientWidth);
        setHeight(Math.max(500, window.innerHeight * 0.7));
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (heatMapMode) {
      applyHeatMapStyling();
    } else if (graphData && nodeRef.current) {
      // Reset to normal styling
      nodeRef.current
        .transition()
        .duration(500)
        .select('circle')
        .attr('r', 8)
        .attr('fill', (d: any) => d3.schemeCategory10[d.group % 10])
        .attr('stroke', null)
        .attr('stroke-width', 0);
    }
  }, [heatMapMode, applyHeatMapStyling, graphData]);

  useEffect(() => {
    if (!graphData || !svgRef.current) return;

    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height]);

    const g = svg.append('g');

    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 8])
      .on('zoom', (event) => {
        g.attr('transform', event.transform);
      });

    svg.call(zoom as any);

    const simulation = d3
      .forceSimulation(graphData.nodes as any)
      .force(
        'link',
        d3
          .forceLink(graphData.links as any)
          .id((d: any) => d.id)
          .distance(100)
      )
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collide', d3.forceCollide(50));

    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Draw links
    const link = g
      .append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(graphData.links)
      .enter()
      .append('line')
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6)
      .attr('stroke-width', (d: any) => Math.sqrt(d.value));

    // Store reference to links
    linkRef.current = link;

    // Create link labels group
    const linkLabels = g
      .append('g')
      .attr('class', 'link-labels')
      .selectAll('text')
      .data(graphData.links)
      .enter()
      .append('text')
      .attr('font-size', 10)
      .attr('text-anchor', 'middle')
      .attr('dy', -5)
      .attr('fill', '#666')
      .text((d: any) => d.label || '');

    // Store reference to link labels
    linkLabelsRef.current = linkLabels;

    // Create nodes group
    const node = g
      .append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(graphData.nodes)
      .enter()
      .append('g')
      .call(
        (d3 as any)
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      )
      .on('click', (event: MouseEvent, d: Node) => {
        handleNodeClick(d, event);
      })
      .on('mouseover', function (event: MouseEvent, d: Node) {
        // Highlight the node on hover only if no node is selected
        if (!selectedNode && searchQuery === '') {
          // Enlarge the hovered node
          d3.select(this)
            .select('circle')
            .transition()
            .duration(200)
            .attr(
              'r',
              heatMapMode
                ? (d3
                    .select(this)
                    .select('circle')
                    .attr('r') as unknown as number) * 1.2
                : 12
            )
            .attr('stroke', '#000')
            .attr('stroke-width', 2);

          d3.select(this).style('cursor', 'pointer');

          highlightConnections(d);
        } else {
          d3.select(this).style('cursor', 'pointer');
        }
      })
      .on('mouseout', function (event: MouseEvent, d: Node) {
        // Reset appearance only if no node is selected and no search is active
        if (!selectedNode && searchQuery === '') {
          d3.select(this).style('cursor', 'default');

          resetHighlights();
        } else {
          d3.select(this).style('cursor', 'default');
        }
      });

    // Store reference to nodes
    nodeRef.current = node;

    // Add circles to nodes
    node
      .append('circle')
      .attr('r', 8)
      .attr('fill', (d: any) => colorScale(d.group));

    // Add labels to nodes
    node
      .append('text')
      .attr('dx', 12)
      .attr('dy', '.35em')
      .text((d: any) => d.label)
      .style('font-size', '12px')
      .style('font-family', 'system-ui, -apple-system, sans-serif')
      .style('font-weight', '500');

    // Add title for tooltip on hover
    node.append('title').text((d: any) => {
      const connectionInfo =
        d.connectionCount !== undefined
          ? ` | Connections: ${d.connectionCount}`
          : '';
      return `${d.label}${connectionInfo}`;
    });

    // Add click handler to SVG background to dismiss popover
    svg.on('click', handleBackgroundClick);

    // Drag functions
    function dragstarted(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event: any, d: any) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    // Update positions on simulation tick
    simulation.on('tick', () => {
      link
        .attr('x1', (d: any) => d.source.x)
        .attr('y1', (d: any) => d.source.y)
        .attr('x2', (d: any) => d.target.x)
        .attr('y2', (d: any) => d.target.y);

      linkLabels
        .attr('x', (d: any) => (d.source.x + d.target.x) / 2)
        .attr('y', (d: any) => (d.source.y + d.target.y) / 2);

      node.attr('transform', (d: any) => `translate(${d.x},${d.y})`);
    });

    // If there's already a selected node when the graph is re-rendered, highlight its connections
    if (selectedNode) {
      const nodeInNewData = graphData.nodes.find(
        (n) => n.id === selectedNode.id
      );
      if (nodeInNewData) {
        highlightConnections(nodeInNewData);
      }
    }

    // If there's an active search query, perform the search
    if (searchQuery) {
      performSearch(searchQuery);
    }

    // If heat map mode is on, apply styling
    if (heatMapMode) {
      applyHeatMapStyling();
    }

    // Return cleanup function
    return () => {
      simulation.stop();
    };
  }, [
    graphData,
    width,
    height,
    selectedNode,
    searchQuery,
    heatMapMode,
    handleNodeClick,
    handleBackgroundClick,
    highlightConnections,
    resetHighlights,
    performSearch,
    applyHeatMapStyling,
  ]);

  // Function to render node details
  const renderNodeDetails = () => {
    if (!selectedNode) return null;

    return (
      <Box sx={{ p: 0 }}>
        <Box
          sx={{
            p: 2,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            background: '#0053FF',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            {selectedNode.label}
          </Typography>
          <IconButton
            size="small"
            onClick={handleClosePopover}
            sx={{ color: 'white' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Content section */}
        <Box sx={{ p: 2 }}>
          {selectedNode.connectionCount !== undefined && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                p: 1.5,
                borderRadius: 1,
                backgroundColor: '#f1f5f9',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#3b82f6',
                  color: 'white',
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  mr: 1.5,
                }}
              >
                {selectedNode.connectionCount}
              </Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Connections
              </Typography>
            </Box>
          )}

          {selectedNode.description && (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 0.5,
                  fontWeight: 500,
                  color: '#475569',
                }}
              >
                Description
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  backgroundColor: '#f8fafc',
                  p: 1.5,
                  borderRadius: 1,
                  border: '1px solid #e2e8f0',
                  lineHeight: 1.5,
                }}
              >
                {selectedNode.description}
              </Typography>
            </Box>
          )}

          {selectedNode.properties &&
            Object.keys(selectedNode.properties).length > 0 && (
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mb: 1,
                    fontWeight: 500,
                    color: '#475569',
                  }}
                >
                  Properties
                </Typography>
                <Box
                  sx={{
                    borderRadius: 1,
                    overflow: 'hidden',
                    border: '1px solid #e2e8f0',
                  }}
                >
                  {Object.entries(selectedNode.properties).map(
                    ([key, value], index) => (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          p: 1.5,
                          backgroundColor:
                            index % 2 === 0 ? '#f8fafc' : 'white',
                          '&:not(:last-child)': {
                            borderBottom: '1px solid #e2e8f0',
                          },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 500,
                            color: '#64748b',
                            width: '40%',
                          }}
                        >
                          {key}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: '#334155',
                            width: '60%',
                            wordBreak: 'break-word',
                          }}
                        >
                          {String(value)}
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            )}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 2,
            py: 1,
            borderRadius: 2,
            border: '1px solid #e0e0e0',
            backgroundColor: '#f8f9fa',
          }}
        >
          <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
          <TextField
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search nodes and connections..."
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ '& input': { py: 1, ...karlaProRegularFontStyles } }}
          />

          <Tooltip title="Heat Map Mode">
            <FormControlLabel
              control={
                <Switch
                  checked={heatMapMode}
                  onChange={toggleHeatMapMode}
                  color="primary"
                  size="small"
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <HeatMapIcon sx={{ mr: 0.5, fontSize: 20 }} />
                  <Typography variant="body2">Heat Map</Typography>
                </Box>
              }
              sx={{ ml: 2, mr: 2 }}
            />
          </Tooltip>

          {!content && (
            <Tooltip title="Test with JSON Data">
              <IconButton
                onClick={toggleJsonDrawer}
                sx={{
                  backgroundColor: showJsonDrawer
                    ? 'rgba(0, 0, 0, 0.05)'
                    : 'transparent',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
                  p: 1,
                }}
              >
                <CodeIcon />
              </IconButton>
            </Tooltip>
          )}
        </Paper>

        {error && (
          <Alert severity="error" sx={{ borderRadius: 2 }}>
            {error}
          </Alert>
        )}

        <Paper
          elevation={0}
          sx={{
            overflow: 'hidden',
            position: 'relative',
            borderRadius: 2,
            border: '1px solid #e0e0e0',
            flex: 1,
            minHeight: height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />

          {loading && (
            <Fade in={loading}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 1,
                }}
              >
                <Paper
                  elevation={4}
                  sx={{
                    p: 3,
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  }}
                >
                  <CircularProgress size={24} sx={{ mr: 2 }} />
                  <Typography>Processing data...</Typography>
                </Paper>
              </Box>
            </Fade>
          )}

          {!graphData && !loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 4,
                textAlign: 'center',
              }}
            >
              <InfoIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
              <Typography variant="h6" sx={{ color: 'text.secondary', mb: 1 }}>
                No Graph Data Available
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
      {/* JSON Input Drawer - Hidden by default, toggled by Test button */}
      <Drawer
        anchor="right"
        open={showJsonDrawer}
        onClose={toggleJsonDrawer}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '450px', md: '500px' },
            p: 3,
            backgroundColor: '#f8fafc',
            borderLeft: '1px solid #e2e8f0',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}
            >
              <CodeIcon sx={{ mr: 1 }} /> Test Data
            </Typography>
            <IconButton onClick={toggleJsonDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
            Enter JSON Data
          </Typography>
          <TextField
            multiline
            fullWidth
            rows={16}
            value={jsonInput}
            onChange={handleJsonInput}
            placeholder='{"nodes": [...], "links": [...]}'
            variant="outlined"
            InputProps={{
              sx: {
                fontFamily: 'monospace',
                fontSize: '14px',
                backgroundColor: 'white',
              },
            }}
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
            <Button
              onClick={toggleJsonDrawer}
              variant="outlined"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              onClick={parseJsonInput}
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: '#3b82f6',
                '&:hover': { backgroundColor: '#2563eb' },
              }}
            >
              Visualize Data
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Popover
        open={!!selectedNode}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition || undefined}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 4,
          sx: {
            borderRadius: 2,
            width: 340,
            maxWidth: '95vw',
            overflow: 'hidden',
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        {renderNodeDetails()}
      </Popover>
    </Box>
  );
};

export default KnowledgeGraph;
