import { Box, Stack, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';
import { LEIAATextLikeDatePicker } from '../../../inputs/LEIAATextLikeDatePicker';

interface MatterDetailTimelineRangePickerProps {
  handleFetchEvents: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
}

const MatterDetailTimelineRangePicker = ({
  handleFetchEvents,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: MatterDetailTimelineRangePickerProps) => {
  const { control, setValue, watch } = useForm({
    defaultValues: {
      startDate,
      endDate,
    } as any,
  });

  const selectedStartDate = watch('startDate');
  const selectedEndDate = watch('endDate');

  useEffect(() => {
    handleFetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  const clearStartDate = () => {
    setValue('startDate', null);
    setStartDate(null);
  };

  const clearEndDate = () => {
    setValue('endDate', null);
    setEndDate(null);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box position="relative" display="inline-flex" alignItems="center">
        <LEIAATextLikeDatePicker
          labelText="Start Date"
          controllerName="startDate"
          control={control}
          handleChange={(date: any) => {
            setValue('startDate', date);
            setStartDate(date);
          }}
          labelStyle={{
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            fontWeight: '500',
            letterSpacing: '0.2px',
            color: '#0053FF',
          }}
          required={false}
        />
        {selectedStartDate && (
          <IconButton
            onClick={clearStartDate}
            size="small"
            sx={{
              position: 'absolute',
              top: '50%',
              right: '15%',
              transform: 'translate(50%, -50%)',
              backgroundColor: 'white',
              zIndex: 1,
              '&:hover': {
                backgroundColor: 'lightgray',
              },
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Box>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#464B53',
            fontSize: '14px',
            ...karlaProRegularFontStyles,
            marginRight: 'auto',
          }}
        >
          to
        </Typography>
      </Box>

      <Box position="relative" display="inline-flex" alignItems="center">
        <LEIAATextLikeDatePicker
          labelText="End Date"
          controllerName="endDate"
          control={control}
          handleChange={(date: any) => {
            setValue('endDate', date);
            setEndDate(date);
          }}
          labelStyle={{
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            fontWeight: '500',
            letterSpacing: '0.2px',
            color: '#0053FF',
          }}
          required={false}
        />
        {selectedEndDate && (
          <IconButton
            onClick={clearEndDate}
            size="small"
            sx={{
              position: 'absolute',
              top: '50%',
              right: '15%',
              transform: 'translate(50%, -50%)',
              backgroundColor: 'white',
              zIndex: 1,
              '&:hover': {
                backgroundColor: 'lightgray',
              },
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
};

export default MatterDetailTimelineRangePicker;
