import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { LEIAASelect } from '../../../components/inputs';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { secondaryButtonStyles } from '../../../components/styles';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import {
  createAllegationSubject,
  updateAllegationSubject,
} from '../../../api/whistleblower';
import CreateIndividualModal from './CreateIndividualModal';

interface OccurrencesChipProps {
  number: number;
}

const OccurrencesChip = ({ number }: OccurrencesChipProps) => {
  let borderColor = '#e0e0e0';
  let color = '#FFFFFF';

  switch (number) {
    case 0:
      color = '#38B763';
      borderColor = '#38B763';
      break;
    case 1:
    case 2:
      color = '#3375FF';
      borderColor = '#3375FF';
      break;
    default:
      if (number >= 3) {
        color = '#E22727';
        borderColor = '#E22727';
      }
      break;
  }

  return (
    <Box
      sx={{
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 10px',
          gap: '4px',
          height: '16px',
          background: 'white',
          borderRadius: '5px',
          border: `1px solid ${borderColor}`,
          '& > p': {
            textTransform: 'capitalize',
            fontSize: '14px',
            ...karlaProRegularFontStyles,
            fontWeight: '300',
            color,
          },
        },
      }}
    >
      <Box>
        <Typography>{number}</Typography>
      </Box>
    </Box>
  );
};

interface AllegationSubjectsProps {
  reportAdminData: any;
  refreshData: any;
  userRole: string | undefined;
}

const AllegationSubjects = ({
  reportAdminData,
  refreshData,
  userRole = '',
}: AllegationSubjectsProps) => {
  const [hoverStates, setHoverStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [openCreateIndividualModal, setOpenCreateIndividualModal] =
    useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      await updateAllegationSubject(reportAdminData.id, data);
      refreshData();
    } catch (error: any) {
      console.log(error);
    }
  };

  const addNewSubjectRequest = async () => {
    if (!watch('newAllegationSubject')) return;
    try {
      await createAllegationSubject(reportAdminData.id, {
        newAllegationSubject: watch('newAllegationSubject'),
        newAllegationSubjectType: watch('newAllegationSubjectType'),
      });
      setValue('newAllegationSubject', '');
      refreshData();
    } catch (error: any) {
      console.log(error);
    }
  };

  const removePersonRequest = async (personId: any) => {
    try {
      await updateAllegationSubject(reportAdminData.id, {
        remove: personId,
      });
      refreshData();
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleSetMatchedUser = (
    event: React.ChangeEvent<{ value: any }>,
    index: any
  ) => {
    const person = reportAdminData.engagedPersons[index];
    person.matched = event.target.value;
    setValue(`engagedPersons[${index}]`, person);
    handleSubmit(onSubmit)();
  };

  const addNewSubject = (event: React.ChangeEvent<{ value: any }>) => {
    setValue(`newAllegationSubject`, event.target.value);
  };

  const setSubjectType = (type: any) => {
    setValue(`newAllegationSubjectType`, type);
  };

  const setHoverState = (personId: string, isHovered: boolean) => {
    setHoverStates((prevHoverStates) => ({
      ...prevHoverStates,
      [personId]: isHovered,
    }));
  };

  return (
    <>
      <CreateIndividualModal
        open={openCreateIndividualModal}
        onClose={() => setOpenCreateIndividualModal(false)}
        refreshData={refreshData}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            ...matterInformationWrapperStyles,
            padding: '24px 16px',
            borderRadius: '24px',
            backgroundColor: '#F5FAFD',
          }}
        >
          <Box
            sx={{
              ...matterInformationFormWrapperStyles,
              width: '100%',
            }}
          >
            <Box
              sx={{
                ...matterInformationInputWrapperStyles,
                paddingTop: '5px',
              }}
            >
              <Box>
                <Box>
                  {reportAdminData.engagedPersons?.map(
                    (person: any, index: any) => {
                      return (
                        <Box
                          sx={{
                            padding: '8px 16px',
                            borderRadius: '12px',
                            backgroundColor: '#FFF',
                            width: '100%',
                          }}
                          key={person.id}
                        >
                          {person.created_by_whistleblower && (
                            <>
                              <Typography
                                sx={{
                                  color: '#464B53',
                                  ...karlaProRegularFontStyles,
                                  fontSize: '16px',
                                  fontWeight: 400,
                                  lineHeight: '22px',
                                  paddingBottom: '5px',
                                }}
                              >
                                Information sourced from whistleblower report:
                              </Typography>
                              <Stack
                                key={person.id}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                  width: '100%',
                                  paddingLeft: '10px',
                                  '& > div > p': {
                                    fontSize: '14px',
                                    ...karlaProRegularFontStyles,
                                    lineHeight: '22px',
                                    fontWeight: '500',
                                  },
                                  '& > div > p > span': {
                                    color: '#464B53',
                                    fontSize: '14px',
                                    ...karlaProRegularFontStyles,
                                    lineHeight: '22px',
                                  },
                                }}
                              >
                                <Box>
                                  <Typography>
                                    <span>First name: </span>{' '}
                                    {person.first_name}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    <span>Last name: </span> {person.last_name}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    <span>Job title: </span> {person.job_title}
                                  </Typography>
                                </Box>
                                {!person.matched &&
                                  ['Admin', 'Superuser', 'Leader'].includes(
                                    userRole
                                  ) && (
                                    <Box>
                                      <LEIAASelect
                                        labelText=""
                                        control={control}
                                        controllerName={`engagedPersons[${index}].matched`}
                                        inputPlaceholder="Select person to match"
                                        inputHeight="25px !important"
                                        baseHeight="25px !important"
                                        inputWidth="100%"
                                        paperCustomStylings={{
                                          height: '300px',
                                        }}
                                        handleChange={(e: any) =>
                                          handleSetMatchedUser(e, index)
                                        }
                                        required={false}
                                      >
                                        {reportAdminData.users?.map(
                                          (user: any) => {
                                            return (
                                              <MenuItem
                                                key={user.id}
                                                value={user.id}
                                                onClick={() =>
                                                  setValue(
                                                    `newAllegationSubjectType`,
                                                    user.type
                                                  )
                                                }
                                              >
                                                {user.type} - {user.name}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </LEIAASelect>
                                    </Box>
                                  )}
                              </Stack>
                            </>
                          )}
                          {person.matched && (
                            <>
                              {person.created_by_whistleblower && (
                                <Typography
                                  sx={{
                                    borderTop: '2px solid #F5FAFD',
                                    width: '100%',
                                    color: '#464B53',
                                    ...karlaProRegularFontStyles,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    paddingBottom: '5px',
                                    marginTop: '10px',
                                    paddingTop: '5px',
                                  }}
                                >
                                  Matched with user:
                                </Typography>
                              )}
                              <Stack
                                key={person.id}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                onMouseEnter={() =>
                                  setHoverState(person.id, true)
                                }
                                onMouseLeave={() =>
                                  setHoverState(person.id, false)
                                }
                                sx={{
                                  width: '100%',
                                  paddingLeft: person.created_by_whistleblower
                                    ? '10px'
                                    : '0',
                                  '& > div > p': {
                                    fontSize: '14px',
                                    ...karlaProRegularFontStyles,
                                    lineHeight: '22px',
                                    fontWeight: '500',
                                  },
                                  '& > div > p > span:not(.material-icons-round)':
                                    {
                                      color: '#464B53',
                                      fontSize: '14px',
                                      ...karlaProRegularFontStyles,
                                      lineHeight: '22px',
                                    },
                                  '& .material-icons-round': {
                                    verticalAlign: 'middle',
                                    fontSize: '18px',
                                    marginBottom: '4px',
                                  },
                                }}
                              >
                                <Box>
                                  <Typography>
                                    {hoverStates[person.id] &&
                                    ['Admin', 'Superuser', 'Leader'].includes(
                                      userRole
                                    ) ? (
                                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                      <span
                                        style={{
                                          color: 'red',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          removePersonRequest(person.id)
                                        }
                                        className="material-icons-round"
                                      >
                                        delete
                                      </span>
                                    ) : (
                                      <span className="material-icons-round">
                                        {person.matched.type === 'Individual'
                                          ? 'person'
                                          : 'badge'}
                                      </span>
                                    )}{' '}
                                    <span>First name: </span>{' '}
                                    {person.matched.first_name}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    <span>Last name: </span>{' '}
                                    {person.matched.last_name}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    <span>Job title: </span>{' '}
                                    {person.matched.job_title}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography>
                                    <span>Past occurrences: </span>
                                    <Box
                                      sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        marginLeft: '5px',
                                      }}
                                    >
                                      <OccurrencesChip
                                        number={person.matched.occurrences}
                                      />
                                    </Box>
                                  </Typography>
                                </Box>
                              </Stack>
                            </>
                          )}
                        </Box>
                      );
                    }
                  )}
                </Box>
                {['Admin', 'Superuser', 'Leader'].includes(userRole) && (
                  <Box>
                    <Stack
                      key={uuidv4()}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Box>
                        <LEIAASelect
                          labelText=""
                          control={control}
                          controllerName="newAllegationSubject"
                          inputPlaceholder="Select person"
                          inputHeight="25px !important"
                          baseHeight="25px !important"
                          inputWidth="100%"
                          paperCustomStylings={{
                            height: '300px',
                            background: 'white',
                          }}
                          handleChange={(e: any) => addNewSubject(e)}
                          required={false}
                        >
                          {reportAdminData.users?.map((user: any) => {
                            return (
                              <MenuItem
                                key={user.id}
                                value={user.id}
                                onClick={() => setSubjectType(user.type)}
                              >
                                {user.type} - {user.name}
                              </MenuItem>
                            );
                          })}
                        </LEIAASelect>
                      </Box>

                      <Box>
                        <Button
                          variant="contained"
                          sx={{
                            ...secondaryButtonStyles(),
                            height: '25px',
                            padding: '8px 16px',
                            gap: '8px',
                          }}
                          onClick={addNewSubjectRequest}
                          disabled={!watch('newAllegationSubject')}
                        >
                          <span className="material-icons-round">add</span>
                          <Typography>Add new allegation subject</Typography>
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          borderLeft: '2px solid white',
                          paddingLeft: '16px',
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            ...secondaryButtonStyles(),
                            height: '25px',
                            padding: '8px 16px',
                            gap: '8px',
                          }}
                          onClick={() => setOpenCreateIndividualModal(true)}
                        >
                          <span className="material-icons-round">
                            person_add
                          </span>
                          <Typography>Create Individual</Typography>
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default AllegationSubjects;
