import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { primaryButtonStyles, tertiaryButtonStyles } from '../../../styles';
import NotificationToast from '../../../shared/toast/NotificationToast';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../../styles/textStyles';
import { LEIAADatePicker, LEIAAInput, LEIAATimeInput } from '../../../inputs';
import { matterInformationInputWrapperStyles } from '../../matter-creation/matter-creation-steps/matter-information-step/styles';
import MatterNote from '../../../matter-notes/MatterNotes';
import {
  createMatterTimelineEvent,
  deleteMatterTimelineEvent,
  updateMatterTimelineEvent,
} from '../../../../api/matters';
import { TimelineEvent } from '../types';
import { getNote } from '../../../../api/general';

interface MatterDetailTimelineEditEventProps {
  matterId: any;
  matterDetails: any;
  refreshTimeline: any;
  event: TimelineEvent | null;
  openModal: any;
  setOpenModal: any;
  setShowArchiveNotification: any;
  setShowEditNotification: any;
}

const MatterDetailTimelineEditEvent = ({
  matterId,
  matterDetails,
  refreshTimeline,
  event,
  openModal,
  setOpenModal,
  setShowArchiveNotification,
  setShowEditNotification,
}: MatterDetailTimelineEditEventProps) => {
  const { handleSubmit, control, reset, setValue } = useForm();
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [clickedNote, setClickedNote] = useState(undefined);

  const resetForm = () => {
    setShowErrorNotification(false);
    setRequestError(null);
    setClickedNote(undefined);
    setOpenNoteModal(false);
    reset();
  };

  const onClose = () => {
    setOpenModal(false);
    setTimeout(() => {
      resetForm();
    }, 150);
  };

  const onSubmitEvent: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      if (!data.date || !data.time) {
        setRequestError('Please set a date and time of occurrence');
        setShowErrorNotification(true);
      } else {
        await updateMatterTimelineEvent(
          data.id,
          matterId,
          data.date,
          data.time,
          data.type,
          data.description,
          data.name
        );
        refreshTimeline();
        onClose();
        setShowEditNotification(true);
      }
    } catch (error: any) {
      setRequestError(error as string);
      setShowErrorNotification(true);
    }
  };

  const deleteEvent = async (eventId: any) => {
    try {
      await deleteMatterTimelineEvent(eventId);
      refreshTimeline();
      onClose();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error as string);
      setShowErrorNotification(true);
    }
  };

  const formatDate = (date: any) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const requestNoteContent = async (eventId: any) => {
    try {
      const response = await getNote(eventId);
      setClickedNote(response);
      setOpenNoteModal(true);
    } catch (error: any) {
      setRequestError(error as string);
      setShowErrorNotification(true);
    }
  };

  useEffect(() => {
    if (event) {
      if (event.type !== 'Note') {
        setValue('id', event.id);
        setValue('name', event.title);
        setValue('description', event.description);
        setValue('time', event.time);
        setValue('date', formatDate(event.date));
        setValue('type', event.type);
      } else {
        requestNoteContent(event.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      {showErrorNotification && (
        <Box
          sx={{
            position: 'fixed',
            top: '48px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '99999 !important',
            width: '100%',
          }}
        >
          <NotificationToast
            showNotification={showErrorNotification}
            requestError={requestError}
            handleCloseNotification={() => setShowErrorNotification(false)}
          />
        </Box>
      )}
      {openNoteModal && clickedNote && (
        <MatterNote
          matterId={matterId}
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          tableRefresh={refreshTimeline}
          note={clickedNote}
          resourceName="Investigation"
          tagResourceId={matterId}
          backdrop
          customOnClose={onClose}
        />
      )}
      {event && event.type !== 'Note' && (
        <Dialog
          onClose={() => onClose()}
          open={openModal}
          sx={{
            '& > div:nth-of-type(3)': {
              background: 'rgba(211, 236, 246, 0.70)',
              backdropFilter: 'blur(2px)',
              '& > div.MuiPaper-root': {
                padding: '10px',
                borderRadius: '24px',
                border: '1px solid #D3ECF6',
                background: '#FFF',
                boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
              },
            },
          }}
        >
          <form
            style={{ width: '100%' }}
            onSubmit={handleSubmit(onSubmitEvent)}
          >
            <Box
              sx={{
                width: '550px !important',
                padding: '15px',
              }}
            >
              <IconButton
                onClick={() => onClose()}
                sx={{ marginLeft: '90%', '& > span': { color: '#464B53' } }}
              >
                <span className="material-icons-round">close</span>
              </IconButton>
              <Box>
                <Typography
                  sx={{
                    color: '#202020',
                    ...sofiaProBoldFontStyles,
                    fontSize: '18px',
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    marginBottom: '20px',
                    textAlign: 'center',
                  }}
                >
                  {event.type}
                </Typography>
                <Stack
                  direction="column"
                  spacing={4}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      ...matterInformationInputWrapperStyles,
                      gap: '0 !important',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <LEIAAInput
                        labelText="Name"
                        controllerName="name"
                        control={control}
                        inputPlaceholder="Brief description of the event"
                        inputWidth="100%"
                        inputHeight="40px"
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <LEIAADatePicker
                        labelText="Date of occurrence"
                        inputPlaceholder="Date of Occurrence"
                        controllerName="date"
                        control={control}
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <LEIAATimeInput
                        labelText="Hour of Occurrence"
                        controllerName="time"
                        control={control}
                        inputPlaceholder="Hour of Occurrence"
                        inputWidth="100%"
                        inputHeight="40px"
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <LEIAAInput
                        labelText="Description"
                        controllerName="description"
                        control={control}
                        inputPlaceholder="Provide a detailed description of the event"
                        inputWidth="100%"
                        inputHeight="130px"
                        multiline
                        customClassName="scrollable-content"
                        required={false}
                      />
                    </Box>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        ...tertiaryButtonStyles(),
                        width: '100%',
                        '& > p': {
                          color: 'red !important',
                          textAlign: 'center',
                          ...karlaProRegularFontStyles,
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: 'normal',
                          letterSpacing: '0.2px',
                          textTransform: 'none',
                        },
                        '&:hover': {
                          backgroundColor: '#fff !important',
                          boxShadow: 'unset !important',
                          '& > *': {
                            color: '#FF4D4D !important',
                          },
                        },
                      }}
                      onClick={() => deleteEvent(event.id)}
                    >
                      <Typography>Delete</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        ...primaryButtonStyles(),
                        width: '100%',
                      }}
                      type="submit"
                    >
                      <Typography>Edit</Typography>
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default MatterDetailTimelineEditEvent;
