import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Grid,
  Select,
} from '@mui/material';
import { primaryButtonStyles, secondaryButtonStyles } from '../../../styles';
import {
  matterAvailableUsersRequest,
  matterEditDocumentStepRequest,
} from '../../../../api/matters';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../../styles/textStyles';
import { matterUsersAccordionWrapperStyles } from '../../matter-creation/matter-creation-steps/matter-users-step/styles';
import {
  matterInformationSelectPaperStyles,
  matterInformationSelectStyles,
} from '../../matter-creation/matter-creation-steps/matter-information-step/styles';

const permissionsCombinations = {
  'true,false,false': 'R (Read)',
  'false,true,false': 'U (Update)',
  'false,false,true': 'D (Delete)',
  'true,false,true': 'RD (Read, Delete)',
  'true,true,false': 'RU (Read, Update)',
  'false,true,true': 'UD (Update, Delete)',
  'true,true,true': 'RUD (Read, Update, Delete)',
  'false,false,false': 'No permission',
};

interface MatterDocumentsPermissionsProps {
  document: any;
  open: boolean;
  onClose: () => void;
  matterId?: string | number;
  setShowPermissionsUpdatedNotification?: (
    showPermissionsUpdatedNotification: boolean
  ) => void;
}

const MatterDocumentsPermissions = ({
  document,
  open,
  onClose,
  matterId,
  setShowPermissionsUpdatedNotification,
}: MatterDocumentsPermissionsProps) => {
  const [expandedAccordionDocId, setExpandedAccordionDocId] = useState<
    number | null
  >(document.id);

  const [permissions, setPermissions] = useState(document.permissions);
  const [users, setUsers] = useState<any[]>([]);

  const handleFetchUsers = async () => {
    try {
      const response = await matterAvailableUsersRequest();
      setUsers(response);
    } catch (error: any) {
      console.error(error);
    }
  };

  const userIdToName = (id: any) => {
    const user = users.find((u: any) => u.id === id);
    return user ? user.name : '';
  };

  const permissionsObjectToString = (permissionsData: any) => {
    const order = ['view', 'change', 'delete'];
    return order
      .map((key) => (permissionsData[key] ? 'true' : 'false'))
      .join(',');
  };

  const handleChangeFilePermissions = (index: number, value: string) => {
    const [view, change, del] = value.split(',');

    const updatedPermissions = [...permissions];
    updatedPermissions[index] = {
      ...permissions[index],
      view: view === 'true',
      change: change === 'true',
      delete: del === 'true',
    };

    setPermissions(updatedPermissions);
  };

  const handleSave = async () => {
    const payloadData = {
      [document.name]: permissions.map((permission: any) => ({
        user_id: permission.user_id,
        delete: permission.delete,
        change: permission.change,
        view: permission.view,
        file_id: document.id,
      })),
    };
    await matterEditDocumentStepRequest(matterId as string, {
      permissions: JSON.stringify(payloadData),
    });
    document.permissions = [...permissions];
    if (setShowPermissionsUpdatedNotification) {
      setShowPermissionsUpdatedNotification(true);
    }
    onClose();
  };

  useEffect(() => {
    handleFetchUsers();
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '& > div:nth-of-type(3)': {
          background: 'rgba(211, 236, 246, 0.70)',
          backdropFilter: 'blur(2px)',
          '& > div.MuiPaper-root': {
            display: 'flex',
            width: '50%',
            padding: '48px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '24px',
            border: '1px solid #D3ECF6',
            background: '#FFF',
            boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
            maxHeight: 'unset',
            maxWidth: 'unset',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: '1 0 0',
          width: '100%',
          '& > div:nth-of-type(2)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            alignSelf: 'stretch',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Typography
            sx={{
              color: '#202020',
              ...sofiaProBoldFontStyles,
              fontSize: '18px',
              textTransform: 'uppercase',
              marginBottom: '20px',
            }}
          >
            Document Permissions
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              overflowY: 'auto', // Added overflow Y scroll
              maxHeight: '300px', // Set a max height for scrolling
            }}
            className="scrollable-content"
          >
            <Accordion
              key={document.id}
              sx={matterUsersAccordionWrapperStyles(
                document.id === expandedAccordionDocId
              )}
              expanded={document.id === expandedAccordionDocId}
              onChange={() =>
                setExpandedAccordionDocId((prev) =>
                  prev === document.id ? null : document.id
                )
              }
            >
              <Box>
                <AccordionSummary
                  expandIcon={
                    <span className="material-icons-round">
                      arrow_drop_down
                    </span>
                  }
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>{document.name}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
              </Box>

              <AccordionDetails
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '10px',
                  alignSelf: 'stretch',
                  padding: '0px',
                  '& > div': {
                    display: 'flex',
                    paddingBottom: '16px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    flex: '1 0 0',
                    borderRadius: '0px 0px 14px 14px',
                    backgroundColor: '#F5FAFD',
                    '& > div:nth-of-type(1)': {
                      display: 'flex',
                      height: '40px',
                      padding: '0px 16px 0px 24px',
                      alignItems: 'center',
                      gap: '10px',
                      alignSelf: 'stretch',
                      borderBottom: '1px solid #D3ECF6',
                      '& > p': {
                        color: '#464B53',
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                      },
                    },
                    '& > div:not(:first-of-type)': {
                      display: 'flex',
                      padding: '0px 16px 0px 40px',
                      justifyContent: 'space-between',
                      alignSelf: 'stretch',
                      alignItems: 'center',
                      '& > div:nth-of-type(1) > p': {
                        color: '#464B53',
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        fontWeight: 300,
                        lineHeight: '22px',
                      },
                      '& > div:nth-of-type(2)': {
                        width: '285px',
                      },
                    },
                  },
                }}
              >
                <Box>
                  <Box>
                    <Typography>Document Permissions</Typography>
                  </Box>
                  {permissions.map((permission: any, index: number) => (
                    <Box key={`${document.id}-${index}`}>
                      <Box>
                        <Typography>
                          {userIdToName(permission.user_id)}
                        </Typography>
                      </Box>
                      <Box>
                        <Select
                          value={permissionsObjectToString(permission)}
                          onChange={(e: any) =>
                            handleChangeFilePermissions(index, e.target.value)
                          }
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                ...matterInformationSelectPaperStyles,
                                height: '300px',
                              },
                            },
                          }}
                          sx={{
                            ...matterInformationSelectStyles(
                              '40px !important',
                              '100%',
                              'unset'
                            ),
                            '& > div': {
                              marginRight: '25px',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            Select a permission
                          </MenuItem>
                          {Object.entries(permissionsCombinations).map(
                            ([value, label]) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ width: '100%', marginTop: '20px' }}
            gap="10px"
          >
            <Button
              variant="contained"
              sx={{ ...secondaryButtonStyles(), width: '100%' }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ ...primaryButtonStyles(), width: '100%' }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MatterDocumentsPermissions;
