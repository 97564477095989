/* eslint-disable no-nested-ternary */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material';
import { useState, useEffect } from 'react';
import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
} from '../../../../styles/textStyles';
import MicrosoftSmall from '../../../../assets/integrations/MicrosoftSmall.png';
import iManageSmall from '../../../../assets/integrations/iManageSmall.png';
import leiaaSmall from '../../../../assets/integrations/leiaaSmall.png';
import relativitySmall from '../../../../assets/integrations/relativitySmall.png';
import { matterDetailIntegrationGetRequest } from '../../../../api/matters';
import IntegrationsAuthModal from '../matter-detail-integrations/IntegrationsAuthModal';

const SourceStatus = ({ status }: { status: any }) => {
  let backgroundColor;
  let color = '#FFFFFF';
  let text = 'Authenticate';
  if (status === true) {
    backgroundColor = '#EFF9EA';
    color = '#72A85A';
    text = 'Connected';
  } else if (status === false) {
    backgroundColor = '#F2F2F2';
    color = '#707070';
    text = 'Authenticate';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        width: '100%',
        height: '20px',
        backgroundColor,
        borderRadius: '12px',
      }}
    >
      <Typography
        sx={{
          color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          lineHeight: '22px',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

interface MatterDetailDocumentsSourcesProps {
  matterId: any;
  toggledIntegrations: string[];
  setToggledIntegrations: React.Dispatch<React.SetStateAction<string[]>>;
}

const MatterDetailDocumentsSources = ({
  matterId,
  toggledIntegrations,
  setToggledIntegrations,
}: MatterDetailDocumentsSourcesProps) => {
  const [integrationStatus, setIntegrationStatus] = useState<any>([]);
  const [integrationResponse, setIntegrationResponse] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [targetIntegration, setTargetIntegration] = useState<null | string>(
    null
  );

  useEffect(() => {
    const requestIntegrationsStatus = async () => {
      try {
        setLoading(true);
        const response = await matterDetailIntegrationGetRequest({
          matter_status: matterId,
        });
        setIntegrationResponse(response);

        const updatedSources = [
          {
            id: '0',
            name: 'Leiaa',
            image: leiaaSmall,
            status: true,
          },
          {
            id: '1',
            name: 'iManage',
            image: iManageSmall,
            status: response.imanage.configured,
          },
          {
            id: '2',
            name: 'Relativity',
            image: relativitySmall,
            status: response.relativity.configured,
          },
          {
            id: '3',
            name: 'Microsoft',
            image: MicrosoftSmall,
            status: response.microsoft.configured,
          },
        ];
        setIntegrationStatus(updatedSources);

        const connectedIntegrations = updatedSources
          .filter((source: { status: string }) => source.status)
          .map((source: { name: string }) => source.name);

        setToggledIntegrations(connectedIntegrations as any);
      } catch (error: any) {
        setErrorMessage(error.response?.data?.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    if (matterId) {
      requestIntegrationsStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterId]);

  const toggleIntegration = (sourceName: string, forceUpdate = false) => {
    const sourceStatus = integrationStatus.find(
      (source: any) => source.name === sourceName
    )?.status;

    if (sourceStatus === true || forceUpdate) {
      setToggledIntegrations((prev: string[]) => {
        const newToggledIntegrations = prev.includes(sourceName)
          ? prev.filter((name: string) => name !== sourceName)
          : [...prev, sourceName];
        return newToggledIntegrations;
      });
    }
  };

  const authSuccessHandler = (integration: string) => {
    setIntegrationStatus((prevStatus: any[]) =>
      prevStatus.map((source) =>
        source.name === integration ? { ...source, status: true } : source
      )
    );

    toggleIntegration(integration, true);
  };

  const openIntegrationModal = (integration: string) => {
    setTargetIntegration(integration);
    setOpenAuthModal(true);
  };

  return (
    <>
      <IntegrationsAuthModal
        matterId={matterId}
        openAuthModal={openAuthModal}
        setOpenAuthModal={setOpenAuthModal}
        integrationResponse={integrationResponse}
        targetIntegration={targetIntegration}
        authSuccessHandler={authSuccessHandler}
      />
      <Box sx={{ padding: '10px 0px' }}>
        <Accordion
          sx={{
            padding: '0',
            boxShadow: 'none',
            border: 'none',
            backgroundColor: 'transparent',
          }}
        >
          <AccordionSummary
            expandIcon={
              <span className="material-icons-round">arrow_drop_down</span>
            }
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              padding: '0',
              borderBottom: '1px solid #f0f0f0',
            }}
          >
            <Typography
              sx={{
                color: '#69707B',
                ...karlaProBoldFontStyles,
                fontSize: '16px',
                lineHeight: '22px',
              }}
            >
              Sources
            </Typography>
            <Typography
              sx={{
                color: '#69707B',
                ...karlaProRegularFontStyles,
                fontSize: '14px',
                lineHeight: '22px',
                marginLeft: '10px',
              }}
            >
              {integrationStatus.filter((source: any) => source.status).length}/
              {integrationStatus.length} Connected
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '0',
              marginTop: '10px',
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                overflowX: 'scroll',
              }}
              className="scrollable-content"
            >
              {loading
                ? Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px',
                          border: '1px solid #F5FAFD',
                          height: '68px !important',
                          maxHeight: '68px !important',
                          minHeight: '68px !important',
                          backgroundColor: 'white',
                          borderRadius: '15px',
                        }}
                        key={index}
                      >
                        <Skeleton variant="rounded" width={65} height={65} />
                        <Stack
                          direction="column"
                          spacing={1}
                          sx={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            marginLeft: '5px',
                          }}
                        >
                          <Skeleton variant="text" width={100} />
                          <Skeleton variant="text" width={80} />
                        </Stack>
                      </Box>
                    ))
                : integrationStatus.map((source: any) => (
                    <Box
                      key={source.id}
                      onClick={() => toggleIntegration(source.name)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        border: toggledIntegrations.includes(source.name)
                          ? '2px solid #3A7BB5'
                          : '2px solid #F5FAFD',
                        height: '68px !important',
                        maxHeight: '68px !important',
                        minHeight: '68px !important',
                        backgroundColor: 'white',
                        borderRadius: '15px',
                        cursor: 'pointer',
                        transition: 'border 0.2s ease',
                        '&:hover': {
                          border: toggledIntegrations.includes(source.name)
                            ? '2px solid #3A7BB5'
                            : '2px solid #F5FAFD',
                        },
                      }}
                    >
                      <img
                        src={source.image}
                        style={{
                          height: '65px',
                          width: '65px',
                        }}
                        alt={source.name}
                      />
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          marginLeft: '8px',
                        }}
                      >
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            color: '#69707B',
                            ...karlaProBoldFontStyles,
                            fontSize: '14px',
                            lineHeight: '22px',
                          }}
                        >
                          {source.name}
                        </Typography>
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            if (source.name !== 'Leiaa' && !source.status) {
                              openIntegrationModal(source.name);
                            }
                          }}
                        >
                          <SourceStatus status={source.status} />
                        </Box>
                      </Stack>
                    </Box>
                  ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default MatterDetailDocumentsSources;
