import {
  Box,
  Typography,
  List,
  ListItem,
  Collapse,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  karlaProRegularFontStyles,
  karlaProBoldFontStyles,
} from '../../../styles/textStyles';

interface FolderFileInformationProps {
  item: any;
}

const FolderFileInformation = ({ item }: FolderFileInformationProps) => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(() => {
    const initialState: { [key: string]: boolean } = {};
    if (item && !item.folder) {
      initialState['PredictedRelevanceReasoning-1'] = true;
    }
    return initialState;
  });

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US');
  };

  const toggleExpand = (field: string) => {
    setExpanded((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const renderField = (label: string, value: string, index: number) => {
    const isLongText = value && value.length > 100;
    const fieldId = `${label.replace(/\s+/g, '')}-${index}`;
    const isRelevanceReasoning = label === 'Predicted Relevance Reasoning';
    const isExpanded = isRelevanceReasoning
      ? expanded[fieldId] !== false
      : expanded[fieldId] || false;

    return (
      <ListItem
        key={index}
        sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ ...karlaProBoldFontStyles, marginRight: '5px' }}
          >
            {label}:{' '}
          </Typography>

          {isLongText ? (
            <IconButton
              size="small"
              onClick={() => toggleExpand(fieldId)}
              sx={{ ml: 'auto' }}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          ) : (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{
                ...karlaProRegularFontStyles,
              }}
            >
              {value}
            </Typography>
          )}
        </Box>

        {isLongText && (
          <Collapse
            in={isExpanded}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Box
              sx={{
                p: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                borderRadius: 1,
                mt: 1,
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  ...karlaProRegularFontStyles,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {value}
              </Typography>
            </Box>
          </Collapse>
        )}

        {isLongText && !isExpanded && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              ...karlaProRegularFontStyles,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              width: '100%',
              mt: 1,
            }}
          >
            {value}
          </Typography>
        )}
      </ListItem>
    );
  };

  return (
    <Box>
      <List sx={{ marginBottom: '8px' }}>
        {item.folder ? (
          <>
            {[
              {
                label: 'Permissions',
                value: item.permissions
                  .map((user: any) => user.name)
                  .join(', '),
              },
            ].map(({ label, value }, index) =>
              renderField(label, value, index)
            )}
          </>
        ) : (
          <>
            {[
              {
                label: 'Predicted Relevance',
                value: item.relevance,
              },
              {
                label: 'Predicted Relevance Reasoning',
                value: item.relevance_reasoning,
              },
              { label: 'Source', value: item.source },
              { label: 'File Extension', value: item.file_extension },
              { label: 'Last Modified', value: formatDate(item.updated) },
              {
                label: 'Permissions',
                value: item.permissions
                  .map((user: any) => user.name)
                  .join(', '),
              },
            ].map(({ label, value }, index) =>
              renderField(label, value, index)
            )}
          </>
        )}
      </List>
    </Box>
  );
};

export default FolderFileInformation;
