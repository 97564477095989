import React, { useState } from 'react';
import { Box, InputLabel, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Control, Controller, FieldValues } from 'react-hook-form';
import dayjs from 'dayjs';
import { dateTextLikePickerStyles } from './styles';

interface LEIAATextLikeDatePickerProps {
  labelText?: string;
  controllerName: string;
  control: Control<FieldValues>;
  required?: boolean;
  handleChange?: any;
  disabled?: boolean;
  labelStyle?: any;
  dateStyle?: any;
}

const convertDateFormat = (inputDate: string) => {
  const [day, month, year] = inputDate.split('/');
  return `${month}/${day}/${year}`;
};

export const LEIAATextLikeDatePicker = ({
  labelText,
  controllerName,
  control,
  required = true,
  handleChange,
  disabled = false,
  labelStyle = {},
  dateStyle = {},
}: LEIAATextLikeDatePickerProps) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const handleOpenPicker = () => setIsPopperOpen(true);
  const handleClosePicker = () => setIsPopperOpen(false);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {labelText && (
        <InputLabel
          htmlFor={labelText}
          sx={{
            margin: 0,
            cursor: disabled ? 'not-allowed' : 'default',
            ...labelStyle,
          }}
        >
          {required ? '*' : ''}
          {labelText}
        </InputLabel>
      )}
      <Controller
        name={controllerName}
        control={control}
        defaultValue=""
        render={({ field: { value, onChange } }) => (
          <DatePicker
            disabled={disabled}
            open={isPopperOpen}
            onOpen={handleOpenPicker}
            onClose={handleClosePicker}
            value={value ? dayjs(convertDateFormat(value)) : null}
            format="D/M/YYYY"
            onChange={(e) => {
              let formattedDate = '';

              if (e !== null) {
                formattedDate = (e as any).$d!.toLocaleDateString('pt-PT', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                });
              }

              onChange(formattedDate);
              if (handleChange) {
                handleChange(formattedDate);
              }
            }}
            sx={{
              ...dateTextLikePickerStyles(),
            }}
          />
        )}
      />
    </Box>
  );
};

export default LEIAATextLikeDatePicker;
