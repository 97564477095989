import { Box, Typography } from '@mui/material';
import { getSeverityBackgroundColor } from '../../../styles/sharedStyles';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface SeverityChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const SeverityChip = ({
  status,
  heightValue,
  wrapperHeight,
}: SeverityChipProps) => {
  const backgroundColor = getSeverityBackgroundColor(status);
  const color = '#FFFFFF';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        height: wrapperHeight,
        alignSelf: 'flex-start',
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 10px',
          gap: '4px',
          heightValue,
          backgroundColor,
          borderRadius: '10px',
          '& > p': {
            ...karlaProRegularFontStyles,
            textTransform: 'capitalize',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.3px',
            color,
          },
        },
      }}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default SeverityChip;
