/* eslint-disable no-else-return */

import api from '.';
import { ChatMessage } from '../components/llm/api';
// MOCK
const mockResponses: Record<string, string> = {
  database: "Here's information about databases...",
  'help me with': "I'd be happy to help with your request about...",
  'create a function': "Here's a sample function based on your requirements...",
};

const generateMessageId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const generateMockResponse = (userInput: string): string => {
  const normalizedInput = userInput.toLowerCase().trim();

  if (mockResponses[normalizedInput]) {
    return mockResponses[normalizedInput];
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(mockResponses)) {
    if (normalizedInput.includes(key.toLowerCase())) {
      return mockResponses[key];
    }
  }
  if (normalizedInput.includes('hello') || normalizedInput.includes('hi')) {
    return 'Hello! How can I assist you today?';
  } else if (normalizedInput.includes('help')) {
    return "I'd be happy to help. What do you need assistance with?";
  } else if (normalizedInput.includes('thank')) {
    return "You're welcome! Let me know if there's anything else you need.";
  } else if (normalizedInput.includes('bye')) {
    return 'Goodbye! Have a great day!';
  } else if (
    normalizedInput.includes('sql') ||
    normalizedInput.includes('database') ||
    normalizedInput.includes('query')
  ) {
    return "Here's a SQL query that might help with your request:\n\n```sql\nSELECT column1, column2 \nFROM table_name \nWHERE condition \nORDER BY column1;\n```\n\nYou can modify this query based on your specific database schema and requirements.";
  } else if (
    normalizedInput.includes('code') ||
    normalizedInput.includes('javascript') ||
    normalizedInput.includes('react')
  ) {
    return "Here's a sample React component that might be useful:\n\n```jsx\nimport React, { useState } from 'react';\n\nconst Counter = () => {\n  const [count, setCount] = useState(0);\n  \n  return (\n    <div>\n      <p>Count: {count}</p>\n      <button onClick={() => setCount(count + 1)}>Increment</button>\n    </div>\n  );\n};\n\nexport default Counter;\n```";
  } else {
    return `I understand you're asking about "${userInput}". While I don't have specific information about this, I can help you explore this topic further. Could you provide more details about what you're looking for?`;
  }
};

async function LlmChatDocumentUserHistoryRequest(
  documentId: string
): Promise<any> {
  const response = await api.get(`chat/${documentId}`);
  return response.data;
}
// END MOCK

async function LlmGetChatSessionsRequest(
  documentId: number | string
): Promise<any> {
  const response = await api.get(`chat/${documentId}`);
  return response.data;
}

async function LlmCreateChatSessionsRequest(
  documentId: number | string,
  chatSessionId: number | string,
  role: string,
  content: ChatMessage | any
): Promise<any> {
  // content temporary fix
  const structuredContent = {
    type: 'text',
    text: content,
  };

  const response = await api.post(`chat/${documentId}`, {
    chat_session_id: chatSessionId,
    role,
    content: content ? JSON.stringify(structuredContent) : null,
  });
  return response.data;
}

async function LlmGetChatSessionHistoryRequest(
  documentId: number | string,
  chatSessionId: number | string
): Promise<any> {
  const filters: { chat_session_id: any } = {
    chat_session_id: chatSessionId || null,
  };

  const response = await api.get(`chat-history/${documentId}`, {
    params: filters,
  });
  return response.data;
}

// eslint-disable-next-line import/prefer-default-export
export {
  generateMessageId,
  generateMockResponse,
  LlmChatDocumentUserHistoryRequest,
  LlmGetChatSessionsRequest,
  LlmCreateChatSessionsRequest,
  LlmGetChatSessionHistoryRequest,
};
