import {
  Box,
  Button,
  Divider,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { simpleGoBackButtonStyles } from '../../styles';
import {
  matterCreationHeaderStepperStyles,
  matterCreationHeaderWrapperStyles,
} from './styles';

interface MatterCreationHeaderProps {
  activeStep: number;
  steps: string[];
  visited?: boolean[];
  currentStep: number;
  locationState: any;
  showSteps?: boolean;
}

const MatterCreationHeader = ({
  activeStep,
  steps,
  visited,
  currentStep,
  locationState = {},
  showSteps = true,
}: MatterCreationHeaderProps) => {
  const { matterId } = useParams();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (locationState && locationState.previous === 'my-dashboard-interviews') {
      navigate('/', { state: { activeStep: 3 } });
    } else if (
      locationState &&
      locationState.previous === 'matter-interviews'
    ) {
      navigate(`/matters/detail/${matterId}`, { state: { currentStep: 4 } });
    } else {
      navigate(-1);
    }
  };

  return (
    <Box sx={matterCreationHeaderWrapperStyles}>
      <Box>
        <Box>
          <Typography>
            {currentStep === undefined ? 'New Matter' : 'Edit Matter Details'}
          </Typography>
          {/* SEARCH INPUT BOX  */}
        </Box>
        <Button
          sx={simpleGoBackButtonStyles}
          variant="text"
          onClick={handleGoBack}
        >
          <span className="material-symbols-outlined">arrow_back</span>
          <Typography variant="caption" component="p">
            Back
          </Typography>
        </Button>
      </Box>
      {showSteps && (
        <Box sx={{ width: '100%' }}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={matterCreationHeaderStepperStyles}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  color="inherit"
                  // onClick={handleStep(index)}
                  className={visited && visited[index] ? 'visited-step' : ''}
                >
                  {label}
                </StepButton>
                {activeStep === index ? (
                  <Divider
                    sx={{ width: '100%', border: '1px solid #0053FF' }}
                  />
                ) : (
                  <Divider
                    sx={{ width: '100%', border: '1px solid #69707B' }}
                  />
                )}
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </Box>
  );
};

export default MatterCreationHeader;
