/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import '../../../pages/styles/matterlist.css';
import '../../styles/datagridStyles.css';
import {
  bundleActionsRequest,
  bundleDocsRequest,
  getDocumentURLRequest,
} from '../../../api/matters';
import ListButtons from '../../shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';
import DocumentsListTable from '../../shared/docs-list-table/DocumentsListTable';
import NotificationToast from '../../shared/toast/NotificationToast';

const MatterDetailBundleDetail = () => {
  const { matterId, bundleId } = useParams();
  const matterIdInt: number = matterId ? parseInt(matterId, 10) : 0;
  const location = useLocation();
  const { matterDetails, bundleName, selectedBundle } = location.state;
  const [loading, setLoading] = useState(false);
  const [bundleDocs, setBundleDocs] = useState<any>(location.state.response);
  const [checkedDocs, setCheckedDocs] = useState<any>([]);
  const [selectedDocs, setSelectedDocs] = useState<any | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleGetMatterDetailDocuments = async (page = 1) => {
    setLoading(true);
    try {
      const response = await bundleDocsRequest(
        bundleId || '',
        inputRef.current?.value || '',
        page
      );
      setBundleDocs(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const handleListSearchbarInputChange = () => {
    handleGetMatterDetailDocuments();
  };

  const handleDownloadFile = async () => {
    if (!selectedDocs) return;
    try {
      const data = await getDocumentURLRequest(matterIdInt, selectedDocs.id);
      const link = document.createElement('a');
      link.href = data.file;
      link.download = selectedDocs.name;
      link.click();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const removeFromResourceHandler = async () => {
    try {
      if (checkedDocs.length > 1) {
        await bundleActionsRequest(
          parseInt(bundleId || '', 10),
          undefined,
          undefined,
          undefined,
          undefined,
          checkedDocs
        );
      } else {
        await bundleActionsRequest(
          parseInt(bundleId || '', 10),
          undefined,
          undefined,
          undefined,
          undefined,
          [selectedDocs.id]
        );
      }

      await handleGetMatterDetailDocuments();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handlePageChange = async (newPage: any) => {
    setSelectedDocs(null);
    setCheckedDocs([]);
    setCurrentPage(newPage);
    await handleGetMatterDetailDocuments(newPage);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}

      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          },
        }}
      >
        <Box>
          <Breadcrumbs
            separator={
              <span className="material-icons-round">chevron_right</span>
            }
            aria-label="breadcrumb"
            sx={{
              '& > ol': {
                gap: '6px',
                '& > .MuiBreadcrumbs-li > div': {
                  '& > button': {
                    ...sofiaProRegularFontStyles,
                    color: '#69707B',
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    padding: '10px 0px',
                  },
                  '& > p': {
                    color: '#949CA9',
                    ...karlaProRegularFontStyles,
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.2px',
                  },
                },
                '& > .MuiBreadcrumbs-separator': {
                  margin: '0px',
                  padding: '10px 0',
                  alignSelf: 'baseline',
                },
              },
            }}
          >
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => navigate(-1)}
              >
                {matterDetails.name}
              </Link>
              <Typography>Matter</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() =>
                  navigate(`/matters/detail/${matterId}`, {
                    state: { currentStep: 2 },
                  })
                }
              >
                Documents
              </Link>
              <Typography>Tab</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() =>
                  navigate(`/matters/detail/${matterId}`, {
                    state: { currentStep: 2 },
                  })
                }
              >
                Interview Bundles
              </Link>
              <Typography>Resource</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                sx={{
                  ...sofiaProBoldFontStyles,
                  color: '#202020 !important',
                  fontSize: '18px !important',
                  fontWeight: '700 !important',
                  lineHeight: 'normal !important',
                  letterSpacing: '2px !important',
                  textTransform: 'uppercase !important',
                  padding: '10px 0 !important',
                  cursor: 'default',
                  pointerEvents: 'none',
                }}
              >
                {bundleName}
              </Link>
              <Typography>Bundle</Typography>
            </Box>
          </Breadcrumbs>

          {selectedBundle &&
          selectedBundle !== undefined &&
          selectedBundle.interviee_name !== '' &&
          selectedBundle.interview_date !== '' ? (
            <Box
              sx={{
                display: 'flex',
                padding: '8px 16px',
                alignItems: 'center',
                gap: '24px',
                borderRadius: '12px',
                border: '1px solid #D3ECF6',
                alignSelf: 'flex-start',
                '& > div': {
                  display: 'flex',
                  gap: '8px',
                  '& > *': { color: '#69707B' },
                  '& > p': {
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    lineHeight: '22px',
                  },
                },
              }}
            >
              <Box>
                <span className="material-icons-round">assignment_ind</span>
                <Typography>{selectedBundle?.interviee_name}</Typography>
              </Box>
              <Box>
                <span className="material-icons-round">event</span>
                <Typography>{selectedBundle?.interview_date}</Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '40px',
            alignSelf: 'stretch',
            '& > div:nth-of-type(1)': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flex: '1 0 0',
            },
          }}
        >
          <Box>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by document..."
              sx={{
                ...homePortalSearchInputStyles,
                marginLeft: 'auto',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
          </Box>
          {checkedDocs.length > 0 && (
            <ListButtons
              checkedResources={checkedDocs}
              resourceName="Documents"
              removeFromResourceHandler={removeFromResourceHandler}
              handleDownloadFile={handleDownloadFile}
              limitCheckedDocs={false}
            />
          )}
        </Box>
      </Box>
      <Grid item xs={12} md={12}>
        <DocumentsListTable
          documentsListData={bundleDocs}
          listClass="docs-list"
          loading={loading}
          setCheckedDocs={setCheckedDocs}
          setSelectedDocs={setSelectedDocs}
          onPageChange={handlePageChange}
          pageSize={5}
        />
      </Grid>
    </Box>
  );
};

export default MatterDetailBundleDetail;
