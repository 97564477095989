import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import { primaryButtonStyles } from '../../styles';
import ListButtons from '../../shared/list-buttons/ListButtons';
import MatterNote from '../../matter-notes/MatterNotes';
import NotesListTable from '../../shared/notes-list-table/NotesListTable';
import { batchDeleteNotes, getMatterNotesList } from '../../../api/general';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import NotificationToast from '../../shared/toast/NotificationToast';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface MatterDetailNotesProps {
  matterId: any;
  matterDetails: any;
  resourceName: string;
  isModal?: boolean;
  taggedResourceId?: any;
}

const MatterDetailNotes = ({
  matterId,
  matterDetails,
  resourceName,
  isModal = false,
  taggedResourceId = matterId,
}: MatterDetailNotesProps) => {
  const [loading, setLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [notesListData, setNotesListData] = useState<any[]>([]);
  const [checkedNotes, setCheckedNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState<any>(null);
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [clickedNote, setClickedNote] = useState<any>(undefined);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showArchivedNotes, setShowArchivedNotes] = useState(false);

  const handleFetchNotesList = async () => {
    setLoading(true);

    try {
      const response = await getMatterNotesList(
        inputRef.current?.value || '',
        matterId,
        showArchivedNotes,
        isModal
          ? [{ tag_type: resourceName, tag_resource_id: taggedResourceId }]
          : []
      );
      setNotesListData(response.notes);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchNotesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchivedNotes]);

  const handleAddNote = () => {
    setClickedNote(undefined);
    setOpenNoteModal(true);
  };

  const handleDetailNotes = (_note: any) => {
    setClickedNote(_note);
    setOpenNoteModal(true);
  };

  const deleteNotesHandler = async () => {
    try {
      await batchDeleteNotes(checkedNotes);
      await handleFetchNotesList();
      if (!isModal) {
        setShowArchiveNotification(true);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  return (
    <>
      {showArchiveNotification && (
        <CreationNotification
          title={`${
            checkedNotes.length !== 1 ? 'Notes' : 'Note'
          } archived successfully!`}
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {openNoteModal && (
        <MatterNote
          matterId={matterId}
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          tableRefresh={handleFetchNotesList}
          note={clickedNote}
          resourceName={resourceName}
          tagResourceId={taggedResourceId}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '30px',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}>
            <Button
              variant="contained"
              onClick={handleAddNote}
              sx={{ ...primaryButtonStyles(), height: '40px' }}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add Note</Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  checked={showArchivedNotes}
                  onChange={() => setShowArchivedNotes(!showArchivedNotes)}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 24 },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: '#464B53',
                    fontSize: '16px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                    display: 'flex',
                    marginRight: 'auto',
                  }}
                >
                  Archived Notes
                </Typography>
              }
            />
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search note..."
              sx={{
                ...homePortalSearchInputStyles,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleFetchNotesList,
                inputRef,
              }}
              variant="standard"
            />
          </Box>
        </Box>
        {checkedNotes.length > 0 && (
          <ListButtons
            checkedResources={checkedNotes}
            deleteHandler={deleteNotesHandler}
            resourceName="note"
          />
        )}
      </Box>
      <Box sx={{ width: '100%', marginTop: isModal ? 'unset' : '40px' }}>
        <NotesListTable
          notesListData={notesListData}
          loading={loading}
          setCheckedNotes={setCheckedNotes}
          setSelectedNote={setSelectedNote}
          handleDetailNotes={handleDetailNotes}
          isModal={isModal}
        />
      </Box>
    </>
  );
};

export default MatterDetailNotes;
