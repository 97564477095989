import { Box, Drawer, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PDFNavigator from '../document-preview/PDFNavigator';
import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
} from '../../../styles/textStyles';

import FolderFileInformationTabs from './FolderFileInformationTabs';
import PredictedRelevance from '../docs-list-table/PredictedRelevance';

// TODO: Refactor prop drilling
interface FolderFileInformationDrawerProps {
  item: any;
  open: boolean;
  setOpen: any;
  folderPermissionsHandler?: any;
  folderUploadLinkHandler?: any;
  handleDownloadFile?: any;
  addToFraudDetectionHandler?: any;
  renameHandler?: any;
  fileDownloadLinkHandler?: any;
}

const FolderFileInformationDrawer = ({
  item,
  open,
  setOpen,
  folderPermissionsHandler,
  folderUploadLinkHandler,
  handleDownloadFile,
  addToFraudDetectionHandler,
  renameHandler,
  fileDownloadLinkHandler,
}: FolderFileInformationDrawerProps) => {
  const isFile = !item.folder;

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {isFile && <PDFNavigator file={item} />}
        <Box
          sx={{ width: isFile ? '40vw' : '30vw', padding: '16px' }}
          role="presentation"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              marginBottom: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  ...karlaProBoldFontStyles,
                  marginRight: '16px',
                }}
              >
                {item.name}
              </Typography>
              {false && isFile && item.relevance && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '4px 12px',
                    border: '1px solid #D3ECF6',
                    borderRadius: '8px',
                    marginTop: '8px',
                    width: 'fit-content',
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: '6px',
                      color: '#69707B',
                      ...karlaProRegularFontStyles,
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '18px',
                    }}
                  >
                    Predicted Relevance
                  </Typography>
                  <PredictedRelevance file={item} />
                </Box>
              )}
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                padding: '4px',
                marginTop: '-4px',
                marginRight: '-4px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <FolderFileInformationTabs
            item={item}
            folderPermissionsHandler={folderPermissionsHandler}
            folderUploadLinkHandler={folderUploadLinkHandler}
            handleDownloadFile={handleDownloadFile}
            addToFraudDetectionHandler={addToFraudDetectionHandler}
            renameHandler={renameHandler}
            fileDownloadLinkHandler={fileDownloadLinkHandler}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default FolderFileInformationDrawer;
