import { useEffect, useState, useRef } from 'react';
import {
  Card,
  Button,
  Box,
  Typography,
  Divider,
  Stack,
  IconButton,
  Chip,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import { LEIAAInput } from '../inputs';
import { primaryButtonStyles } from '../styles';
import ChatHistorySidebar from './ChatHistorySidebar';
import { matterInformationInputWrapperStyles } from '../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import InChatConfirmation from './InChatConfirmation';

export interface Message {
  id: string;
  role: 'user' | 'assistant' | 'system';
  content: string;
  createdAt: Date;
  isWelcomeMessage?: boolean;
  isConfirmation?: boolean;
  confirmationData?: any;
}

export interface Session {
  id: string;
  created_date: string;
}

interface ChatInterfaceProps {
  title?: string;
  messages: Message[];
  sessions?: Session[];
  onSubmit: (message: string) => void;
  onLoadSession?: (sessionId: string) => void;
  onCreateNewSession?: () => void;
  isStreaming: boolean;
  chatWidth?: string;
  chatHeight?: string;
  minimizedHeight?: string;
  allowMinimize?: boolean;
  cardStyles?: React.CSSProperties;
  previewLabel?: string;
  enableChatHistory?: boolean;
  onConfirmAction?: (data: any) => void;
  onRejectAction?: () => void;
}

const ChatInterface = ({
  title = 'Chat',
  messages,
  sessions = [],
  onSubmit,
  onLoadSession,
  onCreateNewSession,
  isStreaming,
  chatWidth = '70vw',
  chatHeight = '60vh',
  minimizedHeight = '48px',
  allowMinimize = true,
  cardStyles,
  previewLabel = 'Preview',
  enableChatHistory = true,
  onConfirmAction,
  onRejectAction,
}: ChatInterfaceProps) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(enableChatHistory);
  const [isMinimized, setIsMinimized] = useState(false);

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormSubmit: SubmitHandler<FieldValues> = async () => {
    const input = watch('message');
    setValue('message', '');
    onSubmit(input);
  };

  const handleSelectSession = (sessionId: string) => {
    if (onLoadSession) {
      onLoadSession(sessionId);
    }
    setIsOpen(false);
  };

  const handleCreateNewSession = () => {
    if (onCreateNewSession) {
      onCreateNewSession();
    }
    setIsOpen(false);
  };

  const toggleMinimize = () => {
    if (!allowMinimize) return;

    setIsMinimized(!isMinimized);
    if (isOpen && !isMinimized) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        handleSubmit(handleFormSubmit)();
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit, handleFormSubmit]);

  const truncateMessage = (message: string, maxLength: number) => {
    if (message.length <= maxLength) return message;
    return `${message.substring(0, maxLength)}...`;
  };

  const getLastMessage = () => {
    const userMessages = messages.filter((msg) => msg.role === 'user');
    const assistantMessages = messages.filter(
      (msg) => msg.role === 'assistant'
    );

    if (assistantMessages.length > 0) {
      const lastAssistantMsg = assistantMessages[assistantMessages.length - 1];
      return truncateMessage(lastAssistantMsg.content, 30);
    }
    if (userMessages.length > 0) {
      const lastUserMsg = userMessages[userMessages.length - 1];
      return truncateMessage(lastUserMsg.content, 30);
    }
    return 'No messages yet';
  };

  return (
    <Card
      style={{
        width: chatWidth,
        height: isMinimized ? minimizedHeight : chatHeight,
        transition: 'height 0.3s ease',
        overflow: 'hidden',
        position: 'relative',
        // border: '1px solid rgb(228 228 228)',
        // borderRadius: '10px',
        // boxShadow: '3px 3px 10px rgba(0,0,0,0.2)',
        ...cardStyles,
      }}
    >
      {isMinimized && allowMinimize ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 16px',
            height: '100%',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: allowMinimize ? '#f5f5f5' : 'unset',
            },
          }}
          onClick={toggleMinimize}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontSize: '12px',
                color: '#666',
                maxWidth: '300px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {getLastMessage()}
            </Typography>
          </Box>
          <IconButton onClick={toggleMinimize} size="small">
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="material-icons-round"
            >
              expand_less
            </span>
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {enableChatHistory && (
            <ChatHistorySidebar
              sessions={sessions}
              onSelectSession={handleSelectSession}
              onCreateNewChat={handleCreateNewSession}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}

          <Box
            sx={{
              marginLeft:
                // eslint-disable-next-line no-nested-ternary
                enableChatHistory && isOpen
                  ? '250px'
                  : enableChatHistory
                  ? '50px'
                  : '0px',
              height: '100%',
              width: `calc(100% - ${
                // eslint-disable-next-line no-nested-ternary
                enableChatHistory && isOpen
                  ? '250px'
                  : enableChatHistory
                  ? '50px'
                  : '0px'
              })`,
              transition: 'margin-left 0.3s ease, width 0.3s ease',
              display: 'flex',
              flexDirection: 'column',
              padding: isMinimized ? 0 : '10px',
            }}
            onClick={() => enableChatHistory && setIsOpen(false)}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 8px',
                '&:hover': {
                  backgroundColor: allowMinimize ? '#f5f5f5' : 'unset',
                  borderRadius: '10px 10px 0 0',
                },
                marginBottom: '5px',
                cursor: allowMinimize ? 'pointer' : 'default',
              }}
              onClick={allowMinimize ? toggleMinimize : undefined}
            >
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontSize: '14px',
                  color: '#202020',
                }}
              >
                {title}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {allowMinimize && (
                  <IconButton onClick={toggleMinimize} size="small">
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      className="material-icons-round"
                    >
                      expand_more
                    </span>
                  </IconButton>
                )}
                <Chip
                  label={previewLabel}
                  size="small"
                  sx={{
                    backgroundColor: 'white',
                    border: '1px solid #0053ff',
                    color: '#0053ff',
                    fontSize: '12px',
                    height: '24px',
                    ...karlaProRegularFontStyles,
                  }}
                />
              </Box>
            </Stack>
            <Divider />

            <Box
              ref={messagesContainerRef}
              style={{
                flexGrow: 1,
                overflowY: 'auto',
                padding: '5px 0',
                display: 'flex',
                flexDirection: 'column',
                scrollBehavior: 'smooth',
              }}
              className="scrollable-content"
            >
              {error && (
                <Box
                  style={{
                    padding: '10px',
                    margin: '10px 0',
                    backgroundColor: '#ffebee',
                    borderRadius: '4px',
                  }}
                >
                  <Typography color="error">{error}</Typography>
                </Box>
              )}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {messages
                  .filter((message) => message.role !== 'system')
                  .map((message) => (
                    <Box
                      key={message.id}
                      style={{
                        padding: message.isConfirmation ? 0 : '12px',
                        alignSelf:
                          message.role === 'user' ? 'flex-end' : 'flex-start',
                        maxWidth: message.isConfirmation ? '100%' : '80%',
                        wordBreak: 'break-word',
                        // eslint-disable-next-line no-nested-ternary
                        background: message.isConfirmation
                          ? 'transparent'
                          : message.role === 'user'
                          ? '#f1fafe'
                          : '#d3ecf6',
                        borderRadius: message.isConfirmation ? 0 : '12px',
                        marginTop: '5px',
                        marginBottom: '5px',
                        width: message.isConfirmation ? '100%' : 'fit-content',
                      }}
                    >
                      {message.isConfirmation && message.confirmationData ? (
                        <InChatConfirmation
                          data={message.confirmationData}
                          onConfirm={() =>
                            onConfirmAction &&
                            onConfirmAction(message.confirmationData)
                          }
                          onReject={() => onRejectAction && onRejectAction()}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          style={{
                            whiteSpace: 'pre-wrap',
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            color: '#202020',
                          }}
                        >
                          {message.content}
                        </Typography>
                      )}
                    </Box>
                  ))}
              </Box>
            </Box>

            <form
              style={{
                width: '100%',
                marginTop: 'auto',
              }}
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  alignSelf: 'stretch',
                  width: '100%',
                  marginTop: '10px',
                  marginBottom: '10px',
                  gap: '12px',
                }}
              >
                <Box
                  sx={{
                    ...matterInformationInputWrapperStyles,
                    width: '100%',
                    '& > div > div > div > div > fieldset': {
                      border: '1px solid #acacac',
                      borderRadius: '8px',
                    },
                  }}
                >
                  <Box>
                    <LEIAAInput
                      labelText=""
                      controllerName="message"
                      control={control}
                      inputPlaceholder="Type your message..."
                      inputWidth="100%"
                      required={false}
                      disabled={isStreaming}
                      inputHeight="90px"
                      multiline
                      customClassName="scrollable-content"
                    />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    minWidth: '48px',
                    width: '48px',
                    height: '48px',
                    borderRadius: '8px',
                    padding: 0,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#0039b3',
                    },
                  }}
                  type="submit"
                  disabled={!watch('message') && !isStreaming}
                >
                  <span
                    style={{
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px',
                    }}
                    className="material-icons-round"
                  >
                    {isStreaming ? 'stop' : 'send'}
                  </span>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default ChatInterface;
