export const getSeverityBackgroundColor = (status: string) => {
  const colorPalette: { [key: string]: string } = {
    '5': '#E22727',
    FIVE: '#E22727',
    '4': '#EDB900',
    FOUR: '#EDB900',
    '3': '#f7658b',
    THREE: '#f7658b',
    '2': '#a183f7',
    TWO: '#a183f7',
    '1': '#5bb8f5',
    ONE: '#5bb8f5',
    '0': '#34d1bf',
    ZERO: '#34d1bf',
    TBD: '#CCCCCC',
  };

  return colorPalette[status] || '#a183f7';
};

export const getInterviewStatusBackgroundColor = (status: string) => {
  const colorPalette: { [key: string]: string } = {
    Scheduled: '#5bb8f5',
    Active: '#3375FF',
    Conducted: '#38B763',
    Archived: '#CBCFDD',
  };

  return colorPalette[status] || '#A962D5';
};

export const getTaskStatusBackgroundColor = (status: string) => {
  const colorPalette: { [key: string]: string } = {
    'To Do': '#5bb8f5',
    'In Progress': '#3375FF',
    'In Review': '#EDB900',
    Done: '#38B763',
    Blocked: '#E22727',
  };

  return colorPalette[status] || '#CCCCCC';
};

export const getNoteTagBackgroundColor = (status: string) => {
  const colorPalette: { [key: string]: string } = {
    investigation: '#5bb8f5',
    investigationdocument: '#34d1bf',
    investigationtask: '#ff8566',
    interview: '#a183f7',
    user: '#f7658b',
    stage: '#f7c84a',
  };

  return colorPalette[status] || '#CCCCCC';
};

export const getStageStatusBackgroundColor = (status: string) => {
  const colorPalette: { [key: string]: string } = {
    'Report Acknowledgement': '#5bb8f5',
    Scoping: '#34d1bf',
    'Data Collection': '#3ba9c7',
    Analysis: '#f7c84a',
    'Further Steps': '#ff8566',
    Interviews: '#a183f7',
    Report: '#4a90e2',
    Closed: '#f7658b',
  };

  return colorPalette[status] || '#CCCCCC';
};

export const getReportStatusBackgroundColor = (status: string) => {
  const colorPalette: { [key: string]: string } = {
    New: '#34d1bf',
    Open: '#3375FF',
    Delayed: '#EDB900',
    Blocked: '#E22727',
    Closed: '#a183f7',
  };

  return colorPalette[status] || '#CCCCCC';
};

export const getReportWhistleblowerStatusBackgroundColor = (status: string) => {
  const colorPalette: { [key: string]: string } = {
    Received: '#5bb8f5',
    'In Progress': '#3375FF',
    'Further Information Required': '#EDB900',
    Completed: '#38B763',
  };

  return colorPalette[status] || '#CCCCCC';
};
