import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { motion } from 'motion/react';
import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
} from '../../../../styles/textStyles';
import { TimelineEvent } from '../types';

interface MatterDetailTimelineCalendarProps {
  currentDate: Date;
  events: TimelineEvent[];
  handleDateClick: (date: Date) => void;
  eventThresholds?: { min: number; max: number; circles: number }[];
}

const MatterDetailTimelineCalendar = ({
  currentDate,
  events,
  handleDateClick,
  eventThresholds = [
    { min: 1, max: 5, circles: 1 },
    { min: 6, max: 10, circles: 2 },
    { min: 11, max: 15, circles: 3 },
    { min: 16, max: 20, circles: 4 },
    { min: 21, max: Infinity, circles: 5 },
  ],
}: MatterDetailTimelineCalendarProps) => {
  const countEventsForDate = (date: Date) =>
    events.filter(
      (event) => (event.date as any).toDateString() === date.toDateString()
    ).length;

  const getCircleCount = (eventCount: number) => {
    const threshold = eventThresholds.find(
      ({ min, max }) => eventCount >= min && eventCount <= max
    );
    return threshold ? threshold.circles : 0;
  };

  const generateMonthDates = (centerDate: Date) => {
    const monthStart = new Date(
      centerDate.getFullYear(),
      centerDate.getMonth(),
      1
    );
    const monthEnd = new Date(
      centerDate.getFullYear(),
      centerDate.getMonth() + 1,
      0
    );

    const startDayOfWeek = monthStart.getDay();
    const endDayOfWeek = monthEnd.getDay();
    const daysInMonth = monthEnd.getDate();

    const dates = [];

    if (startDayOfWeek > 0) {
      const prevMonthEnd = new Date(
        centerDate.getFullYear(),
        centerDate.getMonth(),
        0
      );
      const daysInPrevMonth = prevMonthEnd.getDate();

      for (let i = startDayOfWeek - 1; i >= 0; i -= 1) {
        const day = new Date(
          centerDate.getFullYear(),
          centerDate.getMonth() - 1,
          daysInPrevMonth - i
        );
        dates.push(day);
      }
    }

    for (let i = 1; i <= daysInMonth; i += 1) {
      const day = new Date(centerDate.getFullYear(), centerDate.getMonth(), i);
      dates.push(day);
    }

    const remainingDays = 6 - endDayOfWeek;
    for (let i = 1; i <= remainingDays; i += 1) {
      const day = new Date(
        centerDate.getFullYear(),
        centerDate.getMonth() + 1,
        i
      );
      dates.push(day);
    }

    return dates;
  };

  const monthDates = generateMonthDates(currentDate);

  const formatEntryDate = (date: Date) => {
    const dayOfWeek = date
      .toLocaleDateString('en-US', { weekday: 'short' })
      .toUpperCase();

    const day = date
      .toLocaleDateString('en-US', { day: '2-digit' })
      .toUpperCase();

    const month = date
      .toLocaleDateString('en-US', { month: 'long' })
      .toUpperCase();

    const year = date.toLocaleDateString('en-US', { year: 'numeric' });

    return { dayOfWeek, day, month, year };
  };

  const isDateInCurrentMonth = (date: Date) => {
    return (
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  };

  const getBackgroundColor = (isCurrentMonth: boolean, eventCount: number) => {
    if (!isCurrentMonth) {
      return '#f6f6f6';
    }
    return eventCount > 0 ? '#F5FAFD' : '#FFFFFF';
  };

  return (
    <Box sx={{ paddingTop: '16px' }}>
      {monthDates
        .reduce((rows: Date[][], date, index) => {
          if (index % 7 === 0) {
            rows.push([]);
          }
          rows[rows.length - 1].push(date);
          return rows;
        }, [])
        .map((row, rowIndex) => (
          <Box
            key={rowIndex}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              marginBottom: '10px',
            }}
          >
            {row.map((date, index) => {
              const { dayOfWeek, day, month, year } = formatEntryDate(date);
              const eventCount = countEventsForDate(date);
              const circleCount = getCircleCount(eventCount);

              const isCurrentMonth = isDateInCurrentMonth(date);

              return (
                <motion.div
                  key={index}
                  onClick={() => handleDateClick(date)}
                  style={{
                    borderRadius: '5px',
                    padding: '16px',
                    flex: 1,
                    textAlign: 'center',
                    backgroundColor: getBackgroundColor(
                      isCurrentMonth,
                      eventCount
                    ),
                    cursor: 'pointer',
                    opacity: isCurrentMonth ? 1 : 0.6,
                    border: '2px solid #F5FAFD',
                  }}
                  whileHover={
                    isCurrentMonth
                      ? {
                          scale: 1.05,
                          transition: { duration: 0.2 },
                          border: '2px solid #D8EAF3',
                        }
                      : {}
                  }
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          ...karlaProRegularFontStyles,
                          fontWeight: 200,
                          fontSize: '18px',
                          lineHeight: '21px',
                          letterSpacing: '0.2px',
                          textTransform: 'uppercase',
                          color: '#69707bd6',
                        }}
                      >
                        {dayOfWeek}
                      </Typography>

                      <Typography
                        sx={{
                          ...karlaProBoldFontStyles,
                          fontWeight: 500,
                          fontSize: '18px',
                          lineHeight: '21px',
                          letterSpacing: '2px',
                          textTransform: 'uppercase',
                          color: '#69707B',
                        }}
                      >
                        {day}
                      </Typography>
                    </Stack>
                    <Tooltip title={`${eventCount} Events`}>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {[...Array(circleCount)].map((_, i) => (
                            <Box
                              key={i}
                              sx={{
                                width: '6px',
                                height: '6px',
                                borderRadius: '50%',
                                backgroundColor: '#1E88E5',
                              }}
                            />
                          ))}
                        </Stack>
                      </Box>
                    </Tooltip>
                  </Stack>
                </motion.div>
              );
            })}
          </Box>
        ))}
    </Box>
  );
};

export default MatterDetailTimelineCalendar;
