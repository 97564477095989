import { Box, Typography } from '@mui/material';
import { mattersListStatusChipWrapperStyles } from './styles';
import { getTaskStatusBackgroundColor } from '../../../styles/sharedStyles';

interface StatusChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const StatusChipTasks = ({
  status,
  heightValue,
  wrapperHeight,
}: StatusChipProps) => {
  const backgroundColor = getTaskStatusBackgroundColor(status);
  const color = '#FFFFFF';

  return (
    <Box
      sx={mattersListStatusChipWrapperStyles(
        backgroundColor,
        color,
        heightValue,
        wrapperHeight
      )}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default StatusChipTasks;
