import { Box, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  batchDeleteMatterBundlesRequest,
  bundleActionsRequest,
  bundleDocsRequest,
} from '../../../api/matters';
import BundlesListTable from '../../shared/bundles-list-table/BundlesListTable';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import DocumentAssociateToInterviewDialog from '../../shared/docs-list-table/DocumentAssociateToInterviewDialog';
import DocumentDialog from '../../shared/docs-list-table/DocumentDialog';
import ListButtons from '../../shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import NotificationToast from '../../shared/toast/NotificationToast';

interface MatterDetailDocumentBundleTabProps {
  matterId: string;
  matterDetails: any;
  matterDetailBundles: any;
  handleGetMatterDetailBundles: any;
  documentPerms: any;
  loadingBundle: any;
  handleListSearchbarInputChange: any;
  inputRef: any;
}

const MatterDetailDocumentBundleTab = ({
  matterId,
  matterDetails,
  matterDetailBundles,
  handleGetMatterDetailBundles,
  documentPerms,
  loadingBundle,
  handleListSearchbarInputChange,
  inputRef,
}: MatterDetailDocumentBundleTabProps) => {
  const [selectedBundle, setSelectedBundle] = useState<any | null>(null);
  const [checkedBundles, setCheckedBundles] = useState([]);
  const [openRenameBundleDialog, setRenameBundleDialogOpen] = useState(false);
  const [inputRenameBundleValue, setInputRenameBundleValue] = useState('');
  const [openAssociateToInterviewDialog, setOpenAssociateToInterviewDialog] =
    useState(false);
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [showRenameNotification, setShowRenameNotification] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationInterviewName, setNotificationInterviewName] =
    useState('');

  const handleDetailBundle = async (bundle: any) => {
    try {
      bundleDocsRequest(bundle.id, '', 1).then((response: any) => {
        navigate(`/matters/detail/${matterId}/bundle/${bundle.id}`, {
          state: {
            response,
            matterDetails,
            selectedBundle: bundle,
            bundleName: bundle.name,
          },
        });
      });
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleBatchDeleteBundles = async () => {
    try {
      await batchDeleteMatterBundlesRequest(checkedBundles);
      await handleGetMatterDetailBundles();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const renameBundleHandler = async () => {
    try {
      await bundleActionsRequest(
        selectedBundle.id,
        undefined,
        undefined,
        inputRenameBundleValue,
        undefined,
        undefined
      );
      setRenameBundleDialogOpen(false);
      setInputRenameBundleValue('');
      await handleGetMatterDetailBundles();
      setShowRenameNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };
  return (
    <>
      {showNotification && (
        <Box
          sx={{
            opacity: !showNotification ? 0 : 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            padding: '0 32px',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              padding: '104px 32px 0px 32px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '64px',
            }}
          >
            <CreationNotification
              title={`Bundle added to ${notificationInterviewName} successfully!`}
              subTitle="You can access the interview(s) in the Interview List."
              showNotification={showNotification}
              handleCloseNotification={handleCloseNotification}
            />
          </Box>
        </Box>
      )}
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showRenameNotification && (
        <CreationNotification
          title="Resource renamed successfully!"
          subTitle=""
          showNotification={showRenameNotification}
          handleCloseNotification={() => setShowRenameNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Resource archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      <DocumentDialog
        open={openRenameBundleDialog}
        onClose={() => setRenameBundleDialogOpen(false)}
        title="Rename Bundle"
        label="Bundle name "
        primaryBtnText="OK"
        onPrimaryButtonClick={renameBundleHandler}
        setInputValue={setInputRenameBundleValue}
      />
      <DocumentAssociateToInterviewDialog
        open={openAssociateToInterviewDialog}
        onClose={() => setOpenAssociateToInterviewDialog(false)}
        setShowNotification={setShowNotification}
        setNotificationInterviewName={setNotificationInterviewName}
        selectedBundle={selectedBundle}
        refreshTable={handleGetMatterDetailBundles}
        matterId={matterId}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '40px',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            marginLeft: 'auto',
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search by bundle..."
            sx={{
              ...homePortalSearchInputStyles,
              marginLeft: 'auto',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& > span': { color: '#69707B' } }}
                >
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              onChange: handleListSearchbarInputChange,
              inputRef,
            }}
            variant="standard"
          />
          {checkedBundles.length > 0 && documentPerms.delete && (
            <ListButtons
              deleteHandler={handleBatchDeleteBundles}
              checkedResources={checkedBundles}
              resourceName={checkedBundles.length > 1 ? 'bundles' : 'bundle'}
              renameHandler={() => setRenameBundleDialogOpen(true)}
              associateInterviewHandler={() =>
                setOpenAssociateToInterviewDialog(true)
              }
            />
          )}
        </Box>
      </Box>

      <BundlesListTable
        bundlesListData={matterDetailBundles}
        listClass="bundles-list"
        loading={loadingBundle}
        setCheckedBundles={setCheckedBundles}
        setSelectedBundle={setSelectedBundle}
        handleDetailBundle={handleDetailBundle}
      />
    </>
  );
};

export default MatterDetailDocumentBundleTab;
