import { Box, Typography } from '@mui/material';
import { mattersListStatusChipWrapperStyles } from './styles';

interface StatusChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const StatusChip = ({
  status,
  heightValue,
  wrapperHeight,
}: StatusChipProps) => {
  let backgroundColor;
  let color = '#FFFFFF';

  switch (status) {
    case 'Active':
      backgroundColor = '#3375FF';
      break;
    case 'Archived':
      backgroundColor = '#CBCFDD';
      color = '#464B53';
      break;
    case 'Closed':
      backgroundColor = '#EDB900';
      color = '#202020';
      break;
    default:
      backgroundColor = '#3375FF';
      break;
  }

  return (
    <Box
      sx={mattersListStatusChipWrapperStyles(
        backgroundColor,
        color,
        heightValue,
        wrapperHeight
      )}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default StatusChip;
