import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface RelevanceProps {
  file: any;
  compact?: boolean;
}

const PredictedRelevance = ({ file, compact = false }: RelevanceProps) => {
  const relevance = file.relevance || 'no data';

  let relevanceColors = {
    color: '#CCCCCC',
    borderColor: '#CCCCCC',
    icon: 'help_outline',
  };

  switch (relevance) {
    case 'Normal':
      relevanceColors = {
        color: '#607D8B',
        borderColor: '#607D8B',
        icon: 'low_priority',
      };
      break;
    case 'High':
      relevanceColors = {
        color: '#FFC107',
        borderColor: '#FFC107',
        icon: 'warning',
      };
      break;
    case 'Very High':
      relevanceColors = {
        color: '#F44336',
        borderColor: '#F44336',
        icon: 'error',
      };
      break;
    default:
      relevanceColors = {
        color: '#9E9E9E',
        borderColor: '#9E9E9E',
        icon: 'help_outline',
      };
  }

  return (
    <Box>
      {relevance !== 'no data' && (
        <Typography
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            padding: compact
              ? '0px 4px'
              : {
                  xs: '2px 8px',
                  sm: '4px 12px',
                },
            borderRadius: compact ? '12px' : '16px',
            backgroundColor: 'white',
            border: `1px solid ${relevanceColors.borderColor}`,
            color: relevanceColors.color,
            ...karlaProRegularFontStyles,
            fontSize: compact
              ? '10px'
              : {
                  xs: '12px',
                  sm: '14px',
                },
            lineHeight: compact
              ? '16px'
              : {
                  xs: '18px',
                  sm: '22px',
                },
            gap: compact
              ? '2px'
              : {
                  xs: '4px',
                  sm: '6px',
                },
            whiteSpace: 'nowrap',
          }}
        >
          <span
            className="material-icons-round"
            style={{
              fontSize: compact ? '12px' : '16px',
            }}
          >
            {relevanceColors.icon}
          </span>
          {relevance}
        </Typography>
      )}
    </Box>
  );
};

export default PredictedRelevance;
