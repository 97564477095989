import { Skeleton, Box } from '@mui/material';

interface ListSkeletonLoaderProps {
  pageSize: number;
}

const ListSkeletonLoader = ({ pageSize }: ListSkeletonLoaderProps) => {
  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="36px"
        sx={{
          marginBottom: '10px',
          borderRadius: '5px',
          backgroundColor: '#F5FAFD',
        }}
      />
      {[...Array(pageSize)].map((_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginBottom: '5px',
            borderBottom: '1px solid rgb(245, 250, 253)',
            gap: 1,
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height="60px"
            sx={{
              borderRadius: '24px',
              backgroundColor: index % 2 === 0 ? '#F5FAFD' : 'white',
              border: index % 2 !== 0 ? '1px solid #F5FAFD' : 'none',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ListSkeletonLoader;
