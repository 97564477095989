import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import MatterCreationFooter from '../components/matters/matter-creation/MatterCreationFooter';
import MatterCreationConfirmation from '../components/matters/matter-creation/MatterCreationConfirmation';
import { createMatterStepRequest } from '../api/matters';
import { sanitizePayload, validateStepPayload } from '../utils/utils';
import { clearMatterUsers } from '../redux/features/matter-creation/matterUsersSlice';
import {
  MatterDocumentsPayload,
  MatterInformationPayload,
  MatterInterviewsPayload,
  MatterTasksPayload,
  MatterUsersPayload,
} from '../types/matters';
import MatterCreationContent from '../components/matters/matter-creation/MatterCreationContent';
import NotificationToast from '../components/shared/toast/NotificationToast';
import LoaderSpinner from '../components/shared/misc-components/LoaderSpinner';
import { useInterviewManagement } from '../hooks';

const steps = ['Matter', 'Users', 'Tasks', 'Documents', 'Interviews'];

const MatterCreation = () => {
  const { matterId } = useParams();
  const location = useLocation();
  const locationState = location.state; // keeping overall state for future usage
  const currentStep: number = location.state?.currentStep;
  const matterDetails: any = location.state?.matterDetails;
  const isEdit: boolean = location.state?.isEdit;
  const showHeader: boolean = location.state?.showHeader ?? true;
  const showSteps: boolean = location.state?.showSteps ?? true;
  const hideFooterNav: boolean = location.state?.hideFooterNav ?? true;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(currentStep || 0);
  const [previousStep, setPreviousStep] = useState<number | null>(null);

  const [matterCreationFinish, setMatterCreationFinish] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [visited, setVisited] = useState(new Array(steps.length).fill(false));
  // const [matterDetails, setMatterDetails] = useState<any>();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    unregister,
    formState: { touchedFields },
  } = useForm({
    defaultValues: matterDetails || {},
  });
  const dispatch = useDispatch();
  const fieldsData = watch();
  const [showNotification, setShowNotification] = useState(false);
  const [loading, Setloading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = async () => {
    if (isLastStep || isEdit) {
      setMatterCreationFinish(true);
    } else {
      setPreviousStep(activeStep);
      if (requestError !== null) {
        setRequestError(null);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setVisited((prevVisited) => {
        const newVisited = [...prevVisited];
        newVisited[activeStep] = true; // Mark the current step as visited
        return newVisited;
      });
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setPreviousStep(activeStep);
      if (requestError !== null) {
        setRequestError(null);
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setVisited((prevVisited) => {
        const newVisited = [...prevVisited];
        newVisited[activeStep] = false; // Mark the current step as visited
        return newVisited;
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validateMatterInformation = (
    payload: any
  ): MatterInformationPayload => {
    const allowedFields: (keyof MatterInformationPayload)[] = [
      'client',
      'name',
      'description',
      'questionnaire',
      'jurisdiction',
      'sub_division',
      'budget',
      'currency',
      'notes',
      'questions_to_delete',
      'template_id',
    ];

    return validateStepPayload<MatterInformationPayload>(
      payload,
      allowedFields
    ) as MatterInformationPayload;
  };

  // Step 2: Matter Users
  const validateMatterUsers = (payload: any): MatterUsersPayload => {
    const allowedFields: (keyof MatterUsersPayload)[] = [
      'users',
      'users_to_remove',
    ];

    return validateStepPayload<MatterUsersPayload>(
      payload,
      allowedFields
    ) as MatterUsersPayload;
  };

  // Step 3: Matter Tasks
  const validateMatterTasks = (payload: any): MatterTasksPayload => {
    const allowedFields: (keyof MatterTasksPayload)[] = [
      'tasks',
      'tasks_to_delete',
    ];

    return validateStepPayload<MatterTasksPayload>(
      payload,
      allowedFields
    ) as MatterTasksPayload;
  };

  // Step 4: Matter Docs
  const validateMatterDocuments = (payload: any): MatterDocumentsPayload => {
    const allowedFields: (keyof MatterDocumentsPayload)[] = [
      'files',
      // 'permissions',
      'files_to_delete',
    ];

    return validateStepPayload<MatterDocumentsPayload>(
      payload,
      allowedFields
    ) as MatterDocumentsPayload;
  };

  // Step 5: Matter Interviews
  const validateMatterInterviews = (payload: any): MatterInterviewsPayload => {
    const allowedFields: (keyof MatterInterviewsPayload)[] = [
      'interviews',
      'interviews_to_delete',
    ];

    return validateStepPayload<MatterInterviewsPayload>(
      payload,
      allowedFields
    ) as MatterInterviewsPayload;
  };

  const { handleAddMatterInterview } = useInterviewManagement(
    watch,
    setValue,
    setSelectedIndex
  );

  const onSubmit: SubmitHandler<any> = async (data: any, e: any) => {
    if (matterId) {
      // SANITIZE EMPTY DATA
      // const sanitizedData = sanitizePayload(data);
      const sanitizedData = data;
      let cleanedData;

      // Validate the payload based on the active step
      switch (activeStep) {
        case 0:
          cleanedData = validateMatterInformation(sanitizedData);
          break;
        case 1:
          cleanedData = validateMatterUsers(sanitizedData);
          break;
        case 2:
          cleanedData = validateMatterTasks(sanitizedData);
          break;
        case 3:
          cleanedData = validateMatterDocuments(sanitizedData);
          break;
        case 4:
          cleanedData = validateMatterInterviews(sanitizedData);
          break;
        default:
          cleanedData = sanitizedData;
          break;
      }

      try {
        Setloading(true);
        const response = await createMatterStepRequest(
          matterId,
          activeStep + 1,
          cleanedData
        );
        if (e.nativeEvent.submitter.name === 'addAnotherBtn') {
          if (response) {
            response.forEach(
              (successItem: {
                id: any;
                submission_id: any;
                interviewee_id: any;
              }) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { id, submission_id, interviewee_id } = successItem;
                const matterInterviews = watch('interviews');
                const matchingInterview = matterInterviews.find(
                  (interview: { id: any }) => interview.id === submission_id
                );

                if (matchingInterview) {
                  matchingInterview.id = id;
                  matchingInterview.interviewee_id = interviewee_id;
                }
              }
            );
          }
          handleAddMatterInterview(
            watch(`interviews[${selectedIndex}].interview_category`)
          );
        }
        Setloading(false);
        if (e.nativeEvent.submitter.name === 'finishButton') {
          handleNext();
        }
        if (e.nativeEvent.submitter.name === 'saveAndCloseButton') {
          setMatterCreationFinish(true);
        }
        setRequestError(null);
        setShowNotification(false);
      } catch (error: any) {
        Setloading(false);
        setRequestError(error.response.data.message);
        setShowNotification(true);
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearMatterUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        position: 'relative',
        '& > form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            padding: '48px 32px',
            flex: 1,
          },

          width: '100%',
        },
      }}
      id="creation-box"
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={handleCloseNotification}
        />
      )}
      {loading && activeStep + 1 === 4 && (
        <LoaderSpinner loading={loading} onClose={() => Setloading(false)} />
      )}
      {!matterCreationFinish ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <MatterCreationContent
            activeStep={activeStep}
            previousStep={previousStep}
            steps={steps}
            visited={visited}
            control={control}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
            currentStep={currentStep}
            touchedFields={touchedFields}
            handleAddMatterInterview={handleAddMatterInterview}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            locationState={locationState}
            showHeader={showHeader}
            showSteps={showSteps}
          />
          <MatterCreationFooter
            handleCancel={handleCancel}
            handlePrevious={handlePrevious}
            activeStep={activeStep}
            isLastStep={isEdit ? true : isLastStep}
            isEdit={isEdit}
            hideNavigationButtons={hideFooterNav}
          />
        </form>
      ) : (
        <MatterCreationConfirmation
          isEdition={currentStep !== undefined}
          matter={matterId}
        />
      )}
    </Box>
  );
};

export default MatterCreation;
