import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';

const IntegrationStatus = ({
  status,
  width,
  height,
  fontSize,
}: {
  status: string;
  width: string;
  height: string;
  fontSize: string;
}) => {
  let backgroundColor;
  let color = '#FFFFFF';

  switch (status) {
    case 'Configured':
      backgroundColor = '#EFF9EA';
      color = '#72A85A';
      break;
    case 'Configure':
      backgroundColor = '#F2F2F2';
      color = '#707070';
      break;
    case 'Error':
      backgroundColor = '#F9ECEA';
      color = '#A85A5A';
      break;
    default:
      backgroundColor = '#F2F2F2';
      color = '#707070';
      break;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        width,
        height,
        backgroundColor,
        borderRadius: '12px',
      }}
    >
      <Typography
        sx={{
          color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          ...karlaProRegularFontStyles,
          fontSize,
          lineHeight: '22px',
        }}
      >
        {status}
      </Typography>
    </Box>
  );
};
export default IntegrationStatus;
