import { useEffect, useState, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ChatInterface, { Message } from './ChatInterface';
import { LLMChat, LLMChatEvent } from './api';
import {
  LlmGetChatSessionsRequest,
  LlmGetChatSessionHistoryRequest,
  LlmCreateChatSessionsRequest,
} from '../../api/llm';

interface DocumentChatProps {
  document: any;
}

const DocumentChat = ({ document }: DocumentChatProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [chatSessions, setChatSessions] = useState<
    { id: string; created_date: string }[]
  >([]);
  const [activeChatSession, setActiveChatSession] = useState<string>(uuidv4());
  const activeChatSessionRef = useRef<string>(activeChatSession);

  const llmChatRef = useRef<LLMChat | null>(null);
  const initialized = useRef(false);

  // Handle chat events
  const handleChatEvent = useCallback(
    (event: LLMChatEvent) => {
      try {
        switch (event.type) {
          case 'message:added':
            // Map the LLMChat message to our Message format
            // eslint-disable-next-line no-case-declarations
            const newMessage: Message = {
              id: event.message.id,
              role: event.message.role,
              content: event.message.content,
              createdAt: new Date(event.message.timestamp),
            };

            try {
              if (newMessage.role !== 'assistant') {
                LlmCreateChatSessionsRequest(
                  document.id,
                  activeChatSessionRef.current,
                  newMessage.role,
                  newMessage.content
                );
              }
            } catch (e) {
              console.error('Error creating chat session:', e);
            }

            setMessages((prevMessages) => {
              // Check if this message already exists
              const exists = prevMessages.some(
                (msg) => msg.id === newMessage.id
              );
              if (exists) return prevMessages;

              return [...prevMessages, newMessage];
            });
            break;

          case 'message:updated':
            // Update an existing message (usually during streaming)
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.id === event.message.id
                  ? {
                      ...msg,
                      content: event.message.content,
                    }
                  : msg
              )
            );
            break;

          case 'stream:start':
            setIsStreaming(true);
            break;

          case 'stream:end':
            console.log(
              'Message about to be created from stream end',
              event.message
            );
            setIsStreaming(false);
            LlmCreateChatSessionsRequest(
              document.id,
              activeChatSessionRef.current,
              event.message.role,
              event.message.content
            );
            break;

          case 'error':
            console.error('LLMChat error:', event.error);
            setError(`Error: ${event.error.message}`);
            setIsStreaming(false);
            break;
          default:
            break;
        }
      } catch (err) {
        console.error('Error handling chat event:', err);
        setError(
          `Error handling chat event: ${
            err instanceof Error ? err.message : String(err)
          }`
        );
      }
    },
    [document.id]
  );

  // Initialize the LLMChat instance
  useEffect(() => {
    if (initialized.current) return;

    try {
      if (!process.env.REACT_APP_OPENAI_API_KEY) {
        console.error('No OpenAI API key provided');
        setError('Missing API key. Please provide an OpenAI API key.');
        return;
      }

      const SYSTEM_PROMPT = `
        You are Leiaa, an AI assistant specialized in helping legal professionals understand documents. Your purpose is to assist users in analyzing and extracting information from legal documents efficiently.
        CORE CAPABILITIES:
        - Document analysis: You excel at explaining what a document contains, its purpose, key points, and summarizing its content.
        - Clear communication: You provide concise, accurate responses focused on the document at hand.
        - Professional tone: Your responses are helpful, informative, and appropriate for legal professionals.
        RESPONSE GUIDELINES:
        1. Always focus your responses on the document the user has shared or is discussing.
        2. When asked about the document's content, purpose, or summary, provide thorough, professional analysis.
        3. Highlight important elements like dates, parties involved, obligations, or potential risks when relevant.
        4. If asked to relate the document to a specific investigation subject, analyze any connections and explain your reasoning.
        5. Remember that the document you are being asked about is evidence taken from people related to an alleged offence. They may be directly involved, indirectly involved, or not involved. It is also possible that the document has been intentionally written in a way to cover up wrongdoing, though this is not necessarily the case.
        COMPANY POLICY TAGS:
        - When a message contains the tag [RESTRICTIVE]: Recognize this indicates the user operates under restrictive company policies. Respond by acknowledging the policy constraints and politely decline to provide specific recommendations. Example: "Your company policy prevents me from suggesting particular outcomes. I'm sorry!"
        - When a message contains the tag [PROGRESSIVE]: Recognize this indicates the user operates under progressive company policies. Respond by acknowledging their decision-making autonomy while offering potential considerations based on the document's content. Example: "Remember, it is important that you make your own judgements. However, given that this document shows X, you might consider…"
        LIMITATIONS:
        - If asked about topics unrelated to the document (e.g., general knowledge questions), politely redirect the conversation back to the document.
        - If asked about cross-document analysis: Inform the user that this capability is coming soon and direct them to contact their account manager for early access.
        IDENTITY:
        - If asked who you work for: "I'm Leiaa and I am built by a team of legal experts at Augmetec."
        - If asked about your model: "I am a specialised language model built for purpose by Augmetec."
        Always maintain a helpful, professional demeanor while prioritizing accurate analysis of the document at hand.

        Document titles is: ${document.title || 'Untitled Document'}
        `;
      // Create a new LLMChat instance
      const chat = new LLMChat({
        id: `doc_chat_${document.id}`,
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        systemPrompt: SYSTEM_PROMPT,
        temperature: 0,
      });

      // Add the system message to our messages state
      const systemMessage: Message = {
        id: uuidv4(),
        role: 'system',
        content: `You are a helpful assistant answering questions about the document: ${
          document.title || 'Untitled Document'
        }`,
        createdAt: new Date(),
      };

      setMessages([systemMessage]);

      // Add context of the document content if available
      // Ask jonny about this - its fine if its only on initizalization ?
      if (document.llm_content) {
        chat.addContextFile(document.llm_content, document.name);
        console.log('Added document content as context');
      }

      // Subscribe to events from the chat
      const unsubscribe = chat.subscribe(handleChatEvent);

      llmChatRef.current = chat;
      initialized.current = true;

      // Cleanup function
      // eslint-disable-next-line consistent-return
      return () => {
        unsubscribe();
        llmChatRef.current = null;
        initialized.current = false;
      };
    } catch (err) {
      console.error('Error initializing LLMChat:', err);
      setError(
        `Failed to initialize chat: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  }, [
    document.id,
    document.title,
    document.name,
    document.llm_content,
    handleChatEvent,
  ]);

  const onSubmit = async (input: string) => {
    if (isStreaming) {
      // If currently streaming, stop the generation
      setIsStreaming(false);
      return;
    }

    try {
      setError(null);
      if (llmChatRef.current) {
        setIsStreaming(true);

        try {
          await llmChatRef.current.sendMessage(input);
        } catch (sendError) {
          console.error('Error sending message:', sendError);
          setIsStreaming(false);
          setError(
            `Failed to send message: ${
              sendError instanceof Error ? sendError.message : String(sendError)
            }`
          );
        }
      } else {
        console.error('LLMChat instance not available');
        setError('Chat system not initialized. Please reload the page.');
      }
    } catch (err) {
      console.error('Error in submit handler:', err);
      setError(
        `Error sending message: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
      setIsStreaming(false);
    }
  };

  const fetchChatSessionsHistory = useCallback(async () => {
    try {
      const response = await LlmGetChatSessionsRequest(document.id);
      setChatSessions(response);
    } catch (e) {
      console.error('Error fetching chat history:', e);
    }
  }, [document.id]);

  const handleSelectSession = async (sessionId: string) => {
    try {
      setIsStreaming(false);
      const response = await LlmGetChatSessionHistoryRequest(
        document.id,
        sessionId
      );

      // Convert backend messages to the frontend Message format - TODO: fix this
      const formattedMessages = response.history.map((msg: any) => ({
        id: uuidv4(), // Generate a new ID for each message
        role: msg.role,
        content: msg.content.text,
        createdAt: new Date(), // TODO: fix this
      }));

      // Get the existing system message from current messages - assuming its the same prompt
      const systemMessage = messages.find((msg) => msg.role === 'system');
      if (systemMessage) {
        formattedMessages.unshift(systemMessage);
      }

      setMessages(formattedMessages);
      setActiveChatSession(response.session);

      if (llmChatRef.current) {
        llmChatRef.current.setMessages(formattedMessages);
      }
    } catch (e) {
      console.error('Error fetching chat session history:', e);
      setError(
        `Failed to load chat session: ${
          e instanceof Error ? e.message : String(e)
        }`
      );
    }
  };

  const handleCreateNewSession = () => {
    if (llmChatRef.current) {
      llmChatRef.current.clearMessages();

      const systemMessages = messages.filter((msg) => msg.role === 'system');

      const welcomeMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content:
          "Hi I'm Leiaa, an AI assistant specialized in helping legal professionals understand documents. Feel free to ask me anything about this document.",
        createdAt: new Date(),
        isWelcomeMessage: true,
      };

      setMessages([...systemMessages, welcomeMessage]);

      const newSessionId = uuidv4();
      setActiveChatSession(newSessionId);
      activeChatSessionRef.current = newSessionId;

      setTimeout(() => {
        fetchChatSessionsHistory();
      }, 100);
    }
  };

  // Add the welcome message on initial load as well
  useEffect(() => {
    if (messages.length === 1 && messages[0].role === 'system') {
      const welcomeMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content:
          "Hi I'm Leiaa, an AI assistant specialized in helping legal professionals understand documents.\nFeel free to ask me anything about this document.",
        createdAt: new Date(),
        isWelcomeMessage: true,
      };

      setMessages([...messages, welcomeMessage]);
    }
  }, [messages]);

  useEffect(() => {
    activeChatSessionRef.current = activeChatSession;
    fetchChatSessionsHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChatSession]);

  useEffect(() => {
    fetchChatSessionsHistory();
  }, [fetchChatSessionsHistory]);

  return (
    <>
      {error && (
        <Box
          style={{
            color: 'red',
            margin: '10px 0',
            padding: '10px',
            background: '#ffeeee',
            borderRadius: '4px',
          }}
        >
          {error}
        </Box>
      )}
      <ChatInterface
        title="Chat with Document"
        messages={messages.map((msg) => {
          if (msg.isWelcomeMessage) {
            const { isWelcomeMessage, ...rest } = msg;
            return rest;
          }
          return msg;
        })}
        sessions={chatSessions}
        onSubmit={onSubmit}
        onLoadSession={handleSelectSession}
        onCreateNewSession={handleCreateNewSession}
        isStreaming={isStreaming}
        chatWidth="100%"
        chatHeight="75vh"
        allowMinimize={false}
        cardStyles={{
          border: '1px solid rgb(242 242 242)',
          borderRadius: '5px',
          boxShadow: 'unset',
        }}
      />
    </>
  );
};

export default DocumentChat;
