import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  batchDeleteDocumentRequest,
  documentsListRequest,
  editDocumentWikiRequest,
  wikiFolderDocsRequest,
} from '../../api/wiki';
import { DocumentsListResponse } from '../../types/wiki';
import DocumentDialog from '../shared/docs-list-table/DocumentDialog';
import RepoDocumentsListTable from '../shared/documents-list-table/RepoDocumentsListTable';
import ListButtons from '../shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../shared/resource-page-header/styles';
import NotificationToast from '../shared/toast/NotificationToast';
import { primaryButtonStyles, secondaryButtonStyles } from '../styles';
import {
  createFolderRequest,
  folderDocsRequest,
  getDocumentURLRequest,
  renameFolderRequest,
} from '../../api/matters';
import CreationNotification from '../shared/creation-notification/CreationNotification';
import DocumentAddToFolderDialog from '../shared/docs-list-table/DocumentAddToFolderDialog';
import { DocumentsListItem } from '../shared/documents-list-table/types';

const WikiRepository = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [documentsListData, setDocumentsListData] =
    useState<DocumentsListResponse | null>(null);
  const [checkedDocuments, setCheckedDocuments] = useState<any[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [inputRenameValue, setInputRenameValue] = useState('');
  const navigate = useNavigate();
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [showFolderCreationNotification, setShowFolderCreationNotification] =
    useState(false);
  const [openAddToFolderDialog, setOpenAddToFolderDialog] = useState(false);
  const [showAddedNotification, setShowAddedNotification] = useState(false);
  const [openRenameDialog, setRenameDialogOpen] = useState(false);
  const [showRenameNotification, setShowRenameNotification] = useState(false);

  const handleFetchDocumentsList = async (searchText = '') => {
    setLoading(true); // Start loading

    try {
      const response = await documentsListRequest(false, searchText);
      setDocumentsListData(response as DocumentsListResponse);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchDocumentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBatchDeleteDocument = async () => {
    try {
      await batchDeleteDocumentRequest(checkedDocuments);
      await handleFetchDocumentsList();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchDocumentsList(searchText);
  };

  const handleNewFolder = async () => {
    try {
      await createFolderRequest(inputRenameValue);
      setOpenCreateFolderDialog(false);
      setInputRenameValue('');
      await handleFetchDocumentsList();
      setShowFolderCreationNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleAddNewFolder = () => {
    navigate('/wiki/repository/new/document');
  };

  const handleDownloadDoc = async (doc: any) => {
    try {
      if (doc.folder) {
        wikiFolderDocsRequest(doc.id, '').then((response: any) => {
          navigate(`/wiki/folder/${doc.id.toString()}`, {
            state: {
              response,
              folderName: doc.name,
            },
          });
        });
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
      console.error(error);
    }
  };

  const handleDownloadFile = async () => {
    if (!selectedDocument) return;
    try {
      const link = document.createElement('a');
      link.href = selectedDocument.url;
      link.download = selectedDocument.name;
      link.click();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const addToFolderHandler = async () => {
    setOpenAddToFolderDialog(true);
  };

  const renameHandler = async () => {
    try {
      if (selectedDocument.folder) {
        await renameFolderRequest(selectedDocument.id, inputRenameValue);
      } else {
        await editDocumentWikiRequest(
          selectedDocument.id,
          inputRenameValue,
          undefined,
          undefined
        );
      }

      setRenameDialogOpen(false);
      setInputRenameValue('');
      await handleFetchDocumentsList();
      setShowRenameNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  return (
    <>
      <DocumentDialog
        open={openCreateFolderDialog}
        onClose={() => setOpenCreateFolderDialog(false)}
        title="New Folder"
        label="Folder name"
        primaryBtnText="Create"
        onPrimaryButtonClick={handleNewFolder}
        setInputValue={setInputRenameValue}
      />
      <DocumentAddToFolderDialog
        open={openAddToFolderDialog}
        onClose={() => setOpenAddToFolderDialog(false)}
        refreshTable={handleFetchDocumentsList}
        setShowAddedNotification={setShowAddedNotification}
        selectedDocs={
          documentsListData?.documentsList
            ? documentsListData.documentsList.filter((doc: { id: any }) =>
                checkedDocuments.includes(doc.id)
              )
            : []
        }
      />
      <DocumentDialog
        open={openRenameDialog}
        onClose={() => setRenameDialogOpen(false)}
        title="Rename Document"
        label="Document name"
        primaryBtnText="OK"
        onPrimaryButtonClick={renameHandler}
        setInputValue={setInputRenameValue}
      />
      {showFolderCreationNotification && (
        <CreationNotification
          title="Created folder successfully!"
          subTitle=""
          showNotification={showFolderCreationNotification}
          handleCloseNotification={() =>
            setShowFolderCreationNotification(false)
          }
        />
      )}
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Wiki Document archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {showAddedNotification && (
        <CreationNotification
          title="Added to folder successfully!"
          subTitle=""
          showNotification={showAddedNotification}
          handleCloseNotification={() => setShowAddedNotification(false)}
        />
      )}
      {showRenameNotification && (
        <CreationNotification
          title="Resource renamed successfully!"
          subTitle=""
          showNotification={showRenameNotification}
          handleCloseNotification={() => setShowRenameNotification(false)}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
          }}
        >
          <Button
            variant="contained"
            onClick={handleAddNewFolder}
            sx={{ ...primaryButtonStyles(), height: '40px' }}
          >
            <span className="material-icons-round">add</span>
            <Typography>Add documents</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenCreateFolderDialog(true)}
            sx={{ ...secondaryButtonStyles(), height: '40px' }}
          >
            <span className="material-icons-round">add</span>
            <Typography>Add Folder</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            float: 'left',
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search by documents..."
            sx={{
              ...homePortalSearchInputStyles,
              marginRight: '20px',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& > span': { color: '#69707B' } }}
                >
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              onChange: handleListSearchbarInputChange,
              inputRef,
            }}
            variant="standard"
          />
          <ListButtons
            deleteHandler={handleBatchDeleteDocument}
            checkedResources={checkedDocuments}
            resourceName="document"
            renameHandler={() => setRenameDialogOpen(true)}
            handleDownloadFile={handleDownloadFile}
          />
        </Box>
      </Box>

      <Grid container spacing={2}>
        <ListButtons
          checkedResources={checkedDocuments}
          resourceName="document"
          addToFolderHandler={addToFolderHandler}
        />
        <Grid item xs={12} md={12}>
          <RepoDocumentsListTable
            documentsListData={documentsListData}
            loading={loading}
            listClass="documents-list"
            setCheckedDocuments={setCheckedDocuments}
            setSelectedDocument={setSelectedDocument}
            handleDetailDoc={handleDownloadDoc}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiRepository;
