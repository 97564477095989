import { v4 as uuidv4 } from 'uuid';
import api from './index';

async function batchDeleteInterviewRequest(interviewIds: number[]) {
  const filters: { ids_to_delete?: number[] } = {
    ids_to_delete: [...interviewIds],
  };

  await api.put('/interviews', filters);
}

async function deleteInterviewRequest(interviewId: string) {
  await api.delete(`/interviews/${interviewId}`);
}

async function interviewTranscriptRequest(interviewRecordingId: number) {
  const response = await api.get(`interview-recording/${interviewRecordingId}`);
  return response.data;
}

async function interviewDetailsRequest(interviewId: string): Promise<any> {
  const response = await api.get(`interviews/${interviewId}`);
  return response.data;
}

async function interviewRoomDetailsRequest(roomId: string): Promise<any> {
  const response = await api.get(`interview-room/${roomId}`);
  return response.data;
}

async function interviewAutomatedQuestionnaireRequest(
  room_name: string,
  automated_interview_track_type: string,
  question_index?: number
): Promise<any> {
  const response = await api.post('question-dispatch', {
    room_name,
    question_index,
    automated_interview_track_type,
  });
  return response.data;
}
async function getInterviewBundlesListRequest(
  interviewId: string
): Promise<any> {
  const response = await api.get(`interview-bundle/${interviewId}`);
  return response.data;
}

async function updateInterviewNoteRequest(interviewId: any, newNote: any) {
  await api.put(`/interview-notes/${interviewId}`, { note: newNote });
}

async function saveInterviewChatMessageRequest(
  interviewId: any,
  userId: any,
  messageString: any
) {
  await api.post(`/interviews-chat-message`, {
    user_id: userId,
    interview_id: interviewId,
    message: messageString,
  });
}

async function uploadIntervieweeFilesOnInterviewRequest(
  data: any,
  interviewId: string | number
) {
  const formData = new FormData();

  const payload = {
    ...data,
    interview: interviewId,
  };

  formData.append('json', JSON.stringify(payload));

  if (data.files) {
    data.files.forEach((file: any) => {
      formData.append('sent_files', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await api.post(
    '/interview-documents-action',
    formData,
    config
  );

  return response.data;
}

async function getInterviewTranscriptionEnabledRequest(
  interviewId: string
): Promise<any> {
  const response = await api.get(`transcripts/${interviewId}`);
  return response.data.status;
}

async function changeInterviewTranscriptionEnabledRequest(
  interviewId: string,
  enable: boolean
): Promise<any> {
  const response = await api.put(`transcripts/${interviewId}`, enable);
  return response.data;
}

async function processInterviewRequest(
  matterId: string,
  data: any
): Promise<any> {
  const interviewData = {
    id: uuidv4(),
    interview_type: data.type,
    interview_category: data.category,
    description: data.purpose,
    instructions: data.instructions,

    interviewee_first_name: data.firstName,
    interviewee_last_name: data.lastName,
    interviewee_job_title: data.jobTitle,
    interviewee_organisation: data.organisation,
    interviewee_subsidiary: data.subdivision,
    interviewee_employee_id: data.employeeId,
    interviewee_email: data.email,

    date: data.interviewDate, // ex. 18/12/2024
    start_hour: data.interviewStartTime, // ex. 14:44
    end_hour: data.interviewEndTime, // ex. 15:44
    timezone: 'GMT, (GMT+0:00) UTC; Etc/GMT', // TODO: implement

    interviewer_names: data.interviewers,
    whistleblower: false, // TODO: implement
    transcription_enabled: true, // TODO: implement

    questionnaire: data.questions
      ? data.questions.map((q: string) => ({
          question: q,
          comment: '',
          type: 'manual',
          id: uuidv4(),
        }))
      : [],

    llm_chat_request: true,
  };

  console.log('sending data:', interviewData);

  const response = await api.patch(`/matter-creation-step5/${matterId}`, {
    interviews: [interviewData],
  });
  return response.data;
}

export {
  getInterviewTranscriptionEnabledRequest,
  changeInterviewTranscriptionEnabledRequest,
  batchDeleteInterviewRequest,
  deleteInterviewRequest,
  interviewTranscriptRequest,
  interviewDetailsRequest,
  getInterviewBundlesListRequest,
  interviewRoomDetailsRequest,
  interviewAutomatedQuestionnaireRequest,
  updateInterviewNoteRequest,
  saveInterviewChatMessageRequest,
  uploadIntervieweeFilesOnInterviewRequest,
  processInterviewRequest,
};
