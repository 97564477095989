import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface StageChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const StageChip = ({ status, heightValue, wrapperHeight }: StageChipProps) => {
  let backgroundColor;
  const color = '#FFFFFF';

  switch (status) {
    case 'Report Acknowledgement':
      backgroundColor = '#5bb8f5';
      break;
    case 'Scoping':
      backgroundColor = '#34d1bf';
      break;
    case 'Data Collection':
      backgroundColor = '#3ba9c7';
      break;
    case 'Analysis':
      backgroundColor = '#f7c84a';
      break;
    case 'Further Steps':
      backgroundColor = '#ff8566';
      break;
    case 'Interviews':
      backgroundColor = '#a183f7';
      break;
    case 'Report':
      backgroundColor = '#8697a8';
      break;
    case 'Closed':
      backgroundColor = '#f7658b';
      break;
    default:
      backgroundColor = '#CCCCCC';
      break;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        height: wrapperHeight,
        alignSelf: 'flex-start',
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 10px',
          gap: '4px',
          heightValue,
          backgroundColor,
          borderRadius: '10px',
          '& > p': {
            ...karlaProRegularFontStyles,
            textTransform: 'capitalize',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.3px',
            color,
          },
        },
      }}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default StageChip;
