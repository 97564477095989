import React, { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import {
  Box,
  Skeleton,
  Typography,
  TextField,
  IconButton,
  Drawer,
  Paper,
  Tooltip,
} from '@mui/material';
import {
  ZoomIn,
  ZoomOut,
  Preview,
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { documentDataStreamRequest } from '../../../api/documents';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PDFNavigatorProps {
  file: { id: string };
}
interface PageDimensions {
  width: number;
  height: number;
}

const DEFAULT_DIMENSIONS: PageDimensions = {
  width: 595,
  height: 842,
};

const LoadingIndicator = () => (
  <Box
    sx={{
      width: DEFAULT_DIMENSIONS.width,
      height: DEFAULT_DIMENSIONS.height,
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Skeleton
      variant="rectangular"
      width="100%"
      height="100%"
      sx={{ bgcolor: '#f5f5f5' }}
    />
  </Box>
);

const PDFNavigator = ({ file }: PDFNavigatorProps) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [byteStream, setByteStream] = useState<Uint8Array | null>(null);
  const [scale, setScale] = useState(1);
  const [showThumbnails, setShowThumbnails] = useState(false);
  const [pageInputValue, setPageInputValue] = useState('1');

  useEffect(() => {
    const getDocumentStream = async () => {
      try {
        const documentData = await documentDataStreamRequest(file.id);
        const byteCharacters = window.atob(documentData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        setByteStream(new Uint8Array(byteNumbers));
      } catch (error) {
        console.error('Error fetching document stream:', error);
      }
    };

    getDocumentStream();
  }, [file.id]);

  const onDocumentLoadSuccess = ({ numPages: pages }: { numPages: number }) => {
    setNumPages(pages);
  };

  const handlePageChange = (newPage: number) => {
    const page = Math.max(1, Math.min(newPage, numPages));
    setCurrentPage(page);
    setPageInputValue(page.toString());
  };

  const handlePageInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setPageInputValue(value);

    const pageNum = parseInt(value, 10);
    if (!Number.isNaN(pageNum)) {
      handlePageChange(pageNum);
    }
  };

  const handleZoom = (delta: number) => {
    setScale((prevScale) => {
      const newScale = prevScale + delta;
      return Math.max(0.5, Math.min(2, newScale));
    });
  };

  const pdfUrl = byteStream
    ? URL.createObjectURL(new Blob([byteStream], { type: 'application/pdf' }))
    : '';

  return (
    <Box
      sx={{
        borderRadius: '16px',
        backgroundColor: 'white',
        overflowY: 'scroll',
        alignSelf: 'center',
        textAlign: 'center',
        p: 2,
        position: 'relative',
      }}
      className="scrollable-content"
    >
      {byteStream ? (
        <>
          <Paper
            elevation={4}
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              p: 2,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              backgroundColor: '#fff',
              borderRadius: 2,
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease',
              '&:hover': {
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            <Tooltip title="First Page" arrow>
              <IconButton
                onClick={() => handlePageChange(1)}
                disabled={currentPage <= 1}
                sx={{
                  color:
                    currentPage <= 1 ? 'rgba(0, 0, 0, 0.38)' : 'primary.main',
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
              >
                <FirstPage />
              </IconButton>
            </Tooltip>

            <Tooltip title="Previous Page" arrow>
              <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
                sx={{
                  color:
                    currentPage <= 1 ? 'rgba(0, 0, 0, 0.38)' : 'primary.main',
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
              >
                <KeyboardArrowLeft />
              </IconButton>
            </Tooltip>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                size="small"
                value={pageInputValue}
                onChange={handlePageInputChange}
                sx={{
                  width: 60,
                  input: {
                    textAlign: 'center',
                    fontWeight: 500,
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1,
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.12)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
                inputProps={{ type: 'number', min: 1, max: numPages }}
              />
              <Typography variant="body2" color="text.secondary">
                of {numPages}
              </Typography>
            </Box>

            <Tooltip title="Next Page" arrow>
              <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= numPages}
                sx={{
                  color:
                    currentPage >= numPages
                      ? 'rgba(0, 0, 0, 0.38)'
                      : 'primary.main',
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Tooltip>

            <Tooltip title="Last Page" arrow>
              <IconButton
                onClick={() => handlePageChange(numPages)}
                disabled={currentPage >= numPages}
                sx={{
                  color:
                    currentPage >= numPages
                      ? 'rgba(0, 0, 0, 0.38)'
                      : 'primary.main',
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
              >
                <LastPage />
              </IconButton>
            </Tooltip>

            <Box
              sx={{
                borderLeft: 1,
                pl: 3,
                ml: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Tooltip title="Zoom Out" arrow>
                <IconButton
                  onClick={() => handleZoom(-0.1)}
                  disabled={scale <= 0.5}
                  sx={{
                    color:
                      scale <= 0.5 ? 'rgba(0, 0, 0, 0.38)' : 'primary.main',
                    '&:hover': {
                      color: 'primary.dark',
                    },
                  }}
                >
                  <ZoomOut />
                </IconButton>
              </Tooltip>

              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {Math.round(scale * 100)}%
              </Typography>

              <Tooltip title="Zoom In" arrow>
                <IconButton
                  onClick={() => handleZoom(0.1)}
                  disabled={scale >= 2}
                  sx={{
                    color: scale >= 2 ? 'rgba(0, 0, 0, 0.38)' : 'primary.main',
                    '&:hover': {
                      color: 'primary.dark',
                    },
                  }}
                >
                  <ZoomIn />
                </IconButton>
              </Tooltip>
            </Box>

            {/*
            Disabled for now
            <Tooltip title="Toggle Thumbnails" arrow>
              <IconButton
                onClick={() => setShowThumbnails(!showThumbnails)}
                sx={{
                  color: 'primary.main',
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
              >
                <Preview />
              </IconButton>
            </Tooltip> */}
          </Paper>

          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<LoadingIndicator />}
            error={<LoadingIndicator />}
          >
            <Box sx={{ display: 'flex' }}>
              {showThumbnails && (
                <Drawer
                  variant="persistent"
                  anchor="left"
                  open={showThumbnails}
                  sx={{
                    '& .MuiDrawer-paper': {
                      position: 'relative',
                      width: 200,
                      mr: 2,
                    },
                  }}
                >
                  <Box sx={{ overflow: 'auto', p: 1 }}>
                    {Array.from(new Array(numPages), (_, index) => (
                      <Box
                        key={index + 1}
                        sx={{
                          cursor: 'pointer',
                          mb: 1,
                          border: currentPage === index + 1 ? 2 : 1,
                          borderColor:
                            currentPage === index + 1
                              ? 'primary.main'
                              : 'grey.300',
                          borderRadius: 1,
                        }}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        <Page
                          pageNumber={index + 1}
                          scale={0.2}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                        />
                      </Box>
                    ))}
                  </Box>
                </Drawer>
              )}

              <Box sx={{ flex: 1 }}>
                <Page
                  pageNumber={currentPage}
                  scale={scale}
                  renderTextLayer
                  renderAnnotationLayer
                />
              </Box>
            </Box>
          </Document>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};

export default PDFNavigator;
