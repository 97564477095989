import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

import NotificationToast from '../../shared/toast/NotificationToast';
import MatterDetailTimeline from './matter-detail-timeline/MatterDetailTimeline';
import MatterDetailNotes from './MatterDetailNotes';

interface MatterDetailHistoryProps {
  matterId: any;
  matterDetails: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ display: value !== index ? 'none' : 'block' }}
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const MatterDetailHistory = ({
  matterId,
  matterDetails,
}: MatterDetailHistoryProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            borderBottom: '1px solid #949CA9',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& > div': {
                '& > div': {
                  '& > button': {
                    display: 'flex',
                    width: '224px',
                    padding: '8px 24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '24px 24px 0px 0px',
                    '&.Mui-selected': {
                      backgroundColor: '#F5FAFD',
                      color: '#0053FF',
                    },

                    color: '#69707B',
                    textTransform: 'none',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                '& > span.MuiTabs-indicator': {
                  backgroundColor: '#0053FF',
                },
              },
            }}
          >
            <Tab label="Timeline" {...a11yProps(0)} />
            <Tab label="Notes" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>

      <Box sx={{ width: '100%' }}>
        <CustomTabPanel value={tabValue} index={0}>
          <MatterDetailTimeline
            matterId={matterId}
            matterDetails={matterDetails}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <MatterDetailNotes
            matterId={matterId}
            matterDetails={matterDetails}
            resourceName="Investigation"
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default MatterDetailHistory;
