import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  matterDetailGeneralRequest,
  matterDetailGeneralPOSTRequest,
  closeMatterRequest,
} from '../../../api/matters';
import LEIAAContext from '../../../context/context';
import AllegationSubjects from '../../../pages/whistleblowing/leiaa/AllegationSubjects';
import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';
import NotificationToast from '../../shared/toast/NotificationToast';
import { tertiaryButtonStyles } from '../../styles';
import { matterCreationSidepanelFieldStyles } from '../matter-creation/styles';
import { matterDetailGeneralInsightsWrapperStyles } from './styles';
import {
  redHomePortalButtonsStyles,
  homePortalButtonsStyles,
} from '../../homeportal/styles';
import { BaseButtonStyles } from '../../base/styles';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import { LEIAAStatusSelect } from '../../inputs';
import {
  reportStatusSelectStyles,
  severitySelectStyles,
  stageStatusSelectStyles,
} from '../../inputs/styles';
import { a11yProps, CustomTabPanel } from './MatterDetailDocuments';
import MatterDetailTimelineSettings from './MatterDetailTimelineSettings';
import MatterDetailRelativity from './MatterDetailRelativity';
import MatterDetailIManage from './MatterDetailIManage';
import MatterDetailUsers from './MatterDetailUsers';

// Refactor/split this component when we have time
interface MatterDetailGeneralProps {
  matterId: string | undefined;
}

interface MatterDetailGeneralData {
  active_users: number;
  budget: number;
  client_name: string;
  date_opened: string;
  days_ago: number;
  jurisdiction: string;
  matter_description: string;
  matter_lead: string[];
  template: string;
  report: boolean;
  report_data?: any;
  closed: boolean;
  severity: string;
  admin_status: string;
  stage: string;
  deadline: string;
  completion_deadline: string;
}

const MatterDetailGeneral = ({ matterId }: MatterDetailGeneralProps) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(true);
  const [matterDetailGeneral, setMatterDetailGeneral] =
    useState<MatterDetailGeneralData>();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(LEIAAContext);
  const [showNotification, setShowNotification] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const matterInfoFields = [
    {
      label: process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
        ? 'Organisation Name'
        : 'Client Name',
      value: matterDetailGeneral?.client_name,
    },
    {
      label: 'Matter Description',
      value: matterDetailGeneral?.matter_description,
    },
    { label: 'Matter Lead', value: matterDetailGeneral?.matter_lead },
    { label: 'Jurisdiction', value: matterDetailGeneral?.jurisdiction },
    { label: 'Template', value: matterDetailGeneral?.template },
  ];

  const reportInfoFields = matterDetailGeneral?.report
    ? [
        {
          label: 'Matter Lead',
          value: matterDetailGeneral?.report_data.assignee,
        },
        { label: 'Topic', value: matterDetailGeneral?.report_data.topic },
        { label: 'Subject', value: matterDetailGeneral?.report_data.subject },
        { label: 'Severity', value: matterDetailGeneral?.report_data.severity },
        {
          label: 'Internal Status',
          value: matterDetailGeneral?.report_data.adminstatus,
        },
        { label: 'Stage', value: matterDetailGeneral?.report_data.stage },
        {
          label: 'Stage deadline',
          value: matterDetailGeneral?.report_data.deadline,
        },
        {
          label: 'Final Deadline',
          value: matterDetailGeneral?.report_data.finaldeadline,
        },
      ]
    : [];

  const handleGetDashboardDetails = async () => {
    setLoading(true); // Start loading
    try {
      if (matterId) {
        const response = await matterDetailGeneralRequest(matterId);
        setValue('adminstatus', response.admin_status);
        setValue('severity', response.severity);
        setValue('stage', response.stage);
        setMatterDetailGeneral(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeMatterHandler = async () => {
    try {
      if (matterId) {
        await closeMatterRequest(matterId);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setShowNotification(true);
      handleGetDashboardDetails();
    }
  };

  const handleReportNavigation = (tab: any) => {
    navigate(`/report/${matterDetailGeneral?.report_data.key}`, {
      state: { defaultTab: tab, previous: 'matter-detail-detail' },
    });
  };

  useEffect(() => {
    handleGetDashboardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      if (matterId) await matterDetailGeneralPOSTRequest(matterId, data);
      handleGetDashboardDetails();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      matterDetailGeneral &&
      matterDetailGeneral.severity === watch('severity') &&
      matterDetailGeneral.stage === watch('stage') &&
      matterDetailGeneral.admin_status === watch('adminstatus')
    )
      return;
    if (matterDetailGeneral) handleSubmit(onSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('severity'), watch('stage'), watch('adminstatus')]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    // handleFetchListData('', newValue === 0, pastFilter);
  };

  return (
    <>
      {showNotification && (
        <CreationNotification
          title={`Matter ${
            !matterDetailGeneral?.closed ? 'reopened' : 'closed'
          } successfully!`}
          subTitle=""
          showNotification={showNotification}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {!loading ? (
        <>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                gap: '32px',
                '& > div': {
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '24px',
                  gap: '16px',
                  width: '100%',
                  height: 'auto',
                  backgroundColor: '#F2FAFE',
                  border: '1px solid #F0F8FD',
                  borderRadius: '24px',
                  '& > p': {
                    ...sofiaProRegularFontStyles,
                    fontWeight: 300,
                    fontSize: '16px',
                    lineHeight: '18px',
                    letterSpacing: '0.3px',
                    color: '#202020',
                  },
                  '& > h1': {
                    ...sofiaProRegularFontStyles,
                    fontWeight: 700,
                    fontSize: '32px',
                    lineHeight: '37px',
                    letterSpacing: '4px',
                    color: '#0053FF',
                  },
                },
              }}
            >
              <Box>
                <Typography>Date opened</Typography>
                <Typography variant="h1">
                  {matterDetailGeneral?.date_opened}
                </Typography>
                <Typography>{`${matterDetailGeneral?.days_ago} days ago`}</Typography>
              </Box>
              <Box>
                <Typography>Matter Budget</Typography>

                <Typography variant="h1">
                  {matterDetailGeneral?.budget !== null
                    ? `${matterDetailGeneral?.budget.toLocaleString()}`
                    : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography>Active User</Typography>
                <Typography variant="h1">
                  {matterDetailGeneral?.active_users}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              width: '100%',
              borderBottom: '1px solid #949CA9',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                '& > div': {
                  '& > div': {
                    '& > button': {
                      display: 'flex',
                      width: '250px',
                      padding: '8px 24px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '8px',
                      borderRadius: '24px 24px 0px 0px',
                      '&.Mui-selected': {
                        backgroundColor: '#F5FAFD',
                        color: '#0053FF',
                      },

                      color: '#69707B',
                      textTransform: 'none',
                      ...karlaProRegularFontStyles,
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '22px',
                    },
                  },
                  '& > span.MuiTabs-indicator': {
                    backgroundColor: '#0053FF',
                  },
                },
              }}
            >
              <Tab label="Matter Detail" {...a11yProps(0)} />
              {matterDetailGeneral?.report_data && (
                <Tab label="Allegation Subjects" {...a11yProps(1)} />
              )}
              <Tab
                label="Users"
                {...a11yProps(matterDetailGeneral?.report_data ? 2 : 1)}
              />
              <Tab
                label="Timeline"
                {...a11yProps(matterDetailGeneral?.report_data ? 3 : 2)}
              />
              {matterDetailGeneral?.report && (
                <Tab label="Report Information" {...a11yProps(4)} />
              )}
              {matterDetailGeneral &&
                ['Admin', 'Superuser', 'Leader'].includes(user?.role) && (
                  <Tab
                    label="Integrations"
                    {...a11yProps(matterDetailGeneral?.report ? 5 : 3)}
                  />
                )}
            </Tabs>
          </Box>
          <Box sx={{ width: '100%' }}>
            <CustomTabPanel value={tabValue} index={0}>
              <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '32px',
                    alignSelf: 'stretch',
                    height: '100%',
                    '& > div:nth-of-type(2)': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '40px',
                      flex: '1 0 0',
                      '& > div': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '32px',
                        alignSelf: 'stretch',
                      },
                    },
                  }}
                >
                  {showErrorNotification && (
                    <NotificationToast
                      showNotification={showErrorNotification}
                      requestError={requestError}
                      handleCloseNotification={() =>
                        setShowErrorNotification(false)
                      }
                    />
                  )}

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      padding: '0px !important',
                      margin: '0px !important',
                    }}
                  >
                    <Grid
                      item
                      xs={7}
                      md={7}
                      sx={{
                        display: 'flex',
                        padding: '24px 16px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        borderRadius: '24px',
                        backgroundColor: '#F5FAFD',
                      }}
                    >
                      {matterInfoFields.map((mi) => {
                        const isArrayWithValue =
                          Array.isArray(mi.value) && mi.value.length > 0;

                        if (
                          (typeof mi.value === 'string' && mi.value !== '') ||
                          isArrayWithValue
                        ) {
                          return (
                            <Box
                              key={mi.label}
                              sx={matterCreationSidepanelFieldStyles}
                            >
                              <Box>
                                <Box>
                                  <Grid container>
                                    <Grid item xs={4}>
                                      <Typography>{mi.label}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Typography>
                                        {isArrayWithValue
                                          ? (mi.value as string[]).join(', ')
                                          : mi.value}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Box>
                          );
                        }
                        return null;
                      })}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      md={5}
                      sx={matterDetailGeneralInsightsWrapperStyles}
                    >
                      <Box
                        sx={{
                          background: 'white !important',
                          border: '0 !important',
                          padding: '0 !important',
                        }}
                      >
                        <Box
                          sx={
                            matterDetailGeneral?.closed
                              ? homePortalButtonsStyles
                              : redHomePortalButtonsStyles
                          }
                        >
                          <Button sx={{ display: 'none !important' }}>
                            <span className="material-icons-round">
                              filter_alt
                            </span>
                            Filter
                          </Button>
                          <Button
                            sx={{
                              ...BaseButtonStyles(),
                              backgroundColor: ![
                                'Admin',
                                'Superuser',
                                'Leader',
                              ].includes(user?.role || '')
                                ? 'grey !important'
                                : 'unset',
                              border: ![
                                'Admin',
                                'Superuser',
                                'Leader',
                              ].includes(user?.role || '')
                                ? '1px solid grey !important'
                                : 'unset',
                            }}
                            onClick={closeMatterHandler}
                            disabled={
                              !['Admin', 'Superuser', 'Leader'].includes(
                                user?.role || ''
                              )
                            }
                          >
                            <span className="material-icons-round">
                              {matterDetailGeneral?.closed
                                ? 'restart_alt'
                                : 'done_all'}
                            </span>
                            {matterDetailGeneral?.closed
                              ? 'Reopen Matter'
                              : 'Mark as closed'}
                          </Button>
                        </Box>
                      </Box>

                      {['Admin', 'Superuser', 'Leader'].includes(user.role) && (
                        <>
                          <Box>
                            <Box>
                              <Typography>Severity</Typography>
                              <Box>
                                <Typography variant="h1">
                                  <LEIAAStatusSelect
                                    labelText=""
                                    controllerName="severity"
                                    control={control}
                                    required={false}
                                    statusSelectStyle={severitySelectStyles}
                                    defaultValue="TBD"
                                  >
                                    <MenuItem value="0">0</MenuItem>
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                    <MenuItem value="5">5</MenuItem>
                                    <MenuItem value="TBD">TBD</MenuItem>
                                  </LEIAAStatusSelect>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box>
                              <Typography>Stage</Typography>
                              <Box>
                                <Typography variant="h1">
                                  <LEIAAStatusSelect
                                    labelText=""
                                    controllerName="stage"
                                    control={control}
                                    required={false}
                                    statusSelectStyle={stageStatusSelectStyles}
                                    defaultValue="Report Acknowledgement"
                                  >
                                    <MenuItem value="Report Acknowledgement">
                                      Report Acknowledgement
                                    </MenuItem>
                                    <MenuItem value="Scoping">Scoping</MenuItem>
                                    <MenuItem value="Data Collection">
                                      Data Collection
                                    </MenuItem>
                                    <MenuItem value="Analysis">
                                      Analysis
                                    </MenuItem>
                                    <MenuItem value="Further Steps">
                                      Further Steps
                                    </MenuItem>
                                    <MenuItem value="Interviews">
                                      Interviews
                                    </MenuItem>
                                    <MenuItem value="Report">Report</MenuItem>
                                    <MenuItem value="Closed">Closed</MenuItem>
                                  </LEIAAStatusSelect>
                                </Typography>
                                <Typography sx={{ marginLeft: '8px' }}>
                                  Due, {matterDetailGeneral?.deadline}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box>
                              <Typography>Admin Status</Typography>
                              <Box>
                                <Typography variant="h1">
                                  <LEIAAStatusSelect
                                    labelText=""
                                    controllerName="adminstatus"
                                    control={control}
                                    required={false}
                                    statusSelectStyle={reportStatusSelectStyles}
                                    defaultValue="New"
                                  >
                                    <MenuItem value="New">New</MenuItem>
                                    <MenuItem value="Open">Open</MenuItem>
                                    <MenuItem value="Delayed">Delayed</MenuItem>
                                    <MenuItem value="Blocked">Blocked</MenuItem>
                                    <MenuItem value="Closed">Closed</MenuItem>
                                  </LEIAAStatusSelect>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </CustomTabPanel>
            {matterDetailGeneral?.report_data && (
              <CustomTabPanel value={tabValue} index={1}>
                <Box
                  sx={{
                    padding: '8px 16px',
                    borderRadius: '24px',
                    backgroundColor: '#F5FAFD',
                    width: '100%',
                  }}
                >
                  <AllegationSubjects
                    reportAdminData={matterDetailGeneral?.report_data}
                    refreshData={handleGetDashboardDetails}
                    userRole={user?.role}
                  />
                </Box>
              </CustomTabPanel>
            )}
            <CustomTabPanel
              value={tabValue}
              index={matterDetailGeneral?.report_data ? 2 : 1}
            >
              <Box
                sx={{
                  padding: '8px 16px',
                  width: '100%',
                }}
              >
                <MatterDetailUsers
                  matterId={matterId}
                  matterDetails={matterDetailGeneral}
                />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel
              value={tabValue}
              index={matterDetailGeneral?.report_data ? 3 : 2}
            >
              <Box
                sx={{
                  padding: '8px 16px',
                  borderRadius: '24px',
                  backgroundColor: '#F5FAFD',
                  width: '100%',
                }}
              >
                <MatterDetailTimelineSettings
                  matterData={matterDetailGeneral}
                  matterId={matterId}
                  refreshData={handleGetDashboardDetails}
                  user={user}
                />
              </Box>
            </CustomTabPanel>
            {matterDetailGeneral?.report && (
              <CustomTabPanel value={tabValue} index={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '32px',
                    alignSelf: 'stretch',
                    height: '100%',
                  }}
                >
                  {showErrorNotification && (
                    <NotificationToast
                      showNotification={showErrorNotification}
                      requestError={requestError}
                      handleCloseNotification={() =>
                        setShowErrorNotification(false)
                      }
                    />
                  )}

                  <Box
                    sx={{
                      padding: '0px !important',
                      margin: '0px !important',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '24px 16px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        borderRadius: '24px',
                        backgroundColor: '#F5FAFD',
                        marginBottom: '20px',
                      }}
                    >
                      {['Admin', 'Superuser', 'Leader'].includes(
                        user?.role || ''
                      ) && (
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              ...tertiaryButtonStyles(),
                              height: '25px',
                              '& > span': {
                                fontSize: '16px',
                                color: '#1a5eff',
                              },
                            }}
                            onClick={() => handleReportNavigation(2)}
                          >
                            <span className="material-icons-round">
                              file_copy
                            </span>
                            <Typography>Initial report information</Typography>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              ...tertiaryButtonStyles(),
                              height: '25px',
                              '& > span': {
                                fontSize: '16px',
                                color: '#1a5eff',
                              },
                            }}
                            onClick={() => handleReportNavigation(3)}
                          >
                            <span className="material-icons-round">
                              question_answer
                            </span>
                            <Typography>Secure Communication</Typography>
                          </Button>
                        </Stack>
                      )}

                      {reportInfoFields.map((ri) => {
                        const isArrayWithValue =
                          Array.isArray(ri.value) && ri.value.length > 0;

                        if (
                          (typeof ri.value === 'string' && ri.value !== '') ||
                          isArrayWithValue
                        ) {
                          return (
                            <Box
                              key={ri.label}
                              sx={matterCreationSidepanelFieldStyles}
                            >
                              <Box>
                                <Box>
                                  <Grid container>
                                    <Grid item xs={2}>
                                      <Typography>{ri.label}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Typography>
                                        {isArrayWithValue
                                          ? (ri.value as string[]).join(', ')
                                          : ri.value}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Box>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
            )}
            {['Admin', 'Superuser', 'Leader'].includes(user?.role) && (
              <CustomTabPanel
                value={tabValue}
                index={matterDetailGeneral?.report ? 5 : 3}
              >
                <Box
                  sx={{
                    padding: '8px 16px',
                    borderRadius: '24px',
                    backgroundColor: '#F5FAFD',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '17px',
                      lineHeight: '22px',
                      marginBottom: '20px',
                      paddingTop: '10px',
                      paddingLeft: '5px',
                    }}
                  >
                    Relativity Settings
                  </Typography>
                  <MatterDetailRelativity
                    matterData={matterDetailGeneral}
                    matterId={matterId}
                  />
                </Box>
                <Box
                  sx={{
                    padding: '8px 16px',
                    borderRadius: '24px',
                    backgroundColor: '#F5FAFD',
                    width: '100%',
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '17px',
                      lineHeight: '22px',
                      marginBottom: '20px',
                      paddingTop: '10px',
                      paddingLeft: '5px',
                    }}
                  >
                    iManage Settings
                  </Typography>
                  <MatterDetailIManage
                    matterData={matterDetailGeneral}
                    matterId={matterId}
                  />
                </Box>
              </CustomTabPanel>
            )}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            '& > span': {
              color: '#0053FF',
            },
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default MatterDetailGeneral;
