import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import { DocumentsListResponse } from '../../../types/wiki';
import FileOrDocCell from '../docs-list-table/FileOrDocCell';
import LEIAAPagination from '../lists/LEIAAPagination';
import SortableHeader from '../matters-list-table/SortableHeader';
import {
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
} from '../matters-list-table/styles';
import templatesListWrapperStyles from '../templates-list-table/styles';
import { DocumentsListItem } from './types';
import ListSkeletonLoader from '../lists/ListSkeletonLoader';

interface RepoDocumentsListTableProps {
  documentsListData: DocumentsListResponse | null;
  loading: boolean;
  listClass: string;
  dashboard?: boolean;
  setCheckedDocuments?: any;
  setSelectedDocument?: any;
  handleDetailDoc?: any;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No documents.
    </Typography>
  </GridOverlay>
);

const RepoDocumentsListTable = ({
  documentsListData,
  loading,
  dashboard = false,
  listClass,
  setCheckedDocuments,
  setSelectedDocument,
  handleDetailDoc,
}: RepoDocumentsListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && documentsListData?.documentsList !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, documentsListData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return <FileOrDocCell file={params.row.folder} name={cellValue} />;
      },
    },
    {
      field: 'topic',
      headerName: 'Investigation Topic',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
  ];

  const repositoryRowsAreEmpty = documentsListData?.documentsList.length === 0;

  return (
    <Box sx={templatesListWrapperStyles(repositoryRowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={documentsListData?.documentsList as DocumentsListItem[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection={!dashboard}
            onRowSelectionModelChange={(ids) => {
              setCheckedDocuments(ids);
              if (ids.length !== 0) {
                setSelectedDocument(
                  documentsListData?.documentsList.find(
                    (row: any) => row.id === ids[0]
                  )
                );
              }
            }}
            onRowClick={(params) => {
              handleDetailDoc(params.row);
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            hideFooter={dashboard}
            sx={mattersListStyles}
            className={listClass}
          />
        ) : (
          <ListSkeletonLoader pageSize={5} />
        )}
      </Box>
    </Box>
  );
};

export default RepoDocumentsListTable;
