/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// import '../../../pages/styles/matterlist.css';
// import '../../styles/datagridStyles.css';
import { folderDocsRequest } from '../../api/matters';
import {
  sofiaProRegularFontStyles,
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../styles/textStyles';
import DocumentDialog from '../shared/docs-list-table/DocumentDialog';
import DocumentsListTable from '../shared/docs-list-table/DocumentsListTable';
import ListButtons from '../shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../shared/resource-page-header/styles';
import NotificationToast from '../shared/toast/NotificationToast';
import {
  batchDeleteDocumentRequest,
  editDocumentWikiRequest,
  getDocumentWikiURLRequest,
  wikiFolderDocsRequest,
} from '../../api/wiki';

const WikiFolderDetail = () => {
  const { resourceId } = useParams();
  const location = useLocation();
  const { folderName } = location.state;
  const [loading, setLoading] = useState(false);
  const [matterDetailDocs, setMatterDetailsDocs] = useState<any>(
    location.state.response
  );

  const [checkedDocs, setCheckedDocs] = useState<any>([]);
  const [selectedDocs, setSelectedDocs] = useState<any | null>(null);
  const [openRenameDialog, setRenameDialogOpen] = useState(false);

  const [inputRenameValue, setInputRenameValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handleGetMatterDetailDocuments = async (searchText = '', page = 1) => {
    setLoading(true); // Start loading
    try {
      if (resourceId) {
        const response = await wikiFolderDocsRequest(
          resourceId.toString(),
          searchText,
          page
        );
        setMatterDetailsDocs(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleGetMatterDetailDocuments(searchText);
  };

  const handleDownloadFile = async () => {
    if (!selectedDocs) return;
    try {
      const data = await getDocumentWikiURLRequest(selectedDocs.id);
      const link = document.createElement('a');
      link.href = data.file;
      link.download = selectedDocs.name;
      link.click();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleBatchDeleteDocs = async () => {
    await batchDeleteDocumentRequest(checkedDocs);
    await handleGetMatterDetailDocuments();
  };

  // rename folder / doc
  const renameHandler = async () => {
    try {
      await editDocumentWikiRequest(
        selectedDocs.id,
        inputRenameValue,
        undefined,
        undefined
      );

      setRenameDialogOpen(false);
      setInputRenameValue('');
      await handleGetMatterDetailDocuments();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const removeFromResourceHandler = async () => {
    try {
      await editDocumentWikiRequest(
        selectedDocs.id,
        undefined,
        undefined,
        true
      );

      await handleGetMatterDetailDocuments();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handlePageChange = async (newPage: any) => {
    setSelectedDocs(null);
    setCheckedDocs([]);
    setCurrentPage(newPage);
    await handleGetMatterDetailDocuments(newPage);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <DocumentDialog
        open={openRenameDialog}
        onClose={() => setRenameDialogOpen(false)}
        title="Rename Document"
        label="Document name"
        primaryBtnText="OK"
        onPrimaryButtonClick={renameHandler}
        setInputValue={setInputRenameValue}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          },
        }}
      >
        <Box>
          <Breadcrumbs
            separator={
              <span className="material-icons-round">chevron_right</span>
            }
            aria-label="breadcrumb"
            sx={{
              '& > ol': {
                gap: '6px',
                '& > .MuiBreadcrumbs-li > div': {
                  '& > button': {
                    ...sofiaProRegularFontStyles,
                    color: '#69707B',
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    padding: '10px 0px',
                  },
                  '& > p': {
                    color: '#949CA9',
                    ...karlaProRegularFontStyles,
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.2px',
                  },
                },
                '& > .MuiBreadcrumbs-separator': {
                  margin: '0px',
                  padding: '10px 0',
                  alignSelf: 'baseline',
                },
              },
            }}
          >
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={handleGoBack}
              >
                wiki
              </Link>
              <Typography>Wiki</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={handleGoBack}
              >
                folder
              </Link>
              <Typography>Folder</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                sx={{
                  ...sofiaProBoldFontStyles,
                  color: '#202020 !important',
                  fontSize: '18px !important',
                  fontWeight: '700 !important',
                  lineHeight: 'normal !important',
                  letterSpacing: '2px !important',
                  textTransform: 'uppercase !important',
                  padding: '10px 0 !important',
                  cursor: 'default',
                  pointerEvents: 'none',
                }}
              >
                {folderName}
              </Link>
              <Typography>Folder</Typography>
            </Box>
          </Breadcrumbs>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '40px',
            alignSelf: 'stretch',
            '& > div:nth-of-type(1)': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flex: '1 0 0',
            },
          }}
        >
          <Box>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by document..."
              sx={{
                ...homePortalSearchInputStyles,
                marginLeft: 'auto',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
          </Box>
          {checkedDocs.length > 0 && (
            <ListButtons
              deleteHandler={handleBatchDeleteDocs}
              checkedResources={checkedDocs}
              resourceName="Documents"
              renameHandler={() => setRenameDialogOpen(true)}
              removeFromResourceHandler={removeFromResourceHandler}
              handleDownloadFile={handleDownloadFile}
            />
          )}
        </Box>
      </Box>
      <Grid item xs={12} md={12}>
        <DocumentsListTable
          documentsListData={matterDetailDocs}
          listClass="docs-list"
          loading={loading}
          setCheckedDocs={setCheckedDocs}
          setSelectedDocs={setSelectedDocs}
          onPageChange={handlePageChange}
          pageSize={5}
        />
      </Grid>
    </Box>
  );
};

export default WikiFolderDetail;
