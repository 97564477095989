import { Box, Typography, Stack } from '@mui/material';
import { motion } from 'motion/react';
import {
  karlaProRegularFontStyles,
  karlaProBoldFontStyles,
} from '../../styles/textStyles';
import { MatterStatusUpdate } from './types';

interface StatusUpdateProps {
  update: MatterStatusUpdate;
  showMatter: boolean;
  showOrg: boolean;
}

const StatusUpdate = ({
  update,
  showMatter = false,
  showOrg = false,
}: StatusUpdateProps) => {
  return (
    <>
      {showMatter && (
        <Box
          sx={{
            width: '100%',
            borderRadius: '5px',
            backgroundColor: '#F2FAFE',
            padding: '5px 10px',
            marginBottom: '5px',
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: 'flex-start',
              alignItems: ' baseline',
            }}
          >
            <Typography
              sx={{
                color: '#464B53',
                fontSize: '16px',
                ...karlaProBoldFontStyles,
                lineHeight: '22px',
                textTransform: 'capitalize',
              }}
            >
              {showOrg && update.matter.organisation} {'//'}{' '}
              {update.matter.name} - {update.matter.stage}
            </Typography>
          </Stack>
        </Box>
      )}
      <motion.div
        key={`update-${update.id}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Stack
          key={`update-${update.id}`}
          direction="column"
          spacing={1}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            backgroundColor: '#F2FAFE',
            padding: '10px',
            marginBottom: '10px',
            ...(showMatter
              ? {
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                  borderBottomLeftRadius: '14px',
                  borderBottomRightRadius: '14px',
                }
              : { borderRadius: '14px' }),
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-start',
                alignItems: ' baseline',
              }}
            >
              <Typography
                sx={{
                  color: '#464B53',
                  fontSize: '16px',
                  ...karlaProRegularFontStyles,
                  lineHeight: '22px',
                }}
              >
                {update.author}
              </Typography>
              <Typography
                sx={{
                  color: '#464B53',
                  fontSize: '10px',
                  ...karlaProRegularFontStyles,
                  lineHeight: '18px',
                }}
              >
                {update.timestamp}
              </Typography>
            </Stack>
            <Typography
              sx={{
                color: '#464B53',
                fontSize: '14px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginTop: '5px',
              }}
            >
              {update.content}
            </Typography>
            {update.replies.length > 0 && (
              <Box
                sx={{
                  height: '1px',
                  backgroundColor: '#464B53',
                  marginBottom: '3px',
                  marginTop: '10px',
                  width: '100%',
                }}
              />
            )}
          </Box>

          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            {update.replies?.map(
              (
                reply: {
                  id: number;
                  content: string;
                  author: string;
                  timestamp: number;
                },
                index
              ) => {
                return (
                  <Stack
                    key={`reply-${update.id}-${reply.id}`}
                    direction="column"
                    spacing={0.5}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#464B53',
                        fontSize: '14px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                      }}
                    >
                      {reply.author}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#464B53',
                        fontSize: '12px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                        borderLeft: '1px solid #D4D4D4',
                        paddingLeft: '5px',
                      }}
                    >
                      {reply.content}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: '27px',
                        color: '#464B53',
                        fontSize: '10px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '18px',
                      }}
                    >
                      {reply.timestamp}
                    </Typography>
                    {index < update.replies.length - 1 && (
                      <Box
                        sx={{
                          width: '100%',
                          height: '1px',
                          backgroundColor: 'white',
                          marginBottom: '5px',
                          marginTop: '15px',
                        }}
                      />
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        </Stack>
      </motion.div>
    </>
  );
};

export default StatusUpdate;
