import { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import MicrosoftSmall from '../../../../assets/integrations/MicrosoftSmall.png';
import iManageSmall from '../../../../assets/integrations/iManageSmall.png';
import relativitySmall from '../../../../assets/integrations/relativitySmall.png';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';
import MatterIManageSettings from './MatterIManageSettings';
import MatterMicrosoftSettings from './MatterMicrosoftSettings';
import MatterRelativitySettings from './MatterRelativitySettings';
import { simpleGoBackButtonStyles } from '../../../styles';
import IntegrationStatus from './IntegrationStatus';

interface MatterDetailIntegrationsProps {
  matterId: any;
  matterData: any;
}

const MatterDetailIntegrations = ({
  matterId,
  matterData,
}: MatterDetailIntegrationsProps) => {
  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(
    null
  );

  const availableIntegrations = [
    {
      name: 'iManage',
      description:
        'Connect seamlessly with iManage to access, manage, and collaborate on documents.',
      required: [
        'imanage_hostname',
        'imanage_matter_id',
        'imanage_matter_name',
      ],
    },
    {
      name: 'Relativity',
      description:
        'Connect seamlessly with Relativity to access, review, and manage eDiscovery data.',
      required: ['instance', 'project'],
    },
    {
      name: 'Microsoft',
      description:
        'Connect seamlessly with Microsoft to access, manage, and collaborate on files from OneDrive and SharePoint.',
      required: ['client_id'],
    },
  ];

  const getIntegrationImage = (name: string) => {
    switch (name.toLowerCase()) {
      case 'imanage':
        return iManageSmall;
      case 'relativity':
        return relativitySmall;
      case 'microsoft':
        return MicrosoftSmall;
      default:
        return undefined;
    }
  };

  const getIntegrationStatus = (integration: any) => {
    const missingFields = integration.required.filter(
      (field: string) => !matterData[field]
    );

    if (missingFields.length === 0) {
      return 'Configured';
    }

    if (missingFields.length <= integration.required.length) {
      return 'Configure';
    }

    return 'Error';
  };

  const renderSettingsComponent = () => {
    switch (selectedIntegration) {
      case 'iManage':
        return (
          <MatterIManageSettings matterId={matterId} matterData={matterData} />
        );
      case 'Relativity':
        return (
          <MatterRelativitySettings
            matterId={matterId}
            matterData={matterData}
          />
        );
      case 'Microsoft':
        return (
          <MatterMicrosoftSettings
            matterId={matterId}
            matterData={matterData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {selectedIntegration ? (
        <Box>
          <Button
            sx={{
              ...simpleGoBackButtonStyles,
              marginBottom: '5px',
              marginLeft: '-5px',
            }}
            onClick={() => setSelectedIntegration(null)}
            variant="text"
          >
            <Typography variant="caption" component="p">
              Back
            </Typography>
          </Button>
          {renderSettingsComponent()}
        </Box>
      ) : (
        <Grid spacing={2} container>
          {availableIntegrations.map((integration: any, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              key={index}
              display="flex"
              onClick={() => setSelectedIntegration(integration.name)}
              sx={{
                cursor: 'pointer',
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{
                  scale: 1.02,
                }}
                transition={{ duration: 0.3 }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    border: '1px solid #F5FAFD',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    '&:hover': {
                      border: '4px solid #F5FAFD',
                    },
                  }}
                >
                  <img
                    src={getIntegrationImage(integration.name)}
                    style={{
                      width: '100px',
                      height: '100px',
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                    alt={integration.name}
                  />
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      marginLeft: '5px',
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#69707B',
                          ...karlaProRegularFontStyles,
                          fontWeight: '700',
                          fontSize: '16px',
                          lineHeight: '22px',
                        }}
                      >
                        {integration.name}
                      </Typography>
                      <IntegrationStatus
                        status={getIntegrationStatus(integration)}
                        width="100%"
                        height="20px"
                        fontSize="14px"
                      />
                    </Stack>

                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#69707B',
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        lineHeight: '22px',
                      }}
                    >
                      {integration.description}
                    </Typography>
                  </Stack>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default MatterDetailIntegrations;
