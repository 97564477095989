import { Paper, Typography, Box, Divider } from '@mui/material';
import { karlaProRegularFontStyles } from '../../styles/textStyles';

interface InChatConfirmationProps {
  data: any;
  onConfirm: () => void;
  onReject: () => void;
}

const InChatConfirmation = ({
  data,
  onConfirm,
  onReject,
}: InChatConfirmationProps) => {
  const formatLabel = (key: string) => {
    // Handle keys that start with asterisks (like **FirstName)
    let cleanKey = key.replace(/^\*+/, '');

    // Add spaces before capital letters and remove underscores
    cleanKey = cleanKey
      .replace(/([A-Z])/g, ' $1')
      .replace(/_/g, ' ')
      .trim();

    // Split the string, capitalize first letter of each word
    return cleanKey
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const renderValue = (value: any) => {
    if (value === null || value === undefined) {
      return '-';
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return '-';
      }

      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {value.map((item, i) => (
            <Box key={i} sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography
                component="span"
                sx={{
                  ...karlaProRegularFontStyles,
                  color: '#333',
                  fontSize: '14px',
                  mr: 1,
                }}
              >
                •
              </Typography>
              <Typography
                component="span"
                sx={{
                  ...karlaProRegularFontStyles,
                  color: '#333',
                  fontSize: '14px',
                  wordBreak: 'break-word',
                }}
              >
                {item.toString()}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <Typography
        sx={{
          ...karlaProRegularFontStyles,
          color: '#333',
          fontSize: '14px',
          wordBreak: 'break-word',
        }}
      >
        {value.toString()}
      </Typography>
    );
  };

  return (
    <Paper
      elevation={1}
      sx={{
        padding: '16px 20px',
        borderRadius: '12px',
        backgroundColor: '#f8faff',
        border: '1px solid #e3eafd',
        width: '100%',
        maxWidth: '95%',
        mb: 2,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          ...karlaProRegularFontStyles,
          fontWeight: 600,
          color: '#1a73e8',
          fontSize: '15px',
          mb: 2,
        }}
      >
        Confirm creation:
      </Typography>

      <Box sx={{ mb: 2 }}>
        {Object.entries(data).map(([key, value], index) => (
          <Box key={key}>
            <Box sx={{ display: 'flex', my: 1.5 }}>
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  color: '#555',
                  width: '30%',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {formatLabel(key)}
              </Typography>
              <Box
                sx={{
                  width: '70%',
                }}
              >
                {renderValue(value)}
              </Box>
            </Box>
            {index < Object.entries(data).length - 1 && (
              <Divider sx={{ opacity: 0.6 }} />
            )}
          </Box>
        ))}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 4, mt: 3 }}>
        <Typography
          onClick={onReject}
          sx={{
            ...karlaProRegularFontStyles,
            color: '#d32f2f',
            cursor: 'pointer',
            fontWeight: 500,
            transition: 'opacity 0.2s ease',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          Reject
        </Typography>
        <Typography
          onClick={onConfirm}
          sx={{
            ...karlaProRegularFontStyles,
            color: '#2e7d32',
            cursor: 'pointer',
            fontWeight: 500,
            transition: 'opacity 0.2s ease',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          Confirm
        </Typography>
      </Box>
    </Paper>
  );
};

export default InChatConfirmation;
