import { Box, Typography } from '@mui/material';
import { getInterviewStatusBackgroundColor } from '../../../styles/sharedStyles';
import { interviewsListStatusChipWrapperStyles } from './styles';

interface StatusChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const StatusChip = ({
  status,
  heightValue,
  wrapperHeight,
}: StatusChipProps) => {
  if (status === 'false') {
    status = 'Active';
  } else if (status === 'true') {
    status = 'Archived';
  }

  const backgroundColor = getInterviewStatusBackgroundColor(status);
  const color = '#FFFFFF';

  return (
    <Box
      sx={interviewsListStatusChipWrapperStyles(
        backgroundColor,
        color,
        heightValue,
        wrapperHeight
      )}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default StatusChip;
