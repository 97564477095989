import {
  ClientOptionType,
  MatterTask,
  MatterTaskDetail,
  MatterUser,
  SubDivisonOptionType,
} from '../types/matters';
import api from './index';

async function clientsRequest(): Promise<ClientOptionType[]> {
  const response = await api.get('/organizations-list');
  return response.data;
}

async function intervieweeOrgsRequest(): Promise<ClientOptionType[]> {
  const response = await api.get('/interviewee-organizations-list');
  return response.data;
}

async function intervieweeSubDivisionsRequest(
  clientName: any
): Promise<SubDivisonOptionType[]> {
  const response = await api.get(
    `/interviewee-sub-divisions-list/${clientName}`
  );
  return response.data;
}

async function subDivisionsRequest(
  clientName: any
): Promise<SubDivisonOptionType[]> {
  const response = await api.get(`/sub-divisions-list/${clientName}`);
  return response.data;
}

async function countriesRequest() {
  const response = await api.get('/country-list');
  return response.data;
}

async function questionnaireFileTranslatorRequest(
  file: File,
  forInterview: boolean
) {
  // Upload file
  const url = forInterview
    ? '/questionnaire-file-translator-interview'
    : '/questionnaire-file-translator';
  const formData = new FormData();
  formData.append('file', file, file.name);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await api.patch(url, formData, config);
  return response.data;
}

interface MatterDraftPayload {
  matter_id: string;
}

async function createMatterDraftRequest(): Promise<MatterDraftPayload> {
  const response = await api.post('/create-draft-matter');
  return response.data;
}

async function createMatterStepRequest(
  matterId: string,
  step: number,
  matterData: any // Update type to match the expected data type
) {
  if (step === 4) {
    // Step 4: Upload file
    const formData = new FormData();
    let permissionsData: any = {};

    if (matterData.files) {
      for (let i = 0; i < matterData.files.length; i += 1) {
        if (matterData.files[i].uploaded === false) {
          formData.append(
            'files',
            matterData.files[i],
            matterData.files[i].name
          );
        }
        permissionsData[matterData.files[i].name] =
          matterData.files[i].permissions;
        permissionsData = { ...permissionsData };
      }
    }

    if (matterData.files_to_delete) {
      for (let i = 0; i < matterData.files_to_delete.length; i += 1) {
        formData.append('files_to_delete', matterData.files_to_delete[i]);
      }
    }

    if (matterData.folder) {
      formData.append('target_folder', matterData.folder);
    }

    formData.append('permissions', JSON.stringify(permissionsData));

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    // Use the formData and config in the request for step 4
    await api.patch(
      `/matter-creation-step${step}/${matterId}`,
      formData,
      config
    );
  } else if (step === 3) {
    // Remove tasks with uploaded === true before sending the request
    if (matterData.tasks) {
      const updatedTasks = matterData.tasks.filter(
        (task: MatterTask) => !task.uploaded
      );
      // Update the tasks array in matterData
      const updatedMatterData = { ...matterData, tasks: updatedTasks };

      // For other steps, send the matterData object as it is
      await api.patch(
        `/matter-creation-step${step}/${matterId}`,
        updatedMatterData
      );
    } else {
      await api.patch(`/matter-creation-step${step}/${matterId}`, {});
    }
  } else {
    const response = await api.patch(
      `/matter-creation-step${step}/${matterId}`,
      matterData
    );
    return response.data;
  }
  return null;
}

async function matterAvailableUsersRequest(): Promise<MatterUser[]> {
  const response = await api.get('/matter-creation-get-users');

  return response.data;
}

async function batchDeleteMatterRequest(matterIds: number[]) {
  await api.post(`/bulk-matter-actions`, { matters_to_delete: matterIds });
}

async function matterDetailsRequest(matterId: string): Promise<any> {
  const response = await api.get(`matter-edit-data/${matterId}`);
  return response.data;
}

async function createFolderRequest(
  name: string,
  matterId: any = undefined,
  parentId: any = undefined
) {
  const requestData: {
    folder_name: string;
    matter_id?: any;
    parent_id?: any;
  } = {
    folder_name: name,
  };

  if (typeof matterId !== undefined) {
    requestData.matter_id = matterId;
  }

  if (parentId) {
    // removes string
    const [parentIdNumber] = parentId.match(/\d+/) || [];
    if (parentIdNumber) {
      requestData.parent_id = parentIdNumber;
    }
  }

  await api.post(`/create-folder`, requestData);
}

async function matterDetailDashboardRequest(
  matterId: string,
  startDate: string
): Promise<any> {
  const response = await api.get(`matter-detail-dashboard/${matterId}`, {
    params: { start_day: startDate },
  });
  return response.data;
}

async function closeMatterRequest(matterId: string): Promise<any> {
  const response = await api.get(`close-matter/${matterId}`);
  return response;
}

async function matterDetailGeneralRequest(matterId: string): Promise<any> {
  const response = await api.get(`matter-detail-general/${matterId}`);
  return response.data;
}

async function matterDetailGeneralPOSTRequest(
  matterId: string,
  data: any
): Promise<any> {
  const response = await api.patch(`matter-detail-general/${matterId}`, data);
  return response.data;
}

async function matterDetailGeneraPUTRequest(
  matterId: string,
  data: any
): Promise<any> {
  const response = await api.put(`matter-detail-general/${matterId}`, data);
  return response.data;
}

async function matterDetailTasksRequest(
  matterId: string,
  searchText: string
): Promise<any> {
  const filters: { limit?: number; search?: string } = {};

  if (searchText !== '') {
    filters.search = searchText;
  }

  const response = await api.get(`matter-detail-tasks/${matterId}`, {
    params: filters,
  });
  return response.data;
}

async function matterDetailTaskDetailRequest(
  taskId: string
): Promise<MatterTaskDetail | undefined> {
  const { data } = await api.get(`matter-detail-task-detail/${taskId}`);
  if (!data) {
    return undefined;
  }

  // turn string date into an object
  data.deadline = new Date(data.deadline);
  return data;
}

async function matterDetailArchiveTask(
  taskId: string
): Promise<MatterTaskDetail | undefined> {
  const { data } = await api.delete(`matter-detail-task-detail/${taskId}`);
  if (!data) {
    return undefined;
  }

  // turn string date into an object
  data.deadline = new Date(data.deadline);
  return data;
}

async function matterDetailAddTaskComment(
  taskId: string,
  comment: string
): Promise<MatterTaskDetail | undefined> {
  const { data } = await api.patch(`matter-create-task-comment/${taskId}`, {
    comment,
  });

  return data;
}

async function batchDeleteTasksRequest(taskIds: any[]) {
  await api.post(`bulk-tasks-actions`, { tasks_to_delete: taskIds });
}

async function matterDetailDocumentsRequest(
  matterId: string,
  searchText: string,
  page = 1,
  size = 5,
  type = 'documents',
  integrations = []
): Promise<any> {
  const filters: {
    page: number;
    type: string;
    size: number;
    search_text?: string;
    integrations?: string;
  } = {
    page,
    size,
    type,
  };

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  if (integrations.length > 0) {
    filters.integrations = integrations.join(',');
  }

  const response = await api.get(`matter-documents/${matterId}`, {
    params: filters,
  });
  return response.data;
}

async function batchDeleteMatterDocsRequest(matterId: number, fileIds: any[]) {
  await api.put(`/document-list/${matterId}`, { file_ids: fileIds });
}

async function renameFolderRequest(folderId: string, renameValue: string) {
  const splitId = folderId.split('-');
  await api.patch(`folder/${splitId[splitId.length - 1]}`, {
    rename: renameValue,
  });
}

async function addToFolderRequest(
  folderId: string | number,
  files: number[],
  folders: number[]
) {
  const patchData: { [key: string]: any } = {};

  if (files !== undefined) {
    patchData.files_to_add = files;
  }

  if (folders !== undefined) {
    patchData.folders_to_add = folders;
  }

  await api.put(`folder/${folderId}`, patchData);
}

async function folderPermsRequest(folderId: string, users: number[]) {
  const splitId = folderId.split('-');

  await api.patch(`folder-permissions/${splitId[splitId.length - 1]}`, {
    users,
  });
}

async function folderDocsRequest(
  folderId: string,
  searchText: string,
  page = 1
): Promise<any> {
  const splitId = folderId.split('-');
  const filters: { limit?: number; search_text?: string; page: number } = {
    page,
  };

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  const response = await api.get(`folder/${splitId[splitId.length - 1]}`, {
    params: filters,
  });
  return response.data;
}

async function editDocumentRequest(
  matterId: number,
  fileId: number,
  renameValue?: string,
  targetFolderId?: string,
  removeFromFolder?: boolean
) {
  const patchData: { [key: string]: any } = {};
  patchData.file_id = fileId;
  if (renameValue !== undefined) {
    patchData.rename = renameValue;
  }
  if (targetFolderId !== undefined) {
    patchData.add_to_folder = targetFolderId;
  }
  if (removeFromFolder !== undefined) {
    patchData.remove_from_folder = true;
  }
  await api.patch(`document-get/${matterId}`, patchData);
}

async function getDocumentURLRequest(matterId: number, fileId: number) {
  const filters: { file_id?: number } = {};

  filters.file_id = fileId;

  const response = await api.get(`document-get/${matterId}`, {
    params: filters,
  });

  return response.data;
}

async function matterDetailBundlesRequest(
  matterId: string,
  searchText: string
): Promise<any> {
  const filters: { matter_id: string; limit?: number; search_text?: string } = {
    matter_id: matterId,
  };

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  const response = await api.get(`bundles`, {
    params: filters,
  });
  return response.data;
}

async function bundleActionsRequest(
  bundleId: number,
  files?: number[],
  folders?: any[],
  rename?: string,
  associate_interview?: string,
  files_to_remove?: number[]
) {
  const patchData: { [key: string]: any } = {};
  if (files !== undefined) {
    patchData.files_to_add = files;
  }
  if (folders !== undefined) {
    patchData.folders_to_add = folders;
  }
  if (rename !== undefined) {
    patchData.rename = rename;
  }
  if (associate_interview !== undefined) {
    patchData.associate_to_interview = associate_interview;
  }
  if (files_to_remove !== undefined) {
    patchData.files_to_remove = files_to_remove;
  }
  await api.patch(`bundle-actions/${bundleId}`, patchData);
}

async function createBundleRequest(
  bundleName: string,
  matterId: number,
  files?: number[],
  folders?: any[]
) {
  const patchData: { [key: string]: any } = {};

  patchData.bundle_name = bundleName;
  patchData.matter_id = matterId;

  if (files !== undefined) {
    patchData.files_to_add = files;
  }
  if (folders !== undefined) {
    patchData.folders_to_add = folders;
  }
  await api.post(`/bundles`, patchData);
}

async function batchDeleteMatterBundlesRequest(bundleIds: any[]) {
  await api.patch(`/bundles`, { bundle_ids: bundleIds });
}

async function bundleDocsRequest(
  bundleId: string,
  searchText: string,
  page = 1
): Promise<any> {
  const filters: { limit?: number; search_text?: string; page: number } = {
    page,
  };

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  const response = await api.get(`bundle-actions/${bundleId}`, {
    params: filters,
  });
  return response.data;
}

async function matterDetailRiskReportsRequest(
  matterId: string,
  searchText: string
): Promise<any> {
  const filters: { limit?: number; search?: string } = {};

  if (searchText !== '') {
    filters.search = searchText;
  }

  const response = await api.get(`risk-report/${matterId}`, {
    params: filters,
  });
  return response.data;
}

async function matterDetailMatterReportsRequest(
  matterId: string,
  searchText: string
): Promise<any> {
  const filters: { limit?: number; search?: string } = {};

  if (searchText !== '') {
    filters.search = searchText;
  }

  const response = await api.get(`matter-reports/${matterId}`, {
    params: filters,
  });
  return response.data;
}

async function batchDeleteMatterReportsRequest(
  matterId: number,
  reportIds: any[]
) {
  await api.patch(`matter-reports/${matterId}`, { reports_ids: reportIds });
}

async function createMatterReportRequest(matterId: number) {
  await api.post(`matter-reports/${matterId}`);
}

async function addToFraudDetectionDBRequest(matterId: string, fileIds: any[]) {
  await api.put(`/fraud/${matterId}`, { file_ids: fileIds });
}

async function matterFraudDetectionListRequest(
  matterId: string,
  searchText: string
): Promise<any> {
  const filters: { matter_id: string; limit?: number; search_text?: string } = {
    matter_id: matterId,
  };

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  const response = await api.get(`/fraud/${matterId}`, {
    params: filters,
  });

  return response.data;
}

async function requestDocumentAnalysisRequest(
  matterId: string,
  fileIds: any[]
) {
  await api.post(`/fraud/${matterId}`, { file_ids: fileIds });
}

async function fraudDocDetailRequest(id: number) {
  const response = await api.get(`/fraud-analysis/${id}`);
  return response.data;
}

async function getAssignedMattersRequest() {
  const response = await api.get(`/assigned-matters`);
  return response.data;
}

async function matterDetailRelativityFileListRequest(
  authData: any,
  matterId: string,
  searchText: any,
  filterBy: string,
  filterValue: string
): Promise<any> {
  const params: any = {
    ...authData,
    matter: matterId,
    search: searchText,
  };

  if (filterBy !== '') {
    params.filter_by = filterBy;
  }

  if (filterValue !== '') {
    params.filter_value = filterValue;
  }

  if (searchText !== '') {
    params.search = searchText;
  }

  const response = await api.get(`relativity-documents`, {
    params,
  });

  return response.data;
}

async function matterDetailRelativityDocOpsRequest(
  matter: string,
  operation: string,
  files: any[],
  folder?: string
): Promise<any> {
  const requestData = folder
    ? { operation, files, matter, folder }
    : { operation, files, matter };

  const response = await api.post(`relativity-documents`, requestData);
  return response.data;
}

async function handleGetRelativityMetadata(
  authData: any,
  matterId: string,
  resource: string
): Promise<any> {
  const response = await api.get(`relativity-metadata`, {
    params: { ...authData, matter: matterId, resource },
  });

  return response.data;
}

async function uploadPortalRequest(data: any, token: any) {
  const formData = new FormData();

  formData.append('jsonField', JSON.stringify(data));

  if (data.files) {
    data.files.forEach((file: any) => {
      formData.append('sent_files', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await api.post(`/upload-portal/${token}`, formData, config);
  return response.data;
}

async function matterDetailImanageFileListRequest(
  authData: any,
  matterId: string,
  searchText: any,
  filterBy: string,
  filterValue: string
): Promise<any> {
  const params: any = {
    ...authData,
    matter: matterId,
    search: searchText,
  };

  if (filterBy !== '') {
    params.filter_by = filterBy;
  }

  if (filterValue !== '') {
    params.filter_value = filterValue;
  }

  if (searchText !== '') {
    params.search = searchText;
  }

  const response = await api.get(`imanage-documents`, {
    params,
  });

  return response.data;
}

async function createOrGetUploadTokenRequest(folderId: string): Promise<any> {
  const response = await api.post(`upload-portal-admin/${folderId}`);
  return response.data.token;
}

// old delete on refactor
async function matterDetailImanageDocOpsRequest(
  authData: any,
  matter: string,
  operation: string,
  files: any[],
  folder?: string
): Promise<any> {
  const requestData = folder
    ? { ...authData, operation, files, matter, folder }
    : { ...authData, operation, files, matter };

  const response = await api.post(`imanage-documents`, requestData);
  return response.data;
}

async function matterDetailImanageDocOpsNewRequest(
  matter: string,
  operation: string,
  files: any[],
  folder?: string
): Promise<any> {
  const requestData = folder
    ? { operation, files, matter, folder }
    : { operation, files, matter };
  const response = await api.post(`imanage-documents`, requestData);
  return response.data;
}

async function createOrGetDownloadTokenRequest(docId: string): Promise<any> {
  const response = await api.post(`create-direct-link/${docId}`);
  return response.data.token;
}

async function getDocumentURLFromTokenRequest(token: string) {
  const response = await api.get(`direct-link/${token}`);
  return response.data;
}

async function createMatterTimelineEvent(
  matterId: any,
  date: any,
  time: any,
  type: any,
  description: any,
  name: any
) {
  const response = await api.post(`events`, {
    matter: matterId,
    date_of_occurrence: date,
    time_of_occurrence: time,
    event_type: type,
    description,
    name,
  });

  return response.data;
}

async function updateMatterTimelineEvent(
  eventId: any,
  matterId: any,
  date: any,
  time: any,
  type: any,
  description: any,
  name: any
) {
  const response = await api.put(`event/${eventId}`, {
    matter: matterId,
    date_of_occurrence: date,
    time_of_occurrence: time,
    event_type: type,
    description,
    name,
  });

  return response.data;
}

async function deleteMatterTimelineEvent(eventId: string | number) {
  const response = await api.delete(`event/${eventId}`);
  return response.data;
}

async function getMatterTimelineEvents(
  matterId: any,
  wantedEventTypes: string[],
  starDate: any,
  endDate: any
) {
  const returnNotes = wantedEventTypes.includes('Notes');

  const filteredEventTypes = wantedEventTypes.filter(
    (type) => type !== 'Notes'
  );

  const eventTypesParam = filteredEventTypes.join(',');

  const response = await api.get('events', {
    params: {
      matter: matterId,
      event_types: eventTypesParam,
      return_notes: returnNotes,
      start_date: starDate,
      end_date: endDate,
    },
  });

  const transformedData = response.data.map((event: any) => {
    return {
      ...event,
      date: new Date(event.date),
    };
  });

  return transformedData;
}

async function matterDetailMicrosoftFileListRequest(
  authData: any,
  matterId: string,
  searchText: any,
  filterBy: string,
  filterValue: string
): Promise<any> {
  const params: any = {
    ...authData,
    matter: matterId,
    search: searchText,
  };

  if (filterBy !== '') {
    params.filter_by = filterBy;
  }

  if (filterValue !== '') {
    params.filter_value = filterValue;
  }

  if (searchText !== '') {
    params.search = searchText;
  }

  const response = await api.get(`microsoft-documents`, {
    params,
  });

  return response.data;
}

async function matterDetailMicrosoftDocOpsRequest(
  authData: any,
  matter: string,
  operation: string,
  files: any[],
  folder?: string
): Promise<any> {
  const requestData = folder
    ? { ...authData, operation, files, matter, folder }
    : { ...authData, operation, files, matter };

  const response = await api.post(`microsoft-documents`, requestData);
  return response.data;
}

async function matterDetailIntegrationConfigRequest(
  matterId: string,
  data: any
): Promise<any> {
  const requestData = { ...data, matter: matterId };

  const response = await api.patch(`integration-settings`, requestData);
  return response.data;
}

async function matterDetailIntegrationGetRequest(params: any): Promise<any> {
  const response = await api.get(`integration-settings`, {
    params,
  });
  return response.data;
}

async function matterDetailIntegrationAuthRequest(
  data: any,
  matterId: string
): Promise<any> {
  const response = await api.post(`integration-settings`, {
    ...data,
    matter: matterId,
  });
  return response.data;
}

async function matterDetailIntegrationOperationsRequest(
  matterId: string,
  integration: string,
  operation: string,
  fileId: string
): Promise<any> {
  const response = await api.post(`integration-operations`, {
    matter_id: matterId,
    integration,
    operation,
    file_id: fileId,
  });
  return response.data;
}

async function matterEditDocumentStepRequest(matterId: string, data: any) {
  const response = await api.patch(`matter-creation-step4/${matterId}`, data);
  return response.data;
}

export {
  clientsRequest,
  intervieweeOrgsRequest,
  intervieweeSubDivisionsRequest,
  subDivisionsRequest,
  countriesRequest,
  questionnaireFileTranslatorRequest,
  createMatterDraftRequest,
  createMatterStepRequest,
  matterAvailableUsersRequest,
  batchDeleteMatterRequest,
  matterDetailsRequest,
  matterDetailDashboardRequest,
  matterDetailGeneralRequest,
  matterDetailGeneralPOSTRequest,
  matterDetailTasksRequest,
  matterDetailDocumentsRequest,
  matterDetailBundlesRequest,
  batchDeleteMatterDocsRequest,
  renameFolderRequest,
  editDocumentRequest,
  getDocumentURLRequest,
  createFolderRequest,
  bundleActionsRequest,
  createBundleRequest,
  addToFolderRequest,
  batchDeleteMatterBundlesRequest,
  folderDocsRequest,
  bundleDocsRequest,
  matterDetailRiskReportsRequest,
  matterDetailMatterReportsRequest,
  matterDetailTaskDetailRequest,
  matterDetailArchiveTask,
  matterDetailAddTaskComment,
  createMatterReportRequest,
  batchDeleteMatterReportsRequest,
  batchDeleteTasksRequest,
  closeMatterRequest,
  addToFraudDetectionDBRequest,
  matterFraudDetectionListRequest,
  requestDocumentAnalysisRequest,
  fraudDocDetailRequest,
  getAssignedMattersRequest,
  folderPermsRequest,
  matterDetailGeneraPUTRequest,
  matterDetailRelativityFileListRequest,
  matterDetailRelativityDocOpsRequest,
  handleGetRelativityMetadata,
  uploadPortalRequest,
  createOrGetUploadTokenRequest,
  createOrGetDownloadTokenRequest,
  getDocumentURLFromTokenRequest,
  matterDetailImanageFileListRequest,
  matterDetailImanageDocOpsRequest,
  getMatterTimelineEvents,
  createMatterTimelineEvent,
  updateMatterTimelineEvent,
  deleteMatterTimelineEvent,
  matterDetailMicrosoftFileListRequest,
  matterDetailMicrosoftDocOpsRequest,
  matterDetailIntegrationAuthRequest,
  matterDetailIntegrationGetRequest,
  matterDetailIntegrationConfigRequest,
  matterDetailImanageDocOpsNewRequest,
  matterDetailIntegrationOperationsRequest,
  matterEditDocumentStepRequest,
};
