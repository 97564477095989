import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import { addToFolderRequest } from '../../../api/matters';
import { getFolders } from '../../../api/documents';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import ErrorMessage from '../../error-message/ErrorMessage';
import DropdownMenuIcon from '../../matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import {
  matterInformationSelectPaperStyles,
  matterInformationSelectStyles,
} from '../../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import NotificationToast from '../toast/NotificationToast';
import {
  leiaaAddToDialogContentStyles,
  leiaaAddToWrapperStyles,
} from './styles';

export interface DocumentAddToFolderDialogProps {
  open: boolean;
  onClose: () => void;
  refreshTable: () => void;
  setShowAddedNotification: any;
  matterId?: string | number | null;
  parentId?: string | null;
  selectedDocs?: any;
}

const DocumentAddToFolderDialog = ({
  open,
  onClose,
  refreshTable,
  setShowAddedNotification,
  matterId = null,
  parentId = null,
  selectedDocs,
}: DocumentAddToFolderDialogProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState('');
  const [addedDocs, setAddedDocs] = useState<any[]>(selectedDocs ?? []);
  const [showWarning, setShowWarning] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [availableFolders, setAvailableFolders] = useState([]);

  const cleanData = () => {
    setShowWarning(false);
    setAddedDocs([]);
    setValue('');
    setMenuOpen(false);
    setRequestError(null);
    setShowErrorNotification(false);
  };

  const handleClose = () => {
    cleanData();
    onClose();
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    setValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (addedDocs.length !== 0 && value) {
      try {
        const files: number[] = [];
        const folders: number[] = [];
        addedDocs.forEach((element: any) => {
          if (element.folder) {
            folders.push(parseInt(element.id.match(/\d+/)[0], 10));
          } else {
            files.push(element.id);
          }
        });
        await addToFolderRequest(value, files, folders);
        handleClose();
        refreshTable();
        setShowAddedNotification(true);
      } catch (error: any) {
        setRequestError(error.response.data.message);
        setShowErrorNotification(true);
        console.error(error);
      }
    } else {
      setShowWarning(true);
    }
  };

  const handleRemoveDoc = (id: any) => {
    setAddedDocs(addedDocs.filter((doc: any) => doc.id !== id));
  };

  const getAvailableFolders = async () => {
    try {
      const response = await getFolders(matterId, parentId);

      setAvailableFolders(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  useEffect(() => {
    setAddedDocs([...selectedDocs]);
    if (open) getAvailableFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocs]);

  return (
    <Dialog onClose={handleClose} open={open} sx={leiaaAddToWrapperStyles}>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <Box sx={leiaaAddToDialogContentStyles()}>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: '#202020',
                ...sofiaProBoldFontStyles,
                fontSize: '18px',
                lineHeight: 'normal',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              Add to folder
            </Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <InputLabel
                  data-testid="matter-form-label-id"
                  htmlFor="Interview-Bundle-Name"
                  sx={{
                    ...karlaProRegularFontStyles,
                    paddingLeft: '12px',
                    fontSize: '14px',
                  }}
                >
                  Folder Name
                </InputLabel>
              </Box>

              <Select
                displayEmpty
                labelId="leiaa-simple-select-label"
                id="leiaa-simple-select"
                size="small"
                open={menuOpen}
                onClose={handleMenuClose}
                onOpen={handleMenuOpen}
                // eslint-disable-next-line react/no-unstable-nested-components
                IconComponent={() => (
                  <DropdownMenuIcon
                    isMenuOpen={menuOpen}
                    handleDropdownClientIconClick={handleMenuOpen}
                    isAdornment={false}
                  />
                )}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      ...matterInformationSelectPaperStyles,
                    },
                  },
                }}
                sx={matterInformationSelectStyles('40px !important', '100%')}
                value={value}
                onChange={handleChange}
              >
                <MenuItem disabled value="">
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#69707B',
                    }}
                  >
                    Select folder
                  </Typography>
                </MenuItem>
                {availableFolders.map((folder: any) => {
                  return (
                    <MenuItem key={folder.id} value={folder.id}>
                      {folder.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {availableFolders?.find((doc: any) => doc.id === value) &&
                (availableFolders?.find((doc: any) => doc.id === value) as any)
                  .name === 'Final Report' && (
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#69707B',
                    }}
                  >
                    * Only accepts a single file in pdf format
                  </Typography>
                )}
            </Box>

            <Box
              sx={{
                alignSelf: 'stretch',
                maxHeight: '140px',
                overflowY: 'scroll',
              }}
              className="scrollable-content"
            >
              {addedDocs.map((doc: any, index: number) => {
                if (doc.folder) {
                  return (
                    <Box
                      key={doc.id}
                      sx={{
                        borderRadius: '12px',
                        background: '#F0F8FD',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '16px',
                        marginBottom: '16px',
                        '& > p': {
                          ...karlaProRegularFontStyles,
                          fontSize: '14px',
                          fontWeight: '400',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ marginRight: '5px', color: '#949CA9' }}
                        >
                          {index + 1}.
                        </Typography>
                        <Typography sx={{ color: '#464B53' }}>
                          {doc.folder && (
                            <span style={{ color: '#949CA9 !important' }}>
                              Folder -
                            </span>
                          )}{' '}
                          {doc.name}
                        </Typography>
                        <IconButton
                          data-testid="clear-folder-id"
                          aria-label="clear folder"
                          sx={{
                            padding: '0px',
                            '&:hover': { background: 'transparent' },
                            '& > span': {
                              fontSize: '16px',
                            },
                            marginLeft: 'auto',
                          }}
                          onClick={() => handleRemoveDoc(doc.id)}
                        >
                          <span className="material-icons-round">close</span>
                        </IconButton>
                      </Box>
                    </Box>
                  );
                }
                return (
                  <Box
                    key={doc.id}
                    sx={{
                      borderRadius: '12px',
                      background: '#F0F8FD',
                      display: 'inline-flex',
                      width: '100%',
                      height: '38px',
                      padding: '16px',
                      marginBottom: '16px',
                      alignItems: 'center',
                      '& > p': {
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        fontWeight: '400',
                      },
                    }}
                  >
                    <Typography sx={{ marginRight: '5px', color: '#949CA9' }}>
                      {index + 1}.
                    </Typography>
                    <Typography sx={{ color: '#464B53' }}>
                      {doc.name}
                    </Typography>
                    <IconButton
                      data-testid="clear-doc-id"
                      aria-label="clear doc added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                        marginLeft: 'auto',
                      }}
                      onClick={() => handleRemoveDoc(doc.id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
            {showWarning && (
              <ErrorMessage type="error" message="Incorrect Information" />
            )}
            <Box sx={{ display: 'inherit', width: '100%', gap: '16px' }}>
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={onClose}
                >
                  <Typography>Cancel</Typography>
                </Button>
              </Box>
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={handleSubmit}
                >
                  <Typography>Add</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentAddToFolderDialog;
