import Keycloak from 'keycloak-js';

const initOptions = {
  checkLoginIframe: false,
  // enableLogging: true,
  pkceMethod: 'S256',
};

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

const keycloak = new Keycloak(keycloakConfig);

export { keycloak, initOptions };
