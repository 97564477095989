import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { matterDetailIntegrationConfigRequest } from '../../../api/matters';
import { LEIAAInput } from '../../inputs';
import ImanageMatterPicker from '../../imanage/ImanageMatterPicker';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import { secondaryButtonStyles } from '../../styles';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../matter-creation/matter-creation-steps/matter-information-step/styles';

interface MatterDetailIManageProps {
  matterData: any;
  matterId: any;
}

const MatterDetailIManage = ({
  matterData,
  matterId,
}: MatterDetailIManageProps) => {
  const [showUpdateNotification, setShowUpdateNotification] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmitImanageServerConfig: SubmitHandler<FieldValues> = async (
    data: any
  ) => {
    try {
      const integrationData = {
        ...data,
        integration: 'imanage',
      };
      if (matterId) {
        await matterDetailIntegrationConfigRequest(matterId, integrationData);
      }
      matterData.server = data.server;
      matterData.imanage_hostname = data.server;
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValue('server', matterData.imanage_hostname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBrowseMatters = async () => {
    await handleSubmit(onSubmitImanageServerConfig)();
    setOpen(true);
  };

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit(onSubmitImanageServerConfig)}
    >
      {showUpdateNotification && (
        <CreationNotification
          title="iManage settings updated!"
          subTitle=""
          showNotification={showUpdateNotification}
          handleCloseNotification={() => setShowUpdateNotification(false)}
        />
      )}
      <ImanageMatterPicker
        matterId={matterId}
        matterData={matterData}
        open={open}
        setOpen={setOpen}
        setShowUpdateNotification={setShowUpdateNotification}
      />
      <Box
        sx={{
          ...matterInformationFormWrapperStyles,
          width: '100%',
          alignItems: 'flex-end',
        }}
      >
        <Box sx={matterInformationInputWrapperStyles}>
          <Box>
            <LEIAAInput
              labelText="Server hostname"
              controllerName="server"
              control={control}
              inputPlaceholder="Ex. cloudimanage.com"
              inputWidth="100%"
              inputHeight="40px"
            />
          </Box>
          {matterData.imanage_matter_name && (
            <Box
              sx={{
                '& > div > div': {
                  borderRadius: '15px',
                },
              }}
            >
              <LEIAAInput
                labelText="iManage Matter"
                controllerName="null"
                inputValue={matterData.imanage_matter_name}
                control={control}
                inputPlaceholder=""
                inputWidth="100%"
                inputHeight="40px"
                disabled
                required={false}
              />
            </Box>
          )}

          <Stack
            direction="row"
            justifyContent="flex-end !important"
            alignItems="center"
            spacing={2}
            sx={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <Button
              variant="contained"
              sx={{ ...secondaryButtonStyles() }}
              onClick={handleBrowseMatters}
              disabled={!watch('server')}
            >
              <Typography>
                Authenticate and{' '}
                {matterData.imanage_matter_name
                  ? 'Change Matter'
                  : 'Browse Matters'}
              </Typography>
              <span className="material-icons-round">settings_remote</span>
            </Button>
          </Stack>
        </Box>
      </Box>
    </form>
  );
};

export default MatterDetailIManage;
