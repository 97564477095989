import React, { useContext, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import {
  mainLoginContainerStyles,
  mainFormWrapperStyles,
} from './styles/login';
import LEIAALogo1 from '../assets/LEIAALogo1.png';
import LEIAAContext from '../context/context';

const Logout = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { user, setUser } = useContext(LEIAAContext);

  useEffect(() => {
    const handleLogout = async () => {
      try {
        setUser({
          username: '',
          email: '',
          first_name: '',
          last_name: '',
          role: '',
          date_joined: '',
          phone_number: '',
          organisation: '',
        });
        localStorage.clear();
        sessionStorage.clear();

        if (keycloak?.authenticated) {
          await keycloak.logout();
        }

        localStorage.removeItem('authToken');
        navigate('/login');
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, navigate]);

  return (
    <Box sx={mainLoginContainerStyles}>
      <Box sx={mainFormWrapperStyles}>
        <Box>
          <Box>
            <img
              src={LEIAALogo1}
              style={{ width: '228.12px', height: '99.41px' }}
              alt="LEIAALogo1"
            />
          </Box>
          <Typography variant="h6" sx={{ margin: '20px 0' }}>
            Logging you out...
          </Typography>
          <CircularProgress />
          <Button
            sx={{ marginTop: '20px' }}
            onClick={() => navigate('/login')}
            variant="outlined"
          >
            Return to Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Logout;
