/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import DocumentChat from '../../llm/DocumentChat';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import FolderFileActions from './FolderFileActions';
import FolderFileInformation from './FolderFileInformation';
import { CustomTabPanel, a11yProps } from '../lists/Tabs';
import KnowledgeGraph from '../../llm/KnowledgeGraph';

interface FolderFileInformationTabsProps {
  item: any;
  folderPermissionsHandler?: any;
  folderUploadLinkHandler?: any;
  handleDownloadFile?: any;
  addToFraudDetectionHandler?: any;
  renameHandler?: any;
  fileDownloadLinkHandler?: any;
}

const FolderFileInformationTabs = ({
  item,
  folderPermissionsHandler,
  folderUploadLinkHandler,
  handleDownloadFile,
  addToFraudDetectionHandler,
  renameHandler,
  fileDownloadLinkHandler,
}: FolderFileInformationTabsProps) => {
  const [tabValue, setTabValue] = useState(0);

  const displayChatTab =
    process.env.REACT_APP_DOCUMENT_CHAT_ENABLED === 'true' &&
    !item.folder &&
    item.llm_content;

  const displayKnowledgeGraphTab =
    process.env.REACT_APP_DOCUMENT_KNOWLEDGE_GRAPH_ENABLED === 'true' &&
    !item.folder &&
    item.llm_graph_content;

  // Function to calculate the correct tab index based on visibility of tabs
  const getTabIndex = (baseIndex: number) => {
    let actualIndex = baseIndex;

    // If chat tab should be hidden and index is after chat tab position
    if (!displayChatTab && baseIndex >= 0) {
      actualIndex -= 1;
    }

    // If knowledge graph tab should be hidden and index is after knowledge graph position
    if (!displayKnowledgeGraphTab && baseIndex >= 1) {
      actualIndex -= 1;
    }

    return Math.max(0, actualIndex);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          width: '100%',
          borderBottom: '1px solid #949CA9',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="file information tabs"
          sx={{
            flexGrow: 1,
            '& > div': {
              '& > div': {
                '& > button': {
                  display: 'flex',
                  flex: 1,
                  padding: '8px 0',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '24px 24px 0px 0px',
                  '&.Mui-selected': {
                    backgroundColor: '#F5FAFD',
                    color: '#0053FF',
                  },
                  color: '#69707B',
                  textTransform: 'none',
                  ...karlaProRegularFontStyles,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '22px',
                },
              },
              '& > span.MuiTabs-indicator': {
                backgroundColor: '#0053FF',
              },
            },
          }}
        >
          {displayChatTab && <Tab label="Ask Leiaa" {...a11yProps(0)} />}
          {displayKnowledgeGraphTab && (
            <Tab label="Knowledge Graph" {...a11yProps(1)} />
          )}
          <Tab
            label="Information"
            {...a11yProps(
              displayChatTab
                ? displayKnowledgeGraphTab
                  ? 2
                  : 1
                : displayKnowledgeGraphTab
                ? 0
                : 0
            )}
          />
          <Tab
            label="Actions"
            {...a11yProps(
              displayChatTab ? (displayKnowledgeGraphTab ? 3 : 2) : 1
            )}
          />
        </Tabs>
      </Box>

      {/* Tab Panels */}
      {displayChatTab && (
        <CustomTabPanel value={tabValue} index={0}>
          <Box sx={{ padding: '16px 0' }}>
            <DocumentChat document={item} />
          </Box>
        </CustomTabPanel>
      )}

      {displayKnowledgeGraphTab && (
        <CustomTabPanel value={tabValue} index={displayChatTab ? 1 : 0}>
          <Box sx={{ padding: '16px 0' }}>
            <KnowledgeGraph content={item.llm_graph_content} />
          </Box>
        </CustomTabPanel>
      )}

      <CustomTabPanel value={tabValue} index={getTabIndex(2)}>
        <FolderFileInformation item={item} />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={getTabIndex(3)}>
        <FolderFileActions
          item={item}
          folderPermissionsHandler={folderPermissionsHandler}
          folderUploadLinkHandler={folderUploadLinkHandler}
          handleDownloadFile={handleDownloadFile}
          addToFraudDetectionHandler={addToFraudDetectionHandler}
          renameHandler={renameHandler}
          fileDownloadLinkHandler={fileDownloadLinkHandler}
        />
      </CustomTabPanel>
    </>
  );
};

export default FolderFileInformationTabs;
