import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Popover,
  Typography,
} from '@mui/material';
import { motion } from 'motion/react';
import { useEffect, useState } from 'react';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';

interface MatterDetailTimelineEventTypePickerProps {
  eventTypes: string[];
  handleFetchEvents: any;
  wantedTypes: string[];
  setWantedTypes: any;
}

const MatterDetailTimelineEventTypePicker = ({
  eventTypes,
  handleFetchEvents,
  wantedTypes,
  setWantedTypes,
}: MatterDetailTimelineEventTypePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [checked, setChecked] = useState<boolean[]>(
    new Array(eventTypes.length).fill(true)
  );
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const triggerPopup = () => {
    if (showPopup) return;
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 1500);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeAll = () => {
    const allChecked = checked.every((item) => item);
    const newChecked = new Array(eventTypes.length).fill(!allChecked);
    setChecked(newChecked);
    setWantedTypes(allChecked ? [] : eventTypes);
    setPopupMessage(
      allChecked
        ? 'All event types are now hidden'
        : 'All event types are now displayed'
    );
    triggerPopup();
  };

  const handleChangeType = (index: number) => {
    const newChecked = checked.slice();
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
    const newWantedTypes = newChecked
      .map((isChecked, i) => (isChecked ? eventTypes[i] : null))
      .filter(Boolean) as string[];
    setWantedTypes(newWantedTypes);

    const toggledEventType = eventTypes[index];
    const isDisplayed = newChecked[index];
    setPopupMessage(
      isDisplayed
        ? `${toggledEventType} is now visible`
        : `${toggledEventType} is now hidden`
    );
    triggerPopup();
  };

  useEffect(() => {
    handleFetchEvents(wantedTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantedTypes]);

  return (
    <Box>
      {showPopup && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          style={{
            position: 'fixed',
            top: '15%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '12px 24px',
            borderRadius: '8px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            border: '2px solid rgb(211 236 247)',
            zIndex: 9999,
          }}
        >
          <Typography
            sx={{
              color: '#464B53',
              fontSize: '14px',
              ...karlaProRegularFontStyles,
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
            }}
          >
            {popupMessage}
          </Typography>
        </motion.div>
      )}

      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '16px',
          ...karlaProRegularFontStyles,
          fontWeight: '500',
          letterSpacing: '0.2px',
          cursor: 'pointer',
          color: '#0053FF',
          '&:hover': {
            color: '#0B25B0',
            '& > span': {
              color: '#0B25B0',
            },
          },
          '& > span': {
            color: '#0053FF',
            fontSize: '30px',
          },
        }}
        onClick={handleClick}
      >
        Filters
        <span className="material-icons-round">arrow_drop_down</span>
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          boxShadow: 'none !important',
          border: '1px solid #D3ECF7',
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none !important',
          },
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Box
            style={{
              padding: '5px 0px 5px 10px',
              border: '1px solid #D3ECF7',
              borderRadius: '5px',
            }}
          >
            <FormControlLabel
              label={
                <InputLabel
                  sx={{
                    color: '#464B53',
                    fontSize: '14px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                    display: 'flex',
                    marginRight: 'auto',
                  }}
                >
                  All
                </InputLabel>
              }
              control={
                <motion.div
                  whileTap={{ scale: 1.1 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <Checkbox
                    checked={checked.every((item) => item)}
                    onChange={handleChangeAll}
                  />
                </motion.div>
              }
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
              {eventTypes.map((type, index) => (
                <motion.div
                  key={type}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <FormControlLabel
                    label={
                      <InputLabel
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#464B53',
                          fontSize: '14px',
                          ...karlaProRegularFontStyles,
                          lineHeight: '22px',
                          marginRight: 'auto',
                        }}
                      >
                        {type}
                      </InputLabel>
                    }
                    control={
                      <motion.div
                        whileTap={{ scale: 1.1 }}
                        whileHover={{ scale: 1.05 }}
                      >
                        <Checkbox
                          checked={checked[index]}
                          onChange={() => handleChangeType(index)}
                          sx={{
                            transition: 'all 0.3s ease',
                            '&.Mui-checked': {
                              color: '#0B25B0',
                            },
                          }}
                        />
                      </motion.div>
                    }
                  />
                </motion.div>
              ))}
            </Box>
          </Box>
        </motion.div>
      </Popover>
    </Box>
  );
};

export default MatterDetailTimelineEventTypePicker;
