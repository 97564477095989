/* eslint-disable no-param-reassign */
import {
  GetTempTokenResponse,
  LoginData,
  LoginResponse,
  ResetPasswordData,
  TwoFactorAuthData,
  UserDetailsResponse,
  User,
} from '../types/auth';
import { transformObjectKeysToSnakeCase } from '../utils/utils';
import api from './index';

async function loginRequest(data: LoginData): Promise<LoginResponse> {
  const response = await api.post('/login', data);

  return {
    statusCode: response.status,
    tempToken: response.data.temp_token,
    message: response.data.message,
    reportingAuthToken: response.data.reporting_auth_token,
  };
}

async function userDetailsRequest(
  method: 'GET' | 'PATCH',
  data?: User,
  userId?: string
): Promise<UserDetailsResponse | undefined> {
  const endpoint = `/user/${userId}`;

  let transformedData: Partial<User> = {};
  if (data) {
    transformedData = transformObjectKeysToSnakeCase(data);
  }

  const response = await api.request({
    method,
    url: endpoint,
    data: transformedData,
  });

  if (method === 'GET') {
    return {
      user: {
        username: response.data.username,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
        role: response.data.role,
        date_joined: response.data.date_joined,
        phone_number: response.data.phone_number,
        organisation: response.data.organisation,
        id: response.data.id,
      },
    };
  }

  return undefined;
}

async function userMeDetailsRequest(
  method: 'GET' | 'PATCH',
  data?: User
): Promise<UserDetailsResponse> {
  const endpoint = '/me';

  let transformedData: Partial<User> = {};
  if (data) {
    transformedData = transformObjectKeysToSnakeCase(data);
  }

  const response = await api.request({
    method,
    url: endpoint,
    data: transformedData,
  });

  return {
    user: {
      username: response.data.user.username,
      first_name: response.data.user.first_name,
      last_name: response.data.user.last_name,
      email: response.data.user.email,
      role: response.data.user.role,
      date_joined: response.data.user.date_joined,
      phone_number: response.data.user.phone_number,
      organisation: response.data.user.organisation,
      id: response.data.user.id,
    },
  };
}

async function userPermissionsRequest(matterId?: any) {
  let response;
  if (matterId) {
    response = await api.get(`/me-permissions`, {
      params: { matter_id: matterId },
    });
  } else {
    response = await api.get(`/me-permissions`);
  }

  return response;
}

// async function userPermissionsRequest() {
//   const { data: permissions } = await api.get('/me-permissions');
//   return permissions;
// }

async function twoFactorAuthenticationRequest(
  twoFactorData: TwoFactorAuthData
) {
  const response = await api.post('/two-factor-auth', twoFactorData);
  localStorage.setItem('authToken', response.data.auth_token);
}

async function getTempTokenRequest(
  email: string
): Promise<GetTempTokenResponse> {
  const response = await api.get('/temp-token', { params: { email } });

  return {
    tempToken: response.data.temp_token,
    message: response.data.message,
  };
}

async function resetPasswordRequest(
  resetPasswordData: ResetPasswordData
): Promise<string> {
  const response = await api.patch('/reset-password', resetPasswordData);

  return response.data.message;
}

async function handleKeycloakTokenRequest(token: string, refreshToken: string) {
  const response = await api.patch('/keycloak-login', {
    token,
    refresh_token: refreshToken,
  });
  return response;
}

async function handleKeycloakRefreshTokenRequest(
  token: string,
  refreshToken: string
) {
  const response = await api.put('/keycloak-login', {
    token,
    refresh_token: refreshToken,
  });
  return response;
}

export {
  loginRequest,
  userDetailsRequest,
  userPermissionsRequest,
  userMeDetailsRequest,
  twoFactorAuthenticationRequest,
  getTempTokenRequest,
  resetPasswordRequest,
  handleKeycloakTokenRequest,
  handleKeycloakRefreshTokenRequest,
};
