import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import {
  handleKeycloakTokenRequest,
  twoFactorAuthenticationRequest,
} from '../api/auth';
import LEIAALogo1 from '../assets/LEIAALogo1.png';
import {
  mainFormWrapperStyles,
  mainLoginContainerStyles,
} from './styles/login';
import { loginFormButtonStyles } from '../components/login/styles';
import TwoFactorAuthForm from '../components/login/TwoFactorAuthForm';
import { LoginError } from '../types/authProps';
import { TwoFactorAuthData } from '../types/auth';
import { createErrorObject } from '../utils/utils';

const Login = () => {
  const [forbiddenAccess, setForbiddenAccess] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout | null>(null);
  const [isTwoFactorAuth, setIsTwoFactorAuth] = useState(false);
  const [isTwoFactorAuthComplete, setIsTwoFactorAuthComplete] = useState(false);
  const [errorsObject, setErrorsObject] = useState<LoginError>();
  const [tempToken, setTempToken] = useState<string | null>('');

  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();

  const requestToken = async () => {
    try {
      if (isTwoFactorAuthComplete) navigate('/');
      if (!keycloak?.token) return;
      const authToken = keycloak.token;
      const response = await handleKeycloakTokenRequest(authToken);
      if (response.status === 200) {
        localStorage.setItem('authToken', authToken);
        navigate('/');
      } else if (response.status === 202) {
        // Username-Password based user, trigger 2FA
        setTempToken(response.data.tempToken);
        setIsTwoFactorAuth(true);
      }
    } catch (error) {
      if ((error as any).response?.status === 403) {
        setForbiddenAccess(true);
        const timer = setTimeout(() => {
          keycloak?.logout();
        }, countdown * 1000);
        setLogoutTimer(timer);
      }
    }
  };

  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      requestToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, keycloak?.authenticated, isTwoFactorAuthComplete]);

  useEffect(() => {
    if (forbiddenAccess && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [forbiddenAccess, countdown]);

  const {
    handleSubmit: handleSubmitTwoFactor,
    control: controlTwoFactor,
    watch: watchTwoFactor,
  } = useForm();

  const onSubmitTwoFactor: SubmitHandler<
    TwoFactorAuthData | FieldValues
  > = async (data) => {
    try {
      // eslint-disable-next-line no-param-reassign
      data.temp_token = tempToken;
      data.source = 'keycloak';
      await twoFactorAuthenticationRequest(data as TwoFactorAuthData);
      setIsTwoFactorAuth(false);
      setIsTwoFactorAuthComplete(true);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        const errors = createErrorObject(
          error.response.data.message,
          'two_factor_auth_code'
        );
        setErrorsObject(errors);
      }
    }
  };

  const renderForm = () => {
    switch (true) {
      case isTwoFactorAuth:
        return (
          <TwoFactorAuthForm
            handleSubmit={handleSubmitTwoFactor}
            onSubmit={onSubmitTwoFactor}
            control={controlTwoFactor}
            watch={watchTwoFactor}
            errors={errorsObject}
          />
        );
      default:
        return (
          <>
            {forbiddenAccess && (
              <Box>
                <Alert severity="error">
                  Forbidden Access, please contact an admin for access.
                  Redirecting you in {countdown} seconds
                </Alert>
              </Box>
            )}
            <Box>
              <Button
                variant="contained"
                type="button"
                sx={{
                  ...loginFormButtonStyles('Restricted Area'),
                  margin: '0 !important',
                }}
                onClick={() => keycloak?.login()}
                disabled={forbiddenAccess}
              >
                Restricted Area
              </Button>
            </Box>
          </>
        );
    }
  };

  return (
    <Box sx={mainLoginContainerStyles}>
      <Box sx={mainFormWrapperStyles}>
        <Box>
          <Box>
            <img
              src={LEIAALogo1}
              style={{ width: '228.12px', height: '99.41px' }}
              alt="LEIAALogo1"
            />
            <img
              src={process.env.REACT_APP_CLIENT_LOGO_URL}
              style={{
                width: `calc(228.12px * ${process.env.REACT_APP_CLIENT_LOGO_RATIO})`,
                height:
                  process.env.REACT_APP_CLIENT_LOGO_FULLHEIGHT === 'true'
                    ? '100%'
                    : `calc(99.41px * ${process.env.REACT_APP_CLIENT_LOGO_RATIO})`,
                marginLeft: '15px',
                borderLeft: '1px solid #e5e5e5',
              }}
              alt="Clients logo"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '24px',
              width: '352px',
              '> form': {
                width: '100%',
              },
            }}
          >
            {renderForm()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
