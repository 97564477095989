import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  InputLabel,
  Button,
  FormControlLabel,
  Radio,
  TextField,
  IconButton,
  Dialog,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  matterInformationNestedInputWrapperStyles,
  matterQuestionnaireRadioGroupWrapperStyles,
  matterQuestionnaireUploadAreaWrapperStyles,
  matterUploadAreaAndButtonsWrapperStyles,
} from '../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  LEIAADialog,
  LEIAAInput,
  LEIAARadioButtons,
  LEIAASelect,
  LEIAAUploadFileArea,
} from '../../components/inputs';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  tertiaryButtonStyles,
} from '../../components/styles';
import { Jurisdiction, SubDivisonOptionType } from '../../types/matters';
import { countriesRequest, subDivisionsRequest } from '../../api/matters';
import { InvestigationOptionType } from '../../types/wiki';
import { investigationTopicsRequest } from '../../api/wiki';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../styles/textStyles';
import { currencies } from '../../components/matters/matter-creation/matter-creation-steps/matter-information-step/MatterInformation';
import { MatterAddedDocumentsInformation } from '../../components/matters/matter-creation/sidepanel-information';
import ShowPassword from '../../components/login/form/ShowPassword';
import { loginFormInputStyles } from '../../components/login/styles';
import PasswordInputInstructions from '../../components/login/form/PasswordInputInstructions';
import { submitConcernReportRequest } from '../../api/whistleblower';
import NotificationToast from '../../components/shared/toast/NotificationToast';
import { leiaaDialogContentStylesReporting } from '../../components/matters/matter-creation/styles';
import FixedContextualHelpWrapper from '../../components/llm/FixedContextualHelpWrapper';

const whistleblowingTopicHelpPrompt = `You are a helpful assistant for the Whistleblowing Reporting form.
Your role is to provide guidance on completing all sections of the form accurately and comprehensively.

Help users with the following form fields by their exact labels:
- "Location where incident occurred": Guide users to choose the country where the incident occurred.
- "Issue Type": Help users identify the most appropriate issue type for their concern.
- "Organisation": Explain that this field is auto-filled and cannot be changed.
- "Sub-Division": Assist users in selecting the correct organizational division.
- "What is your relationship to [organization]?": Help users clarify their connection to the organization.
- "Please specify relationship": Help users who selected "Other" to describe their relationship.
- "Do you wish to remain anonymous for this report?": Explain the implications of anonymous vs. identified reporting.
- "First Name"/"Last Name": Guide on providing personal information if not anonymous.
- "Enter phone number"/"Email address": Help with contact information for non-anonymous reports.
- "Best time to communicate with you": Suggest how to specify preferred contact times.
- "Please identify the person(s), if any, engaged in this behaviour": Guide users on identifying persons involved.
- "Do you suspect or know that a supervisor or management is involved?": Help explain management involvement.
- "Is Management aware of this issue?": Assist with details about management awareness.
- "Please provide an estimation of any monetary value relating to the allegation": Guide on estimating financial impact.
- "Where did this incident or violation occur?": Help describe location with appropriate detail.
- "Please provide the specific or approximate time this incident occurred?": Guide on timing details.
- "How long do you think this problem has been going on?": Help select the appropriate timeframe.
- "How did you become aware of this violation?": Assist with explaining how they learned of the violation.
- "Please identify any persons who have attempted to conceal this problem and the steps they took to conceal it?": Help describe any cover-up attempts.
- "If you have documents or files that support your report, please upload them here": Guide on supporting documents.
- "Please provide full details regarding the alleged violation...": Help provide comprehensive incident details.
- "Please choose a secure password": Ensure users create a secure, memorable password.

For specific issue topics:
- Financial misconduct: "Accounting, audit and other financial impropriety", "Fraud, theft or other dishonest activities"
- Ethical violations: "Bribery or Corruption", "Violation of corporate policy or procedure"
- Workplace issues: "Discrimination or harassment", "Retaliation"
- Safety concerns: "Health and safety violations", "Environmental issues"
- Other misconduct: "Conflict of interest", "Data protection or privacy violations"

Remember that users will see the form fields with these exact labels, so refer to them this way when providing guidance. Answer any questions to help users submit a complete, accurate report while maintaining appropriate confidentiality.`;

const welcomeMessage =
  'Welcome to the Whistleblowing Reporting Assistant! I can help you complete all sections of this form with clear guidance.\n\n' +
  'Ask me about specific fields, what information to include, or how to describe your concerns effectively.\n\n' +
  "Your report is important, and I'm here to make the submission process as straightforward as possible.";

interface FileWithId extends File {
  id: string;
  uploaded: boolean;
}

const ReportingOnlineForm = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [anonymous, setAnonymous] = useState<boolean | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>();
  const [topics, setTopics] = useState<InvestigationOptionType[]>();
  const [subDivisons, setSubDivisons] =
    useState<readonly SubDivisonOptionType[]>();
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [repeatedFiles, setRepeatedFiles] = useState<FileWithId[]>([]);
  const templateReport = watch('files');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState({ password: '' });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [engagedPersons, setEngagedPersons] = useState<any>([]);
  const [formKey, setFormKey] = useState<string | null>(null);
  const [openKeyOpenDialog, setOpenKeyOpenDialog] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultOrg = process.env.REACT_APP_DEFAULT_ORGANISATION;
  const defaultWebsite = process.env.REACT_APP_DEFAULT_WEBSITE;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      data.engagedPersons = watch('engagedPersons');

      const response = await submitConcernReportRequest(data);
      setFormKey(response);
      setOpenKeyOpenDialog(true);

      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const downloadKey = () => {
    const currentDate = new Date();
    const currentTime = currentDate.toLocaleDateString();

    if (formKey) {
      const link = document.createElement('a');
      const file = new Blob([formKey], { type: 'text/plain' });
      link.href = URL.createObjectURL(file);
      link.download = `my_report_key_${currentTime}.txt`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleFetchCountries = async () => {
    try {
      const response = await countriesRequest();
      setJurisdictions(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchSubDivisions = async () => {
    try {
      const response = await subDivisionsRequest(defaultOrg);
      setSubDivisons(response as SubDivisonOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchInvestigationTopics = async () => {
    try {
      const response = await investigationTopicsRequest();
      setTopics(response as InvestigationOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleSetJurisdiction = (event: React.ChangeEvent<{ value: any }>) => {
    const countryCode = event.target.value;
    const jurisdiction = jurisdictions?.find(
      (j) => Object.keys(j)[0] === countryCode
    );
    const jurisdictionName = jurisdiction ? Object.values(jurisdiction)[0] : '';

    setValue('countryName', jurisdictionName);
  };

  const handleSetTopic = (event: React.ChangeEvent<{ value: any }>) => {
    const pickedTopic = topics?.find(
      (topic: InvestigationOptionType) => topic.id === event.target.value
    );

    setValue('topicName', pickedTopic?.name);
  };

  const handleSetSubdivision = (event: React.ChangeEvent<{ value: any }>) => {
    const pickedTopic = topics?.find(
      (topic: InvestigationOptionType) => topic.id === event.target.value
    );

    setValue('topicName', pickedTopic?.name);
  };

  const handleSetMatterCurrency = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    setValue('currency', event.target.value);
  };

  const handleChangeAnonymous = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if ((event.target as HTMLInputElement).value === 'no') {
      setValue('anonymous', 'no');
      setAnonymous(false);
    } else {
      setValue('anonymous', 'yes');
      setAnonymous(true);
    }
  };

  const handleUploadFiles = (fileList: FileList) => {
    let existingFiles: FileWithId[] = [];

    if (fileList.length === 0) return;

    if (templateReport && templateReport.length > 0) {
      existingFiles = templateReport.filter((md: FileWithId) =>
        Array.from(fileList).some(
          (selectedFile) => md.name === selectedFile.name
        )
      );
      setRepeatedFiles(existingFiles);
    }

    if (existingFiles.length > 0) {
      setOpenAlertOpenDialog(true);
    } else {
      const filesArray: FileWithId[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        const fileId = uuidv4();
        const fileWithId = Object.assign(file, { id: fileId, uploaded: false });
        filesArray.push(fileWithId);
      }
      setValue('files', [...(templateReport || []), ...filesArray]);
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.target.files as FileList;
    handleUploadFiles(fileList);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const fileList = e.dataTransfer.files;
      handleUploadFiles(fileList);
    }
  };

  const handleRemoveFile = (id: string | number) => {
    setValue(
      'files',
      (templateReport || []).filter((file: FileWithId) => file.id !== id)
    );
    if (typeof id === 'number') {
      const filesIdsToDelete = watch('files_to_delete') || [];
      setValue('files_to_delete', [...filesIdsToDelete, id]);
    }
  };

  const handleValidate = (value?: string) => {
    if (!value) {
      return true;
    }
    if (typeof value === 'string' && isValidPhoneNumber(value)) {
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }
    return false;
  };

  const addNewEngagedUser = () => {
    setEngagedPersons([
      ...engagedPersons,
      {
        first_name: '',
        last_name: '',
        job_title: '',
        id: uuidv4(),
      },
    ]);
  };

  const removeEngagedUser = (id: number) => {
    const updatedEngagedPersons = engagedPersons.filter(
      (person: any) => person.id !== id
    );

    setEngagedPersons(updatedEngagedPersons);
  };

  useEffect(() => {
    setValue('organisation', defaultOrg);
    handleFetchCountries();
    handleFetchInvestigationTopics();
    handleFetchSubDivisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: { xs: '20px', md: '40px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: { xs: '20px', md: '40px' },
        }}
      >
        {showNotification && (
          <NotificationToast
            showNotification={showNotification}
            requestError={requestError}
            handleCloseNotification={() => setShowNotification(false)}
          />
        )}
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              width: '100%',
              maxWidth: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: '608px' },
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '24px',
                '& > p': {
                  color: '#202020',
                  fontSize: { xs: '12px', md: '14px' },
                  ...karlaProRegularFontStyles,
                  lineHeight: '22px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '32px',
                  alignSelf: 'stretch',

                  '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '32px',
                    alignSelf: 'stretch',
                    '& > div': {
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '8px',
                      alignSelf: 'stretch',
                      '& > label.MuiFormLabel-root': {
                        display: 'flex',
                        padding: '0px 12px',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        color: '#464B53',
                        fontSize: { xs: '12px', md: '14px' },
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                      },
                      '& > div.MuiFormControl-root': {
                        margin: '0px',
                        padding: '0px',
                      },
                    },
                  },
                  '& > div > .engaged-box-report': {
                    '@media (max-width: 600px)': {
                      flexDirection: 'column',
                    },
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '15px',
                    padding: '15px',
                    background: '#f1fafd',
                    borderRadius: '24px',
                    '& > div': {
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '8px',
                      alignSelf: 'stretch',
                      '& > label.MuiFormLabel-root': {
                        display: 'flex',
                        padding: '0px 12px',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        color: '#464B53',
                        fontSize: { xs: '12px', md: '14px' },
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                      },
                      '& > div.MuiFormControl-root': {
                        background: 'white',
                        borderRadius: '24px',
                        margin: '0px',
                        padding: '0px',
                      },
                    },
                    '& > div:last-child': {
                      marginLeft: 'auto',
                      width: 'unset !important',
                    },
                  },
                }}
              >
                <Box>
                  <LEIAASelect
                    labelText="Location where incident occurred"
                    control={control}
                    controllerName="country"
                    inputPlaceholder="Select country"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '300px' }}
                    handleChange={handleSetJurisdiction}
                    required
                  >
                    {jurisdictions?.map((j) => {
                      const countryCode = Object.keys(j)[0];
                      const countryName = Object.values(j)[0];

                      return (
                        <MenuItem key={countryCode} value={countryCode}>
                          {countryName}
                        </MenuItem>
                      );
                    })}
                  </LEIAASelect>
                </Box>
                <Box>
                  <LEIAASelect
                    labelText="Issue Type"
                    control={control}
                    controllerName="topic"
                    inputPlaceholder="Select an issue type"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '300px' }}
                    handleChange={handleSetTopic}
                    required
                  >
                    {topics?.map((topic) => (
                      <MenuItem key={topic.id} value={topic.id}>
                        {topic.name}
                      </MenuItem>
                    ))}
                  </LEIAASelect>
                </Box>
                <Box>
                  <LEIAAInput
                    labelText="Organisation"
                    controllerName="organisation"
                    control={control}
                    inputPlaceholder="Enter username"
                    inputWidth="100%"
                    inputHeight="40px"
                    disabled
                    required={false}
                  />
                </Box>
                <Box>
                  <LEIAASelect
                    labelText="Sub-Division"
                    control={control}
                    controllerName="subdivision"
                    inputPlaceholder="Select Sub-Division"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '300px' }}
                    handleChange={handleSetSubdivision}
                    required
                  >
                    {subDivisons?.map((subDivison: SubDivisonOptionType) => {
                      return (
                        <MenuItem key={subDivison.name} value={subDivison.name}>
                          {subDivison.name}
                        </MenuItem>
                      );
                    })}
                  </LEIAASelect>
                </Box>
                <Box>
                  <LEIAASelect
                    labelText={`What is your relationship to ${defaultOrg}?`}
                    controllerName="relationship"
                    control={control}
                    inputPlaceholder="Select relationship"
                    inputHeight="40px"
                    inputWidth="100%"
                  >
                    <MenuItem value="Employee">Employee</MenuItem>
                    <MenuItem value="Contractor">Contractor</MenuItem>
                    <MenuItem value="Consultant">Consultant</MenuItem>
                    <MenuItem value="Former Employee">Former Employee</MenuItem>
                    <MenuItem value="Supplier">Supplier</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </LEIAASelect>
                  {watch('relationship') === 'Other' && (
                    <LEIAAInput
                      labelText="Please specify relationship"
                      controllerName="otherRelationship"
                      control={control}
                      inputPlaceholder="Specify relationship"
                      inputWidth="100%"
                      inputHeight="40px"
                      required
                    />
                  )}
                </Box>
                <Box sx={matterUploadAreaAndButtonsWrapperStyles}>
                  <Box sx={matterInformationNestedInputWrapperStyles(true)}>
                    <Typography>Anonymous Reporting</Typography>
                    <LEIAARadioButtons
                      labelText="Do you wish to remain anonymous for this report?"
                      controllerName="anonymous"
                      control={control}
                      formControlStyles={
                        matterQuestionnaireRadioGroupWrapperStyles
                      }
                      customHandleChange={handleChangeAnonymous}
                      required
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio required />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio required />}
                        label="No"
                      />
                    </LEIAARadioButtons>
                  </Box>
                </Box>
                {anonymous != null && !anonymous && (
                  <>
                    <Box>
                      <LEIAAInput
                        labelText="First Name"
                        controllerName="first_name"
                        control={control}
                        inputPlaceholder="Enter first name"
                        inputWidth="100%"
                        inputHeight="40px"
                        required={anonymous != null && !anonymous}
                      />
                    </Box>
                    <Box>
                      <LEIAAInput
                        labelText="Last Name"
                        controllerName="last_name"
                        control={control}
                        inputPlaceholder="Enter last name"
                        inputWidth="100%"
                        inputHeight="40px"
                        required={anonymous != null && !anonymous}
                      />
                    </Box>
                    <Box>
                      <Controller
                        name="phone_number"
                        defaultValue=""
                        control={control}
                        rules={{
                          validate: (value) => handleValidate(value),
                        }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Box>
                              <InputLabel
                                data-testid="matter-form-label-id"
                                htmlFor="Phone number"
                              >
                                Enter phone number
                              </InputLabel>
                              <PhoneInput
                                value={value}
                                onChange={onChange}
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="GB"
                                id="phone-input"
                              />
                              <InputLabel
                                data-testid="matter-form-label-id"
                                htmlFor="Phone number"
                                style={{ color: 'red' }}
                              >
                                {errors.phone_number
                                  ? 'Please enter a correct phone number, this phone number is invalid!'
                                  : null}
                              </InputLabel>
                            </Box>
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      <LEIAAInput
                        labelText="Email address"
                        controllerName="email"
                        control={control}
                        inputPlaceholder="Enter email address"
                        inputWidth="100%"
                        inputHeight="40px"
                        type="email"
                        required={anonymous != null && !anonymous}
                      />
                    </Box>
                    <Box>
                      <LEIAAInput
                        labelText="Best time to communicate with you"
                        controllerName="bestTimeCommunicate"
                        control={control}
                        inputPlaceholder="Enter answer"
                        inputWidth="100%"
                        inputHeight="80px"
                        required={anonymous != null && !anonymous}
                        multiline
                        customClassName="scrollable-content"
                      />
                    </Box>
                  </>
                )}
                <Box>
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '-20px',
                    }}
                  >
                    *Please identify the person(s), if any, engaged in this
                    behaviour
                  </Typography>
                  {engagedPersons.map((person: any) => {
                    return (
                      <Box
                        key={`engaged-b-${person.id}`}
                        className="engaged-box-report"
                      >
                        <LEIAAInput
                          labelText="First Name"
                          controllerName={`engagedPersons[${person.id}].first_name`}
                          control={control}
                          inputPlaceholder="Enter first name"
                          inputWidth="100%"
                          inputHeight="40px"
                          required
                          key={`engaged-f-n-${person.id}`}
                        />
                        <LEIAAInput
                          labelText="Last Name"
                          controllerName={`engagedPersons[${person.id}].last_name`}
                          control={control}
                          inputPlaceholder="Enter last name"
                          inputWidth="100%"
                          inputHeight="40px"
                          required
                          key={`engaged-l-n-${person.id}`}
                        />
                        <LEIAAInput
                          labelText="Job Title"
                          controllerName={`engagedPersons[${person.id}].job_title`}
                          control={control}
                          inputPlaceholder="Enter job title"
                          inputWidth="100%"
                          inputHeight="40px"
                          required
                          key={`engaged-j-b-${person.id}`}
                        />

                        <Box key={`engaged-delete-${person.id}`}>
                          <IconButton
                            onClick={() => removeEngagedUser(person.id)}
                            sx={{ '& > span': { color: '#464B53' } }}
                          >
                            <span className="material-icons-round">close</span>
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}

                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        ...secondaryButtonStyles(),
                        height: '30px',
                        padding: '8px 16px',
                        gap: '8px',
                      }}
                      onClick={() => addNewEngagedUser()}
                    >
                      <span className="material-icons-round">add</span>
                      <Typography>Add new person</Typography>
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <LEIAASelect
                    labelText="Do you suspect or know that a supervisor or management is involved?"
                    controllerName="managementSuspect"
                    control={control}
                    inputPlaceholder="Select answer"
                    inputHeight="40px"
                    inputWidth="100%"
                    required
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Do not know">Do not know</MenuItem>
                    <MenuItem value="Do not wish to disclose">
                      Do not wish to disclose
                    </MenuItem>
                  </LEIAASelect>
                  {(watch('managementSuspect') === 'Yes' ||
                    watch('managementSuspect') === 'Do not know') && (
                    <LEIAAInput
                      labelText={
                        watch('managementSuspect') === 'Yes'
                          ? 'Please provide details of supervisor or management involvement'
                          : 'Please provide details (Optional)'
                      }
                      controllerName="managementSuspectAnswer"
                      control={control}
                      inputPlaceholder="Provide details"
                      inputWidth="100%"
                      inputHeight="80px"
                      required={watch('managementSuspect') === 'Yes'}
                      multiline
                      customClassName="scrollable-content"
                    />
                  )}
                </Box>
                <Box>
                  <LEIAASelect
                    labelText="Is Management aware of this issue?"
                    controllerName="managementAware"
                    control={control}
                    inputPlaceholder="Select answer"
                    inputHeight="40px"
                    inputWidth="100%"
                    required
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Do not know">Do not know</MenuItem>
                    <MenuItem value="Do not wish to disclose">
                      Do not wish to disclose
                    </MenuItem>
                  </LEIAASelect>
                  {(watch('managementAware') === 'Yes' ||
                    watch('managementAware') === 'Do not know') && (
                    <LEIAAInput
                      labelText={
                        watch('managementAware') === 'Yes'
                          ? 'Please provide details of management awareness'
                          : 'Please provide details (Optional)'
                      }
                      controllerName="managementAwareAnswer"
                      control={control}
                      inputPlaceholder="Provide details"
                      inputWidth="100%"
                      inputHeight="80px"
                      required={watch('managementAware') === 'Yes'}
                      multiline
                      customClassName="scrollable-content"
                    />
                  )}
                </Box>
                <InputLabel
                  data-testid="matter-form-label-id"
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch',
                    color: '#464B53',
                    fontSize: '14px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                    marginBottom: '-22px',
                  }}
                >
                  Please provide an estimation of any monetary value relating to
                  the allegation
                </InputLabel>
                <Box
                  sx={{
                    display: 'flex  !important',
                    flexDirection: 'row  !important',
                    alignItems: 'center  !important',
                    justifyContent: 'start  !important',
                    gap: '15px !important',
                  }}
                >
                  <LEIAAInput
                    labelText=""
                    controllerName="monetaryEstimation"
                    control={control}
                    inputPlaceholder="Enter estimation"
                    inputWidth="100%"
                    inputHeight="40px"
                    type="NUMERIC"
                    customClassName="scrollable-content"
                    required={false}
                  />
                  <LEIAASelect
                    labelText=""
                    control={control}
                    controllerName="currency"
                    inputPlaceholder="Select Currency"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '300px' }}
                    handleChange={handleSetMatterCurrency}
                    required={false}
                  >
                    {currencies?.map((c: any) => {
                      return (
                        <MenuItem key={c.value} value={c.label}>
                          {c.value} - {c.label}
                        </MenuItem>
                      );
                    })}
                  </LEIAASelect>
                </Box>
                <Box>
                  <LEIAAInput
                    labelText="Where did this incident or violation occur?"
                    controllerName="incidentPlace"
                    control={control}
                    inputPlaceholder="Enter answer"
                    inputWidth="100%"
                    inputHeight="130px"
                    required
                    multiline
                    customClassName="scrollable-content"
                  />
                </Box>
                <Box>
                  <LEIAAInput
                    labelText="Please provide the specific or approximate time this incident occurred?"
                    controllerName="incidentTime"
                    control={control}
                    inputPlaceholder="Enter answer"
                    inputWidth="100%"
                    inputHeight="130px"
                    required
                    multiline
                    customClassName="scrollable-content"
                  />
                </Box>
                <Box>
                  <LEIAASelect
                    labelText="How long do you think this problem has been going on?"
                    controllerName="incidentTimeframe"
                    control={control}
                    inputPlaceholder="Select answer"
                    inputHeight="40px"
                    inputWidth="100%"
                    required
                  >
                    <MenuItem value="Once">Once</MenuItem>
                    <MenuItem value="One week">One week</MenuItem>
                    <MenuItem value="1-3 Months">1-3 Months</MenuItem>
                    <MenuItem value="3-12 Months">3-12 Months</MenuItem>
                    <MenuItem value="More than 12 months">
                      More than 12 months
                    </MenuItem>
                    <MenuItem value="Do not know">Do not know</MenuItem>
                  </LEIAASelect>
                </Box>
                <Box>
                  <LEIAASelect
                    labelText="How did you become aware of this violation?"
                    controllerName="whenViolationAware"
                    control={control}
                    inputPlaceholder="Select answer"
                    inputHeight="40px"
                    inputWidth="100%"
                    required
                  >
                    <MenuItem value="It happened to me">
                      It happened to me
                    </MenuItem>
                    <MenuItem value="I observed it">I observed it</MenuItem>
                    <MenuItem value="I heard it">I heard it</MenuItem>
                    <MenuItem value="A co-worker told me">
                      a co-worker told me
                    </MenuItem>
                    <MenuItem value="Someone outside the company told me">
                      Someone outside the company told me
                    </MenuItem>
                    <MenuItem value="I found or was sent some documents">
                      I found or was sent some documents
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </LEIAASelect>
                  {watch('whenViolationAware') === 'Other' && (
                    <LEIAAInput
                      labelText="Please provide details"
                      controllerName="whenViolationAwareDetail"
                      control={control}
                      inputPlaceholder="Enter answer"
                      inputWidth="100%"
                      inputHeight="130px"
                      required
                      multiline
                      customClassName="scrollable-content"
                    />
                  )}
                </Box>
                <Box>
                  <LEIAAInput
                    labelText="Please identify any persons who have attempted to conceal this problem and the steps they took to conceal it?"
                    controllerName="concealAttempt"
                    control={control}
                    inputPlaceholder="Enter answer"
                    inputWidth="100%"
                    inputHeight="130px"
                    multiline
                    customClassName="scrollable-content"
                    required={false}
                  />
                </Box>

                <Box>
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '-20px',
                    }}
                  >
                    If you have documents or files that support your report,
                    please upload them here.
                  </Typography>
                  {templateReport && templateReport.length > 0 && (
                    // eslint-disable-next-line react/jsx-no-undef
                    <MatterAddedDocumentsInformation
                      matterDocuments={templateReport}
                      handleRemoveFile={handleRemoveFile}
                      showHeader={false}
                    />
                  )}
                  <LEIAAUploadFileArea
                    title="Select a document to import."
                    subTitle=""
                    handleDrag={handleDrag}
                    handleAddFile={handleAddFile}
                    uploadAreaStyles={{
                      ...matterQuestionnaireUploadAreaWrapperStyles,
                      paddingLeft: '0px',
                    }}
                  />
                  <LEIAADialog
                    open={openAlertOpenDialog}
                    onClose={() => setOpenAlertOpenDialog(false)}
                    title="alert"
                    description="We have detected similar existing files. Please change the final name if you want to add this file."
                    simpleButtonText="Ok"
                    onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
                    isTextAlign
                  />
                </Box>

                <Box>
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '-20px',
                    }}
                  >
                    *Please provide full details regarding the alleged
                    violation, including the locations of other witnesses, and
                    any other information that could be valuable in the
                    evaluation and ultimate resolution of this situation.
                    <br />
                    <span
                      style={{
                        color: '#464B53',
                        ...karlaProRegularItalicFontStyles,
                        fontStyle: 'normal',
                        fontSize: '13px',
                        lineHeight: '22px',
                      }}
                    >
                      Please take your time and provide as much detail as
                      possible, but exercise care to not provide details that
                      may reveal your identity unless you wish to do so. It may
                      be important to know if you are the only person aware of
                      this situation.
                    </span>
                  </Typography>

                  <LEIAAInput
                    labelText=""
                    controllerName="fullIncidentDetails"
                    control={control}
                    inputPlaceholder="Enter answer"
                    inputWidth="100%"
                    inputHeight="130px"
                    required
                    multiline
                    customClassName="scrollable-content"
                  />
                </Box>
                <Box sx={{}}>
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '-20px',
                    }}
                  >
                    <b>NOTE:</b> When you submit this report, you will be issued
                    a Report Key. Please write it down and keep it in a safe
                    place. We ask you to use this Report Key along with the
                    password of your choosing to return to{' '}
                    <a href={defaultWebsite}>{defaultWebsite}</a> or telephone
                    hotline in 5-6 business days. By returning in 5-6 business
                    days, you will have the opportunity to review any Follow-up
                    Questions or submit more information about this incident.
                  </Typography>
                </Box>
                <Box>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, ...rest } }) => {
                      return (
                        <Box>
                          <InputLabel
                            data-testid="form-label-id"
                            htmlFor="password"
                          >
                            *Please choose a secure password
                          </InputLabel>
                          <TextField
                            {...rest}
                            placeholder="Enter password"
                            autoComplete="new-password"
                            InputProps={{
                              endAdornment: (
                                <ShowPassword
                                  showPassword={showPassword}
                                  handleClickShowPassword={
                                    handleClickShowPassword
                                  }
                                />
                              ),
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{
                              'data-testid': 'form-text-field-id',
                            }}
                            /* eslint-disable react/jsx-boolean-value */
                            required={true}
                            /* eslint-enable react/jsx-boolean-value */
                            onChange={(e) => {
                              onChange(
                                e.target.value,
                                setPassword({ password: e.target.value })
                              );
                            }}
                            sx={loginFormInputStyles('100%', '40px')}
                            InputLabelProps={{ shrink: false }}
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                          <PasswordInputInstructions
                            fieldsData={password as unknown as FieldValues}
                          />
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              paddingTop: '10px',
              paddingBottom: '10px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '24px',
              borderTop: '1px solid  #D3ECF6',
              marginTop: '40px',
              position: 'sticky',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: '#FFF',
              zIndex: 2,
              '& > div': {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                gap: '10px',
              },
            }}
          >
            <Box>
              <Button
                sx={tertiaryButtonStyles()}
                variant="contained"
                onClick={() => navigate('/reporting')}
              >
                <Typography>Cancel</Typography>
              </Button>

              <Button
                variant="contained"
                sx={primaryButtonStyles()}
                type="submit"
              >
                <Typography>Submit</Typography>
                <span className="material-icons-round">save</span>
              </Button>
            </Box>
          </Box>
        </form>

        <FixedContextualHelpWrapper
          systemPrompt={whistleblowingTopicHelpPrompt}
          title="Reporting Assistant"
          tooltipText="Toggle help panel"
          chatWidth="330px"
          chatHeight="60vh"
          iconColor="#2196f3"
          iconPosition={{
            right: '0px',
          }}
          welcomeMessageContent={welcomeMessage}
        />
      </Box>

      {formKey && (
        <Dialog
          open={openKeyOpenDialog}
          sx={{
            '& > div:nth-of-type(3)': {
              background: 'rgba(211, 236, 246, 0.70)',
              backdropFilter: 'blur(2px)',
              '& > div.MuiPaper-root': {
                display: 'flex',
                width: isSmallScreen ? '90%' : '736px',
                padding: '48px',
                margin: 0,
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '24px',
                border: '1px solid #D3ECF6',
                background: '#FFF',
                boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
                maxHeight: 'unset',
                maxWidth: 'unset',
                paddingY: '15px',
                paddingX: '8px',
              },
            },
          }}
        >
          <Box sx={leiaaDialogContentStylesReporting(theme)}>
            <Box />
            <Box>
              <Box>
                <Box>
                  <Typography>Concern submitted!</Typography>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Typography textAlign="justify">
                    Please write the Report Key down and keep it in a safe
                    place. We ask you to use this Report Key along with the
                    password of your choosing to return to{' '}
                    <a href={defaultWebsite}>{defaultWebsite}</a> or telephone
                    hotline in 5-6 business days. By returning in 5-6 business
                    days, you will have the opportunity to review any Follow-up
                    Questions or submit more information about this incident.
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ paddingTop: '30px' }}
                    textAlign="center"
                  >
                    <span
                      style={{
                        border: '2px solid #ff8787',
                        borderRadius: '10px',
                        padding: '10px',
                        background: '#f4f4f4',
                        fontSize: isSmallScreen ? '15px' : '25px',
                      }}
                    >
                      {formKey}
                    </span>
                    <Button sx={{ color: '#3A79FD' }} onClick={downloadKey}>
                      <span className="material-icons-round">save</span>
                    </Button>
                  </Typography>
                  <FormControlLabel
                    sx={{
                      '& .MuiTypography-root': { ...karlaProRegularFontStyles },
                    }}
                    control={
                      <Checkbox checked={checked} onChange={handleCheckbox} />
                    }
                    label="I've copied the report key"
                  />
                </Box>
                <Box>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        ...primaryButtonStyles(),
                        width: '100%',
                      }}
                      onClick={() => navigate('/reporting')}
                      disabled={!checked}
                    >
                      <span className="material-icons-round">save</span>
                      <Typography>I saved my key</Typography>
                    </Button>
                    <Box>
                      <Typography>
                        <b>Note:</b> you will not be able to see your key again,
                        please save it in a safe place
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default ReportingOnlineForm;
