import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputLabel,
  Popover,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  filterScheduledResources,
  getDateReadable,
  getFilteredResources,
  getFormattedMonthYear,
  getWeekDay,
} from '../../../utils/utils';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../../styles/textStyles';

import EmptyWeekDays from '../../../components/homeportal/scheduled-interviews/EmptyWeekDays';
import InterviewWeekDayItem from '../../../components/homeportal/scheduled-interviews/InterviewWeekDayItem';
import {
  scheduledInterviewsWrapperStyles,
  scheduledInterviewsButtonsWrapperStyles,
  interviewsItemsWrapperStyles,
  interviewsItemWrapperStyles,
} from '../../../components/homeportal/scheduled-interviews/styles';
import ScheduledInterviewItem from '../../../components/homeportal/scheduled-interviews/ScheduledInterviewItem';
import { getMyDashboardCalendarRequest } from '../../../api/general';
import ScheduledTaskItem from './ScheduledTaskItem';
import ScheduledMatterItem from './ScheduledMatterItem';

interface MyCalendarProps {
  page: string; // my-dashboard , clients , matter-detail
  targetId?: any; // matter / client id
}

const renderResourceContent = (resource: any) => {
  if (resource.matterTitle) {
    return (
      <>
        {resource.interviews.length > 0 && (
          <ScheduledInterviewItem
            key={uuidv4()}
            matterTitle={resource.matterTitle}
            info={resource.info}
            interviews={resource.interviews}
          />
        )}
        {resource.tasks.length > 0 && (
          <ScheduledTaskItem
            key={uuidv4()}
            matterTitle={resource.matterTitle}
            info={resource.info}
            tasks={resource.tasks}
          />
        )}
      </>
    );
  }
  switch (resource.resource_type) {
    case 'matter':
      return <ScheduledMatterItem key={uuidv4()} matter={resource} />;
    case 'task':
      return <ScheduledTaskItem key={uuidv4()} singleTask={resource} />;
    default:
      return null;
  }
};

const ScheduledResources = ({ page, targetId = null }: MyCalendarProps) => {
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const [currentDate, setCurrentDate] = useState(new Date());
  const [wantedResources, setWantedResources] = useState<any>([
    'tasks',
    'matters',
    'interviews',
  ]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [scheduledResources, setScheduledResources] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  let filteredResources: any[] = [];
  const [checked, setChecked] = useState([true, true, true]);

  const handleFetchScheduledResources = async () => {
    setLoading(true); // Start loading

    try {
      const response = await getMyDashboardCalendarRequest(
        currentDate.toLocaleDateString('pt-PT', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
        page,
        targetId,
        wantedResources
      );
      setScheduledResources({
        scheduled: getFilteredResources(response),
      });
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An unknown error related to calendar occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchScheduledResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFetchScheduledResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantedResources]);

  if (scheduledResources && scheduledResources.scheduled !== undefined) {
    // check if we need extra helper
    filteredResources = filterScheduledResources(
      scheduledResources.scheduled,
      currentDate
    );
  }

  const hasVisibleDataInPast = () => {
    let visibleData: any[] = [];
    if (scheduledResources && scheduledResources.scheduled !== undefined) {
      // check if we need extra helper
      visibleData = filterScheduledResources(
        scheduledResources?.scheduled as any[],
        currentDate
      );
    }

    return scheduledResources?.scheduled.some(
      (item: any) =>
        getDateReadable(item.day) < getDateReadable(visibleData[0].day)
    );
  };

  const hasVisibleDataInFuture = () => {
    let visibleData: any[] = [];
    if (scheduledResources && scheduledResources.scheduled !== undefined) {
      visibleData = filterScheduledResources(
        scheduledResources?.scheduled as any[],
        currentDate
      );
    }

    return scheduledResources?.scheduled.some(
      (item: any) =>
        getDateReadable(item.day) >
        getDateReadable(visibleData[visibleData.length - 1].day)
    );
  };

  const handlePreviousWeek = () => {
    setCurrentDate((prevDate) => {
      const previousWeek = new Date(prevDate);
      previousWeek.setDate(previousWeek.getDate() - 7);
      return previousWeek;
    });
  };

  const handleNextWeek = () => {
    setCurrentDate((prevDate) => {
      const nextWeek = new Date(prevDate);
      nextWeek.setDate(nextWeek.getDate() + 7);
      return nextWeek;
    });
  };

  const hasResources = (day: string) => {
    return filteredResources.some((resource) => resource.day === day);
  };
  // && item.nonmatters.length === 0
  const scheduledInterviewsEmpty =
    scheduledResources &&
    scheduledResources.scheduled.every(
      (item: any) =>
        item.matters.length === 0 &&
        item.nonmatters &&
        item.nonmatters.length === 0
    );

  const backButtonDisabled = hasVisibleDataInPast();
  const forwardButtonDisabled = hasVisibleDataInFuture();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeAll = () => {
    const allChecked = checked.every((item) => item);
    setChecked([!allChecked, !allChecked, !allChecked]);
    setWantedResources(allChecked ? [] : ['tasks', 'matters', 'interviews']);
  };

  const handleChangeTasks = () => {
    setChecked([!checked[0], checked[1], checked[2]]);
    setWantedResources(
      [
        !checked[0] ? 'tasks' : null,
        checked[1] ? 'matters' : null,
        checked[2] ? 'interviews' : null,
      ].filter(Boolean)
    );
  };

  const handleChangeMatters = () => {
    setChecked([checked[0], !checked[1], checked[2]]);
    setWantedResources(
      [
        checked[0] ? 'tasks' : null,
        !checked[1] ? 'matters' : null,
        checked[2] ? 'interviews' : null,
      ].filter(Boolean)
    );
  };

  const handleChangeInterviews = () => {
    setChecked([checked[0], checked[1], !checked[2]]);
    setWantedResources(
      [
        checked[0] ? 'tasks' : null,
        checked[1] ? 'matters' : null,
        !checked[2] ? 'interviews' : null,
      ].filter(Boolean)
    );
  };

  return (
    <Box sx={scheduledInterviewsWrapperStyles}>
      <Box sx={{ maxHeight: '494px', height: '100%' }}>
        <Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '17px',
                lineHeight: '30px',
                color: '#140070',
              }}
            >
              Scheduled -
            </Typography>
            <Typography
              variant="h5"
              sx={{
                display: 'flex',
                alignItems: 'center',
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '17px',
                lineHeight: '30px',
                color: '#140070',
                cursor: 'pointer',
                marginLeft: '-20px',
                '&:hover': {
                  color: '#0053fe',
                  '& > span': {
                    color: '#0053fe',
                  },
                },
                '& > span': {
                  color: '#140070',
                  fontSize: '30px',
                },
              }}
              onClick={handleClick}
            >
              {wantedResources.length > 0
                ? wantedResources
                    .map((w: any) => w.charAt(0).toUpperCase() + w.slice(1))
                    .join(', ')
                : 'Choose resources'}{' '}
              <span className="material-icons-round">arrow_drop_down</span>
            </Typography>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{
                boxShadow: 'none !important',
                border: '1px solid #D3ECF7',
              }}
              PaperProps={{
                sx: {
                  boxShadow: 'none !important', // Use 'none' instead of '0'
                },
              }}
            >
              <Box
                style={{
                  padding: '5px 0px 5px 10px',
                  border: '1px solid #D3ECF7',
                  borderRadius: '5px',
                }}
              >
                <FormControlLabel
                  label={
                    <InputLabel
                      data-testid="matter-form-label-id"
                      sx={{
                        color: '#464B53',
                        fontSize: '14px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                        display: 'flex',
                        marginRight: 'auto',
                      }}
                    >
                      All
                    </InputLabel>
                  }
                  control={
                    <Checkbox
                      checked={checked[0] && checked[1] && checked[2]}
                      indeterminate={
                        checked[0] !== checked[1] || checked[1] !== checked[2]
                      }
                      onChange={handleChangeAll}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 20 },
                      }}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <FormControlLabel
                    label={
                      <InputLabel
                        data-testid="matter-form-label-id"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#464B53',
                          fontSize: '14px',
                          ...karlaProRegularFontStyles,
                          lineHeight: '22px',
                          marginRight: 'auto',
                          '& > span': {
                            marginLeft: '5px',
                            color: '#8A2BE2',
                            fontSize: '8px',
                          },
                        }}
                      >
                        Tasks <span className="material-icons-round">lens</span>
                      </InputLabel>
                    }
                    control={
                      <Checkbox
                        checked={checked[0]}
                        onChange={handleChangeTasks}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 20 },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <InputLabel
                        data-testid="matter-form-label-id"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#464B53',
                          fontSize: '14px',
                          ...karlaProRegularFontStyles,
                          lineHeight: '22px',
                          marginRight: 'auto',
                          '& > span': {
                            marginLeft: '5px',
                            color: '#08684E',
                            fontSize: '8px',
                          },
                        }}
                      >
                        Matters{' '}
                        <span className="material-icons-round">lens</span>
                      </InputLabel>
                    }
                    control={
                      <Checkbox
                        checked={checked[1]}
                        onChange={handleChangeMatters}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 20 },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <InputLabel
                        data-testid="matter-form-label-id"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#464B53',
                          fontSize: '14px',
                          ...karlaProRegularFontStyles,
                          lineHeight: '22px',
                          marginRight: 'auto',
                          '& > span': {
                            marginLeft: '5px',
                            color: '#0053ff',
                            fontSize: '8px',
                          },
                        }}
                      >
                        Interviews{' '}
                        <span className="material-icons-round">lens</span>
                      </InputLabel>
                    }
                    control={
                      <Checkbox
                        checked={checked[2]}
                        onChange={handleChangeInterviews}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 20 },
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
            </Popover>
          </Box>
          {/* Date Info and Controls Content  */}
          {loading ? null : (
            <Box>
              {!scheduledInterviewsEmpty ? (
                <>
                  <Typography
                    variant="caption"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '16px',
                      letterSpacing: '0.2px',
                      color: '#464B53',
                    }}
                  >
                    {getFormattedMonthYear(
                      filteredResources[0].day,
                      filteredResources[filteredResources.length - 1].day
                    )}
                  </Typography>
                  <Box sx={scheduledInterviewsButtonsWrapperStyles}>
                    <IconButton
                      aria-label="week-back"
                      onClick={handlePreviousWeek}
                      disabled={!backButtonDisabled}
                    >
                      <span className="material-symbols-rounded">
                        arrow_back
                      </span>
                    </IconButton>
                    <Typography>week</Typography>
                    <IconButton
                      aria-label="week-forward"
                      onClick={handleNextWeek}
                      disabled={!forwardButtonDisabled}
                    >
                      <span className="material-symbols-rounded">
                        arrow_forward
                      </span>
                    </IconButton>
                  </Box>
                </>
              ) : null}
            </Box>
          )}
        </Box>
        {
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                '& > span': {
                  color: '#0053FF',
                },
              }}
            >
              <CircularProgress />
            </Box>
          ) : scheduledInterviewsEmpty ? (
            <Box
              sx={{
                height: '100%',
                width: '100',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '0px !important',
              }}
            >
              <Typography
                sx={{ ...karlaProRegularItalicFontStyles, color: '#464B53' }}
              >
                No items.
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  margin: '24px 0px 16px 0px',
                }}
              >
                {weekdays.map((weekday, index) => {
                  const day = getWeekDay(filteredResources, index);
                  return (
                    <InterviewWeekDayItem key={uuidv4()} interviewDay={day} />
                  );
                })}
              </Box>
              <Box
                className="scrollable-content"
                sx={{
                  ...interviewsItemsWrapperStyles,
                  ...(page !== 'my-dashboard'
                    ? {
                        maxHeight: '350px',
                        height: '100%',
                      }
                    : {
                        maxHeight: '430px',
                        height: '100%',
                      }),
                }}
              >
                {weekdays.map((weekday, index) => {
                  const day = getWeekDay(filteredResources, index);

                  const resourcesForDay = filteredResources.filter(
                    (resource) => resource.day === day
                  );

                  return hasResources(day) ? (
                    resourcesForDay.map((resource: any) => {
                      return (
                        <Box key={uuidv4()} sx={interviewsItemWrapperStyles}>
                          {resource.matters?.map((m: any) => {
                            return renderResourceContent(m);
                          })}
                          {resource.nonmatter?.map((r: any) => {
                            return renderResourceContent(r);
                          })}
                        </Box>
                      );
                    })
                  ) : (
                    <EmptyWeekDays key={uuidv4()} count={1} />
                  );
                })}
              </Box>
            </>
          )
        }
      </Box>
    </Box>
  );
};

export default ScheduledResources;
