import { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  InputBase,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { karlaProRegularFontStyles } from '../../styles/textStyles';

interface ChatHistorySidebarProps {
  sessions: { id: string; created_date: string; preview?: string }[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSelectSession: (id: string) => void;
  onCreateNewChat: () => void;
}

const ChatHistorySidebar = ({
  isOpen,
  setIsOpen,
  sessions,
  onSelectSession,
  onCreateNewChat,
}: ChatHistorySidebarProps) => {
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [activeSession, setActiveSession] = useState<string | null>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filteredSessions = sessions.filter((session) =>
    session.preview
      ? session.preview.toLowerCase().includes(search.toLowerCase())
      : session.id.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    const checkIfScrollable = () => {
      if (scrollableRef.current) {
        const isContentScrollable =
          scrollableRef.current.scrollHeight >
          scrollableRef.current.clientHeight;
        setIsScrollable(isContentScrollable);
      }
    };

    checkIfScrollable();

    window.addEventListener('resize', checkIfScrollable);

    return () => window.removeEventListener('resize', checkIfScrollable);
  }, [filteredSessions, isOpen, showSearch]);

  useEffect(() => {
    if (showSearch && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearch]);

  const handleClearSearch = () => {
    setSearch('');
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <Box
      sx={{
        width: isOpen ? '250px' : '50px',
        height: '100%',
        backgroundColor: '#F9F9F9',
        paddingTop: isOpen ? '10px' : '0',
        paddingBottom: isOpen ? '10px' : '0',
        paddingLeft: isOpen ? '10px' : '0',
        // eslint-disable-next-line no-nested-ternary
        paddingRight: isOpen ? (isScrollable ? '0' : '10px') : '0',
        position: 'absolute',
        left: 0,
        top: 0,
        boxShadow: '2px 0px 5px rgba(0,0,0,0.1)',
        transition: 'width 0.3s ease',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: isOpen ? 'space-between' : 'center',
          alignItems: 'center',
          padding: isOpen ? '0px' : '10px 0',
          borderBottom: isOpen ? '1px solid #e0e0e0' : 'none',
          marginRight: isOpen && isScrollable ? '20px' : '0',
        }}
      >
        <IconButton onClick={() => setIsOpen(!isOpen)}>
          <MenuIcon />
        </IconButton>

        {isOpen && (
          <Stack direction="row" spacing={1}>
            <Tooltip title="New Chat">
              <IconButton onClick={onCreateNewChat}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Search Chats">
              <IconButton onClick={() => setShowSearch(!showSearch)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Box>

      {isOpen && (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            paddingTop: '10px',
          }}
        >
          {showSearch && (
            <Box
              sx={{
                marginRight: isScrollable ? '20px' : '0',
                marginBottom: '10px',
                padding: '4px 8px',
                backgroundColor: '#ffffff',
                borderRadius: '5px',
                border: '1px solid #e0e0e0',
              }}
            >
              <InputBase
                inputRef={searchInputRef}
                fullWidth
                sx={{
                  ...karlaProRegularFontStyles,
                  fontSize: '14px',
                  color: '#202020',
                }}
                placeholder="Search chats..."
                inputProps={{ 'aria-label': 'search chats' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" sx={{ color: '#757575' }} />
                  </InputAdornment>
                }
                endAdornment={
                  search && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={handleClearSearch}
                        edge="end"
                        size="small"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Box>
          )}

          <Box
            ref={scrollableRef}
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              paddingRight: isScrollable ? '4px' : '0',
            }}
            className={isScrollable ? 'scrollable-content' : ''}
          >
            {filteredSessions.length > 0 ? (
              filteredSessions.map((session) => (
                <Box
                  key={session.id}
                  sx={{
                    padding: '8px',
                    cursor: 'pointer',
                    backgroundColor:
                      activeSession === session.id ? '#e0e0e0' : '#ffffff',
                    marginBottom: '5px',
                    borderRadius: '5px',
                    '&:hover': { backgroundColor: '#e0e0e0' },
                  }}
                  onClick={() => {
                    onSelectSession(session.id);
                    setActiveSession(session.id);
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontSize: '14px',
                      color: '#202020',
                      fontWeight:
                        activeSession === session.id ? 'bold' : 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {session.preview ? session.preview : session.id}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  padding: '8px',
                  backgroundColor: '#ffffff',
                  marginBottom: '5px',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    color: '#757575',
                  }}
                >
                  No chats found
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatHistorySidebar;
