import { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { LEIAADialog } from '../inputs';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
} from '../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { tertiaryButtonStyles } from '../styles';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import MicrosoftLogin from './MicrosoftLogin';
import { matterDetailIntegrationAuthRequest } from '../../api/matters';

interface MicrosoftAuthProps {
  matterId: any;
  integrationAuthPopup?: boolean;
  closeIntegrationAuthPopup?: any;
  microsoftData: any;
}

const MicrosoftAuth = ({
  matterId,
  integrationAuthPopup = false,
  closeIntegrationAuthPopup = false,
  microsoftData,
}: MicrosoftAuthProps) => {
  const [content, setContent] = useState('auth');
  const [errorMessage, setErrorMessage] = useState('auth');
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);

  const handleBack = () => {
    setContent('auth');
  };

  const authSuccessCallback = async (token: string) => {
    if (integrationAuthPopup && token) {
      // TODO: add the rest of the data - not needed for now
      const integrationData = {
        token,
        integration: 'microsoft',
      };
      await matterDetailIntegrationAuthRequest(integrationData, matterId);
      if (closeIntegrationAuthPopup) {
        closeIntegrationAuthPopup('Microsoft');
      }
    }
  };

  const renderContent = () => {
    switch (content) {
      case 'loading':
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Authenticating...
            </Typography>
          </Stack>
        );
      case 'auth':
        return (
          <>
            <Typography
              id="modal-modal-title"
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: 'center',
              }}
            >
              Authenticate using your Microsoft credentials to access Microsoft
              Documents
            </Typography>

            <Box sx={matterInformationWrapperStyles}>
              <Box
                sx={{
                  ...matterInformationFormWrapperStyles,
                  width: '100%',
                }}
              >
                <MicrosoftLogin
                  microsoftData={microsoftData}
                  authSuccessCallback={authSuccessCallback}
                />
              </Box>
            </Box>
            <LEIAADialog
              open={openAlertOpenDialog}
              onClose={() => setOpenAlertOpenDialog(false)}
              title="alert"
              description="error"
              simpleButtonText="Ok"
              onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
              isTextAlign
            />
          </>
        );
      case 'error':
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <span
                className="material-icons-round"
                style={{
                  fontSize: '30px',
                  color: '#E22727',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                error
              </span>
              {errorMessage}
            </Typography>
            <Button
              variant="contained"
              sx={{ ...tertiaryButtonStyles(), width: '100%' }}
              onClick={handleBack}
            >
              <Typography>Go Back</Typography>
            </Button>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: '736px',
        height: 'auto',
        padding: '48px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '24px',
        border: '1px solid #D3ECF6',
        background: '#FFF',
        boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
        margin: '0 auto',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {renderContent()}
      </Stack>
    </Box>
  );
};

export default MicrosoftAuth;
