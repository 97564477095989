import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { matterDetailIntegrationAuthRequest } from '../../api/matters';
import { LEIAADialog, LEIAAInput } from '../inputs';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { primaryButtonStyles, tertiaryButtonStyles } from '../styles';
import { karlaProRegularFontStyles } from '../../styles/textStyles';

interface RelativityAuthProps {
  matterId: any;
  relativityUserData: any;
  setRelativityUserData: any;
  integrationAuthPopup?: boolean;
  closeIntegrationAuthPopup?: any;
}

const RelativityAuth = ({
  matterId,
  relativityUserData,
  setRelativityUserData,
  integrationAuthPopup = false,
  closeIntegrationAuthPopup = false,
}: RelativityAuthProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [content, setContent] = useState('auth');
  const [errorMessage, setErrorMessage] = useState('auth');
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);

  const handleBack = () => {
    setContent('auth');
    setValue('username', '');
    setValue('password', '');
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    setContent('loading');
    try {
      const integrationData = {
        ...data,
        integration: 'relativity',
      };
      await matterDetailIntegrationAuthRequest(integrationData, matterId);
      setRelativityUserData({
        username: 'username',
        password: 'password',
        connected: true,
        token: 'token',
      });

      if (integrationAuthPopup) {
        if (closeIntegrationAuthPopup) {
          closeIntegrationAuthPopup('Relativity');
        }
      }
    } catch (error: any) {
      setContent('error');
      setErrorMessage(error.response.data.message);
    }
  };

  const renderContent = () => {
    switch (content) {
      case 'loading':
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Authenticating...
            </Typography>
          </Stack>
        );
      case 'auth':
        return (
          <>
            <Typography
              id="modal-modal-title"
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Authenticate using your Relativity credentials to access the
              E-Discovery tab
            </Typography>

            <Box sx={matterInformationWrapperStyles}>
              <Box
                sx={{
                  ...matterInformationFormWrapperStyles,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    ...matterInformationInputWrapperStyles,
                    gap: '10px !important',
                  }}
                >
                  <Box>
                    <LEIAAInput
                      labelText="Username"
                      controllerName="username"
                      control={control}
                      inputPlaceholder="Enter relativity username"
                      inputWidth="100%"
                      inputHeight="40px"
                    />
                  </Box>
                  <Box>
                    <LEIAAInput
                      labelText="Password"
                      controllerName="password"
                      control={control}
                      inputPlaceholder="Enter relativity password"
                      inputWidth="100%"
                      inputHeight="40px"
                      type="password"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <LEIAADialog
              open={openAlertOpenDialog}
              onClose={() => setOpenAlertOpenDialog(false)}
              title="alert"
              description="error"
              simpleButtonText="Ok"
              onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
              isTextAlign
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ width: '100%', marginTop: '35px !important' }}
            >
              <Button
                variant="contained"
                sx={{ ...primaryButtonStyles(), width: '100%' }}
                onClick={handleSubmit(onSubmit)}
              >
                <Typography>Authenticate</Typography>
                <span className="material-icons-round">settings_remote</span>
              </Button>
            </Stack>
          </>
        );
      case 'error':
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <span
                className="material-icons-round"
                style={{
                  fontSize: '30px',
                  color: '#E22727',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                error
              </span>
              {errorMessage}
            </Typography>
            <Button
              variant="contained"
              sx={{ ...tertiaryButtonStyles(), width: '100%' }}
              onClick={handleBack}
            >
              <Typography>Go Back</Typography>
            </Button>
          </Stack>
        );
      default:
        return null;
    }
  };

  if (!relativityUserData.connected) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: '736px',
            height: 'auto',
            padding: '48px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '24px',
            border: '1px solid #D3ECF6',
            background: '#FFF',
            boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
            margin: '0 auto',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {renderContent()}
          </Stack>
        </Box>
      </form>
    );
  }
  return null;
};

export default RelativityAuth;
