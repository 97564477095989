import {
  Box,
  IconButton,
  InputLabel,
  Typography,
  createFilterOptions,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import {
  bundleActionsRequest,
  createBundleRequest,
  folderDocsRequest,
} from '../../../api/matters';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import { addToInterviewDialog, leiaaAddToWrapperStyles } from './styles';
import { LEIAAAutoComplete } from '../../inputs';
import NotificationToast from '../toast/NotificationToast';

export interface DocumentAddToInterviewDialogProps {
  open: boolean;
  onClose: () => void;
  docList: any;
  bundlesOptions: any;
  refreshTable: () => void;
  matterId: any;
  control: any;
  setValue: any;
  setShowAddedBundleNotification: any;
  selectedDocs?: any;
}

interface BundleOptionType {
  inputValue?: string;
  name: string;
  id: number;
}

const bundleFilter = createFilterOptions<BundleOptionType>();

const DocumentAddToInterviewDialog = ({
  open,
  onClose,
  docList,
  bundlesOptions,
  refreshTable,
  matterId,
  control,
  setValue,
  setShowAddedBundleNotification,
  selectedDocs,
}: DocumentAddToInterviewDialogProps) => {
  const [bundleValue, setBundleValue] = useState<any>();
  const [isError, setIsError] = useState<boolean>();
  const [addedDocs, setAddedDocs] = useState<any>(selectedDocs ?? []);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [isErrorBundle, setIsErrorBundle] = useState<boolean>();

  const cleanData = () => {
    setBundleValue(undefined);
    setValue('bundle_name', undefined);
    setIsError(false);
    setAddedDocs([]);
  };

  const handleClose = () => {
    cleanData();
    onClose();
  };

  const handleChangeBundleName = (bundleField: any) => {
    setBundleValue(bundleField);
  };

  const handleSubmit = async () => {
    if (addedDocs.length !== 0 && bundleValue && bundlesOptions) {
      try {
        const files: number[] = [];
        const folders: number[] = [];
        addedDocs.forEach((element: any) => {
          if (element.folder) {
            folders.push(parseInt(element.id.match(/\d+/)[0], 10));
          } else {
            files.push(element.id);
          }
        });

        const existingBundle = bundlesOptions.find(
          (bundle: any) => bundle.id === bundleValue
        );

        if (!existingBundle) {
          await createBundleRequest(
            bundleValue,
            matterId,
            files.length === 0 ? undefined : files,
            folders.length === 0 ? undefined : folders
          );
        } else {
          await bundleActionsRequest(
            bundleValue,
            files,
            folders,
            undefined,
            undefined,
            undefined
          );
        }
        setValue('bundle_name', undefined);
        handleClose();
        refreshTable();
        setShowAddedBundleNotification(true);
      } catch (error: any) {
        setRequestError(error.response.data.message);
        setShowErrorNotification(true);
        console.error(error);
      }
    } else {
      if (addedDocs.length === 0) setIsError(true);
      if (!bundleValue) setIsErrorBundle(true);
    }
  };

  const handleRemoveDoc = (docId: any) => {
    setAddedDocs((prevAddedDocs: any) =>
      prevAddedDocs.filter((doc: any) => doc.id !== docId)
    );
  };

  const handleFolderSelection = async (selectedDocsArray: any) => {
    const docsToUpdate: any[] = [];

    await Promise.all(
      selectedDocsArray.map(async (doc: any) => {
        if (doc.folder) {
          const response = await folderDocsRequest(doc.id.toString(), '');
          doc.documents = response.documents;
          docsToUpdate.push(doc);
        }
      })
    );

    return docsToUpdate;
  };

  useEffect(() => {
    async function updateSelectedDocs() {
      const updatedDocs = await handleFolderSelection(selectedDocs);
      setAddedDocs([
        ...updatedDocs,
        ...selectedDocs.filter((doc: any) => !doc.folder),
      ]);
    }

    updateSelectedDocs();
  }, [selectedDocs]);

  return (
    <Dialog onClose={handleClose} open={open} sx={leiaaAddToWrapperStyles}>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <Box sx={addToInterviewDialog()}>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: '#202020',
                ...sofiaProBoldFontStyles,
                fontSize: '18px',
                lineHeight: 'normal',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              Add to interview bundle
            </Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <InputLabel
                  data-testid="matter-form-label-id"
                  htmlFor="Interview-Bundle-Name"
                  sx={{
                    ...karlaProRegularFontStyles,
                    paddingLeft: '12px',
                    fontSize: '14px',
                  }}
                >
                  Interview Bundle Name
                </InputLabel>
              </Box>

              <LEIAAAutoComplete
                labelText=""
                required={false}
                controllerName="bundle_name"
                control={control}
                autoCompleteFilter={bundleFilter}
                autoCompleteOptions={bundlesOptions}
                inputPlaceholder="Select or add a interview bundle"
                inputWidth="100%"
                inputHeight="40px"
                handleChange={handleChangeBundleName}
                propField="id"
                isError={isErrorBundle}
                setIsError={setIsErrorBundle}
                errorMsg="You need to select a bundle"
              />
            </Box>

            <Box
              sx={{
                alignSelf: 'stretch',
                maxHeight: '140px',
                overflowY: 'scroll',
                padding: '0px',
              }}
              className="scrollable-content"
            >
              {addedDocs?.map((doc: any, index: number) => {
                // folders and nested folders
                if (doc.folder) {
                  return (
                    <Box
                      key={doc.id}
                      sx={{
                        borderRadius: '12px',
                        background: '#F0F8FD',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '16px',
                        marginBottom: '16px',
                        '& > p': {
                          ...karlaProRegularFontStyles,
                          fontSize: '14px',
                          fontWeight: '400',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ marginRight: '5px', color: '#949CA9' }}
                        >
                          {index + 1}.
                        </Typography>
                        <Typography sx={{ color: '#464B53' }}>
                          {doc.folder && (
                            <span style={{ color: '#949CA9 !important' }}>
                              Folder -
                            </span>
                          )}{' '}
                          {doc.name}
                        </Typography>
                        <IconButton
                          data-testid="clear-folder-id"
                          aria-label="clear folder"
                          sx={{
                            padding: '0px',
                            '&:hover': { background: 'transparent' },
                            '& > span': {
                              fontSize: '16px',
                            },
                            marginLeft: 'auto',
                          }}
                          onClick={() => handleRemoveDoc(doc.id)}
                        >
                          <span className="material-icons-round">close</span>
                        </IconButton>
                      </Box>
                    </Box>
                  );
                }
                // plain document
                return (
                  <Box
                    key={doc.id}
                    sx={{
                      borderRadius: '12px',
                      background: '#F0F8FD',
                      display: 'inline-flex',
                      width: '100%',
                      height: '38px',
                      padding: '16px',
                      marginBottom: '16px',
                      alignItems: 'center',
                      '& > p': {
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        fontWeight: '400',
                      },
                    }}
                  >
                    <Typography sx={{ marginRight: '5px', color: '#949CA9' }}>
                      {index + 1}.
                    </Typography>
                    <Typography sx={{ color: '#464B53' }}>
                      {doc.name}
                    </Typography>
                    <IconButton
                      data-testid="clear-doc-id"
                      aria-label="clear doc added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                        marginLeft: 'auto',
                      }}
                      onClick={() => handleRemoveDoc(doc.id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                display: 'inherit',
                width: '100%',
                gap: '16px',
              }}
            >
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={handleClose}
                >
                  <Typography>Cancel</Typography>
                </Button>
              </Box>
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={handleSubmit}
                >
                  <Typography>Add</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentAddToInterviewDialog;
